import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as KalyanLiteSelector from "../../Store/KalyanLite/selectors";
import hobbiesicon from '../../Components/Kalyanlite/assets/Images/hobbiesicon.svg';
import musicgenresicon from '../../Components/Kalyanlite/assets/Images/musicgenresicon.svg';
import musicicon from '../../Components/Kalyanlite/assets/Images/musicicon.svg';
import bookicon from '../../Components/Kalyanlite/assets/Images/bookicon.svg';
import movieicon from '../../Components/Kalyanlite/assets/Images/movieicon.svg';
import typemovieicon from '../../Components/Kalyanlite/assets/Images/typemoiveicon.svg';
import sportsicon from '../../Components/Kalyanlite/assets/Images/sportsicon.svg';
import cuisineicon from '../../Components/Kalyanlite/assets/Images/cuisineicon.svg';
import languageicon from '../../Components/Kalyanlite/assets/Images/languageicon.svg';
import languageimage from '../../Components/Kalyanlite/assets/Images/languageimage.svg';
import physicalstatusIcon from '../../Components/Kalyanlite/assets/Images/physicalstatusIcon.svg';
import familystatusIcon from '../../Components/Kalyanlite/assets/Images/familyStatus.svg';
import familyTypeIcon from '../../Components/Kalyanlite/assets/Images/familyType.svg';
import familyValueIcon from '../../Components/Kalyanlite/assets/Images/familyValues.svg';
import parentsIncomeIcon from '../../Components/Kalyanlite/assets/Images/parentsIncome.svg';

import EmployedType from '../../Components/Kalyanlite/assets/Images/EmployedIn.svg';
import Designation from '../../Components/Kalyanlite/assets/Images/designation.svg';

import FatherIcon from '../../Components/Kalyanlite/assets/Images/father.svg';
import MotherIcon from '../../Components/Kalyanlite/assets/Images/mother.svg';
import SisterBrotherIcon from '../../Components/Kalyanlite/assets/Images/sister_brother.svg';
import MarriedIcon from '../../Components/Kalyanlite/assets/Images/married.svg';

import { commaWithSpace, getIsLoginFromAdmin } from "../../Utils";

import { getDeviceInfo, getGroupAuthId, requestLookup } from './utils';
import Constants, { EventName } from '../../Constants/constants';
import { profileSendMessage } from '../../Store/Chats/Action';
import LoaderGif from "../../Gif/loader.gif";
import { storeRequestType } from '../../Store/KalyanLite/Action';
import { useAnalytics } from '../../Hooks/usePageChange';
import { CommonProfilePopup, ValidationNotificationPopup } from '../CommonPopup';
import { useTranslation } from 'react-i18next';
import * as Images from "../../Components/Kalyanlite/assets/images";
import BasicForm from '../Edit Profile/MyProfileComponents/BasicForm';
import EditProfile from '../../Pages/EditProfile';
import ProfileBasicComponent from '../Edit Profile/ProfileBasicComponent';
import ProfileBasicDetails from '../../Pages/AfterRegistation/ProfileBasicDetails';
import { NavBarContext } from '../../Contexts/NavBarContext';
import Loader from '../Loader';
import PreviewDescription from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewDescription';
import PreviewBasicForm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewBasicForm';
import PreviewReligiousForm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewReligiousForm';

import PreviewReligiousFPreviewAdditionalFormorm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewAdditionalForm';
import PreviewFamilyForm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewFamilyForm';
import PreviewHabbitsForm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewHabbitsForm';
import PreviewHobbiesForm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewHobbiesForm';
import PreviewHoroscope from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewHoroscope';
import PreviewProfessionalForm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewProfessionalForm';
import PreviewAdditionalForm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewAdditionalForm';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegment';
import { getDenominationList } from '../../Store/Home/Action';
import PreviewLocationForm from '../../Pages/AfterRegistation/ProfilepreviewComponents/PreviewLocationForm';




export const PreviewBasicDetails = ({ page, isFrom, editMyProfielData, apiCallEditProfile }) => {

    const profileData = useSelector(KalyanLiteSelector.getMatchProfileById)
    const { isDataLoading, setIsDataLoading } = useContext(NavBarContext)
    const userData = useSelector(
        (state) => state?.DashBoardAPI?.ProfileData?.data,
    );
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()



    const { t: trans } = useTranslation();


    console.log(editMyProfielData?.parentsMonthly, userData, "profileDataprofileData");

    const additionalDetails = editMyProfielData?.gender === "M" ? 'his_additional_details' : 'her_additional_details';
    const { showbasicdetails, setShowBasicdetails } = useContext(NavBarContext)

    const OnclickAddDetails = () => {
        setShowBasicdetails(true)
    }

    useEffect(() => {
        dispatch(getDenominationList())
    }, [])


    console.log(editMyProfielData?.hobbiesInterest?.hobbiesAndInterest, "editMyProfielData?.hobbiesInterest?.hobbiesAndInterest");

    const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);


    return (
        <>

            {isDataLoading ? <Loader /> :
                <div className={` ${isFrom !== 'ProfilePreview' ? 'lg:bg-[#F0F2F5] bg-[#ffffff] lg:px-8  mt-[2rem] md:mt-[-4rem] lg:mt-0 space-y-4 md:px-5 mb-5 md:mb-8 text-[13px] mx-3 '
                    : 'bg-[#ffffff] md:mx-[3rem] lg:mx-[5rem]'} `}>
                    {page === "chat" ? <p className='border-[#E9E9E9] border-b-[2px] w-full pt-[3rem] hidden lg:block md:hidden'></p> : null}
                    {((profileData?.aboutprofile == null || profileData?.aboutprofile === 'Not Specified' || profileData?.aboutprofile === "requested" || profileData?.aboutprofile.trim() == "") && isFrom !== 'ProfilePreview') ?
                        (
                            // <div className='lg:mt-5 md:mt-24'></div>
                            <>
                                {isFrom == "ProfilePreview" ?
                                    <RequestItem
                                        className={"flex md:p-2  py-1 lg:mt-5 md:mt-24"}
                                        // icon={"/Assets/Images/Group 274.svg"}
                                        title={trans('view_profile.about_me')}
                                        requestKey={"description"}
                                        value={profileData?.aboutprofile}
                                        keyName={"aboutprofile"}
                                        page={page}
                                        eventTrack={EventName.View_Profile_Not_Specified_field_request_Description_box_Button}
                                        style={{ marginRight: "20px" }}
                                        isFrom={"ProfilePreviewAdd"}


                                    /> :
                                    <RequestItem
                                        className={"flex md:p-2  py-1 lg:mt-5 md:mt-24"}
                                        // icon={"/Assets/Images/Group 274.svg"}
                                        title={trans('view_profile.about_me')}
                                        requestKey={"description"}
                                        value={profileData?.aboutprofile}
                                        keyName={"aboutprofile"}
                                        page={page}
                                        eventTrack={EventName.View_Profile_Not_Specified_field_request_Description_box_Button}
                                        style={{ marginRight: "20px" }}
                                    />}
                                <p className='border-[#E9E9E9] border-b-[2px] w-full pt-[.5rem] mb-4 md:mb-4 lg:mb-4'></p>
                            </>
                        ) :


                        isFrom == 'ProfilePreview' ?






                            <div className={`block  py-5 lg:py-0  ${showbasicdetails ? "lg:mx-[2rem]" : ""} ${page === "chat" ? "mt-1" : ""}`}>
                                <div className='flex justify-between items-center lg:items-baseline mb-2'>
                                    <p className={`px-4 md:px-1 text-[#575556] text-[20px] tracking-wide font-Poppins font-semibold lg:pt-[3rem]  lg:pt-0`}>{trans('view_profile.about_me')}</p>

                                    {showbasicdetails ? "" :
                                        <button onClick={OnclickAddDetails} className='flex gap-3 items-center hidden text-[12px] md:text-[15px] px-2 md:px-0 text-[#D10A11] font-bold cursor-pointer'>
                                            <img className='' src={Images.addgroupicon} />Add / Edit Details</button>}
                                </div> 

                                {showbasicdetails ?
                                    <PreviewDescription ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :
                                    <p className='px-4 md:px-1 text-[#1A1A1A] text-[12px] font-light font-Poppins-Regular '>
                                        {editMyProfielData?.moreInfo ||
                                            <RequestItem
                                                style={{ marginRight: "20px" }}
                                                isFrom={"ProfilePreviewAdd"}
                                                isFroms="aboutme"
                                            />
                                        }</p>}

                                <p className='border-[#E9E9E9] border-b-[2px] w-full pt-[1rem]'></p>


                            </div>
                            :
                            <div className={`hidden md:block  py-5 lg:py-0  ${showbasicdetails ? "lg:mx-[2rem]" : ""} ${page === "chat" ? "mt-1" : ""}`}>
                                <p className={`px-4 md:px-0 text-[#1A1A1A] text-[20px] tracking-wide font-Poppins font-semibold pt-[3rem]  lg:pt-0`}>{trans('view_profile.about_me')}</p>
                                <p className='px-4 md:px-0 text-[#1A1A1A] text-[12px] font-light font-Poppins-Regular pt-4'>{profileData?.aboutprofile || 'Not Specified'}</p>
                                <p className='border-[#E9E9E9] border-b-[2px] w-full pt-[1rem]'></p>


                            </div>

                    }

                    <div className="px-4 md:px-0">
                        <p className={`text-[#575556] text-[20px] mt-4 lg:text-[16px] ${isFrom == "ProfilePreview" && showbasicdetails ? "pl-0 md:pl-[1rem] mb-[2rem] md:mb-0 lg:pl-[3rem] mt-[2rem]" : ""} lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui`}>
                            {trans('view_profile.basic_details')}
                        </p>
                        {showbasicdetails ?
                            <PreviewBasicForm ProfileData={userData} ispaid={userData?.isPaid} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :

                            <div className='hidden md:block'>
                                <div className="grid grid-cols-2 py-2 lg:grid-cols-4 md:gap-4 lg:space-x-8">
                                    <DetailsItem
                                        className={"flex md:p-2 "}
                                        icon={"/Assets/Images/Group 503.svg"}
                                        title={trans('view_profile.age')}
                                        value={profileData?.basic_detailsprofile?.basicprofileage ?
                                            profileData?.basic_detailsprofile?.basicprofileage : editMyProfielData?.age
                                        }
                                        style={{ marginRight: "20px" }}
                                    />
                                    <DetailsItem
                                        className={
                                            "flex pl-[2rem] leading-[17px] mt-2 md:mt-0 md:leading-0 md:p-2"
                                        }
                                        icon={"/Assets/Images/Group 500.svg"}
                                        title={trans('view_profile.weight')}
                                        value={profileData?.basic_detailsprofile?.basicprofileweight ?
                                            profileData?.basic_detailsprofile?.basicprofileweight?.replaceAll(",", ",  ") : editMyProfielData?.weight?.replaceAll(",", ",  ")

                                        }
                                        style={{ marginRight: "20px" }}
                                    />
                                    <DetailsItem
                                        className={
                                            "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                                        }
                                        icon={"/Assets/Images/Group 272.svg"}
                                        title={trans('view_profile.marital_status')}
                                        value={profileData?.basic_detailsprofile?.basicprofilemartialstatus ?

                                            profileData?.basic_detailsprofile?.basicprofilemartialstatus : editMyProfielData?.maritalStatus?.[0]?.name
                                        }
                                        style={{ marginRight: "20px" }}
                                    />
                                    <DetailsItem
                                        className={
                                            "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 pl-[2rem] md:p-2"
                                        }
                                        icon={"/Assets/Images/Group 504.svg"}
                                        title={trans('view_profile.citizen')}
                                        value={profileData?.basic_detailsprofile?.basicprofilecitzen ?
                                            profileData?.basic_detailsprofile?.basicprofilecitzen : editMyProfielData?.country?.[0]?.name
                                        }
                                        style={{ marginRight: "20px" }}
                                    />
                                </div>
                                <div className='grid grid-cols-2 gap-4 py-2 lg:grid-cols-4 lg:space-x-8'>
                                    <DetailsItem
                                        className={"flex mt-[0.5rem] md:mt-0 md:p-2"}
                                        icon={"/Assets/Images/Group 505.svg"}
                                        title={trans('view_profile.lives_in')}
                                        value={`${editMyProfielData?.city?.[0]?.name || ''}, ${editMyProfielData?.state?.[0]?.name || ''}, ${editMyProfielData?.country?.[0]?.name || ''}`}

                                        style={{ marginRight: "20px" }}
                                    />
                                    <DetailsItem
                                        className={
                                            "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                                        }
                                        icon={Images.physicalStatusIcon}
                                        title={trans('view_profile.physical_status')}
                                        value={profileData?.basic_detailsprofile?.basicphysicalstatus ?
                                            profileData?.basic_detailsprofile?.basicphysicalstatus : editMyProfielData?.physicalStatus?.[0]?.name
                                        }
                                        style={{ marginRight: "20px" }}
                                        isFrom="physicalicon"
                                        classNames={" w-[2rem] h-[1.8rem] lg:w-[1rem] lg:h-[1rem] xl:w-[1.5rem] xl:h-[2rem]  "}
                                    />
                                    <DetailsItem
                                        className={
                                            "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                                        }
                                        icon={physicalstatusIcon}
                                        title={trans('view_profile.complexion')}
                                        value={profileData?.basic_detailsprofile?.basicprofilecomplexion ?
                                            profileData?.basic_detailsprofile?.basicprofilecomplexion :
                                            editMyProfielData?.complexion?.[0]?.name
                                        }
                                        style={{ marginRight: "20px" }}
                                        classNames={" w-[2rem] h-[1.8rem] lg:w-[1rem] lg:h-[1rem] xl:w-[1.5rem] xl:h-[2rem]  "}

                                    />
                                </div>
                            </div>
                        }
                        {showbasicdetails ?
                            "" :
                            <div className="grid grid-cols-2 gap-2 py-2 md:hidden lg:grid-cols-4 lg:gap-4 md:gap-4">
                                <DetailsItem
                                    className={"flex md:p-2 "}
                                    icon={"/Assets/Images/Group 503.svg"}
                                    title={trans('view_profile.age')}
                                    value={profileData?.basic_detailsprofile?.basicprofileage ?
                                        profileData?.basic_detailsprofile?.basicprofileage : editMyProfielData?.age
                                    }
                                />
                                <DetailsItem
                                    className={
                                        "flex pl-[2rem] leading-[17px] mt-2 md:mt-0 md:leading-0 md:p-2"
                                    }
                                    icon={"/Assets/Images/Group 500.svg"}
                                    title={trans('view_profile.weight')}
                                    value={profileData?.basic_detailsprofile?.basicprofileweight ?
                                        profileData?.basic_detailsprofile?.basicprofileweight?.replaceAll(",", ",  ") : editMyProfielData?.weight?.replaceAll(",", ",  ")

                                    }
                                />
                                <DetailsItem
                                    className={
                                        "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                                    }
                                    icon={"/Assets/Images/Group 272.svg"}
                                    title={trans('view_profile.marital_status')}
                                    value={profileData?.basic_detailsprofile?.basicprofilemartialstatus ?

                                        profileData?.basic_detailsprofile?.basicprofilemartialstatus : editMyProfielData?.maritalStatus?.[0]?.name
                                    }
                                />
                                <DetailsItem
                                    className={
                                        "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 pl-[2rem] md:p-2"
                                    }
                                    icon={"/Assets/Images/Group 504.svg"}
                                    title={trans('view_profile.citizen')}
                                    value={profileData?.basic_detailsprofile?.basicprofilecitzen ?
                                        profileData?.basic_detailsprofile?.basicprofilecitzen : editMyProfielData?.country?.[0]?.name
                                    }
                                />
                                <DetailsItem
                                    className={"flex mt-[0.5rem] md:mt-0 md:p-2"}
                                    icon={"/Assets/Images/Group 505.svg"}
                                    title={trans('view_profile.lives_in')}
                                    value={profileData?.basic_detailsprofile?.basicprofilelivesin ?
                                        profileData?.basic_detailsprofile?.basicprofilelivesin?.replaceAll(",", ",  ") :
                                        editMyProfielData?.city?.[0]?.name
                                    }
                                />
                                <DetailsItem
                                    className={
                                        "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                                    }
                                    icon={Images.physicalStatusIcon}
                                    title={trans('view_profile.physical_status')}
                                    value={profileData?.basic_detailsprofile?.basicphysicalstatus ?
                                        profileData?.basic_detailsprofile?.basicphysicalstatus : editMyProfielData?.physicalStatus?.[0]?.name
                                    }
                                    classNames={" w-[2rem] h-[1.8rem] lg:w-[1rem] lg:h-[1rem] xl:w-[1.5rem] xl:h-[2rem]  "}

                                />
                                <DetailsItem
                                    classNames={" w-[2rem] h-[1.8rem] lg:w-[1rem] lg:h-[1rem] xl:w-[1.5rem] xl:h-[2rem]  "}

                                    className={
                                        "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                                    }
                                    icon={physicalstatusIcon}
                                    title={trans('view_profile.complexion')}
                                    value={profileData?.basic_detailsprofile?.basicprofilecomplexion ?
                                        profileData?.basic_detailsprofile?.basicprofilecomplexion :
                                        editMyProfielData?.complexion?.[0]?.name
                                    }
                                />
                            </div>}

                        <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                        <div className='mt-4'>
                            <p className={`text-[#575556] text-[20px] lg:text-[16px] ${isFrom == "ProfilePreview" && showbasicdetails ? "lg:pl-[3rem] mb-[2rem] md:mb-0 p-0 md:pl-[1rem]  mt-[2rem]" : ""} lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui`}>
                                {editMyProfielData?.gender === "M"
                                    ? trans('view_profile.his_religious_background')
                                    : trans('view_profile.her_religious_background')}{" "}
                            </p>

                            {showbasicdetails ?
                                <PreviewReligiousForm ispaid={userData?.isPaid} ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :

                                editMyProfielData?.religion?.at(0)?.id == Constants.christialId ?
                                    <>
                                        <div className="grid grid-cols-1 gap-2 py-2 lg:grid-cols-4 md:grid-cols-2  lg:gap-4 md:gap-4 lg:space-x-8">
                                            <DetailsItem
                                                className={"flex md:p-2 py-1"}
                                                icon={"/Assets/Images/Group 506.svg"}
                                                title={trans('view_profile.religion__mother_tongue')}
                                                value={`${editMyProfielData?.religion?.at(0)?.name} - ${editMyProfielData?.motherTongue?.at(0)?.name}`}
                                                style={{ marginRight: "20px" }}
                                            />
                                            {editMyProfielData?.religion?.at(0)?.id == Constants.christialId && <DetailsItem
                                                className={"flex md:p-2 py-1"}
                                                icon={"/Assets/Images/Group 274.svg"}
                                                title={trans('view_profile.denomination')}
                                                value={editMyProfielData?.domain?.at(0)?.name}
                                                style={{ marginRight: "20px" }}
                                            />}
                                            <DetailsItem
                                                className={"flex md:p-2 py-1"}
                                                icon={"/Assets/Images/Group 274.svg"}
                                                title={trans('view_profile.caste')}
                                                value={editMyProfielData?.otherCaste ? editMyProfielData?.otherCaste : editMyProfielData?.caste?.at(0)?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                            {
                                                editMyProfielData?.otherSubCaste
                                                    ? <DetailsItem
                                                        className={"flex md:p-2  py-1"}
                                                        icon={"/Assets/Images/Group 274.svg"}
                                                        title={trans('view_profile.sub_caste')}
                                                        value={editMyProfielData?.otherSubCaste
                                                        }
                                                        style={{ marginRight: "20px" }}
                                                    />
                                                    : editMyProfielData?.subCaste?.length > 0 ||
                                                        editMyProfielData?.subCaste != ""
                                                        ? <DetailsItem
                                                            className={"flex md:p-2  py-1"}
                                                            icon={"/Assets/Images/Group 274.svg"}
                                                            title={trans('view_profile.sub_caste')}
                                                            value={editMyProfielData?.subCaste?.at(0)?.name}
                                                            style={{ marginRight: "20px" }}
                                                        />
                                                        :
                                                        <RequestItem
                                                            className={"flex md:p-2  py-1"}
                                                            icon={"/Assets/Images/Group 274.svg"}
                                                            title={trans('view_profile.sub_caste')}
                                                            requestKey={"Sub Caste"}
                                                            value={editMyProfielData?.subCaste?.at(0)?.name}
                                                            keyName={"religious?.subcaste"}
                                                            page={page}
                                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_SubCaste_Button}
                                                            style={{ marginRight: "20px" }}
                                                            isFrom="ProfilePreviewAdd"
                                                        />
                                            }
                                        </div>
                                        {editMyProfielData?.gothra?.at(0)?.name &&

                                            <div className="grid grid-cols-1 py-2 lg:grid-cols-4 md:grid-cols-2 lg:pb-2 lg:pt-0 lg:space-x-8">
                                                <DetailsItem
                                                    className={"flex md:p-2  py-1"}
                                                    icon={"/Assets/Images/Group 274.svg"}
                                                    title={trans('view_profile.gothram')}
                                                    value={editMyProfielData?.gothra?.[0]?.name}
                                                    style={{ marginRight: "20px" }}
                                                />
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className="grid grid-cols-1 gap-2 py-3 lg:grid-cols-4 md:grid-cols-2 lg:gap-4 md:gap-4 lg:space-x-8">
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={"/Assets/Images/Group 506.svg"}
                                            title={trans('view_profile.religion__mother_tongue')}
                                            value={`${editMyProfielData?.religion?.at(0)?.name} - ${editMyProfielData?.motherTongue?.at(0)?.name}`}
                                            style={{ marginRight: "20px" }}
                                        />
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={"/Assets/Images/Group 274.svg"}
                                            title={trans('view_profile.caste')}
                                            value={editMyProfielData?.caste?.[0]?.name == "Others" ? editMyProfielData?.otherCaste : editMyProfielData?.caste?.[0]?.name}
                                            style={{ marginRight: "20px" }}
                                        />
                                        {editMyProfielData?.otherSubCaste
                                            ? <DetailsItem
                                                className={"flex md:p-2  py-1"}
                                                icon={"/Assets/Images/Group 274.svg"}
                                                title={trans('view_profile.sub_caste')}
                                                value={editMyProfielData?.otherSubCaste
                                                }
                                                style={{ marginRight: "20px" }}
                                            />
                                            : editMyProfielData?.subCaste?.length > 0 ||
                                                editMyProfielData?.subCaste != "" ?
                                                <DetailsItem
                                                    className={"flex md:p-2  py-1"}
                                                    icon={"/Assets/Images/Group 274.svg"}
                                                    title={trans('view_profile.sub_caste')}
                                                    value={

                                                        editMyProfielData?.subCaste?.[0]?.name}
                                                    style={{ marginRight: "20px" }}
                                                />
                                                :
                                                <RequestItem
                                                    className={"flex md:p-2  py-1"}
                                                    icon={"/Assets/Images/Group 274.svg"}
                                                    title={trans('view_profile.sub_caste')}
                                                    requestKey={"Sub Caste"}
                                                    value={editMyProfielData?.subCaste?.[0]?.name}
                                                    keyName={"religious?.subcaste"}
                                                    page={page}
                                                    eventTrack={EventName.View_Profile_Not_Specified_field_request_SubCaste_Button}
                                                    style={{ marginRight: "20px" }}
                                                    isFrom="ProfilePreviewAdd"
                                                />
                                        }
                                        {editMyProfielData?.gothra?.at(0)?.name ? (
                                            <DetailsItem
                                                className={"flex md:p-2  py-1"}
                                                icon={"/Assets/Images/Group 274.svg"}
                                                title={trans('view_profile.gothram')}
                                                value={editMyProfielData?.gothra?.at(0)?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) : <></>}
                                    </div>
                            }
                        </div>
                        <>
                            <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                            <div className='mt-4'>
                                <p className={`text-[#575556] text-[20px] lg:text-[16px] ${isFrom == "ProfilePreview" && showbasicdetails ? " lg:pl-[3rem] mb-[2rem] md:mb-0 p-0 md:pl-[1rem]  mt-[2rem]" : ""} lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui`}>
                                    {trans('view_profile.horoscope')}
                                </p>
                                {showbasicdetails ?
                                    <PreviewHoroscope ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :
                                    <div className="grid lg:grid-cols-4 grid-cols-2 py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8">
                                        {((

                                            editMyProfielData?.star?.length != 0)) ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 511.svg"}
                                                title={trans('view_profile.star')}
                                                value={profileData?.horoscope?.star ? profileData?.horoscope?.star : editMyProfielData?.star?.[0]?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) :
                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 511.svg"}
                                                title={trans('view_profile.star')}
                                                requestKey={"star"}
                                                value={profileData?.horoscope?.star ? profileData?.horoscope?.star : editMyProfielData?.star?.[0]?.name}
                                                keyName={"horoscope?.star"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Star_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />
                                        }
                                        {((editMyProfielData?.raasi?.length != 0
                                        )) ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 512.svg"}
                                                title={trans('view_profile.raasi')}
                                                value={profileData?.horoscope?.rashi ? profileData?.horoscope?.rashi : editMyProfielData?.raasi?.[0]?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) :
                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 512.svg"}
                                                title={trans('view_profile.raasi')}
                                                requestKey={"raasi"}
                                                value={profileData?.horoscope?.rashi ? profileData?.horoscope?.rashi : editMyProfielData?.raasi?.[0]?.name}
                                                keyName={"horoscope?.rashi"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Raasi_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />
                                        }
                                        {((editMyProfielData?.dosham?.length != 0)) ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 513.svg"}
                                                title={trans('view_profile.dosham')}
                                                value={profileData?.horoscope?.dosham ? profileData?.horoscope?.dosham : editMyProfielData?.dosham?.[0]?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) :
                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 513.svg"}
                                                title={trans('view_profile.dosham')}
                                                requestKey={"dosham"}
                                                value={profileData?.horoscope?.dosham ? profileData?.horoscope?.dosham : editMyProfielData?.dosham?.[0]?.name}
                                                keyName={"horoscope?.dosham"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_ChevvaiDosham_Mangalik_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />

                                        }
                                    </div>}
                            </div></>




                        <>
                            <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                            <div className='mt-4'>
                                <p className={`text-[#575556] text-[20px] lg:text-[16px] ${isFrom == "ProfilePreview" && showbasicdetails ? " lg:pl-[3rem] p-0 md:pl-[1rem] mb-[2rem] md:mb-0 mt-[2rem]" : ""} lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui`}>
                                    {trans('view_profile.family_details')}
                                </p>
                                {showbasicdetails ?
                                    <PreviewFamilyForm ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :
                                    <div className="grid lg:grid-cols-4 grid-cols-2 py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8">


                                        {editMyProfielData?.familyStatus?.length != 0 ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={familystatusIcon}
                                                title={trans('view_profile.family_status')}
                                                value={profileData?.familydetails?.familystatus ? profileData?.familydetails?.familystatus : editMyProfielData?.familyStatus?.[0]?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        )

                                            :

                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={familystatusIcon}
                                                title={trans('view_profile.family_status')}
                                                requestKey={"family_status"}
                                                value={profileData?.familydetails?.familystatus ? profileData?.familydetails?.familystatus : editMyProfielData?.familyStatus?.[0]?.name}
                                                keyName={"familydetails?.familystatus"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_FamilyStatus_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />}



                                        {((editMyProfielData?.familyType != 0 || editMyProfielData?.familyType != "")) ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={familyTypeIcon}
                                                title={trans('view_profile.family_type')}
                                                value={profileData?.familydetails?.familytype ? profileData?.familydetails?.familytype : editMyProfielData?.familyType?.[0]?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) :
                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={familyTypeIcon}
                                                title={trans('view_profile.family_type')}
                                                requestKey={"family_type"}
                                                value={profileData?.familydetails?.familytype ? profileData?.familydetails?.familytype : editMyProfielData?.familyType?.[0]?.name}
                                                keyName={"familydetails?.familytype"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_FamilyType_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />
                                        }
                                        {((editMyProfielData?.familyValues != "" || editMyProfielData?.familyValues?.length != 0)) ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={familyValueIcon}
                                                title={trans('view_profile.family_values')}
                                                value={profileData?.familydetails?.familyvalues ? profileData?.familydetails?.familyvalues : editMyProfielData?.familyValues?.[0]?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) :
                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={familyValueIcon}
                                                title={trans('view_profile.family_values')}
                                                requestKey={"family_values"}
                                                value={profileData?.familydetails?.familyvalues ? profileData?.familydetails?.familyvalues : editMyProfielData?.familyType?.[0]?.name}
                                                keyName={"familydetails?.familyvalues"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_FamilyValues_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />
                                        }
                                        {editMyProfielData?.parentsMonthly || editMyProfielData?.parentsMonthly != "" || editMyProfielData?.parentsMonthly?.length != 0
                                            ? (
                                                <DetailsItem
                                                    className={"flex md:p-2 "}
                                                    icon={parentsIncomeIcon}
                                                    title={trans('view_profile.parents_income')}
                                                    value={editMyProfielData?.parentsMonthly}
                                                    style={{ marginRight: "20px" }}
                                                />
                                            ) :

                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={parentsIncomeIcon}
                                                title={trans('view_profile.parents_income')}
                                                requestKey={"family_monthly_income"}
                                                value={editMyProfielData?.parentsMonthly}
                                                keyName={"familydetails?.parentsmonthlyincome"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_ParentsMonthlyIncome_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />
                                        }
                                    </div>}
                            </div>
                        </>

                        
                        {showbasicdetails ? 
                        <><p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                            <div className='mt-4'>
                                <p className={`text-[#575556] text-[20px] lg:text-[16px] ${isFrom == "ProfilePreview" && showbasicdetails ? " lg:pl-[3rem] p-0 md:pl-[1rem] mb-[2rem] md:mb-0 mt-[2rem]" : ""} lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui`}>
                                    {"Location"}
                                </p>
                               
                                    <PreviewLocationForm ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} />
                                   
                                
                            </div></>
                            :""
                        }
                        




                        <>
                            <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                            <div className="mt-4 md:pt-0">
                                <p className={`text-[#575556] text-[20px] lg:text-[16px]  ${isFrom == "ProfilePreview" && showbasicdetails ? "lg:pl-[3rem] md:pl-[1rem]  mb-[2rem] md:mb-0 pl-0 mt-[2rem]" : ""} lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui`}>
                                    {trans('view_profile.food_and_habits')}
                                </p>
                                {showbasicdetails ?
                                    <PreviewHabbitsForm ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :
                                    <div className="grid lg:grid-cols-4 grid-cols-2 py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8">
                                        {((editMyProfielData?.food?.length != 0
                                        )) ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 509.svg"}
                                                title={trans('view_profile.food_habits')}
                                                value={
                                                    profileData?.foodandhabits?.dietPreferences ? profileData?.foodandhabits?.dietPreferences : editMyProfielData?.food?.[0]?.name
                                                }
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) :
                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 509.svg"}
                                                title={trans('view_profile.food_habits')}
                                                requestKey={"food_habits"}
                                                value={
                                                    profileData?.foodandhabits?.dietPreferences ? profileData?.foodandhabits?.dietPreferences : editMyProfielData?.food?.[0]?.name
                                                } keyName={"foodandhabits?.dietPreferences"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_FoodHabits_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />
                                        }
                                        {((editMyProfielData?.drinking?.length != 0)) ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 507 (1).svg"}
                                                title={trans('view_profile.drinking')}
                                                value={profileData?.foodandhabits?.drinking ? profileData?.foodandhabits?.drinking : editMyProfielData?.drinking?.[0]?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) :
                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 507 (1).svg"}
                                                title={trans('view_profile.drinking')}
                                                requestKey={"drinking"}
                                                value={profileData?.foodandhabits?.drinking ? profileData?.foodandhabits?.drinking : editMyProfielData?.drinking?.[0]?.name}
                                                keyName={"foodandhabits?.drinking"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Drinking_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />
                                        }
                                        {((editMyProfielData?.smoking?.length != 0)) ? (
                                            <DetailsItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 508.svg"}
                                                title={trans('view_profile.smoking')}
                                                value={profileData?.foodandhabits?.Smoking ? profileData?.foodandhabits?.Smoking : editMyProfielData?.smoking?.[0]?.name}
                                                style={{ marginRight: "20px" }}
                                            />
                                        ) :
                                            <RequestItem
                                                className={"flex md:p-2 "}
                                                icon={"/Assets/Images/Group 508.svg"}
                                                title={trans('view_profile.smoking')}
                                                requestKey={"smoking"}
                                                value={profileData?.foodandhabits?.Smoking ? profileData?.foodandhabits?.Smoking : editMyProfielData?.smoking?.[0]?.name}
                                                keyName={"foodandhabits?.Smoking"}
                                                page={page}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Smoking_Button}
                                                style={{ marginRight: "20px" }}
                                                isFrom="ProfilePreviewAdd"
                                            />
                                        }
                                    </div>
                                }
                            </div></>
                        {/* } */}
                        <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                        <p className={`text-[#575556] md:pt-0 text-[20px] md:text-[17px] ${isFrom == "ProfilePreview" && showbasicdetails ? "md:pl-[1rem] lg:pl-[3rem] mb-[2rem] md:mb-0 mt-[2rem]" : ""} lg:text-[16px] xl:text-[18px] font-bold mt-4 font-segoe-ui`}>
                            {editMyProfielData?.gender === "M"
                                ? trans('view_profile.his_professional_details')
                                : trans('view_profile.her_professional_details')}{" "}
                        </p>
                        {showbasicdetails ?
                            <PreviewProfessionalForm ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :
                            <div className="grid grid-cols-1 gap-2 py-3 lg:grid-cols-4 md:grid-cols-2 lg:gap-4 md:gap-4 lg:space-x-8">
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    icon={"/Assets/Images/Group 514.svg"}
                                    title={trans('view_profile.degree')}
                                    value={profileData?.professionaldetails?.degree ? profileData?.professionaldetails?.degree : editMyProfielData?.education?.[0]?.name}
                                    style={{ marginRight: "20px" }}
                                />
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    icon={EmployedType}
                                    title={trans('view_profile.employment_type')}
                                    value={
                                        profileData?.professionaldetails?.employementType ? profileData?.professionaldetails?.employementType : editMyProfielData?.employedIn?.[0]?.name
                                    }
                                    style={{ marginRight: "20px" }}
                                    // classNames={" w-[1.5rem] h-[1.5rem] lg:w-[1.5rem] lg:h-[1.5rem] xl:w-[5rem] xl:h-[2.5rem]   "}
                                    isFrom="physicalicon"
                                />
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    icon={Designation}
                                    title={trans('view_profile.designation')}
                                    value={
                                        profileData?.professionaldetails?.designation ? profileData?.professionaldetails?.designation : editMyProfielData?.occupation?.[0]?.name
                                    }
                                    style={{ marginRight: "20px" }}
                                />
                                {((
                                    editMyProfielData?.monthlyIncome != "" || editMyProfielData?.monthlyIncome
                                )) ? (
                                    <DetailsItem
                                        className={"flex md:p-2 py-1"}
                                        icon={"/Assets/Images/Group 499.svg"}
                                        title={trans('view_profile.monthly_income')}
                                        value={
                                            profileData?.professionaldetails?.AnnualIncome ? profileData?.professionaldetails?.AnnualIncome : editMyProfielData?.monthlyIncome
                                        }
                                        style={{ marginRight: "20px" }}
                                    />
                                ) :
                                    <RequestItem
                                        className={"flex md:p-2 py-1"}
                                        icon={"/Assets/Images/Group 499.svg"}
                                        title={trans('view_profile.monthly_income')}
                                        requestKey={"Monthly Income"}
                                        value={
                                            profileData?.professionaldetails?.AnnualIncome ? profileData?.professionaldetails?.AnnualIncome : editMyProfielData?.monthlyIncome
                                        }
                                        keyName={"professionaldetails?.AnnualIncome"}
                                        page={page}
                                        eventTrack={EventName.View_Profile_Not_Specified_field_request_MonthlyIncome_Button}
                                        style={{ marginRight: "20px" }}
                                        isFrom="ProfilePreviewAdd"
                                    />

                                }

                            </div>
                        }

                        {/* {isHobbiesEmpty
                    ? null
                    :  */}
                        <>
                            <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                            <p className={`text-[#575556] md:pt-0 text-[20px] lg:text-[16px] ${isFrom == "ProfilePreview" && showbasicdetails ? "md:pl-[1.5rem] mb-[2rem] md:mb-0 lg:pl-[3rem] mt-[2rem]" : ""} md:text-[17px] lg:text-[16px] xl:text-[18px] font-bold mt-4 font-segoe-ui`}>
                                {trans('view_profile.hobbies__interests')}
                            </p>
                            {showbasicdetails ?
                                <PreviewHobbiesForm ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :
                                <div className="grid lg:grid-cols-2 sm:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 sm:gap-4 gap-4 py-2 mt-2">
                                    {((editMyProfielData?.hobbiesInterest?.hobbiesAndInterest != "" || editMyProfielData?.hobbiesInterest?.hobbiesAndInterest
                                    )) ? (
                                        <HobbiesDetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={hobbiesicon}
                                            title={trans('view_profile.hobbies__interests')}
                                            value={profileData?.hobbiesInterest?.hobbiesAndInterest ?
                                                profileData?.hobbiesInterest?.hobbiesAndInterest : editMyProfielData?.hobbiesInterest ? editMyProfielData?.hobbiesInterest?.hobbiesAndInterest : 'Not Specified'}
                                            style={{ marginRight: "20px" }}
                                            classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}

                                        />
                                    ) :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={hobbiesicon}
                                            title={trans('view_profile.hobbies__interests')}
                                            requestKey={"hobbies_and_interests"}
                                            value={profileData?.hobbiesInterest?.hobbiesAndInterest ? profileData?.hobbiesInterest?.hobbiesAndInterest : editMyProfielData?.hobbiesInterest?.hobbiesAndInterest}
                                            keyName={"hobbiesInterest?.hobbiesAndInterest"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                            classNames={"w-[1.5rem] h-[1.5rem] lg:w-[1.5rem] lg:h-[1.5rem] xl:w-[5rem] xl:h-[2.5rem] "}
                                            isType="hobbies"
                                        />
                                    }
                                    {((editMyProfielData?.hobbiesInterest?.musicListen || editMyProfielData?.hobbiesInterest?.musicListen != "")) ? (
                                        <HobbiesDetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={musicgenresicon}
                                            title={editMyProfielData?.gender === "M" ? trans('view_profile.music_genres_he_listen_to') : trans('view_profile.music_genres_she_listen_to')}
                                            value={
                                                profileData?.hobbiesInterest?.musicListen ?
                                                    profileData?.hobbiesInterest?.musicListen : editMyProfielData?.hobbiesInterest ? editMyProfielData?.hobbiesInterest?.musicListen : 'Not Specified'
                                            }
                                            style={{ marginRight: "20px" }}
                                            classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}
                                        />
                                    ) :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={musicgenresicon}
                                            title={editMyProfielData?.gender === "M" ? trans('view_profile.music_genres_he_listen_to') : trans('view_profile.music_genres_she_listen_to')}
                                            requestKey={"music_genres_you_listen_to"}
                                            value={profileData?.hobbiesInterest?.musicListen ? profileData?.hobbiesInterest?.musicListen : editMyProfielData?.hobbiesInterest?.musicListen}
                                            keyName={"hobbiesInterest?.musicListen"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                            classNames={"w-[1.5rem] h-[1.5rem] lg:w-[1.5rem] lg:h-[1.5rem] xl:w-[5rem] xl:h-[2.5rem] "}
                                            isType="hobbies"
                                        />
                                    }
                                    {((editMyProfielData?.hobbiesInterest?.favoriteMusicDirectors != '' || editMyProfielData?.hobbiesInterest?.favoriteMusicDirectors)) ? (
                                        <HobbiesDetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={musicicon}
                                            title={trans('view_profile.favorite_music_directors')}
                                            value={
                                                profileData?.hobbiesInterest?.favoriteMusicDirectors ?
                                                    profileData?.hobbiesInterest?.favoriteMusicDirectors : editMyProfielData?.hobbiesInterest ? editMyProfielData?.hobbiesInterest?.favoriteMusicDirectors : 'Not Specified'
                                            }
                                            style={{ marginRight: "20px" }}
                                            classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}
                                        />
                                    ) :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={musicicon}
                                            title={trans('view_profile.favorite_music_directors')}
                                            requestKey={"favourite_music_directors"}
                                            value={profileData?.hobbiesInterest?.favoriteMusicDirectors ? profileData?.hobbiesInterest?.favoriteMusicDirectors : editMyProfielData?.hobbiesInterest?.favoriteMusicDirectors}
                                            keyName={"hobbiesInterest?.favoriteMusicDirectors"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                            classNames={"w-[1.5rem] h-[1.5rem] lg:w-[1.5rem] lg:h-[1.5rem] xl:w-[5rem] xl:h-[2.5rem] "}
                                            isType="hobbies"
                                        />
                                    }
                                    {((editMyProfielData?.hobbiesInterest?.TypeofBooksYouRead != '' || editMyProfielData?.hobbiesInterest?.TypeofBooksYouRead)) ? (
                                        <HobbiesDetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={bookicon}
                                            title={editMyProfielData?.gender === "M" ? trans('view_profile.books_he_read') : trans('view_profile.books_she_read')}
                                            value={
                                                profileData?.hobbiesInterest?.TypeofBooksYouRead ?
                                                    profileData?.hobbiesInterest?.TypeofBooksYouRead : editMyProfielData?.hobbiesInterest ? editMyProfielData?.hobbiesInterest?.TypeofBooksYouRead : 'Not Specified'
                                            }
                                            style={{ marginRight: "20px" }}
                                            classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}


                                        />
                                    ) :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={bookicon}
                                            title={editMyProfielData?.gender === "M" ? trans('view_profile.books_he_read') : trans('view_profile.books_she_read')}
                                            requestKey={"type_of_books_you_read"}
                                            value={profileData?.hobbiesInterest?.TypeofBooksYouRead ? profileData?.hobbiesInterest?.TypeofBooksYouRead : editMyProfielData?.hobbiesInterest?.TypeofBooksYouRead}
                                            keyName={"hobbiesInterest?.TypeofBooksYouRead"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                            classNames={"w-[2.5rem] h-[1.5rem] md:h-[3rem] md:w-[3rem] lg:w-[1.5rem] lg:h-[1.5rem] xl:w-[5rem] xl:h-[2.5rem] "}
                                            isType="hobbies"
                                        />
                                    }
                                    {((editMyProfielData?.hobbiesInterest?.TypeOfMoviesYouWatch != '' || editMyProfielData?.hobbiesInterest?.TypeOfMoviesYouWatch)) ? (
                                        <HobbiesDetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={typemovieicon}
                                            title={editMyProfielData?.gender === "M" ? trans('view_profile.movies_he_watch') : trans('view_profile.movies_she_watch')}
                                            value={
                                                profileData?.hobbiesInterest?.TypeOfMoviesYouWatch ?
                                                    profileData?.hobbiesInterest?.TypeOfMoviesYouWatch : editMyProfielData?.hobbiesInterest?.TypeOfMoviesYouWatch ? editMyProfielData?.hobbiesInterest?.TypeOfMoviesYouWatch : 'Not Specified'
                                            }
                                            style={{ marginRight: "20px" }}
                                            classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}
                                        />
                                    ) :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={typemovieicon}
                                            title={editMyProfielData?.gender === "M" ? trans('view_profile.movies_he_watch') : trans('view_profile.movies_she_watch')} requestKey={"type_of_movies_you_watch"}
                                            value={profileData?.hobbiesInterest?.TypeOfMoviesYouWatch ? profileData?.hobbiesInterest?.TypeOfMoviesYouWatch : editMyProfielData?.hobbiesInterest?.TypeOfMoviesYouWatch}
                                            keyName={"hobbiesInterest?.TypeOfMoviesYouWatch"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                            classNames={"w-[3rem] h-[2.3rem] md:h-[2rem] md:h-[3rem] lg:w-[2rem] lg:h-[2.3rem] xl:w-[3rem] xl:h-[3rem]  "}
                                            isType="hobbies"
                                        />
                                    }
                                    {((editMyProfielData?.hobbiesInterest?.FavoriteMovieDirectors != ''
                                        || editMyProfielData?.hobbiesInterest?.FavoriteMovieDirectors
                                    )) ? (
                                        <HobbiesDetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={movieicon}
                                            title={trans('view_profile.favorite_movie_directors')}
                                            value={
                                                profileData?.hobbiesInterest?.FavoriteMovieDirectors ?
                                                    profileData?.hobbiesInterest?.FavoriteMovieDirectors : editMyProfielData?.hobbiesInterest?.FavoriteMovieDirectors ? editMyProfielData?.hobbiesInterest?.FavoriteMovieDirectors : 'Not Specified'
                                            }
                                            style={{ marginRight: "20px" }}
                                            classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}
                                        />
                                    ) :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={movieicon}
                                            title={trans('view_profile.favorite_movie_directors')}
                                            requestKey={"favourite_movie_directors"}
                                            value={profileData?.hobbiesInterest?.FavoriteMovieDirectors ? profileData?.hobbiesInterest?.FavoriteMovieDirectors : editMyProfielData?.hobbiesInterest?.FavoriteMovieDirectors}
                                            keyName={"hobbiesInterest?.FavoriteMovieDirectors"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                            classNames={"w-[2.5rem] h-[2.3rem] md:w-[3rem] md:h-[3rem] lg:w-[2rem] lg:h-[2.3rem] xl:w-[3rem] xl:h-[3.5rem] "}
                                            isType="hobbies"
                                        />
                                    }
                                    {((editMyProfielData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames != ''
                                        || editMyProfielData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames
                                    )) ? (
                                        <HobbiesDetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={sportsicon}
                                            title={trans('view_profile.sports_fitness_activities_game')}
                                            value={
                                                editMyProfielData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames ?
                                                    editMyProfielData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames : 'Not Specified'
                                            }
                                            style={{ marginRight: "20px" }}
                                            classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}
                                        />
                                    ) :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={sportsicon}
                                            title={trans('view_profile.sports_fitness_activities_game')}
                                            requestKey={"sports_fitness_activities_and_games"}
                                            value={editMyProfielData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames}
                                            page={page}
                                            keyName={"hobbiesInterest?.SportsAndFitnessActivitiesAndGames"}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                            classNames={"w-[1.5rem] h-[1.5rem] lg:w-[1.5rem] lg:h-[1.5rem] xl:w-[5rem] xl:h-[2.5rem] "}
                                            isType="hobbies"

                                        />
                                    }
                                    {((editMyProfielData?.hobbiesInterest?.FavouriteCuisine != '' ||
                                        editMyProfielData?.hobbiesInterest?.FavouriteCuisine
                                    )) ? (
                                        <HobbiesDetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={cuisineicon}
                                            title={trans('view_profile.favourite_cuisine')}
                                            value={
                                                editMyProfielData?.hobbiesInterest?.FavouriteCuisine ?
                                                    editMyProfielData?.hobbiesInterest?.FavouriteCuisine : 'Not Specified'
                                            }
                                            style={{ marginRight: "20px" }}
                                            classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}
                                        />
                                    ) :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={cuisineicon}
                                            title={trans('view_profile.favourite_cuisine')}
                                            requestKey={"favourite_cuisine"}
                                            value={profileData?.hobbiesInterest?.FavouriteCuisine ? profileData?.hobbiesInterest?.FavouriteCuisine : editMyProfielData?.hobbiesInterest?.FavouriteCuisine}
                                            keyName={"hobbiesInterest?.FavouriteCuisine"}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                            page={page}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                            classNames={"w-[2.5rem] h-[2.3rem] md:w-[3rem] md:h-[3rem] lg:w-[2rem] lg:h-[2rem] xl:w-[3rem] xl:h-[3rem] "}
                                            isType="hobbies"
                                        />
                                    }
                                    {editMyProfielData?.motherTongue[0]?.name.includes("Malayalam") ?
                                        ((editMyProfielData?.hobbiesInterest?.languagesYouKnow != ''
                                            || editMyProfielData?.hobbiesInterest?.languagesYouKnow
                                        )) ? (
                                            <HobbiesDetailsItem
                                                className={"flex md:p-2 py-1"}
                                                icon={languageimage}
                                                title={editMyProfielData?.gender === "M" ? trans('view_profile.languages_he_know') : trans('view_profile.languages_she_know')}
                                                value={
                                                    editMyProfielData?.hobbiesInterest?.languagesYouKnow ?
                                                        editMyProfielData?.hobbiesInterest?.languagesYouKnow : 'Not Specified'
                                                }
                                                style={{ marginRight: "20px" }}
                                                classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}
                                            />) :

                                            <RequestItem
                                                className={"flex md:p-2 py-1"}
                                                icon={languageimage}
                                                title={editMyProfielData?.gender === "M" ? trans('view_profile.languages_he_know') : trans('view_profile.languages_she_know')} requestKey={"languages_you_know"}
                                                value={editMyProfielData?.hobbiesInterest?.languagesYouKnow}
                                                keyName={"hobbiesInterest?.languagesYouKnow"}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                                page={page}
                                                style={{ marginRight: "20px" }}
                                                classNames={"w-[2.5rem] h-[2.3rem] md:w-[3rem] md:h-[3rem] lg:w-[2rem] lg:h-[2rem] xl:w-[3rem] xl:h-[3.5rem] "}
                                                isFrom="ProfilePreviewAdd"
                                                isType="hobbies"
                                            />
                                        : ""}
                                    {editMyProfielData?.motherTongue[0]?.name.includes("Malayalam") ?
                                        ((editMyProfielData?.hobbiesInterest?.languagesThatIwishToKnow != '' ||
                                            editMyProfielData?.hobbiesInterest?.languagesThatIwishToKnow
                                        )) ? (
                                            <HobbiesDetailsItem
                                                className={"flex md:p-2 py-1"}
                                                icon={languageicon}
                                                title={editMyProfielData?.gender === "M" ? trans('view_profile.languages_that_he_wish_to_know') : trans('view_profile.languages_that_she_wish_to_kno')} value={
                                                    editMyProfielData?.hobbiesInterest?.languagesThatIwishToKnow ?
                                                        editMyProfielData?.hobbiesInterest?.languagesThatIwishToKnow : 'Not Specified'
                                                }
                                                style={{ marginRight: "20px" }}
                                                classNames={" w-[2.3rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem] "}
                                            />) :

                                            <RequestItem
                                                className={"flex md:p-2 py-1"}
                                                icon={languageicon}
                                                title={editMyProfielData?.gender === "M" ? trans('view_profile.languages_that_he_wish_to_know') : trans('view_profile.languages_that_she_wish_to_kno')} requestKey={"languages_that_i_wish_to_know"}
                                                value={editMyProfielData?.hobbiesInterest?.languagesThatIwishToKnow}
                                                keyName={"hobbiesInterest?.languagesThatIwishToKnow"}
                                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                                page={page}
                                                style={{ marginRight: "20px" }}
                                                classNames={"w-[2.5rem] h-[2.3rem] md:w-[3rem] md:h-[3rem] lg:w-[2rem] lg:h-[2rem] xl:w-[3rem] xl:h-[3.5rem] "}
                                                isFrom="ProfilePreviewAdd"
                                                isType="hobbies"
                                            />
                                        : ""}

                                </div>}
                        </>
                        {/* } */}


                        <><p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                            <p className={`text-[#575556] md:pt-0 text-[20px] lg:text-[16px] ${isFrom == "ProfilePreview" && showbasicdetails ? "mt-[2rem] lg:pl-[3rem] md:pl-[1rem]" : ""} lg:text-[16px] xl:text-[18px] font-bold mt-4 font-segoe-ui`}>
                                {editMyProfielData?.gender === "M"
                                    ? trans('view_profile.his_additional_details') : trans('view_profile.her_additional_details')}{" "}
                            </p></>
                        {showbasicdetails ?
                            <PreviewAdditionalForm ProfileData={userData} editMyProfileData={editMyProfielData} apiCallEditProfile={apiCallEditProfile} /> :
                            <>
                                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 sm:gap-4 gap-2 lg:space-x-8 py-2 mt-2">
                                    {((editMyProfielData?.fathersName != '' ||
                                        editMyProfielData?.fathersName
                                    )) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={FatherIcon}
                                            title={trans('view_profile.fathers_name')}
                                            value={profileData?.[additionalDetails]?.fathersName ? profileData?.[additionalDetails]?.fathersName : editMyProfielData?.fathersName}
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :

                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={FatherIcon}
                                            title={trans('view_profile.fathers_name')}
                                            requestKey={"Father name"}
                                            value={profileData?.[additionalDetails]?.fathersName ? profileData?.[additionalDetails]?.fathersName : editMyProfielData?.fathersName}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.fathersName" : "her_additional_details?.fathersName"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Fathers_Name_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }
                                    {((editMyProfielData?.fathersOccupation != ''
                                        || editMyProfielData?.fathersOccupation?.length > 0
                                    )) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Designation}
                                            title={trans('view_profile.fathers_occupation')}
                                            value={profileData?.[additionalDetails]?.fathersOccupation ? profileData?.[additionalDetails]?.fathersOccupation : editMyProfielData?.fathersOccupation?.[0]?.name}
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :

                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Designation}
                                            title={trans('view_profile.fathers_occupation')}
                                            requestKey={"Father occupation"}
                                            value={profileData?.[additionalDetails]?.fathersOccupation ? profileData?.[additionalDetails]?.fathersOccupation : editMyProfielData?.fathersOccupation?.[0]?.name}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.fathersOccupation" : "her_additional_details?.fathersOccupation"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Fathers_Occupation_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }
                                    {((editMyProfielData?.fathersnativeplace != ''
                                        || editMyProfielData?.fathersnativeplace
                                    )) ? (
                                        <DetailsItem
                                            className="flex md:p-2 py-1"
                                            icon={Images.Native}
                                            title="Father's Native"
                                            value={profileData?.[additionalDetails]?.fathersNative ? profileData?.[additionalDetails]?.fathersNative : editMyProfielData?.fathersnativeplace}
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Images.Native}
                                            title="Father's Native"
                                            requestKey="fathers_native"
                                            value={profileData?.[additionalDetails]?.fathersNative ? profileData?.[additionalDetails]?.fathersNative : editMyProfielData?.fathersnativeplace}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.fathersNative" : "her_additional_details?.fathersNative"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Fathers_Native_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }
                                    {((editMyProfielData?.mothersName != '' ||
                                        editMyProfielData?.mothersName
                                    )) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={MotherIcon}
                                            title={trans('view_profile.mothers_name')}
                                            value={
                                                profileData?.[additionalDetails]?.mothersName ? profileData?.[additionalDetails]?.mothersName : editMyProfielData?.mothersName
                                            }
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :

                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={MotherIcon}
                                            title={trans('view_profile.mothers_name')}
                                            requestKey={"Mother name"}
                                            value={profileData?.[additionalDetails]?.mothersName ? profileData?.[additionalDetails]?.mothersName : editMyProfielData?.mothersName}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.mothersName" : "her_additional_details?.mothersName"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Mothers_Name_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }

                                </div>
                                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 sm:gap-4 gap-2 lg:space-x-8 pb-2">

                                    {((editMyProfielData?.mothersOccupation != '' ||
                                        editMyProfielData?.mothersOccupation?.length > 0
                                    )) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Designation}
                                            title={trans('view_profile.mothers_occupation')}
                                            value={
                                                profileData?.[additionalDetails]?.mothersOccupation ? profileData?.[additionalDetails]?.mothersOccupation : editMyProfielData?.mothersOccupation?.[0]?.name
                                            }
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Designation}
                                            title={trans('view_profile.mothers_occupation')}
                                            requestKey={"Mother occupation"}
                                            value={profileData?.[additionalDetails]?.mothersOccupation ? profileData?.[additionalDetails]?.mothersOccupation : editMyProfielData?.mothersOccupation?.[0]?.name}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.mothersOccupation" : "her_additional_details?.mothersOccupation"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Mothers_Occupation_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }

                                    {((
                                        editMyProfielData?.mothersnativeplace != "" ||
                                        editMyProfielData?.mothersnativeplace)) ? (
                                        <DetailsItem
                                            className="flex md:p-2 py-1"
                                            icon={Images.Native}
                                            title="Mother's Native"
                                            value={profileData?.[additionalDetails]?.mothersNative ? profileData?.[additionalDetails]?.mothersNative : editMyProfielData?.mothersnativeplace}
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Images.Native}
                                            title="Mother's Native"
                                            requestKey="mothers_native"
                                            value={profileData?.[additionalDetails]?.mothersNative ? profileData?.[additionalDetails]?.mothersNative : editMyProfielData?.mothersnativeplace}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.mothersNative" : "her_additional_details?.mothersNative"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Mothers_Native_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }

                                    {((editMyProfielData?.brothers != "" || editMyProfielData?.brothers == 0) && editMyProfielData?.brothers != null) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={SisterBrotherIcon}
                                            title={trans('view_profile.brothers')}
                                            value={
                                                editMyProfielData?.brothers
                                            }
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={SisterBrotherIcon}
                                            title={trans('view_profile.brothers')}
                                            requestKey={"Brothers"}
                                            isHaveNoSiblings={profileData?.[additionalDetails]?.ishavenosiblings}
                                            value={editMyProfielData?.brothers}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.brothers" : "her_additional_details?.brothers"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Brothers_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }
                                    {((
                                        editMyProfielData?.marriedBrothers != '' ||
                                        editMyProfielData?.marriedBrothers == 0) && editMyProfielData?.marriedBrothers != null) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={MarriedIcon}
                                            title={trans('view_profile.married_brothers')}
                                            value={
                                                profileData?.[additionalDetails]?.marriedBrothers ? profileData?.[additionalDetails]?.marriedBrothers : editMyProfielData?.marriedBrothers
                                            }
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={MarriedIcon}
                                            title={trans('view_profile.married_brothers')}
                                            requestKey={"Married brothers"}
                                            isHaveNoSiblings={profileData?.[additionalDetails]?.ishavenosiblings}
                                            value={profileData?.[additionalDetails]?.marriedBrothers ? profileData?.[additionalDetails]?.marriedBrothers : editMyProfielData?.marriedBrothers}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.marriedBrothers" : "her_additional_details?.marriedBrothers"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Married_Brothers_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }

                                </div>
                                <div className='grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 sm:gap-4 gap-2 lg:space-x-8 pb-2'>
                                    {((
                                        editMyProfielData?.sisters != "" ||
                                        editMyProfielData?.sisters == 0) && editMyProfielData?.sisters != null) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={SisterBrotherIcon}
                                            title={trans('view_profile.sisters')}
                                            value={
                                                profileData?.[additionalDetails]?.sisters ? profileData?.[additionalDetails]?.sisters : editMyProfielData?.sisters
                                            }
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8 pb-2flex md:p-2 py-1"}
                                            icon={SisterBrotherIcon}
                                            title={trans('view_profile.sisters')}
                                            requestKey={"Sisters"}
                                            isHaveNoSiblings={profileData?.[additionalDetails]?.ishavenosiblings}
                                            value={profileData?.[additionalDetails]?.sisters ? profileData?.[additionalDetails]?.sisters : editMyProfielData?.sisters}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.sisters" : "her_additional_details?.sisters"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Sisters_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }
                                    {((editMyProfielData?.marriedSisters != '' ||
                                        editMyProfielData?.marriedSisters == 0) && editMyProfielData?.marriedSisters != null) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={MarriedIcon}
                                            title={trans('view_profile.married_sisters')}
                                            value={
                                                profileData?.[additionalDetails]?.marriedSisters ? profileData?.[additionalDetails]?.marriedSisters : editMyProfielData?.marriedSisters
                                            }
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={MarriedIcon}
                                            title={trans('view_profile.married_sisters')}
                                            requestKey={"Married sisters"}
                                            isHaveNoSiblings={profileData?.[additionalDetails]?.ishavenosiblings}
                                            value={profileData?.[additionalDetails]?.marriedSisters ? profileData?.[additionalDetails]?.marriedSisters : editMyProfielData?.marriedSisters}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.marriedSisters" : "her_additional_details?.marriedSisters"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Married_Sisters_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }
                                    {((editMyProfielData?.asset?.length > 0 ||
                                        editMyProfielData?.asset != ""
                                    )) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={"/Assets/Images/Group 514.svg"}
                                            title={trans('view_profile.assets_owned_by_family')}
                                            value={
                                                profileData?.[additionalDetails]?.asset ? profileData?.[additionalDetails]?.asset : editMyProfielData?.asset?.[0]?.name
                                            }
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={"/Assets/Images/Group 499.svg"}
                                            title={trans('view_profile.assets_owned_by_family')}
                                            requestKey={"Assets owned"}
                                            value={profileData?.[additionalDetails]?.asset ? profileData?.[additionalDetails]?.asset : editMyProfielData?.asset?.[0]?.name}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.asset" : "her_additional_details?.asset"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Assets_Owned_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }

                                    {((
                                        editMyProfielData?.livesIn != "" ||
                                        editMyProfielData?.livesIn)) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={"/Assets/Images/Group 505.svg"}
                                            title={editMyProfielData?.gender === "M" ? "Groom Lives in" : "Bride Lives in"}
                                            value={profileData?.[additionalDetails]?.livesIn ? profileData?.[additionalDetails]?.livesIn : editMyProfielData?.livesIn}
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={"/Assets/Images/Group 505.svg"}
                                            title={editMyProfielData?.gender === "M" ? "Groom Lives in" : "Bride Lives in"}
                                            requestKey="groom_lives_in"
                                            value={profileData?.[additionalDetails]?.livesIn ? profileData?.[additionalDetails]?.livesIn : editMyProfielData?.livesIn}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.livesIn" : "her_additional_details?.livesIn"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Lives_in_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }

                                </div>
                                <div className='grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 sm:gap-4 gap-2 lg:space-x-8 pb-2'>
                                    {((editMyProfielData?.workState?.length != 0 ||
                                        editMyProfielData?.workState != ""
                                    )) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Images.StateImage}
                                            title="State"
                                            value={profileData?.[additionalDetails]?.workState ? profileData?.[additionalDetails]?.workState : editMyProfielData?.workState?.[0]?.name}
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8 pb-2flex md:p-2 py-1"}
                                            icon={Images.StateImage}
                                            title="State"
                                            requestKey="state"
                                            value={profileData?.[additionalDetails]?.workState ? profileData?.[additionalDetails]?.workState : editMyProfielData?.workState?.[0]?.name}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.workState" : "her_additional_details?.workState"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_State_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }
                                    {((editMyProfielData?.workCity?.length > 0 ||
                                        editMyProfielData?.workCity != ""
                                    )) ? (
                                        <DetailsItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Images.CityImage}
                                            title={"City"}
                                            value={
                                                profileData?.[additionalDetails]?.workCity ? profileData?.[additionalDetails]?.workCity : editMyProfielData?.workCity?.[0]?.name
                                            }
                                            style={{ marginRight: "20px" }}
                                        />)
                                        :
                                        <RequestItem
                                            className={"flex md:p-2 py-1"}
                                            icon={Images.CityImage}
                                            title="City"
                                            requestKey="city"
                                            value={profileData?.[additionalDetails]?.workCity ? profileData?.[additionalDetails]?.workCity : editMyProfielData?.workCity?.[0]?.name}
                                            keyName={editMyProfielData?.gender === "M" ? "his_additional_details?.workCity" : "her_additional_details?.workCity"}
                                            page={page}
                                            eventTrack={EventName.View_Profile_Not_Specified_field_request_City_Button}
                                            style={{ marginRight: "20px" }}
                                            isFrom="ProfilePreviewAdd"
                                        />
                                    }
                                </div>
                                {isCommunitySite ? null : (

                                    <div className="pl-[6rem] md:pl-[12rem] flex justify-end mb-[2rem] md:bottom-[3rem] bottom-[1rem]">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (location?.state?.isFrom == "afterreg") {
                                                    localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.DOWNLOAD_PAGE)
                                                    navigate(RouteSegments.DOWNLOAD_PAGE)
                                                } else {
                                                    navigate(RouteSegments.MATCH_GROUP)
                                                }
                                            }}
                                            className="cursor-pointer pt-2 text-[#C1C1C1] text-[13px] underline"
                                        >
                                            {location?.state?.isFrom == "afterreg" ? "Skip" : "Next"}
                                        </button>
                                    </div>
                                )}
                            </>}

                    </div>
                </div>
            }
        </>
    )
}

const DetailsItem = (props) => {
    return (
        <div
            className="flex justify-start gap-3 items-start"
        // className={props.className}
        >
            {props?.isFrom == "physicalicon" ?
                <img className={` w-9 md:w-12 lg:w-[2rem] xl:w-12`} src={props.icon} alt="" /> :
                <div className="relative flex justify-center item-center">

                    <div className="w-9 md:w-12 lg:w-[2rem] xl:w-12">
                        <img src="/Assets/Images/Ellipse 76.svg" className="w-9 md:w-12" alt="" />
                    </div>
                    <span className="absolute w-9 md:w-[2.6rem] mt-[5px] md:mt-[10px] lg:mt-[7px] xl:mt-[5px]">
                        <div className="flex justify-center item-center">
                            <img className={` ${props?.classNames ? props?.classNames : "w-[1.5rem] h-[1.5rem] lg:w-[1rem] lg:h-[1rem] xl:w-[1.5rem] xl:h-[2rem]"}  `} src={props.icon} alt="" />
                        </div>
                    </span>
                </div>}
            <div className=" mt-0 md:mt-0 lg:mt-0 leading-[19px] md:leading-[23px] lg:leading-[17px] xl:leading-[26px]">
                <p className="text-[#575556] font-regular text-[12px] xl:text-[14px] lg:text-[12px] font-segoe-ui 2xl:break-words">
                    {props.title}
                </p>
                <span
                    className="text-[#575556] w-[106%] md:w-[100%] lg:w-[103%] lg:text-[11px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui"
                    style={{ display: 'inline-block' }}
                >
                    {props.value}
                </span>
            </div>

        </div>
    );
};
export { DetailsItem };


const HobbiesDetailsItem = (props) => {

    const { t: trans } = useTranslation();

    if (props.value === 'Not Specified') return null
    return (
        <>
            <div
                className="items-center hidden lg:flex gap-3 justify-start "
            >
                <div className="shrink-0">

                    <div className="  justify-center item-center">
                        <img className={` ${props?.classNames ? props?.classNames : "w-[2rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem]"} `} src={props.icon} alt="" />
                    </div>
                </div>
                <div className=" mt-0 md:mt-0 lg:mt-0 leading-[19px] md:leading-[23px] lg:leading-[17px] xl:leading-[26px]">
                    <p className="text-[#575556] font-regular text-[12px] xl:text-[14px] lg:text-[12px] font-segoe-ui 2xl:break-words">
                        {props.title}
                    </p>
                    <span
                        className="text-[#575556] w-[106%] md:w-[100%] lg:w-[103%] lg:text-[11px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui"
                        style={{ display: 'inline-block' }}
                    >
                        {commaWithSpace(props?.value)}
                    </span>
                </div>

            </div>

            <div
                className=" flex space-x-1  lg:hidden gap-2 justify-start "
            >
                <div className=" shrink-0 justify-center item-center">
                    <img className={`  ${props?.classNames ? props?.classNames : "w-[2rem] md:w-[3rem] lg:w-[2rem] md:h-[3rem] lg:h-[3rem]  xl:w-[3rem] xl:h-[3rem]"} `} src={props.icon} alt="" />
                </div>
                <div className=" mt-0 md:mt-0 lg:mt-0  md:col-span-3 col-span-4 leading-[19px] md:leading-[23px] lg:leading-[17px] xl:leading-[26px]">
                    <p className="text-[#575556] font-regular text-[12px] xl:text-[14px] lg:text-[12px] font-segoe-ui 2xl:break-words">
                        {props.title}
                    </p>
                    <span
                        className="text-[#575556]  lg:text-[11px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui"
                        style={{ display: 'inline-block' }}
                    >
                        {commaWithSpace(props?.value)}
                    </span>
                </div>

            </div>
        </>
    );
};
export { HobbiesDetailsItem };

const RequestItem = (props) => {

    const { t: trans } = useTranslation();
    const [requestField, setRequestField] = useState("");
    const isRequested = props?.value == 'requested' ? true : false

    const { onClickTrack } = useAnalytics()

    let { requestTypeLoading, requestKey } = useSelector(state => ({
        requestTypeLoading: state.KalyanLite?.requestTypeLoading,
        requestKey: state.KalyanLite?.requestKey,
    }));
    const [showAdminPopup, setShowAdminPopup] = useState(false)
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)

    const { showbasicdetails, setShowBasicdetails } = useContext(NavBarContext)

    console.log(props?.isFrom, "requestKey");

    const dispatch = useDispatch();

    const { viewprofileid, currentConversationId } = useSelector((state) => {
        return {
            viewprofileid: state.KalyanLite?.currentMatch?.data?.profileid,
            currentConversationId: state?.Chats?.conversationStatusData?.conversation_id,
        };
    });

    const sendFieldRequestAPI = async (conversationId) => {

        let data = {
            "sender_id": getGroupAuthId(),
            "receiver_id": viewprofileid,
            "message_type_id": Object.keys(requestLookup).find(k => requestLookup[k] === props?.requestKey),
            "loginFrom": getDeviceInfo(),
            "isFrom": props?.page == "chat" ? "chat" : "viewprofile"
        }
        dispatch(profileSendMessage(data))
    };

    const storeRequestTypeApi = async () => {
        let request = {
            sender_profileid: getGroupAuthId(),
            receiver_profileid: viewprofileid,
            requesttypeid: Object.keys(requestLookup).find(k => requestLookup[k] === props?.requestKey),
            loginFrom: getDeviceInfo(),
            requestsentvia: "viewprofile",
            keyName: props?.keyName,
            fromPage: "viewprofile"
        }
        dispatch(storeRequestType(request))
    }

    const handleRequestClick = (title) => {
        if (getIsLoginFromAdmin()) {
            setShowAdminPopup(true)
        }
        else {
            setRequestField(title)
            storeRequestTypeApi()
            sendFieldRequestAPI()
            onClickTrack(props?.eventTrack);
        }
    }

    const [loading, setLoading] = useState(false)

    const handleAddClick = () => {
        setLoading(true)
        setShowBasicdetails(true)
        localStorage.setItem("profilepreview", true);
    }
    console.log(currentConversationId, "looking", localStorage.getItem(Constants.loginLocalStorageKeys.loginId), viewprofileid);


    return (
        <div
            className={` ${props?.keyName == "hobbiesInterest?.TypeOfMoviesYouWatch" ? " flex justify-start item-start gap-3 pt-0.5" 
                :props?.keyName=="hobbiesInterest?.FavoriteMovieDirectors" || props?.keyName=="hobbiesInterest?.TypeOfMoviesYouWatch" ? "gap-3 flex justify-start item-start ": " gap-3 flex justify-start item-start "} `}
        >
            {showAdminPopup && <CommonProfilePopup
                close={() => setShowAdminPopup(false)}
                title={"Alert!"}
                content={"You cannot send message/request from Admin"} />
            }


            {showSuccessPopup && <ValidationNotificationPopup isFrom={"viewProfile"} message={"Request Send Successfully"} onClose={() => setShowSuccessPopup(false)} />}

            <div className={`${props?.title === trans('view_profile.about_me') || props?.isFroms == "aboutme" ? "hidden" : ""} `}>


                {
                    props?.keyName == "hobbiesInterest?.FavoriteMovieDirectors" ||
                        props?.keyName == "hobbiesInterest?.FavouriteCuisine" || props?.keyName == "hobbiesInterest?.languagesThatIwishToKnow" ?


                        <div className=" flex  justify-center item-center">


                            <span className={` ${props?.keyName == "hobbiesInterest?.FavoriteMovieDirectors" || props?.keyName == "hobbiesInterest?.FavouriteCuisine" ? "" : props?.keyName == "hobbiesInterest?.TypeofBooksYouRead" ? "mt-[5px] md:mt-0 lg:mt-[7px] xl:mt-[5px]" : "mt-[5px] md:mt-[10px] lg:mt-[7px] xl:mt-[5px]"} `}>
                                <div className="flex justify-center item-center">

                                    <img className={`${props?.classNames ? props?.classNames : " "}  `} src={props.icon} alt="" />
                                </div>
                            </span>
                        </div>
                        :

                        <div className=" flex  justify-center item-center">
                            <div className={`w-9 md:w-12 lg:w-[2rem] xl:w-12`}>

                                <img src="/Assets/Images/Ellipse 76.svg" className="w-9 md:w-12" alt="" />
                            </div>

                            <span className={`absolute w-9 md:w-[2.6rem] lg:mb-2  ${props?.keyName == "hobbiesInterest?.TypeOfMoviesYouWatch" || props?.keyName == "hobbiesInterest?.languagesThatIwishToKnow" || props?.keyName == "hobbiesInterest?.languagesYouKnow" ||
                                props?.keyName == "hobbiesInterest?.FavoriteMovieDirectors" || props?.keyName == "hobbiesInterest?.FavouriteCuisine" ? "" : props?.keyName == "hobbiesInterest?.TypeofBooksYouRead" ? "mt-[5px] md:mt-0 lg:mt-[7px] xl:mt-[5px]" : "mt-[5px] md:mt-[10px] lg:mt-[7px] xl:mt-[5px]"} `}>
                                <div className="flex justify-center item-center">

                                    <img className={` ${props?.classNames ? props?.classNames : "w-[1.5rem] h-[1.5rem] cursor-pointer lg:w-[1rem] lg:h-[1rem] xl:w-[1.5rem] xl:h-[2rem] "}  `} src={props.icon} alt="" />
                                </div>
                            </span>
                        </div>}
            </div>
            <div className="  mt-0 md:mt-0 lg:mt-0 leading-[19px] md:leading-[23px] lg:leading-[17px] xl:leading-[26px]">
                <p className={`${props?.title === trans('view_profile.about_me') ? "pb-2 md:px-0 text-[#1A1A1A] text-[20px] tracking-wide font-Poppins font-semibold pt-[1rem] md:pt-[5rem] lg:pt-0" : "text-[#575556] font-regular text-[12px] xl:text-[14px] lg:text-[12px] font-segoe-ui break-words"}`}>
                    {props.title}
                </p>
                {requestTypeLoading && props?.keyName == requestKey ?
                    <img alt="loader" src={LoaderGif} className={`w-10 h-10`} />
                    :
                    props?.isHaveNoSiblings == "Y" ?
                        <span
                            className={`text-start text-[#575556] w-[106%] md:w-[100%] lg:w-[93%] lg:text-[10px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui`}
                            style={{ display: 'inline-block' }}
                        >
                            --
                        </span>
                        :
                        props?.isFrom == "ProfilePreviewAdd" ?
                            <button
                                onClick={() => handleAddClick()}
                                disabled={isRequested}
                                className={`text-start ${isRequested ? "text-[#575556] cursor-not-allowed" : "text-[#D10A11] cursor-pointer "}  underline underline-offset-2 w-[106%] md:w-[100%] lg:w-[103%] lg:text-[10px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui`}
                                style={{ display: 'inline-block' }}
                            >
                                <p className='flex items-center gap-1'><img className='w-3 h-3' src={Images.addgroupicon} />  Add</p>

                            </button>
                            :
                            <button
                                onClick={() => handleRequestClick(props.title)}
                                disabled={isRequested}
                                className={`text-start ${isRequested ? "text-[#575556] cursor-not-allowed" : "text-[#D10A11] cursor-pointer "}  underline underline-offset-2 w-[106%] md:w-[100%] lg:w-[103%] lg:text-[10px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui`}
                                style={{ display: 'inline-block' }}
                            >
                                {isRequested ? trans('view_profile.requested') : trans('view_profile.request')}
                            </button>
                }
            </div>

        </div>
    );
};
export { RequestItem };

export default PreviewBasicDetails
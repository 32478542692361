import React, { useContext, useEffect, useState } from 'react'
import ProfessionalComponent from '../../../Components/Edit Profile/MyProfileComponents/ProfessionalComponent'
import EndPoints from '../../../Constants/EndPoints';
import config from '../../../config';
import { GET } from '../../../Services/api_services';
import { LoginContext } from '../../../Contexts/LoginContext';

function PreviewProfessionalForm({ editMyProfileData, ProfileData,apiCallEditProfile }) {
    const { logout } = useContext(LoginContext);

    const [educationinfofreshData, seteducationinfofresh] = useState();

    function professionalCallBack() {
        apiEducationCallDropDown();
    }

    const apiEducationCallDropDown = async (type) => {

        let url = "";

        url = `${config.api.API_URL}${EndPoints.educationinfofreshUrl()}`;

        let { statusCode, data } = await GET(url);
        //console.log("dropdown data ", data);
        if (statusCode === 200) {
            seteducationinfofresh(data.data);
        } else if (statusCode === 401) {
            logout();
        }

    };

    useEffect(() => {
        apiEducationCallDropDown()
    }, [])

    return (
        <ProfessionalComponent
            data={editMyProfileData}
            refresh={professionalCallBack}
            callBackReload={() => {
                apiCallEditProfile()
             }}
            dropDown={educationinfofreshData}
            editfromadmin={ProfileData?.editfromadmin}
            isFrom="professionaldetails"
        />
    )
}

export default PreviewProfessionalForm
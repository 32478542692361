import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import { Formik, Field, ErrorMessage } from 'formik';

import * as Yup from "yup";
import "./registerfrom.css"
import $, { event } from 'jquery'
import Dropdown from "./Dropdown"

//constant
import { registerUser, restApiError } from "../../Store/Register/Action";
import { registerDetail, registerCasteList, validateName } from "../../Store/Home/Action";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "../withRouter";
import RouteSegments from "../../Routes/RouteSegment";
import Constants, { EventKeys, EventName, blockInvalidCharForNumber, blockInvalidCharForText, nameNumericList } from "../../Constants/constants";
import Loader from "../Loader";
import { useSearchParams } from "react-router-dom";
import eyeicon from '../../Images/passwordeye.svg'
import closeEyeIcon from '../../Images/passwordeyeopen.png'
import DropdownIcon from '../../Images/Group 56.svg'
import EyeOpenIcon from "../../Images/passwordeyeopen.png"
import EyeIcon from "../../Images/passwordeye.svg"
import { validateNumber } from "../../Utils";
import { mailExtensionList } from "../../Constants/constants";
import config from "../../config";
import EndPoints from "../../Constants/EndPoints";
import axios from "axios";
import { LoginContext } from "../../Contexts/LoginContext";
import { useAnalytics } from "../../Hooks/usePageChange";
import { useMediaQuery } from "react-responsive";
import { useRef } from "react";

let isGetRegisterDetail = false
let errorNameMessage = ""

const RegisterForm = (props) => {
  const { logout } = useContext(LoginContext);
  const [errorMessage, setErrorMessage] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [nameErrorMessage, setNameErrorMessage] = useState("")
  const [dataErrorMessage, setDataErrorMessage] = useState("")
  const [emailErrorMessage, setEmailErrorMessage] = useState("")

  const [selectedRelation, setSelectedRelation] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [domainError, setDomainError] = useState(null);
  const {onClickTrack} = useAnalytics();
  // const [isGetRegisterDetail, setIsGetRegisterDetail] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 678 });

  const [isMailFocused, setIsMailFocused] = useState(false);

  const getDevice = () => {
    let loginDevice = window.innerWidth < 1024 ? "Mobile Site" : "Desktop"
    const finalLoginDevice = loginDevice || "Unknown";
    return finalLoginDevice
  }

  let { communitySiteData,registrationAmont } = useSelector(state => ({
    communitySiteData: state.Login?.communitySiteContent?.data?.Subdomaindetails,
    registrationAmont: state.Login?.communitySiteContent?.data?.registrationAmount
  }));


  useEffect(() => {
    $('#password,#name').bind("cut copy paste", function (e) {
      e.preventDefault();
    });
  }, []);

  // useEffect(() => {
  //   if (!isRegisterLoading) {
  //     dispatch(registerDetail());
  //   }
  // }, []);


  const formik = useFormik({
    
    initialValues: {
      name: "",
      profileId: "",
      gender: "",
      motherTongueId: "",
      religionId: "",
      denomination:"",
      casteId: "",
      countryId: props?.isFrom == "NRI" ? "755":"499",
      phone: "",
      email: "",
      password: "",
      terms: "",
      utmsource: searchParams.get("utm_source") ? searchParams.get("utm_source") : props?.utmsource ? props?.utmsource : "",
      utmmedium: searchParams.get("utm_medium") ? searchParams.get("utm_medium") : "",
      utmcampaign: searchParams.get("utm_campaign") ? searchParams.get("utm_campaign") : props?.utmcampaign ? props?.utmcampaign : "",
      otherCaste: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter Name")
      .test("name", errorNameMessage, function (value) {
        const { name } = this.parent;
        let inputExtension = value.toLowerCase().split(" ")
        if (errorNameMessage != "" && errorNameMessage ) {
          formik.touched.name = true
          return this.createError({ message: errorNameMessage, path: "name" });
        }
          return true ;
      }),
      profileId: Yup.string().required("Please Select Profile Created for"),
      gender: Yup.string().required("Please Select Gender"),
      motherTongueId: Yup.string().required("Please Select Mother Tongue"),
      religionId: Yup.string().required("Please Select Religion"),
      denomination: Yup.string().when("religionId",
      (religionId, schema)=>{
        // console.log('value business : ',religionId);
        if(religionId.includes(Constants.christialId)){
          return schema.required('Please Select Denomination');
        }else{
          return schema;
        }
      }
      ),
      casteId: Yup.string().required("Please Select Caste"),
      email: Yup.string().email("Enter a Valid e-mail ID").nullable(),
      
      // .required("Please Enter a Valid e-mail ID"),
      // .test("email", "Enter a valid domain", function (value) {
      //   const { email } = this.parent;
      //   console.log(email,value,"emailemailemail");
      //   let inputExtension = value.toLowerCase().split("@")
      //   if (!mailExtensionList.includes(inputExtension[1])) {
      //     formik.touched.email = true
      //     return this.createError({ message: "Enter a valid domain", path: "email" });
      //   }
      //     return true ;
      // }),
      // email: Yup.string().email("Please Enter a Valid e-mail ID").required("Please Enter a Valid e-mail ID"),
      password: Yup.string()
        .min(6, "Password should contain 6 to 15 characters")
        .max(15, "Password should contain 6 to 15 characters")
        .required("Password should contain 6 to 15 characters"),

      countryId: Yup.string().required('Country code is required'),
      phone: Yup.string()
      .required('Please enter mobile number')
      .test('countryCode', 'Please enter a valid mobile number', function (value) {
        const { countryId } = this.parent;
        const regex = countryId === '499' ? /^\d{10}$/ : /^\d{7,15}$/;
        return regex.test(value);
      }),

      // phone: Yup.string()
      //   .min(10, "Length should be 10")
      //   .required("Please Enter Valid Mobile Number"),
      terms: Yup.boolean()
        .required("Please Agree to the Terms and Conditions")
        .oneOf([true], "Please Agree to the Terms and Conditions"),
    }),
    onSubmit: (values) => {
      if(values.email == ""){
        values.email = null;
      }
      RegisterFormSubmission(values);
      onClickTrack(EventName.Home_page_Register_Submit_Button,{[EventKeys.Home_page_Register_Submit_Button_Request]:values})
    },
  });

  const isChristian = formik.values?.religionId == Constants.christialId
  // console.log({formik});



  const dispatch = useDispatch();

  const [showHidePassword, changeShowHidePassword] = useState(false);

  useEffect(() => {
    if (formik.values?.profileId == "2" || formik.values?.profileId == "4") {
      formik.values.gender = "M"
    }
    else if (formik.values?.profileId == "3" || formik.values?.profileId == "5") {
      formik.values.gender = "F"
    }
    else {

    }
  }, [formik.values.profileId])


  useEffect(() => {
    formik.values.casteId = ""
    if (formik.values.motherTongueId && formik.values.religionId) {
      dispatch(
        registerCasteList(
          formik.values.motherTongueId,
          formik.values.religionId
        )
      );
    }
  }, [formik.values.motherTongueId, formik.values.religionId])

  const { casteList } = useSelector((state) => ({
    casteList: state?.Home?.cList?.data?.data?.castes,
  }));

  console.log(casteList,"casteList");

  const { dropdownData } = useSelector((state) => ({
    dropdownData: state?.Home?.rData?.data,
  }));


  const RegisterFormSubmission = async (values) => {
    setShowLoader(true)
    dispatch(registerUser(values, props.router.navigate));
  };

  const { error } = useSelector((state) => ({
    error: state?.Register?.error?.data,
  }));

  useEffect(() => {

    if (dropdownData?.motherTongue?.length == 1 && localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite)) {
      formik.setFieldValue("motherTongueId", dropdownData?.motherTongue[0]?.id)
    }

    if (dropdownData?.religion?.length == 1 && localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite)) {
      formik.setFieldValue("religionId", dropdownData?.religion[0]?.id)
    }
  }, [dropdownData])

  useEffect(() => {
    if (casteList?.length == 1 && localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite)) {
      formik.setFieldValue("casteId", casteList[0]?.id)
    }
  }, [casteList])




  useEffect(() => {
    setErrorMessage(error?.message)
    setShowLoader(false)
    if(error?.message === "Phone number cannot be set as password"){
      formik.setFieldError("password",error?.message)
    }
  }, [error])

  useEffect(() => {
    setErrorMessage("")
  }, [formik.values])

  console.log(props?.subMenu,'props?.subMenu');
  const nameCheckList = async (value,e) => {
    // dispatch(validateName(value));
        let request = {
          name: value
        };
        let data = await axios.post(
          `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
          request
        );
          if (data.data.status === "Success") {
            // console.log(data.data.data.Messagge,"ata.data.data.Messagge");
            errorNameMessage = data.data.data.Messagge
            setNameErrorMessage(data.data.data.Messagge)
            formik.handleChange(e)
          }
    };

  
    
    const storeApiCall = async (request) => {

      request.utmSource = searchParams.get("utm_source") ?? undefined;
      request.utmMedium = searchParams.get("utm_medium") ?? undefined;
      request.utmCampaign = searchParams.get("utm_campaign") ?? undefined;
      request.subdomainId = communitySiteData ? communitySiteData[0]?.subdomainId: undefined;
      request.loginFrom = getDevice();
      request.registerfrom = getDevice();

      const headers = {
        Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
      };

      try {
        // let url = "https://cms-uat.kalyanmatrimony.com/api/storeDetails";
         axios.post(`${config.api.chat_api}${EndPoints.getstoreDetails()}`, request, {headers}).catch((error) => error?.response)
         .then ((response)=>{

          console.log(response ,"response");
          console.log(response.data,"datab");
          if(response?.data?.error ){
            setDataErrorMessage(response?.data?.error)
            setEmailErrorMessage(response?.data?.error)
            formik.setFieldError("phone",response?.data?.error)
            formik.setFieldError("email",response?.data?.error)
           
          }
          else {
            setDataErrorMessage("")
            setEmailErrorMessage("")
            formik.setFieldError("phone","")
            formik.setFieldError("email","")
          }
            return response.data;
            
         }) 
        
      } catch (error) {
        console.error("Network error:", error);
       
      }
    };
    
   
    const getstoreDetails = async (e) => {
      let request = {};
    
      if (formik.values.gender) {
        request.gender = formik.values.gender;
      }
    
      if (formik.values.email.trim() !== '') {
        request.email = formik.values.email;
      }
    
      if (formik.values.countryId) {
        request.countryId = formik.values.countryId;
      }
    
      if (formik.values.profileId) {
        request.profileForId = formik.values.profileId;
      }
    
      if (formik.values.motherTongueId) {
        request.motherTongueId = formik.values.motherTongueId;
      }
    
      if (formik.values.religionId) {
        request.religionId = formik.values.religionId;
      }
    
      if (formik.values.casteId) {
        request.casteId = formik.values.casteId;
      }
    
      if (formik.values.denomination) {
        request.domainId = formik.values.denomination;
      }
    
      if (formik.values.phone) {
        request.mobileNumber = formik.values.phone;
      }
      
      if (formik.values.name.trim() !== '') {
        request.username = formik.values.name.trim();
      }
    
      try {
         await storeApiCall(request);
      }
         catch (error) {
        console.error("API call failed:", error.message);
      }
    };
    

    // useEffect(() => {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   const { countryId, phone, email,name } = formik.values;
    //   if((countryId === "499" && phone.length === 10 ) || (countryId !== "499" && phone.length >= 7 && phone.length <= 15)){

    //     if ((countryId === "499" && phone.length === 10 ) || (countryId !== "499" && phone.length >= 7 && phone.length <= 15)
    //       || (emailRegex.test(email))) {
    //         if(!isMailFocused){
    //           if(phone?.length > 0 && name?.length > 0){
    //             getstoreDetails();
    //           }
    //         }
    //       } 
    //   }
  
    // }, [formik.values,isMailFocused]);
  

    useEffect(() => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const { countryId, phone, email, name } = formik.values;
    
      const isPhoneValid =
        (countryId === "499" && phone.length === 10) ||
        (countryId !== "499" && phone.length >= 7 && phone.length <= 15);
    
      const isEmailValid = emailRegex.test(email);
    
      // Check conditions based on subMenu prop
      if (props?.subMenu === "onlineLanding") {
        if (isPhoneValid || isEmailValid) {
          if (!isMailFocused && phone?.length > 0 && name?.length > 0) {
          }
        }
      } else {
        if (isPhoneValid || isEmailValid) {
          if (!isMailFocused && phone?.length > 0 && name?.length > 0) {
            getstoreDetails();
          }
        }
      }
    }, [formik.values, isMailFocused]);
    


  const validateEmail = (value) => {
    let inputExtension = value.toLowerCase().split("@")
    if (!mailExtensionList.includes(inputExtension[1])) {
      if(formik.errors.email){
        setDomainError(null)
      }else{
        setDomainError("Enter a valid domain")
      }
    } else {
      setDomainError(null)
    }
  }
  const [motherTongueDropdownSelect,setMotherTongueDropdownSelect] = useState(false)
  const [religionDropdownSelect,setReligionDropdownSelect] = useState(false)
  const [countryDropdownSelect,setCountryDropdownSelect] = useState(true)
  const [genderDropdownSelect,setGenderDropdownSelect] = useState(false)

  const countrySelect = (e)=>{
    e.preventDefault();
    setCountryDropdownSelect(true)
  }

  // const sortedCasteList = casteList?.sort((a, b) => {
  //   if (a.name === "Others") return 1; // Move "Others" to the end
  //   if (b.name === "Others") return -1; // Keep other items before "Others"
  //   return 0; // No change for other items
  // });
  // const isLastOptionOthers =
  // sortedCasteList?.length > 0 && sortedCasteList[sortedCasteList.length - 1].name === "Others";

 
  
  // const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);
  // const RupeeSymbol = () => <span className="rupeefont">â‚¹</span>;

  const sortedCasteList = casteList?.sort((a, b) => {
    if (a.name === "Others") return 1; // Move "Others" to the end
    if (b.name === "Others") return -1; // Keep other items before "Others"
    return 0; // No change for other items
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleCasteDropdown = () => setIsOpenDropdown(!isOpen);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setIsOpenDropdown(false)
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isLastOptionOthers =
    sortedCasteList?.length > 0 && sortedCasteList[sortedCasteList.length - 1].name === "Others";


    const handleOptionClick = (casteId) => {
      formik.setFieldValue("casteId", casteId);
      setIsOpen(false);

      console.log({
        name: formik.values.name,
        phone: formik.values.phone,
        gender: formik.values.gender,
        profileId: formik.values.profileId,
        motherTongueId: formik.values.motherTongueId,
        religionId: formik.values.religionId,
        casteId: casteId
      });
    };
    const handleCasteOptionClick = (casteId) => {
      formik.setFieldValue("casteId", casteId);
      setIsOpenDropdown(false)
      setIsOpen(false);
      console.log({
        name: formik.values.name,
        phone: formik.values.phone,
        gender: formik.values.gender,
        profileId: formik.values.profileId,
        motherTongueId: formik.values.motherTongueId,
        religionId: formik.values.religionId,
        casteId: casteId
      });
    };


    const [typedValue, setTypedValue] = useState(""); // Store typed characters
    const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected index for auto-selection
    
    // Handle keydown event to search and select caste based on typed characters
    const handleKeydown = (event) => {
      const char = event.key.toLowerCase();
      if (char.length === 1 && /[a-zA-Z0-9]/.test(char)) {
        // Append the new character to the typed value
        const newTypedValue = typedValue + char;
        // setTypedValue(newTypedValue);
    
        // Find the first caste name that starts with the typed value
        const foundIndex = sortedCasteList.findIndex((caste) =>
          caste.name.toLowerCase().startsWith(newTypedValue)
        );
    
        // If a match is found, select the caste
        if (foundIndex !== -1) {
          setSelectedIndex(foundIndex); // Set the matched caste index
          const caste = sortedCasteList[foundIndex];
          formik.setFieldValue("casteId", caste.id); // Auto-select caste
        }
    
        // Clear the typed value after a short delay (500ms) for new searches
        setTimeout(() => setTypedValue(""), 500);
      }
    };
  useEffect(() => {


    return () => {
      setShowLoader(false)
    }
  }, [])

  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);
  const RupeeSymbol = () => <span className="rupeefont">₹</span>;

  
  return (
    <div>
      {props?.menu === "campaignRegister" ?
        <div className={`${props?.menu == "registerPopup" ? "App mt-2  pr-[15px] md:px-[12px]  h-[20.5rem] overflow-auto" : "App"}`}>
          {showLoader &&  <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
          {dropdownData ?
            <form onSubmit={formik.handleSubmit}>
              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <input
                      type="text"
                      // onKeyDown={(event) => blockInvalidCharForText(event)}
                      name="name"
                      id="name"
                      autoComplete="off"
                      placeholder="Enter Your Name"
                      className="!placeholder-[#495057]  form-control  shadow-sm bg-[#E9E9E9]   text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F] py-[8px]  px-2  md:py-2 md:px-2  w-full block "
                      value={formik.values.name}
                      onChange={(e) => {
                        nameCheckList(e.target.value,e);
                        setIsMailFocused(true)
                        let text = e.target.value ?? "";
                        text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                        text = text.charAt(0).toUpperCase() + text.slice(1)
                        var splitStr = text.toLowerCase().split(' ');
                        for (var i = 0; i < splitStr.length; i++) {
                            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                        }
                        text =  splitStr.join(' '); 
                        e.target.value = text;
                        if ((e.target.value.split(".").length - 1) <= 1) {
                          formik.handleChange(e)
                          // getstoreDetails(e)
                        }
                      }}
                      onBlur={() => setIsMailFocused(false)}
                    />
                  </label>
                </div>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {formik.errors.name && formik.touched.name && (
                    formik.errors.name
                  )}
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div className="space-y-1 md:mt-[0.3rem] col-span-1">
                  <div>
                    <label className="relative block">
                      <select
                        id="countryId"
                        name="countryId"
                        value={formik.values.countryId}
                        onChange={formik.handleChange}
                        className="profileone form-input shadow-sm pt-[0.4rem] bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2  w-full block !bg-[position:calc(100%_-_5px)_8px] md:!bg-[position:calc(100%_-_5px)_14px] lg:!bg-[position:calc(100%_-_5px)_14px]"
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        
                        {dropdownData?.isdcode?.map((isdcode, i) => (
                          <option className="p-2" value={isdcode.countryId}>
                            {isdcode.isdcode + "," + isdcode.country}
                          </option>
                        ))}
                         
                      </select>
                      {/* <img className="" src={DropdownIcon} /> */}
                    </label>
                  </div>
                </div>
                <div className="space-y-1 md:mt-[0.3rem] col-span-2">
                  <div>
                    <label className="relative block RegPhone" >
                      <input
                        id="phone"
                        name="phone"
                        type="tel"
                        onKeyDown={(event) => blockInvalidCharForNumber(event, props?.values?.countryId == "+91" ? 10 : 15)}
                        autoComplete="off"
                        placeholder="Enter Your Phone Number"
                        value={formik.values.phone}
                        // onChange={(e)=>{
                        //   if(validateNumber(e.target.value)){
                        //     formik.handleChange(e)
                        //   }
                        // }}
                        onChange={(event) => {
                          const { value } = event.target;
                          // formik.handleChange(event);
                          setIsMailFocused(true)
                          if(validateNumber(event.target.value)){
                            formik.handleChange(event)
                          }
                          // getstoreDetails(event)
                          console.log({
                            name: formik.values.name,
                            phone: formik.values.phone,
                            
                          });
                          
                        }}
                        onBlur={() => setIsMailFocused(false)}

                        className="!placeholder-[#495057] RegPhone form-input shadow-sm bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2 w-full  block "
                      />
                    </label>

                    <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                      {error?.message ?
                        error?.message == "Mobile Number already Exist" && (
                          error?.message
                        )
                        :
                        dataErrorMessage?
                        dataErrorMessage ==="Mobile number already exists"&&(
                          dataErrorMessage
                        ):
                        formik.errors.phone && formik.touched.phone && (
                          formik.errors.phone
                        )}{" "}

                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-2 md:mt-[0.3rem] flex grid grid-cols-4 gap-4">
                <div className='col-span-2'>
                  <p className='text-[#575556] font-semibold text-[13px] text-[#D10A11] mt-2'>Gender</p>
                </div>
                <div className="col-span-2">
                  <div className="space-x-2">

                   {
                   <div>
                    {formik.values.profileId == "2" || formik.values.profileId == "4" ?
                    <div className="space-x-2 text-[15px]">
                    <input className="accent-[#D10A11]" type="radio" value="male" id="male" 
                     checked={formik.values.profileId == "2" || formik.values.profileId == "4" ? true:false}
                      name="gender" />
                    <label for="male" value="M" >Male</label>

                    <input className="accent-[#D10A11]" type="radio" value="female" id="female" 

                    checked={formik.values.profileId == "3" || formik.values.profileId == "5" ? true:false}  name="gender" />
                    <label for="female" value="F">Female</label>
                    <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                      {formik.errors.gender && formik.touched.gender && (
                        formik.errors.gender
                      )}{" "}
                    </div>
                  </div>
                  :formik.values.profileId == "3" || formik.values.profileId == "5" ?

                    <div className="space-x-2 text-[15px]">
                    <input className="accent-[#D10A11]" type="radio" value="male" id="male" 
                    checked={formik.values.profileId == "2" || formik.values.profileId == "4" ? true:false}
                      name="gender" />
                    <label for="male" value="M" >Male</label>

                    <input className="accent-[#D10A11]" type="radio" value="female" id="female" 

                    checked={formik.values.profileId == "3" || formik.values.profileId == "5" ? true:false}  name="gender" />
                    <label for="female" value="F">Female</label>
                    <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                      {formik.errors.gender && formik.touched.gender && (
                        formik.errors.gender
                      )}{" "}
                    </div>
                  </div>
                  :
                  <div className="space-x-2 text-[15px]">
                      <input className="accent-[#D10A11]" type="radio" value="male" id="male" 
                        name="gender" />
                      <label for="male" value="M" >Male</label>
                      

                      <input className="accent-[#D10A11]" type="radio" value="female" id="female" 

                      name="gender" />
                      <label for="female" value="F">Female</label>
                      <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                        {formik.errors.gender && formik.touched.gender && (
                          formik.errors.gender
                        )}{" "}
                      </div>
                    </div>

                    }
                   
                    
                    </div>
                   }

                  </div>

                </div>
              </div>

              <div className="space-y-2 md:mt-[0.3rem] flex grid grid-cols-4 gap-4">
                <div className='col-span-2'>
                  <p className=' text-[#575556] font-semibold text-[13px] text-[#D10A11] mt-4'>Profile Created For</p>
                </div>
                <div className="col-span-2">
                  <label className="relative block">
                  {formik.values.gender == "M" ?
                      <select
                      id="profileId"
                      name="profileId"
                      value={formik?.values?.profileId=="3"||formik?.values?.profileId=="5"?"":formik?.values?.profileId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        
                      }}
                      className=" form-input shadow-sm bg-[#E9E9E9] text-[#495057] text-[13px] md:text-[13px] focus:outline-none  text-center  rounded-[8px] focus:ring-[#2D2C6F] py-[8px] px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option className="p-2" value="" disabled selected hidden>
                      --Select--
                      </option>
                      <option value="1">Myself</option>
                      <option value="6">Relative</option>
                      <option value="7">Friend</option>
                      <option value="4">Brother</option>
                      <option value="2">Son</option>
                    </select>
                      :
                      formik.values.gender == "F" ?
                      <select
                      id="profileId"
                      name="profileId"
                      value={formik?.values?.profileId=="2"||formik?.values?.profileId=="4"?"":formik?.values?.profileId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                       
                      }}
                      className="form-input shadow-sm bg-[#E9E9E9] text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px]  text-center py-[8px] px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option className="p-2" value="" disabled selected hidden>
                      --Select--
                      </option>
                      <option value="1">Myself</option>
                      <option value="6">Relative</option>
                      <option value="7">Friend</option>
                      <option value="5">Sister</option>
                      <option value="3">Daughter</option>
                    </select>
                        :                    
                        <select
                          id="profileId"
                          name="profileId"
                          value={formik.values.profileId}
                          // onChange={formik.handleChange}
                          onChange={(event) => {
                            formik.handleChange(event);
                            // getstoreDetails(event)
                           
                          }}
                          className=" form-input shadow-sm bg-[#E9E9E9] text-[#495057] text-[13px] md:text-[13px]  focus:outline-none text-center rounded-[8px] focus:ring-[#2D2C6F] py-[8px] px-1 md:py-2 md:px-2  w-full block "
                        >
                          <option className="p-2" value="" disabled selected hidden>
                          --Select--
                          </option>
                          {dropdownData?.profileFor?.map((profile, i) => (
                            <option className="p-2" value={profile.id}>
                              {profile.name}
                            </option>
                          ))}
                        </select>
                    }
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] whitespace-nowrap">
                    {formik.errors.profileId && formik.touched.profileId && (
                      formik.errors.profileId
                    )}{" "}
                  </div>
                </div>
              </div>

            

              <div className="space-y-2 md:mt-[0.3rem] flex grid grid-cols-4 gap-4">
                <div className='col-span-2'>
                  <p className='text-[#575556] font-semibold text-[13px] text-[#D10A11] mt-4'>Mother Tongue</p>
                </div>
                <div className="col-span-2">
                  <label className="relative block">
                    <select
                      id="motherTongueId"
                      name="motherTongueId"
                      value={formik.values.motherTongueId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:event.target.value,
                        });
                      }}
                      className="profileOne text-center form-input shadow-sm  bg-[#E9E9E9] text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2  w-full block "
                    >
                      {/* <option value="" disabled selected hidden>
                        Select Mother Tongue
                      </option> */}
                      <option value="" >
                        --Select--
                      </option>
                      {dropdownData?.motherTongue?.map((language, i) => (
                        <option className="p-2" value={language.id}>
                          {language.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] whitespace-nowrap">
                    {formik.errors.motherTongueId && formik.touched.motherTongueId && (

                      formik.errors.motherTongueId

                    )}
                  </div>
                </div>
              </div>

              <div className="space-y-2 md:mt-[0.3rem] flex grid grid-cols-4 gap-4">
                <div className='col-span-2'>
                  <p className='text-[#575556] font-semibold text-[13px] text-[#D10A11] mt-4'>Religion</p>
                </div>
                <div className="col-span-2">
                  <label className="relative block">
                    <select
                      id="religionId"
                      name="religionId"
                      value={formik.values.religionId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:event.target.value
                        });
                      }}
                      className="profileOne text-center form-input shadow-sm  bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F] py-[8px] px-2  md:py-2 md:px-2  w-full block "
                    >
                      {/* <option value="" disabled selected hidden>
                        Select Religion
                      </option> */}
                      <option value="" >
                        --Select--
                      </option>
                      {dropdownData?.religion?.map((religion, i) => (
                        <option className="p-2" value={religion.id}>
                          {religion.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.religionId && formik.touched.religionId && (

                      formik.errors.religionId
                    )}{" "}
                  </div>
                </div>
              </div>

              {isChristian && <div className="space-y-2 md:mt-[0.3rem] flex grid grid-cols-4 gap-4">
                <div className='col-span-2'>
                  <p className='text-[#575556] font-semibold text-[13px] text-[#D10A11] mt-4'>Denomination</p>
                </div>
                <div className="col-span-2">
                  <label className="relative block">
                    <select
                      id="denomination"
                      name="denomination"
                      value={formik.values.denomination}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:formik.values.religionId,
                          domainId:event.target.value
                        });
                      }}
                      className="profileOne text-center form-input shadow-sm  bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F] py-[8px] px-2  md:py-2 md:px-2  w-full block "
                    >
                      {/* <option value="" disabled selected hidden>
                        Select Religion
                      </option> */}
                      <option value="" >
                        --Select--
                      </option>
                      {dropdownData?.domain?.map((denomination, i) => (
                        <option className="p-2" value={denomination.id}>
                          {denomination.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] whitespace-nowrap ">
                    {formik.errors.denomination && formik.touched.denomination && (

                      formik.errors.denomination
                    )}{" "}
                  </div>
                </div>
              </div>
              }

              <div className="space-y-2 md:mt-[0.3rem] flex grid grid-cols-4 gap-4" ref={dropdownRef}>
                <div className='col-span-2'>
                  <p className='text-[#575556] font-semibold text-[13px] text-[#D10A11] mt-4'>Caste</p>
                </div>
                <div className="col-span-2">
                  <label className="relative block">
                    {/* <select
                      id="casteId"
                      name="casteId"
                      value={formik.values.casteId}
                      onChange={(event) => {
                        formik.handleChange(event);
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:formik.values.religionId,
                          casteId:event.target.value
                        });
                       
                      }}
                    
                      className="profileOne text-center form-input shadow-sm bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2  w-full block "
                    >
                 
                      <option value="" >
                        --Select--
                      </option>
                      {casteList?.map((caste, i) => (
                        <option value={caste.id}>{caste.name}</option>
                      ))}
                    </select> */}
                        <div
                        className="one selectone text-start form-input shadow-sm  bg-[#FFFFFF]  !focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                        onClick={toggleDropdown}
                        onKeyDown={handleKeydown}
                        tabIndex={0} // To make the div focusable
                      >
                        {formik.values.casteId
                          ? sortedCasteList.find(caste => caste.id === formik.values.casteId)?.name || "--Select--"
                          : "--Select--"}
                      </div>
                 {isOpenDropdown && (
                        <div className=" text-start absolute z-10  w-full focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] bg-white border border-[#F18181]  text-[#000000] text-[10px] md:text-[13px] shadow-lg max-h-60 overflow-auto">
                          {sortedCasteList?.map((caste, i) =>
                            caste.name !== "Others" ? (
                              <div
                                key={i}
                                className="  text-[#000000] text-[10px] md:text-[13px] px-2 hover:text-white  hover:bg-[#f05638]"
                                onClick={() => handleCasteOptionClick(caste.id)}
                              >
                                {caste.name}
                              </div>
                            ) : null
                          )}
                          {sortedCasteList?.some((caste) => caste.name === "Others") && (
                            <div
                              className="sticky bottom-0 bg-white  px-2 hover:bg-[#f05638] hover:text-white  border-[#F18181]"
                              onClick={() =>
                                handleCasteOptionClick(
                                  sortedCasteList?.find((caste) => caste.name === "Others").id
                                )
                              }
                            >
                              Others
                            </div>
                          )}
                        </div>
                      )}
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.casteId && formik.touched.casteId && (
                      formik.errors.casteId
                    )}{" "}
                  </div>
                </div>
              </div>


              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="off"
                      value={formik.values.email}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        setEmailErrorMessage("")
                        setIsMailFocused(true)
                        if(error?.message == "Email already Exist"){
                          dispatch(restApiError());
                        }
                        // getstoreDetails(event)
                      }}
                      onBlur={() => setIsMailFocused(false)}
                      placeholder="Enter Your Email"
                      className="!placeholder-[#495057] form-input shadow-sm  bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2  w-full block "
                    />
                  </label>

                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {error?.message ?
                      error?.message == "Email already Exist" && (
                        error?.message
                      )
                      :
                      emailErrorMessage === "This email is already taken" ?
                      emailErrorMessage
                      :<></>
                     
                      // formik.errors.email && formik.touched.email && (
                      //   formik.errors.email
                      // )
                      }{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative flex justify-end	w-full">
                    <input
                      id="password"
                      name="password"
                      type={showHidePassword ? "text" : "password"}
                      autoComplete="off"
                      placeholder="Enter Your Password"
                      value={formik.values.password}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setIsMailFocused(true)
                      }}
                      onBlur={() => setIsMailFocused(false)}
                      className="!placeholder-[#495057] form-input shadow-sm bg-[#E9E9E9]  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2   md:py-2 md:px-2  w-full block "
                    />
                    <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                      <img
                        onClick={() => changeShowHidePassword(!showHidePassword)}
                        src={
                          showHidePassword
                            ? EyeOpenIcon
                            : EyeIcon
                        }
                        
                        className=" md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                        alt=""
                      />
                    </div>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] whitespace-nowrap">
                    {formik.errors.password && formik.touched.password && (
                      formik.errors.password
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div className="ml-[2rem] md:ml-0">
                  <label className="relative block float-left flex">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      value={formik.values.terms}
                      onChange={formik.handleChange}
                      className="mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181] default:ring-2"
                      autoComplete="off"
                    />
                    <span className=" text-[13px] md:ml-[3px] lg:ml-[5px]">
                      <a href={RouteSegments.TERMS_AND_CONDITION} target="_blank" className="hover:underline hover:text-[#151515] pl-2">
                        I Accept your Terms and Conditions
                      </a>
                    </span>
                  </label><br />
                  <div className="text-left text-[red] text-[12px] h-[0.3rem] left-[3px] relative top-[0px]">
                    {formik.errors.terms && formik.touched.terms && (
                      formik.errors.terms
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.8rem]">
                <div>
                  <label className="relative top-[18px] md:top-[6px] flex justify-center">
                    <button
                      type="submit"
                      className="flex justify-center px-[3rem] py-2 border border-transparent rounded-[10px] shadow-sm text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    >
                      Submit & Continue
                    </button>
                  </label>
                </div>
              </div>
              <br />
            </form>
            : <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
          }
        </div>
        :
        props?.subMenu==="googleLanding" ?
        <div className={`${props?.menu == "registerPopup" ? "App mt-2  pr-[15px] md:px-[12px]  h-[20.5rem] overflow-auto" : "App"}`}>
          {showLoader && <Loader className="loaderTransperancyGoogle" loaderClassName="loaderTransperancySpinGoogle" />}
          {dropdownData ?
            <form onSubmit={formik.handleSubmit}>
              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <input
                      type="text"
                      // onKeyDown={(event) => blockInvalidCharForText(event)}
                      name="name"
                      id="name"
                      autoComplete="off"
                      placeholder="Enter Your Name"
                      className="form-control  shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                      value={formik.values.name}
                      onChange={(e) => {
                        nameCheckList(e.target.value,e);
                        setIsMailFocused(true)
                        let text = e.target.value ?? "";
                        text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                        text = text.charAt(0).toUpperCase() + text.slice(1)
                        var splitStr = text.toLowerCase().split(' ');
                        for (var i = 0; i < splitStr.length; i++) {
                            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                        }
                        text =  splitStr.join(' '); 
                        e.target.value = text;
                        if ((e.target.value.split(".").length - 1) <= 1) {
                          formik.handleChange(e)
                        }
                      }}
                      onBlur={() => setIsMailFocused(false)}
                    />
                  </label>
                </div>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {formik.errors.name && formik.touched.name && (
                    formik.errors.name
                  )}
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div className="space-y-1 md:mt-[0.3rem] col-span-1">
                  <div>
                    <label className="relative block">
                      <select
                        id="countryId"
                        name="countryId"
                        value={formik.values.countryId}
                        onChange={formik.handleChange}
                        onClick={(e)=>{
                          e.preventDefault();
                          // setCountryDropdownSelect(true);
                          countrySelect(e);
                        }}
                        className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block !bg-[position:calc(100%_-_5px)_8px] md:!bg-[position:calc(100%_-_5px)_14px] lg:!bg-[position:calc(100%_-_5px)_14px]"
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {/* {!countryDropdownSelect && !isMobile &&
                        dropdownData?.isdcode?.slice(0,9).map((isdcode, i) => (
                          <option className="p-2" value={isdcode.countryId}>
                            {isdcode.isdcode + "," + isdcode.country}
                          </option>
                        ))} */}
                        {countryDropdownSelect &&
                        dropdownData?.isdcode?.map((isdcode, i) => (
                          <option className="p-2" value={isdcode.countryId}>
                            {isdcode.isdcode + "," + isdcode.country}
                          </option>
                        ))}
                        

                        <img className="" src={DropdownIcon} alt=""/>
                      </select>
                      
                    </label>
                  </div>
                </div>
                <div className="space-y-1 md:mt-[0.3rem] col-span-2">
                  <div>
                    <label className="relative block RegPhone" >
                      <input
                        id="phone"
                        name="phone"
                        type="tel"
                        onKeyDown={(event) => blockInvalidCharForNumber(event, props?.values?.countryId == "+91" ? 10 : 15)}
                        autoComplete="off"
                        placeholder="Enter Your Phone Number"
                        value={formik.values.phone}
                        // onChange={(e)=>{
                        //   if(validateNumber(e.target.value)){
                        //     formik.handleChange(e)
                        //   }
                        // }}
                        onChange={(event) => {
                          const { value } = event.target;
                          // formik.handleChange(event);
                          setIsMailFocused(true)
                          if(validateNumber(event.target.value)){
                            formik.handleChange(event)
                          }
                        }}
                        onBlur={() => setIsMailFocused(false)}
                        className="RegPhone form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2 w-full  block "
                      />
                    </label>

                    <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                      {error?.message ?
                        error?.message == "Mobile Number already Exist" && (
                          error?.message
                        )
                        :
                        dataErrorMessage?
                        dataErrorMessage ==="Mobile number already exists"&&(
                          dataErrorMessage
                        ):
                        formik.errors.phone && formik.touched.phone && (
                          formik.errors.phone
                        )}{" "}

                    </div>
                  </div>
                </div>
              </div>
                <div className="space-y-1 md:mt-[0.3rem]">

                <div>
                  <label className="relative block">
                        <select
                          id="gender"
                          name="gender"
                          value={formik.values.gender}
                          // onChange={formik.handleChange}
                          onChange={(event) => {
                            formik.handleChange(event);
                            // getstoreDetails(event); // This line should trigger the genderstoreDetails function
                            console.log({
                              name: formik.values.name,
                              phone: formik.values.phone,
                              gender: event.target.value,
                            });
                          }}
                          className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                        >
                          <option value="" disabled selected hidden>
                            Select Gender
                          </option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.gender && formik.touched.gender && (
                      formik.errors.gender
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                  {formik.values.gender == "M" ?
                      <select
                      id="profileId"
                      name="profileId"
                      value={formik?.values?.profileId=="3"||formik?.values?.profileId=="5"?"":formik?.values?.profileId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: event.target.value,
                        });
                      }}
                      className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option className="p-2" value="" disabled selected hidden>
                        Profile Created For
                      </option>
                      <option value="1">Myself</option>
                      <option value="6">Relative</option>
                      <option value="7">Friend</option>
                      <option value="4">Brother</option>
                      <option value="2">Son</option>
                    </select>
                      :
                      formik.values.gender == "F" ?
                      <select
                      id="profileId"
                      name="profileId"
                      value={formik?.values?.profileId=="2"||formik?.values?.profileId=="4"?"":formik?.values?.profileId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: event.target.value,
                        });
                      }}
                      className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option className="p-2" value="" disabled selected hidden>
                        Profile Created For
                      </option>
                      <option value="1">Myself</option>
                      <option value="6">Relative</option>
                      <option value="7">Friend</option>
                      <option value="5">Sister</option>
                      <option value="3">Daughter</option>
                    </select>
                        :                    
                        <select
                          id="profileId"
                          name="profileId"
                          value={formik.values.profileId}
                          // onChange={formik.handleChange}
                          onChange={(event) => {
                            formik.handleChange(event);
                            // getstoreDetails(event)
                            console.log({
                              name: formik.values.name,
                              phone: formik.values.phone,
                              gender: formik.values.gender,
                              profileId: event.target.value,
                            });
                          }}
                          className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                        >
                          <option className="p-2" value="" disabled selected hidden>
                            Profile Created For
                          </option>
                          {dropdownData?.profileFor?.map((profile, i) => (
                            <option className="p-2" value={profile.id}>
                              {profile.name}
                            </option>
                          ))}
                        </select>
                    }
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.profileId && formik.touched.profileId && (
                      formik.errors.profileId
                    )}{" "}
                  </div>
                </div>
              </div>

            

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <select
                      id="motherTongueId"
                      name="motherTongueId"
                      value={formik.values.motherTongueId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:event.target.value,
                        });
                      }}
                      onClick={(e)=>{
                        e.preventDefault();
                        // setMotherTongueDropdownSelect(true)
                      }}
                      className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option value="" disabled selected hidden>
                        Select Mother Tongue
                      </option>
                      {
                      dropdownData?.motherTongue?.map((language, i) => (
                        <option className="p-2" value={language.id}>
                          {language.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.motherTongueId && formik.touched.motherTongueId && (

                      formik.errors.motherTongueId

                    )}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <select
                      id="religionId"
                      name="religionId"
                      value={formik.values.religionId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:event.target.value
                        });
                      }}
                      className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option value="" disabled selected hidden>
                        Select Religion
                      </option>
                      {dropdownData?.religion?.map((religion, i) => (
                        <option className="p-2" value={religion.id}>
                          {religion.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.religionId && formik.touched.religionId && (

                      formik.errors.religionId
                    )}{" "}
                  </div>
                </div>
              </div>

                {isChristian && <div className="space-y-1 md:mt-[0.3rem]">
                  <div>
                    <label className="relative block">
                      <select
                        id="denomination"
                        name="denomination"
                        value={formik.values.denomination}
                        // onChange={formik.handleChange}
                        onChange={(event) => {
                          formik.handleChange(event);
                          // getstoreDetails(event)
                          console.log({
                            name: formik.values.name,
                            phone: formik.values.phone,
                            gender: formik.values.gender,
                            profileId: formik.values.profileId,
                            motherTongueId:formik.values.motherTongueId,
                            religionId:formik.values.religionId,
                            domainId:event.target.value
                          });
                        }}
                        className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                      >
                        <option value="" disabled selected hidden>
                          Select Denomination
                        </option>
                        {dropdownData?.domain?.map((denomination, i) => (
                          <option className="p-2" value={denomination.id}>
                            {denomination.name}
                          </option>
                        ))}
                      </select>
                    </label>
                    <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                      {formik.errors.denomination && formik.touched.denomination && (

                        formik.errors.denomination
                      )}{" "}
                    </div>
                  </div>
                </div>}

              {/* <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <select
                      id="casteId"
                      name="casteId"
                      value={formik.values.casteId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:formik.values.religionId,
                          casteId:event.target.value
                        });
                      }}
                     
                      className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option value="" disabled selected hidden>
                        Select Caste
                      </option>
                      {sortedCasteList?.map((caste, i) => (
                      <option
                        key={i}
                        value={caste.id}
                        className={ caste.id=="433" && isLastOptionOthers ? "!absolute !bottom-0 !z-[1000] !bg-white" : ""}
                      >
                        {caste.name}
                      </option>
                    ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.casteId && formik.touched.casteId && (
                      formik.errors.casteId
                    )}{" "}
                  </div>
                </div>
              </div> */}

         
               

                <div className="space-y-1 md:mt-[0.3rem] relative" ref={dropdownRef}>
    <div>
      <label className="relative block">
        <div
          className="one selectone text-start form-input shadow-sm bg-[#FFFFFF] !focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] border border-[#F18181] py-1 px-1 md:py-2 md:px-2 w-full block"
          onClick={toggleDropdown}
          onKeyDown={handleKeydown}
          tabIndex={0} // To make the div focusable
        >
          {formik.values.casteId
            ? sortedCasteList.find(caste => caste.id === formik.values.casteId)?.name || "Select Caste"
            : "Select Caste"}
        </div>
        {isOpen && (
          <div className="text-start absolute z-10 w-full focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] bg-white border border-[#F18181] text-[#000000] text-[10px] md:text-[13px] shadow-lg max-h-60 overflow-auto">
            {sortedCasteList?.map((caste, i) => (
              <div
                key={i}
                className={`text-[#000000] text-[10px] md:text-[13px] px-2 hover:text-white hover:bg-[#f05638] ${
                  i === selectedIndex ? "bg-[#f05638] text-white" : ""
                }`}
                onClick={() => handleOptionClick(caste.id)}
              >
                {caste.name}
              </div>
            ))}
            {sortedCasteList?.some((caste) => caste.name === "Others") && (
              <div
                className="sticky bottom-0 px-2 hover:bg-[#f05638] hover:text-white border-[#F18181] font-bold bg-[#F7F8FA]"
                onClick={() =>
                  handleOptionClick(
                    sortedCasteList?.find((caste) => caste.name === "Others").id
                  )
                }
              >
                Others
              </div>
            )}
          </div>
        )}
      </label>
      <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem]">
        {formik.errors.casteId && formik.touched.casteId && formik.errors.casteId}
      </div>
    </div>
           </div>
                  
              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="off"
                      value={formik.values.email}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event)
                         validateEmail(event.target.value)
                         setEmailErrorMessage("")
                         setIsMailFocused(true)
                         if(error?.message == "Email already Exist"){
                          dispatch(restApiError());
                        }
                        //  getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:formik.values.religionId,
                          casteId:formik.values.casteId,
                          email:event.target.value

                        });
                      }}
                      onBlur={() => setIsMailFocused(false)}
                      placeholder="Enter Your Email"
                      className="form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    />
                  </label>

                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {error?.message ?
                      error?.message == "Email already Exist" && (
                        error?.message
                      )
                      :
                      emailErrorMessage ==="This email is already taken"?
                      emailErrorMessage
                      :
                      <></>
                      // formik.errors.email && formik.touched.email && (
                      //   formik.errors.email
                      // )
                      }{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative flex justify-end	w-full">
                    <input
                      id="password"
                      name="password"
                      type={showHidePassword ? "text" : "password"}
                      autoComplete="off"
                      placeholder="Enter Your Password"
                      value={formik.values.password}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setIsMailFocused(true)
                      }}
                      onBlur={() => setIsMailFocused(false)}
                      className="form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    />
                    <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                      <img
                        onClick={() => changeShowHidePassword(!showHidePassword)}
                        src={
                          showHidePassword
                          ? "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161601/passwordeyeopen_jkm7v5.png"
                          : "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161797/passwordeye_cpa0p8.png"
                        }
                        className=" md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                        alt=""
                      />
                    </div>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem]  whitespace-nowrap">
                    {formik.errors.password && formik.touched.password && (
                      formik.errors.password
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block float-left flex">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      value={formik.values.terms}
                      onChange={formik.handleChange}
                      className="mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181] default:ring-2"
                      autoComplete="off"
                    />
                    <span className=" text-[13px] md:ml-[3px] lg:ml-[5px]">
                      <a href={RouteSegments.TERMS_AND_CONDITION} target="_blank" className="hover:underline hover:text-[#151515]">
                        I Accept your Terms and Conditions
                      </a>
                    </span>
                    {/* <span className="md:hidden text-[13px] md:ml-[3px] lg:ml-[5px]">
                      <a href={RouteSegments.MOBILE_TERMS_AND_CONDITION} target="_blank" className="hover:underline hover:text-[#151515] pl-2">
                        I Accept your Terms and Conditions
                      </a>
                    </span> */}
                  </label><br />
                  <div className="text-left text-[red] text-[12px] h-[0.3rem] left-[3px] relative top-[0px]">
                    {formik.errors.terms && formik.touched.terms && (
                      formik.errors.terms
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.8rem]">
                <div>
                  <label className="relative top-[18px] md:top-[6px] ">
                    <button
                      type="submit"
                      className="w-full flex justify-center text-left py-2 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    >
                      {isCommunitySite 
                        ? <span>Register at just<span className="mx-1"><RupeeSymbol/> {registrationAmont}</span></span> 
                        : "Submit"
                      }
                    </button>
                  </label>
                </div>
              </div>
              <br />
            </form>
            : <Loader className="loaderTransperancyGoogle" loaderClassName="loaderTransperancySpinGoogle" />
          }
        </div>
        :props?.subMenu==="onlineLanding" ?
        <div className={`${props?.menu == "registerPopup" ? "App mt-2  pr-[15px] md:px-[12px]  h-[20.5rem] overflow-auto" : "App"}`}>
        {showLoader && <Loader className="loaderTransperancyGoogle" loaderClassName="loaderTransperancySpinGoogle" />}
        {dropdownData ?
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-1 md:mt-[0.3rem]">
              <div>
                <label className="relative block">
                  <input
                    type="text"
                    // onKeyDown={(event) => blockInvalidCharForText(event)}
                    name="name"
                    id="name"
                    autoComplete="off"
                    placeholder="Enter Your Name"
                    className="form-control  shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    value={formik.values.name}
                    onChange={(e) => {
                      nameCheckList(e.target.value,e);
                      setIsMailFocused(true)
                      let text = e.target.value ?? "";
                      text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                      text = text.charAt(0).toUpperCase() + text.slice(1)
                      var splitStr = text.toLowerCase().split(' ');
                      for (var i = 0; i < splitStr.length; i++) {
                          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                      }
                      text =  splitStr.join(' '); 
                      e.target.value = text;
                      if ((e.target.value.split(".").length - 1) <= 1) {
                        formik.handleChange(e)
                      }
                    }}
                    onBlur={() => setIsMailFocused(false)}
                  />
                </label>
              </div>
              <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                {formik.errors.name && formik.touched.name && (
                  formik.errors.name
                )}
              </div>
            </div>

          
              <div className="space-y-1 md:mt-[0.3rem]">

              <div>
                <label className="relative block">
                      <select
                        id="gender"
                        name="gender"
                        value={formik.values.gender}
                        // onChange={formik.handleChange}
                        onChange={(event) => {
                          formik.handleChange(event);
                          // getstoreDetails(event); // This line should trigger the genderstoreDetails function
                          console.log({
                            name: formik.values.name,
                            phone: formik.values.phone,
                            gender: event.target.value,
                          });
                        }}
                        className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                      >
                        <option value="" disabled selected hidden>
                          Select Gender
                        </option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                </label>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {formik.errors.gender && formik.touched.gender && (
                    formik.errors.gender
                  )}{" "}
                </div>
              </div>
            </div>

            <div className="space-y-1 md:mt-[0.3rem]">
              <div>
                <label className="relative block">
                {formik.values.gender == "M" ?
                    <select
                    id="profileId"
                    name="profileId"
                    value={formik?.values?.profileId=="3"||formik?.values?.profileId=="5"?"":formik?.values?.profileId}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event);
                      // getstoreDetails(event)
                      console.log({
                        name: formik.values.name,
                        phone: formik.values.phone,
                        gender: formik.values.gender,
                        profileId: event.target.value,
                      });
                    }}
                    className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                  >
                    <option className="p-2" value="" disabled selected hidden>
                      Profile Created For
                    </option>
                    <option value="1">Myself</option>
                    <option value="6">Relative</option>
                    <option value="7">Friend</option>
                    <option value="4">Brother</option>
                    <option value="2">Son</option>
                  </select>
                    :
                    formik.values.gender == "F" ?
                    <select
                    id="profileId"
                    name="profileId"
                    value={formik?.values?.profileId=="2"||formik?.values?.profileId=="4"?"":formik?.values?.profileId}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event);
                      // getstoreDetails(event)
                      console.log({
                        name: formik.values.name,
                        phone: formik.values.phone,
                        gender: formik.values.gender,
                        profileId: event.target.value,
                      });
                    }}
                    className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                  >
                    <option className="p-2" value="" disabled selected hidden>
                      Profile Created For
                    </option>
                    <option value="1">Myself</option>
                    <option value="6">Relative</option>
                    <option value="7">Friend</option>
                    <option value="5">Sister</option>
                    <option value="3">Daughter</option>
                  </select>
                      :                    
                      <select
                        id="profileId"
                        name="profileId"
                        value={formik.values.profileId}
                        // onChange={formik.handleChange}
                        onChange={(event) => {
                          formik.handleChange(event);
                          // getstoreDetails(event)
                          console.log({
                            name: formik.values.name,
                            phone: formik.values.phone,
                            gender: formik.values.gender,
                            profileId: event.target.value,
                          });
                        }}
                        className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                      >
                        <option className="p-2" value="" disabled selected hidden>
                          Profile Created For
                        </option>
                        {dropdownData?.profileFor?.map((profile, i) => (
                          <option className="p-2" value={profile.id}>
                            {profile.name}
                          </option>
                        ))}
                      </select>
                  }
                </label>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {formik.errors.profileId && formik.touched.profileId && (
                    formik.errors.profileId
                  )}{" "}
                </div>
              </div>
            </div>

          

            <div className="space-y-1 md:mt-[0.3rem]">
              <div>
                <label className="relative block">
                  <select
                    id="motherTongueId"
                    name="motherTongueId"
                    value={formik.values.motherTongueId}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event);
                      // getstoreDetails(event)
                      console.log({
                        name: formik.values.name,
                        phone: formik.values.phone,
                        gender: formik.values.gender,
                        profileId: formik.values.profileId,
                        motherTongueId:event.target.value,
                      });
                    }}
                    onClick={(e)=>{
                      e.preventDefault();
                      // setMotherTongueDropdownSelect(true)
                    }}
                    className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                  >
                    <option value="" disabled selected hidden>
                      Select Mother Tongue
                    </option>
                    {
                    dropdownData?.motherTongue?.map((language, i) => (
                      <option className="p-2" value={language.id}>
                        {language.name}
                      </option>
                    ))}
                  </select>
                </label>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {formik.errors.motherTongueId && formik.touched.motherTongueId && (

                    formik.errors.motherTongueId

                  )}
                </div>
              </div>
            </div>

            <div className="space-y-1 md:mt-[0.3rem]">
              <div>
                <label className="relative block">
                  <select
                    id="religionId"
                    name="religionId"
                    value={formik.values.religionId}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event);
                      // getstoreDetails(event)
                      console.log({
                        name: formik.values.name,
                        phone: formik.values.phone,
                        gender: formik.values.gender,
                        profileId: formik.values.profileId,
                        motherTongueId:formik.values.motherTongueId,
                        religionId:event.target.value
                      });
                    }}
                    className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                  >
                    <option value="" disabled selected hidden>
                      Select Religion
                    </option>
                    {dropdownData?.religion?.map((religion, i) => (
                      <option className="p-2" value={religion.id}>
                        {religion.name}
                      </option>
                    ))}
                  </select>
                </label>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {formik.errors.religionId && formik.touched.religionId && (

                    formik.errors.religionId
                  )}{" "}
                </div>
              </div>
            </div>

              {isChristian && <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <select
                      id="denomination"
                      name="denomination"
                      value={formik.values.denomination}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:formik.values.religionId,
                          domainId:event.target.value
                        });
                      }}
                      className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option value="" disabled selected hidden>
                        Select Denomination
                      </option>
                      {dropdownData?.domain?.map((denomination, i) => (
                        <option className="p-2" value={denomination.id}>
                          {denomination.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.denomination && formik.touched.denomination && (

                      formik.errors.denomination
                    )}{" "}
                  </div>
                </div>
              </div>}

            <div className="space-y-1 md:mt-[0.3rem]">
              <div>
                <label className="relative block">
                  <select
                    id="casteId"
                    name="casteId"
                    value={formik.values.casteId}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event);
                      // getstoreDetails(event)
                      console.log({
                        name: formik.values.name,
                        phone: formik.values.phone,
                        gender: formik.values.gender,
                        profileId: formik.values.profileId,
                        motherTongueId:formik.values.motherTongueId,
                        religionId:formik.values.religionId,
                        casteId:event.target.value
                      });
                    }}
                    className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                  >
                    <option value="" disabled selected hidden>
                      Select Caste
                    </option>
                    {casteList?.map((caste, i) => (
                      <option value={caste.id}>{caste.name}</option>
                    ))}
                  </select>
                </label>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {formik.errors.casteId && formik.touched.casteId && (
                    formik.errors.casteId
                  )}{" "}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div className="space-y-1 md:mt-[0.3rem] col-span-1">
                <div>
                  <label className="relative block">
                    <select
                      id="countryId"
                      name="countryId"
                      value={formik.values.countryId}
                      onChange={formik.handleChange}
                      // onClick={(e)=>{
                      //   e.preventDefault();
                      //   // setCountryDropdownSelect(true);
                      //   countrySelect(e);
                      // }}
                      className="one form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block !bg-[position:calc(100%_-_5px)_8px] md:!bg-[position:calc(100%_-_5px)_14px] lg:!bg-[position:calc(100%_-_5px)_14px]"
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      {/* {!countryDropdownSelect && !isMobile &&
                      dropdownData?.isdcode?.slice(0,9).map((isdcode, i) => (
                        <option className="p-2" value={isdcode.countryId}>
                          {isdcode.isdcode + "," + isdcode.country}
                        </option>
                      ))}
                      {countryDropdownSelect &&
                      dropdownData?.isdcode?.map((isdcode, i) => (
                        <option className="p-2" value={isdcode.countryId}>
                          {isdcode.isdcode + "," + isdcode.country}
                        </option>
                      ))} */}
                      {dropdownData?.isdcode?.map((isdcode, i) => (
                        <option className="p-2" value={isdcode.countryId}>
                          {isdcode.isdcode + "," + isdcode.country}
                        </option>
                      ))}
                      

                      <img className="" src={DropdownIcon} alt=""/>
                    </select>
                    
                  </label>
                </div>
              </div>
              <div className="space-y-1 md:mt-[0.3rem] col-span-2">
                <div>
                  <label className="relative block RegPhone" >
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      onKeyDown={(event) => blockInvalidCharForNumber(event, props?.values?.countryId == "+91" ? 10 : 15)}
                      autoComplete="off"
                      placeholder="Enter Your Phone Number"
                      value={formik.values.phone}
                      // onChange={(e)=>{
                      //   if(validateNumber(e.target.value)){
                      //     formik.handleChange(e)
                      //   }
                      // }}
                      onChange={(event) => {
                        const { value } = event.target;
                        // formik.handleChange(event);
                        setIsMailFocused(true)
                        if(validateNumber(event.target.value)){
                          formik.handleChange(event)
                        }
                      }}
                      onBlur={() => setIsMailFocused(false)}
                      className="RegPhone form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2 w-full  block "
                    />
                  </label>

                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {error?.message ?
                      error?.message == "Mobile Number already Exist" && (
                        error?.message
                      )
                      :
                      dataErrorMessage?
                      dataErrorMessage ==="Mobile number already exists"&&(
                        dataErrorMessage
                      ):
                      formik.errors.phone && formik.touched.phone && (
                        formik.errors.phone
                      )}{" "}

                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-1 md:mt-[0.3rem]">
              <div>
                <label className="relative block">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="off"
                    value={formik.values.email}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event)
                       validateEmail(event.target.value)
                       setEmailErrorMessage("")
                       setIsMailFocused(true)
                       if(error?.message == "Email already Exist"){
                        dispatch(restApiError());
                      }
                      //  getstoreDetails(event)
                      console.log({
                        name: formik.values.name,
                        phone: formik.values.phone,
                        gender: formik.values.gender,
                        profileId: formik.values.profileId,
                        motherTongueId:formik.values.motherTongueId,
                        religionId:formik.values.religionId,
                        casteId:formik.values.casteId,
                        email:event.target.value

                      });
                    }}
                    onBlur={() => setIsMailFocused(false)}
                    placeholder="Enter Your Email"
                    className="form-input shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                  />
                </label>

                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {error?.message ?
                    error?.message == "Email already Exist" && (
                      error?.message
                    )
                    :
                    emailErrorMessage ==="This email is already taken"?
                    emailErrorMessage
                    :
                    <></>
                    // formik.errors.email && formik.touched.email && (
                    //   formik.errors.email
                    // )
                    }{" "}
                </div>
              </div>
            </div>

            <div className="space-y-1 md:mt-[0.3rem]">
              <div>
                <label className="relative flex justify-end	w-full">
                  <input
                    id="password"
                    name="password"
                    type={showHidePassword ? "text" : "password"}
                    autoComplete="off"
                    placeholder="Enter Your Password"
                    value={formik.values.password}
                    onChange={(e) => {
                      formik.handleChange(e)
                      setIsMailFocused(true)
                    }}
                    onBlur={() => setIsMailFocused(false)}
                    className="form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                  />
                  <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                    <img
                      onClick={() => changeShowHidePassword(!showHidePassword)}
                      src={
                        showHidePassword
                        ? "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161601/passwordeyeopen_jkm7v5.png"
                        : "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161797/passwordeye_cpa0p8.png"
                      }
                      className=" md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                      alt=""
                    />
                  </div>
                </label>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem]  whitespace-nowrap">
                  {formik.errors.password && formik.touched.password && (
                    formik.errors.password
                  )}{" "}
                </div>
              </div>
            </div>

            <div className="space-y-1 md:mt-[0.3rem]">
              <div>
                <label className="relative block float-left flex">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    value={formik.values.terms}
                    onChange={formik.handleChange}
                    className="mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181] default:ring-2"
                    autoComplete="off"
                  />
                  <span className=" text-[13px] md:ml-[3px] lg:ml-[5px]">
                    <a href={RouteSegments.TERMS_AND_CONDITION} target="_blank" className="hover:underline hover:text-[#151515]">
                      I Accept your Terms and Conditions
                    </a>
                  </span>
                  {/* <span className="md:hidden text-[13px] md:ml-[3px] lg:ml-[5px]">
                    <a href={RouteSegments.MOBILE_TERMS_AND_CONDITION} target="_blank" className="hover:underline hover:text-[#151515] pl-2">
                      I Accept your Terms and Conditions
                    </a>
                  </span> */}
                </label><br />
                <div className="text-left text-[red] text-[12px] h-[0.3rem] left-[3px] relative top-[0px]">
                  {formik.errors.terms && formik.touched.terms && (
                    formik.errors.terms
                  )}{" "}
                </div>
              </div>
            </div>

            <div className="space-y-1 md:mt-[0.8rem]">
              <div>
                <label className="relative top-[18px] md:top-[6px] ">
                  <button
                    type="submit"
                    className="w-full flex justify-center text-left py-2 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                  >
                    Submit
                  </button>
                </label>
              </div>
            </div>
            <br />
          </form>
          : <Loader className="loaderTransperancyGoogle" loaderClassName="loaderTransperancySpinGoogle" />
        }
      </div>
       : 
       <div className={`${props?.menu == "registerPopup" ? "App mt-2  pr-[15px] md:px-[12px]  h-[20.5rem] overflow-auto" : "App"}`}>
          {showLoader && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
          {dropdownData ? 
            <form onSubmit={formik.handleSubmit}>
              <div className="space-y-1 md:mt-[0.3rem] 12">
                <div className="">
                  <label className="relative block">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      placeholder="Enter Your Name"
                      className={`focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] form-control bg-[#FFFFFF] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10]  shadow-lg" : "border-[#F18181] shadow-sm"}`}
                      value={formik.values.name}
                      onChange={(e) => {
                        nameCheckList(e.target.value,e)
                        setIsMailFocused(true)
                        let text = e.target.value ?? "";
                        text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                        text = text.charAt(0).toUpperCase() + text.slice(1)
                        var splitStr = text.toLowerCase().split(' ');
                        for (var i = 0; i < splitStr.length; i++) {
                            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                        }
                        text =  splitStr.join(' '); 
                        e.target.value = text;
                        if ((e.target.value.split(".").length - 1) <= 1) {
                          formik.handleChange(e)
                          // getstoreDetails(e)
                        }
                      }
                    }
                    onBlur={() => setIsMailFocused(false)}

                    />
                  </label>
                </div>
                <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                  {formik.errors.name && formik.touched.name && (
                    formik.errors.name
                  )}
                </div>
              </div>
    <div className="grid grid-cols-3 gap-4">
                <div className="space-y-1 md:mt-[0.3rem] col-span-1">
                  <div>
                    <label className="relative block">
                      <select
                        id="countryId"
                        name="countryId"
                        value={formik.values.countryId}
                        onChange={formik.handleChange}
                        onClick={(e)=>{
                          // e.preventDefault();
                          // setCountryDropdownSelect(true);
                          countrySelect(e);
                        }}
                        className={`one form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block !bg-[position:calc(100%_-_5px)_8px] md:!bg-[position:calc(100%_-_5px)_14px] lg:!bg-[position:calc(100%_-_5px)_14px] ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                      >
                        <option className="p-2" value="" disabled selected>
                          Select
                        </option>
                        {/* {!countryDropdownSelect && !isMobile &&
                        dropdownData?.isdcode?.slice(0,9).map((isdcode, i) => (
                          <option className="p-2" value={isdcode.countryId}>
                            {isdcode.isdcode + "," + isdcode.country}
                          </option>
                        ))} */}
                        {
                        countryDropdownSelect &&
                        dropdownData?.isdcode?.map((isdcode, i) => (
                          <option className="p-2" value={isdcode.countryId}>
                            {isdcode.isdcode + "," + isdcode.country}
                          </option>
                        ))}
                        

                        <img className="" src={DropdownIcon} alt=""/>
                      </select>
                      
                    </label>
                  </div>
                </div>
                <div className="space-y-1 md:mt-[0.3rem] col-span-2">
                  <div>
                    <label className="relative block RegPhone" >
                      <input
                        id="phone"
                        name="phone"
                        type="tel"
                        onKeyDown={(event) => blockInvalidCharForNumber(event, props?.values?.countryId == "+91" ? 10 : 15)}
                        autoComplete="off"
                        placeholder="Enter Your Phone Number"
                        value={formik.values.phone}
                       
                        onChange={(event) => {
                          const { value } = event.target;
                          // formik.handleChange(event);
                          setIsMailFocused(true)
                          if(validateNumber(event.target.value)){
                            formik.handleChange(event)
                          }
                          console.log({
                            name: formik.values.name,
                            phone: event.target.value,
                            
                          });
                        }}
                        onBlur={() => setIsMailFocused(false)}
                        className={`RegPhone form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2 w-full  block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10]  shadow-lg" : "border-[#F18181] shadow-sm"}`}
                      />
                    </label>

                    <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                      {error?.message ?
                        error?.message == "Mobile Number already Exist" && (
                          error?.message
                        ):
                        dataErrorMessage?
                        dataErrorMessage ==="Mobile number already exists"&&(
                          dataErrorMessage
                        )
                        :
                       
                        formik.errors.phone  && formik.touched.phone && formik.errors.phone &&
                        (
                          formik.errors.phone
                        )
                        }{" "}

                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                        <select
                          id="gender"
                          name="gender"
                          value={formik.values.gender}
                          // onChange={formik.handleChange}

                          onChange={(event) => {
                            formik.handleChange(event);
                            // getstoreDetails( event); // This line should trigger the genderstoreDetails function
                            console.log({
                              name: formik.values.name,
                              phone: formik.values.phone,
                              gender: event.target.value,
                            });
                          }}
                          // onFocus={(event) => {
                          //   getstoreDetails(event.target.value, event);
                          // }}
                         
                          onTouchStart={()=>setGenderDropdownSelect(true)}
                          className={`one form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                        >
                          <option value="" disabled selected hidden>
                            Select Gender
                          </option>
                          {isMobile && genderDropdownSelect ?
                          <option value="M">Male</option> :
                          <option value="M">Male</option>
                          }
                          {isMobile &&genderDropdownSelect ?
                          <option value="F">Female</option> :
                          <option value="F">Female</option>
                          }
                        </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.gender && formik.touched.gender && (
                      formik.errors.gender
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                  {formik.values.gender == "M" ?
                      <select
                      id="profileId"
                      name="profileId"
                      value={formik?.values?.profileId=="3"||formik?.values?.profileId=="5"?"":formik?.values?.profileId}
                      // onChange={formik.handleChange}
                      // onFocus={() => console.log({ name: formik.values.name, gender: formik.values.gender, profileId: formik.values.profileId })}

                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: event.target.value,
                        });
                      }}
                      // onFocus={() => {
                      //   console.log({
                      //     name: formik.values.name,
                      //     phone: formik.values.phone,
                      //     gender: formik.values.gender,
                      //     profileId: formik.values.profileId
                      //   });
                      // }}
                      className={`one form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                    >
                      <option className="p-2" value="" disabled selected hidden>
                        Profile Created For
                      </option>
                      <option value="1">Myself</option>
                      <option value="6">Relative</option>
                      <option value="7">Friend</option>
                      <option value="4">Brother</option>
                      <option value="2">Son</option>
                    </select>
                      :
                      formik.values.gender == "F" ?
                      <select
                      id="profileId"
                      name="profileId"
                      value={formik?.values?.profileId=="2"||formik?.values?.profileId=="4"?"":formik?.values?.profileId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: event.target.value,
                        });
                      }}
                      // onFocus={() => {
                      //   console.log({
                      //     name: formik.values.name,
                      //     phone: formik.values.phone,
                      //     gender: formik.values.gender,
                      //     profileId: formik.values.profileId
                      //   });
                      // }}
                      className={`one form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                    >
                      <option className="p-2" value="" disabled selected hidden>
                        Profile Created For
                      </option>
                      <option value="1">Myself</option>
                      <option value="6">Relative</option>
                      <option value="7">Friend</option>
                      <option value="5">Sister</option>
                      <option value="3">Daughter</option>
                    </select>
                        :                    
                        <select
                          id="profileId"
                          name="profileId"
                          value={formik.values.profileId}
                          // onChange={formik.handleChange}
                          onChange={(event) => {
                            formik.handleChange(event);
                            // getstoreDetails(event)
                            console.log({
                              name: formik.values.name,
                              phone: formik.values.phone,
                              gender: formik.values.gender,
                              profileId: event.target.value,
                            });
                          }}
                          // onFocus={() => {
                          //   console.log({
                          //     name: formik.values.name,
                          //     phone: formik.values.phone,
                          //     gender: formik.values.gender,
                          //     profileId: formik.values.profileId
                          //   });
                          // }}
                          className={`one form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                        >
                          <option className="p-2" value="" disabled selected hidden>
                            Profile Created For
                          </option>
                          {dropdownData?.profileFor?.map((profile, i) => (
                            <option className="p-2" value={profile.id}>
                              {profile.name}
                            </option>
                          ))}
                        </select>
                    }
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.profileId && formik.touched.profileId && (
                      formik.errors.profileId
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <select
                      id="motherTongueId"
                      name="motherTongueId"
                      value={formik.values.motherTongueId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:event.target.value,
                        });
                      }}
                      onClick={(e)=>{
                        e.preventDefault();
                        // setMotherTongueDropdownSelect(true)
                      }}
                      className={`one form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                    >
                      <option value="" disabled selected hidden>
                        Select Mother Tongue
                      </option>
                      {dropdownData?.motherTongue?.map((language, i) => (
                        <option className="p-2" value={language.id}>
                          {language.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.motherTongueId && formik.touched.motherTongueId && (

                      formik.errors.motherTongueId

                    )}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <select
                      id="religionId"
                      name="religionId"
                      value={formik.values.religionId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:event.target.value
                        });
                      }}
                      onClick={(e)=>{
                        e.preventDefault();
                        // setReligionDropdownSelect(true);
                      }}
                      className={`one form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                    >
                      <option value="" disabled selected hidden>
                        Select Religion
                      </option>
                      {
                      dropdownData?.religion?.map((religion, i) => (
                        <option className="p-2" value={religion.id}>
                          {religion.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.religionId && formik.touched.religionId && (

                      formik.errors.religionId
                    )}{" "}
                  </div>
                </div>
              </div>

              {isChristian && <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <select
                      id="denomination"
                      name="denomination"
                      value={formik.values.denomination}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:formik.values.religionId,
                          domainId:event.target.value
                        });
                      }}
                      className={`one form-input bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                    >
                      <option value="" disabled selected hidden>
                        Select Denomination
                      </option>
                      {dropdownData?.domain?.map((denomination, i) => (
                        <option className="p-2" value={denomination.id}>
                          {denomination.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.denomination && formik.touched.denomination && (

                      formik.errors.denomination
                    )}{" "}
                  </div>
                </div>
              </div>}

              {/* <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <select
                      id="casteId"
                      name="casteId"
                      value={formik.values.casteId}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        formik.handleChange(event);
                        // getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:formik.values.religionId,
                          casteId:event.target.value
                        });
                        
                      }}
                   
                      className="one form-input newone shadow-sm  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block "
                    >
                      <option value="" disabled selected hidden>
                        Select Caste
                      </option>
                      {sortedCasteList?.map((caste, i) => (
                      <><option
                        key={i}
                        value={caste.id}
                        className={ caste.id=="433" && isLastOptionOthers ? "!fixed !absolute !bottom-0 !z-[1000] !bg-white" : ""}
                      >
                        {caste.name}
                      </option>
                      
                         </>
                    ))}
                

   
                    </select>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {formik.errors.casteId && formik.touched.casteId && (
                      formik.errors.casteId
                    )}{" "}
                  </div>
                </div>
              </div> */}
               {/* <div className="space-y-1 md:mt-[0.3rem] relative" ref={dropdownRef}>
                    <div>
                      <label className="relative block">
                        <input
                          type="text"
                          className="one selectone form-input shadow-sm bg-[#FFFFFF] !focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] border border-[#F18181] py-1 px-1 md:py-2 md:px-2 w-full block"
                          placeholder="Select Caste"
                          value={
                            formik.values.casteId
                              ? sortedCasteList.find(caste => caste.id === formik.values.casteId)?.name || searchTerm
                              : searchTerm
                          }
                          onClick={toggleDropdown}
                          onChange={handleSearchChange}
                        />
                        {isOpen && (
                          <div className=" absolute z-10 w-full focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] bg-white border border-[#F18181] text-[#000000] text-[10px] md:text-[13px] shadow-lg max-h-60 overflow-auto">
                            {filteredCasteList?.map((caste, i) =>
                              caste.name !== "Others" ? (
                                <div
                                  key={i}
                                  className="text-[#000000] text-[10px] md:text-[13px] px-2 hover:text-white hover:bg-[#f05638]"
                                  onClick={() => handleOptionClick(caste.id)}
                                >
                                  {caste.name}
                                </div>
                              ) : null
                            )}
                            {filteredCasteList?.some((caste) => caste.name === "Others") && (
                              <div
                                className="sticky bottom-0 bg-white px-2 hover:bg-[#f05638] hover:text-white border-[#F18181]"
                                onClick={() =>
                                  handleOptionClick(
                                    sortedCasteList?.find((caste) => caste.name === "Others").id
                                  )
                                }
                              >
                                Others
                              </div>
                            )}
                          </div>
                        )}
                      </label>
                      <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem]">
                        {formik.errors.casteId && formik.touched.casteId && formik.errors.casteId}
                      </div>
                    </div>
                  </div>  */}
               
               <div className="space-y-1 md:mt-[0.3rem] relative" ref={dropdownRef}>
    <div>
      <label className="relative block">
        <div
          className={`one selectone text-start form-input bg-[#FFFFFF] !focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] border py-1 px-1 md:py-2 md:px-2 w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
          onClick={toggleDropdown}
          onKeyDown={handleKeydown}
          tabIndex={0} // To make the div focusable
        >
          {formik.values.casteId
            ? sortedCasteList.find(caste => caste.id === formik.values.casteId)?.name || "Select Caste"
            : "Select Caste"}
        </div>
        {isOpen && (
          <div className="text-start absolute z-10 w-full focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] bg-white border border-[#F18181] text-[#000000] text-[10px] md:text-[13px] shadow-lg max-h-60 overflow-auto">
            {sortedCasteList?.map((caste, i) => (
              <div
                key={i}
                className={`text-[#000000] text-[10px] md:text-[13px] px-2 hover:text-white hover:bg-[#f05638] ${
                  i === selectedIndex ? "bg-[#f05638] text-white" : ""
                }`}
                onClick={() => handleOptionClick(caste.id)}
              >
                {caste.name}
              </div>
            ))}
            {sortedCasteList?.some((caste) => caste.name === "Others") && (
              <div
                className="sticky bottom-0 px-2 hover:bg-[#f05638] hover:text-white border-[#F18181] font-bold bg-[#F7F8FA]"
                onClick={() =>
                  handleOptionClick(
                    sortedCasteList?.find((caste) => caste.name === "Others").id
                  )
                }
              >
                Others
              </div>
            )}
          </div>
        )}
      </label>
      <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem]">
        {formik.errors.casteId && formik.touched.casteId && formik.errors.casteId}
      </div>
    </div>
           </div>
                  

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="off"
                      value={formik.values.email}
                      // onChange={(e) => {
                      //   formik.handleChange(e)
                      //   validateEmail(e.target.value)
                      // }
                      // } 
                     
                      onChange={(event) => {
                        formik.handleChange(event)
                         validateEmail(event.target.value)
                         setEmailErrorMessage("")
                         setIsMailFocused(true)
                         if(error?.message == "Email already Exist"){
                          dispatch(restApiError());
                        }
                        //  getstoreDetails(event)
                        console.log({
                          name: formik.values.name,
                          phone: formik.values.phone,
                          gender: formik.values.gender,
                          profileId: formik.values.profileId,
                          motherTongueId:formik.values.motherTongueId,
                          religionId:formik.values.religionId,
                          casteId:formik.values.casteId,
                          email:event.target.value

                        });
                      }}
                      onBlur={() => setIsMailFocused(false)}
                      placeholder="Enter Your Email"
                      className={`form-input  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                    />
                  </label>
                  {domainError && (<div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                      Enter a valid domain
                    </div>)
                  }
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {error?.message ?
                      error?.message == "Email already Exist" && (
                        error?.message
                      )
                      :
                      // <></>
                      emailErrorMessage ==="This email is already taken"?
                      emailErrorMessage
                      :<></>
                      }
                      {" "}
                     
    
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative flex justify-end	w-full">
                    <input
                      id="password"
                      name="password"
                      type={showHidePassword ? "text" : "password"}
                      autoComplete="off"
                      placeholder="Enter Your Password"
                      value={formik.values.password}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setIsMailFocused(true)
                      }}
                      onBlur={() => setIsMailFocused(false)}
                      className={`form-input  bg-[#FFFFFF] focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] text-[#000000] text-[10px] md:text-[13px] focus:outline-none rounded-[2px] focus:ring-[#2D2C6F] border py-1 px-1 md:py-2 md:px-2  w-full block ${props?.isFrom == "newRegisterPage" ? "border-[#D10A10] shadow-lg" : "border-[#F18181] shadow-sm"}`}
                    />
                    <div className="flex absolute inset-y-0 m-[0.3rem] md:mt-[0.7rem] md:mr-[0.8rem] lg:mr-[2rem] lg:m-[.6rem] pointer-events-auto">
                      <img
                        onClick={() => changeShowHidePassword(!showHidePassword)}
                        src={
                          showHidePassword
                          ? "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161601/passwordeyeopen_jkm7v5.png"
                          : "https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161797/passwordeye_cpa0p8.png"
                        }
                        className=" md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5"
                        alt=""
                      />
                    </div>
                  </label>
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem]  whitespace-nowrap">
                    {formik.errors.password && formik.touched.password && (
                      formik.errors.password
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.3rem]">
                <div>
                  <label className="relative block float-left flex">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      value={formik.values.terms}
                      onChange={formik.handleChange}
                      className="focus:outline-none focus:border-[#D10A11] focus:ring-1 focus:ring-[#D10A11] mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181] default:ring-2"
                      autoComplete="off"
                    />
                    <span className=" text-[13px] md:ml-[3px] lg:ml-[5px]">
                      <a href={RouteSegments.TERMS_AND_CONDITION} target="_blank" className="hover:underline hover:text-[#151515] pl-2">
                        I Accept your Terms and Conditions
                      </a>
                    </span>
                    {/* <span className="md:hidden text-[13px] md:ml-[3px] lg:ml-[5px]">
                      <a href={RouteSegments.MOBILE_TERMS_AND_CONDITION} target="_blank" className="hover:underline hover:text-[#151515] pl-2">
                        I Accept your Terms and Conditions
                      </a>
                    </span> */}
                  </label><br />
                  <div className="text-left text-[red] text-[12px] h-[0.3rem] left-[3px] relative top-[0px]">
                    {formik.errors.terms && formik.touched.terms && (
                      formik.errors.terms
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="space-y-1 md:mt-[0.8rem]">
                <div>
                  <label className={`relative md:top-[6px]  ${isCommunitySite ? 'top-[1px]' : 'top-[18px]'}`}>
                    <button
                      type="submit"
                      className="w-full flex justify-center text-left py-2 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    >
                      {isCommunitySite 
                        ? <span>Register at just<span className="mx-1"><RupeeSymbol/> {registrationAmont}</span></span> 
                        : "Submit & Continue"
                      }
                    </button>
                  </label>
                </div>
              </div>
              <br />
            </form>
            : <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
          }
        </div>
      }

    </div>

  );
}
export default withRouter(RegisterForm)
import React, {useEffect,useContext} from 'react'
import CloseIcon from "../../../Images/Group 761@2x.png";
import { NavBarContext } from '../../../Contexts/NavBarContext';

const ProfileExpiredPopup = (props) =>{
    const {setNewRegisterData} = useContext(NavBarContext)

  return (
    <div>
      <div className=" flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className=" relative w-auto my-6 mx-auto ">
            <div className="">
                <button
                    className=" bg-transparent z-40 border-0 text-black  absolute top-[-6px] right-[-6px]"
                    onClick={() => {
                        props.close();
                        sessionStorage.removeItem('loginFromAdmin');
                        sessionStorage.removeItem('loginFromSMS');
                        sessionStorage.removeItem('loginFromEmail');
                        setNewRegisterData('');
                    }}
                >
                    <img className="w-8 h-8 " src={CloseIcon} alt="" />
                </button>
            </div>
            <div className="bg-white rounded-[1rem] ">
                <div className=" pt-5 pb-4 p-4 md:p-6  md:w-[35rem] w-[22rem]  rounded-2xl">
                    <div className="  items-start ">
                        <p className="text-[#D10A11] flex justify-center font-bold text-[17px] md:text-[24px] lg:text-[26px]">
                            {props?.title}
                        </p>
                        <div style={{ borderWidth: "2px" }} className="  border-t-[#D10A11] border-dashed border-[#FFFFFF]  md:block md:col-span-5 lg:col-span-5  md:mx-0 md:ml-[0.7rem] mt-[1rem]  ">
                            <div className='flex-col pt-4'>
                                <span className='text-start font-Poppins-SemiBold text-[#000000]'>Dear {props?.content?.profilename},</span>
                                <div className='pt-3 font-Poppins-Medium'>
                                    <span className='text-[#000000]'>{props?.content?.mainContent}</span> <span className='text-[#D10A11]'>{props?.content?.phonenumber}</span> <span className='text-[#000000]'>{props?.content?.secondaryContent}</span> <span className='text-[#D10A11]'>{props?.content?.mailid}.</span> <span className='text-[#000000]'>{props?.content?.availabilityInfo}</span> 
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center px-4 pt-4 pb-6'>
                            <button className='bg-[#D10A11] cursor-pointer text-[#12px] font-Poppins-SemiBold text-[#FFFFFF] px-4 py-2 rounded-md' onClick={props.handleNewRegisterClick}>
                                Register New Profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default ProfileExpiredPopup

import React, { useContext, useEffect, useState } from 'react'
import BasicForm from '../../../Components/Edit Profile/MyProfileComponents/BasicForm'
import config from '../../../config';
import EndPoints from '../../../Constants/EndPoints';
import { GET } from '../../../Services/api_services';
import { LoginContext } from '../../../Contexts/LoginContext';

function PreviewBasicForm({ editMyProfileData, ProfileData ,ispaid, apiCallEditProfile}) {
    const { loginDetail, logout } = useContext(LoginContext);

    const [editbasicinfofreshData, seteditbasicinfofresh] = useState();



    useEffect(() => {
        const apiBasicCallDropDown = async (type) => {

            console.log("Fetching Basic Info...");
            let url = "";
            url = `${config.api.API_URL}${EndPoints.editbasicinfofreshUrl()}`;

            let { statusCode, data } = await GET(url);
            //console.log("dropdown data ", data);
            if (statusCode === 200) {
                seteditbasicinfofresh(data.data);
            } else if (statusCode === 401) {
                logout();
            }
        };
        apiBasicCallDropDown()
    }, [])


    return (
        <BasicForm
            data={editMyProfileData}
            dropDown={editbasicinfofreshData}
            // refresh={basicCallBack}
            callBackReload={() => {
                apiCallEditProfile()
             }}
             isPaid={ispaid != "N"}
            editfromadmin={ProfileData?.editfromadmin}
            isFrom="addDetails"
        />
    )
}

export default PreviewBasicForm
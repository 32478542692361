import React, { useContext, useState,useEffect } from "react";
import { Popover } from "antd";
import doticon from "./assets/Images/Group 4.png";
import { VideoCamera, Groupcall, PhoneCall, ThreeDot } from "./assets/images";
import { NavBarContext } from "../../Contexts/NavBarContext";
import mediaicon from "./assets/Images/mediaicon.png";
import moreicon from "./assets/Images/moreicon.png";
import muteicon from "./assets/Images/muteicon.png";
import chatsearch from "./assets/Images/chatsearch.png";
import viewprofile from "./assets/Images/viewprofileicon.png";
import moreicon1 from "./assets/Images/morearrow.png";
import horoicon from "./assets/Images/horoicon.png";
import reporticon from "./assets/Images/reporticon.png";
import blockicon from "./assets/Images/blockiocn.png";
import trusticon from "./assets/Images/trustdocicon.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleMuteConversations,getConversationList,resetUnreadCount } from "../../Store/Chats/Action";
import { TiStarOutline } from "react-icons/ti";
import { toggleProfileBlock } from "../../Store/KalyanLite/Action";
import { CommonValidPopup } from "../CommonPopup";
import { useAnalytics } from "../../Hooks/usePageChange";
import { EventName ,EventKeys} from "../../Constants/constants";
import { useTranslation } from "react-i18next";


const Profile = (props) => {
  const {
    setShowReportPopup,
    setShowSearch,
    setShowMutePopup,
    setShowMedia,
    setShowBlockPopup,
    setShowUnBlockPopup,
    setShowMessagePopup,
    setShowPaynowPopup,
    setShowPayBackPopup,
    setShowDownloadPopup,
    isBlocked,
    setShowNoMatchesPopup,
    setShowSuccessUpload,
    setShowDangerPopup,
    setShowWarningPopup,
    setShowParentPopup,
    setShowValidPopup,    
  } = useContext(NavBarContext);

  const { onClickTrack } = useAnalytics()

  const [visible, setVisible] = useState(false);
  const [menuPage, setMenuPage] = useState(1)
  const [nonValidationPopupData, setNonValidationPopupData] = useState({ show: false, title: "", content: "" })
  const [showStarMessage, setshowStarMessage] = useState(false);

  const [EnableMedia, setEnableMedia] = useState(false);

  const { t: trans } = useTranslation();

  const dispatch = useDispatch()
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );

  const { receiverId,currentConversationId } = useSelector((state) => ({
    receiverId: state?.Chats?.currentChat?.receiverId,
    currentConversationId: state?.Chats?.currentChat?.conversationId,

  }));

  const { starCount,starredMessageSuccess,isStarMessageLoading,updatedStarCount } = useSelector((state) => ({
    starCount: state?.Chats?.currentChat?.starCount,
    starredMessageSuccess :state?.Chats?.starredMessageSuccess,
    isStarMessageLoading:state?.Chats?.isStarMessageLoading,
    updatedStarCount:state?.Chats?.updatedStarCount

  }));
  console.log(starCount,'kello12');

  const [starMessageCount, setStarMessageCount] = useState(starCount);

  useEffect(() => {
    
    setStarMessageCount(starCount == 0 ? updatedStarCount == 0 ? 0 : updatedStarCount : starCount);
    
  }, [starCount,updatedStarCount])
  

  console.log(starredMessageSuccess,"zzz");

  const loggedInProfileStatus = userData?.status;
  
  const handleClick = (e) => {
    setMenuPage(1)
    e.stopPropagation();
  };

  const onMoreOnClick = (e) => {
   
    e.stopPropagation();
    onClickTrack(EventName.chatThreeDotMoreClick);
    setMenuPage(prev => prev + 2)
  };


  const onSearchOnclick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }else{   
     setShowSearch(true);
    setVisible(!visible);
  };
}

  const onMediaclick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }else{
        setShowMedia(true);
    setVisible(!visible);
    onClickTrack(EventName.chatThreeDotMediaLinksClick,{[EventKeys.reciverId] : receiverId});

  };
}

  const onMuteClick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }else{
    if (props.isNotificationsMute) {
      dispatch(toggleMuteConversations({
        "conversation_id": props.conversationId,
        "receiver_id": props.receiverId,
        "operation": "unmute"
      }))
      props.onMuteChange(false)
    } else {
      setShowMutePopup(true);
    }
    setVisible(!visible);
  };
}

  const onBlockClick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }else{
    e.stopPropagation(); // Prevent Popover from closing
    onClickTrack(EventName.chatThreeDotBlockClick)
    if (loggedInProfileStatus === "pending") {
      setVisible(false);
      setNonValidationPopupData({ show: true, title: trans('non_validation_popup.non_validated_user'), content: trans('non_validation_popup__block.your_profile_is_under_validati') })
      return;
    }
    setShowBlockPopup(true);

    setVisible(!visible);
  };
  }
  const onUnBlockClick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }else{
    e.stopPropagation(); // Prevent Popover from closing
    setShowUnBlockPopup(true);
    setVisible(!visible);
  };
}

  const onReportClick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }else{
    e.stopPropagation(); // Prevent Popover from closing
    onClickTrack(EventName.chatThreeDotReportClick);
    if (loggedInProfileStatus === "pending") {
      setVisible(false);
      setNonValidationPopupData({ show: true, title: trans('non_validation_popup.non_validated_user'), content:trans('non_validation_popup__report.your_profile_is_under_validati') })
      return;
    }
  
    
    setShowReportPopup(true);
    setVisible(!visible);
  };
}
  const onProfileClick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
      || props.profileStatus === "delete") {
      props.setShowProfileHiddenPopup(true);
      setMenuPage(0)
    }
    else {
      props.setShowProfileView(true);
      setVisible(!visible);
    }

   
  };
  const onImageUpload = () => {
    props.setImageUpload(true);
    setVisible(!visible);
  };

  const onMessageClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowMessagePopup(true);
    setVisible(!visible);
  };

  const onPaynowClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowPaynowPopup(true);
    setVisible(!visible);
  };

  const onPayBackClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowPayBackPopup(true);
    setVisible(!visible);
  };

  const onDownloadClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowDownloadPopup(true);
    setVisible(!visible);

  }

  const onNoMatchClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowNoMatchesPopup(true);
    setVisible(!visible);
  }

  const onSuccessfullUpload = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowSuccessUpload(true);
    setVisible(!visible);
  }

  const onDangerClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowDangerPopup(true);
    setVisible(!visible);

  }

  const onWarningClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowWarningPopup(true);
    setVisible(!visible);

  }

  const onParentClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    setShowParentPopup(true);
    setVisible(!visible);

  }

  const onValidClick = (e) => {
    e.stopPropagation(); // Prevent Popover from closing
    onClickTrack(EventName.chatThreeDotTrustedDocumentClick);
    setShowValidPopup(true);
    setVisible(!visible);

  }

  const onTrustClick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }
  else {
    e.stopPropagation();
    setVisible(!visible);
    props.onTrustDocumentClick()
    onClickTrack(EventName.chatThreeDotTrustedDocumentClick);
  }
}

  const onHoroScopeClick = (e) => {
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }else{
    e.stopPropagation();
    onClickTrack(EventName.chatThreeDotHoroscopeClick);
    setVisible(!visible);
    props.onHoroScopeClick()
  }}

  const showMatchHoroPopup=(e)=>{
    if (props.profileStatus === "hidden" || props.profileStatus === "inactive" || props.profileStatus === "suspension"
    || props.profileStatus === "delete") {
    props.setShowProfileHiddenPopup(true);
    setMenuPage(0)
  }else{
    e.stopPropagation();
    setVisible(!visible);
    props.showMatchHoroPopup()
  }
}

  const getTabOverlayStyle = () => {
    // Define your mobile styles here
    return {
      padding: "6px",
      position:"relative",
      left:"-3px",
      top:"28px"
    };
  };
  const getWebOverlayStyle = () => {
    // Define your mobile styles here
    return {
        padding: "6px",
        left: "111px",
        top:"31px",
        position:"relative",
    };
  };

  const getMobileOverlayStyle = ()=>{
    return {
      padding: "6px",
       left: "48px",
       top:"-3px",
       position:"relative",
   };
 
  }

  const onStarClick = (e) => {
    e.stopPropagation();
    onClickTrack(EventName.chatThreeDotStarredMsgClick)
    if(starMessageCount > 0){
      setVisible(!visible);
      props.openStarMessage()
    }
  }

  // useEffect(() => {
    
    
  // }, [starCount])

  // useEffect(() => {
  //   if(!isStarMessageLoading){
  //     if(starredMessageSuccess){
  //       dispatch(
  //         getConversationList({
  //           receiver_id: receiverId,
  //           receiver_status: loggedInProfileStatus,
  //         }),
  //       );
  //     }
  //     }
    
  // }, [isStarMessageLoading])

  useEffect(() => {
    if(props?.isMediaExist?.length > 0){
        setEnableMedia(true)
    }else{
      setEnableMedia(false)

    }
  }, [props?.isMediaExist])
  
  
  

  return (
    <div>
      <div className="">
      {nonValidationPopupData.show && <CommonValidPopup
        close={() => setNonValidationPopupData({ show: false })}
        title={nonValidationPopupData.title}
        content={nonValidationPopupData.content} />
      }

      <Popover
        trigger='click'
        placement="bottomRight"
        openClassName='p-0'
        // overlayInnerStyle={{
        //   padding: 6,
        //   left: 111,
        //   top:31,
        //   position:"relative",
         
        // }}
        style={{
          left: "4rem !important",
        }}

        overlayInnerStyle={
          
          window.innerWidth <= 767
          ? getMobileOverlayStyle():
             window.innerWidth <= 1024
            ? getTabOverlayStyle()
            : getWebOverlayStyle()
        }
        // overlayStyle={window.innerWidth <= 767 ? getMobileOverlayStyle() : {}}

        content={
          <div>
            {props?.menu === "chatpage" ? (
              <div className='w-[250px] md:w-[248px] lg:w-[251px]  lg:w-full py-2 px-2'>
                {menuPage === 3 && (
                  <div>
                    {props.horoscopeAvailable && <div
                      
                      className='gap-3 border-b flex items-center cursor-pointer px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={onHoroScopeClick} className="flex gap-3"><img className='w-4 h-4' src={horoicon} alt="" /> {trans('chat.view_horoscope')}</p>
                    </div>}
                    {props.horoscopeAvailable && <div
                      
                      className='gap-3 border-b flex items-center cursor-pointer px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={showMatchHoroPopup} className="flex gap-3"><img className='w-4 h-4' src={horoicon} alt="" /> {trans('chat.match_horoscope')}</p>
                    </div>}
                    {props.isTrustDocAvailable && <div

                      className='gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={onTrustClick} className="flex gap-3 cursor-pointer">  <img className='w-4 h-4' src={trusticon} alt="" />
                      {trans('chat.view_trust_documents')}</p>
                    </div>}
                    {props.isBlocked ?
                    
                    
                    <div
                      className='gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'
                      
                    >
                      <p onClick={onUnBlockClick} className="flex gap-3 cursor-pointer">
                        <img className='w-4 h-4' src={blockicon} alt=""/> {trans('unblock_popup.unblock')}
                      </p>
                    </div>
                    :
                    <div
                    className="gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100"
                    
                  >
                    <p onClick={onBlockClick} className="flex gap-3 cursor-pointer">
                      <img className='w-4 h-4' src={blockicon} alt=""/>  {trans('chat.block')}
                    </p>
                  </div>
                    }
                    {/* <div
                      
                      className='gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={onBlockClick} className="flex gap-3 cursor-pointer"><img className='w-4 h-4' src={blockicon} /> 
                      {props.isBlocked ? "Unblock" : "Block"}
                      </p>
                    </div> */}


                    {/* <div
                      onClick={onUnBlockClick}
                      className='gap-3 border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <img className='w-4 h-4' src={blockicon} /> Un Block
                    </div> */}
                    {/* Message popup */}
                    {/* <div
                      onClick={onProfileClick}
                      className='gap-3 border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <img className='w-4 h-4' src={blockicon} /> Message
                    </div> */}
                    {/* Upload Photo popup */}
                    {/* <div
                      onClick={onImageUpload}
                      className='gap-3 border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <img className='w-4 h-4' src={blockicon} /> Upload Photo
                    </div> */}
                    <div

                      className='gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={onReportClick} className="flex gap-3 cursor-pointer"><img className='w-4 h-4' src={reporticon} alt=""/> {trans('chat.report')}</p>
                    </div>
                    {/* <div onClick={onDangerClick} className="gap-3 border-b  flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <img className="w-4 h-4" src={reporticon} /> Danger
                    </div> */}
                    {/* <div onClick={onWarningClick} className="gap-3 border-b  flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <img className="w-4 h-4" src={reporticon} /> Warning
                    </div> */}
                    {/* <div onClick={onParentClick} className="gap-3 border-b  flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <img className="w-4 h-4" src={reporticon} /> Parent
                    </div> */}
                    {/* <div onClick={onValidClick} className="gap-3 border-b  flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <img className="w-4 h-4" src={reporticon} /> Valid
                    </div> */}


                    {/* No Matches */}
                    {/* <div
                    onClick={onNoMatchClick}
                    className='gap-3 border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                    <img className='w-4 h-4' src={horoicon} /> No Matches
                  </div> */}
                    {/* Successfull Upload */}
                    {/* <div
                    onClick={onSuccessfullUpload}
                    className='gap-3 border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                    <img className='w-4 h-4' src={horoicon} /> Successfull Upload
                  </div> */}

                  </div>

                )}
                {(menuPage === 1 || menuPage === 2) && (
                  <>
                  {props?.isFrom != "groupChat" &&
                    <div
                      className="gap-3 flex px-2   items-center border-b py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <p className="flex gap-3 cursor-pointer" onClick={()=>{
                        onProfileClick();
                        onClickTrack(EventName.chatThreeDotProfileClick,{[EventKeys.reciverId] : receiverId});
                      }}><img alt="" className="w-4 h-4" src={viewprofile} /> {trans('chat.view_profile')}</p>
                    </div>
                  }
                    <div className="gap-3 items-center border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <p className={`flex gap-3 break-words w-[14rem] ${ props?.isFrom == "groupChat"? EnableMedia ? "cursor-pointer" : "cursor-not-allowed" : "cursor-pointer"}`} 
                      onClick={()=>{
                        if(props?.isFrom == "groupChat"){
                          if(EnableMedia){
                            onMediaclick()
                          }
                        }else{
                          onMediaclick()
                        }
                      }
                      } >
                        <img className="w-4 h-4" src={mediaicon} alt="" /> {trans('chat.media_links_and_docs')}</p>
                    </div>
                    <div className="gap-3 items-center border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <p className="flex gap-3 cursor-pointer" onClick={()=>{
                        onSearchOnclick()
                        onClickTrack(EventName.chatThreeDotSearchClick)
                      }}>
                        <img className="w-4 h-4" src={chatsearch} alt="" /> {trans('chat.search')}</p>
                    </div>
                    { props?.isFrom != "groupChat" ?
                    props.isNotificationsMute  
                      ? <div className="gap-3 items-center border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                        <p className="flex gap-3 cursor-pointer" onClick={()=>{
                          onMuteClick();
                          }}><img className="w-4 h-4" src={muteicon} alt="" /> {trans('view_profile.unmute_notifications')}</p>
                      </div>
                      : <div className="gap-3 items-center border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                        <p className="flex gap-3 cursor-pointer" onClick={()=>{
                          onMuteClick();
                          onClickTrack(EventName.chatThreeDotMuteClick)}}><img className="w-4 h-4" src={muteicon} alt="" /> {trans('chat.mute_notifications')}</p>
                      </div>
                      :<></>
                      }
                      
                    {/* {menuPage === 2 &&  */}
                      <div className={`gap-3  ${props?.isFrom == "groupChat" ? '':'border-b'} flex  px-1  items-center  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100`}>
                        <p onClick={onStarClick} className={`flex gap-2  items-center ${starMessageCount > 0 ? "cursor-pointer" : "cursor-not-allowed"}`}><TiStarOutline color="#4C5B71" size={22} />  {trans('chat.starred_messages')}</p>
                      </div>
                    {/* } */}
                    {/* <div onClick={onPaynowClick} className="gap-3 border-b  flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <img className="w-4 h-4" src={reporticon} /> Pay Now
                    </div>
                    <div onClick={onPayBackClick} className="gap-2 flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <img className="w-4 h-4" src={reporticon} /> Pay Back
                    </div> */}
                  </>
                )}
                { props?.isFrom == "groupChat" ? null 
                :
                menuPage > 2
                  ? null
                  : <div onClick={onMoreOnClick} className={"gap-3 flex items-center cursor-pointer  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100"}>
                    <img alt="" className="w-4 h-4" src={moreicon} /> {trans('chat.more')}  <img alt="" className="w-3 ml-[6rem] h-4" src={moreicon1} />
                  </div>}


              </div>
            )
              : null
            }
          </div>
        }

        open={visible}
        onOpenChange={(visibility) => setVisible(visibility)}>
        {props?.menu === "chatpage" ? (
          <img
            onClick={handleClick}
            className='lg:h-4 h-5 xl:h-5 w-[0.4rem]  brightness-0 invert md:invert-0'
            src={ThreeDot}
            alt=''
          />
        ) : (
          <img
            onClick={handleClick}
            className='lg:h-4 h-5 xl:h-5 w-[0.4rem]  brightness-0 invert md:invert-0'
            src={doticon}
            alt=''
          />
        )}
      </Popover>
      </div>
     {/* <div className="block lg:hidden md:block">
     <Popover
        trigger='click'
        placement="bottomRight"
        openClassName='p-0'
        overlayInnerStyle={{
          padding: 6,
          left: 0,
          top:34,
          position:"relative",
          '@media (max-width: 851px)': {
            padding: 6,
            left: 116 ,
             position:"relative",
          },
        }}


        style={{
          left: "4rem !important",
          '@media (max-width: 767px)': {
            padding: 6, left: 106, top: 34, position: "relative" 
          },
        }}
        content={
          <div>
            {props?.menu === "chatpage" ? (
              <div className='w-[230px] md:w-[206px]  lg:w-full py-2 px-2'>
                {menuPage === 3 && (
                  <div>
                    {props.horoscopeAvailable && <div
                      
                      className='gap-3 border-b flex items-center cursor-pointer px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={onHoroScopeClick} className="flex gap-3"><img className='w-4 h-4' src={horoicon} /> View horoscope</p>
                    </div>}
                    {props.horoscopeAvailable && <div
                      
                      className='gap-3 border-b flex items-center cursor-pointer px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={showMatchHoroPopup} className="flex gap-3"><img className='w-4 h-4' src={horoicon} /> Match horoscope</p>
                    </div>}
                    {props.isTrustDocAvailable && <div

                      className='gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={onTrustClick} className="flex gap-3 cursor-pointer">  <img className='w-4 h-4' src={trusticon} />
                        View trust documents</p>
                    </div>}
                    {props.isBlocked ?
                    
                    
                    <div
                      className='gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'
                      
                    >
                      <p onClick={onUnBlockClick} className="flex gap-3 cursor-pointer">
                        <img className='w-4 h-4' src={blockicon} /> Un Block
                      </p>
                    </div>
                    :
                    <div
                    className="gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100"
                    
                  >
                    <p onClick={onBlockClick} className="flex gap-3 cursor-pointer">
                      <img className='w-4 h-4' src={blockicon} />  Block
                    </p>
                  </div>
                    }
                    
                    <div

                      className='gap-3 border-b flex items-center  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100'>
                      <p onClick={onReportClick} className="flex gap-3 cursor-pointer"><img className='w-4 h-4' src={reporticon} /> Report</p>
                    </div>
                    
                  </div>

                )}
                {(menuPage === 1 || menuPage === 2) && (
                  <>
                    <div



                      className="gap-3 flex px-2   items-center border-b py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <p className="flex gap-3 cursor-pointer" onClick={onProfileClick}><img alt="" className="w-4 h-4" src={viewprofile} /> View profile</p>
                    </div>
                    <div className="gap-3 items-center border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <p className="flex gap-3 cursor-pointer" onClick={onMediaclick} ><img className="w-4 h-4" src={mediaicon} /> Media, Links, and docs</p>
                    </div>
                    <div className="gap-3 items-center border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <p className="flex gap-3 cursor-pointer" onClick={onSearchOnclick}><img className="w-4 h-4" src={chatsearch} /> Search</p>
                    </div>
                    {props.isNotificationsMute
                      ? <div className="gap-3 items-center border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                        <p className="flex gap-3 cursor-pointer" onClick={onMuteClick}><img className="w-4 h-4" src={muteicon} /> Unmute notifications</p>
                      </div>
                      : <div className="gap-3 items-center border-b flex  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                        <p className="flex gap-3 cursor-pointer" onClick={onMuteClick}><img className="w-4 h-4" src={muteicon} /> Mute notifications</p>
                      </div>}
                    <div className="gap-3  border-b flex  px-1  items-center  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100">
                      <p onClick={onDownloadClick} className="flex gap-2  items-center cursor-pointer"><TiStarOutline color="#4C5B71" size={22} />  Starred messages</p>
                    </div>
                 
                  </>
                )}
                {menuPage > 2
                  ? null
                  : <div onClick={onMoreOnClick} className={"gap-3 flex items-center cursor-pointer  px-2  py-2 font-sans_book_body text-[13px] text-[#7A7A7A] hover:bg-gray-100"}>
                    <img alt="" className="w-4 h-4" src={moreicon} /> More  <img alt="" className="w-3 ml-[6rem] h-4" src={moreicon1} />
                  </div>}


              </div>
            )
              : null
            }
          </div>
        }

        open={visible}
        onOpenChange={(visibility) => setVisible(visibility)}>
        {props?.menu === "chatpage" ? (
          <img
            onClick={handleClick}
            className='lg:h-4 h-5 xl:h-5 w-[0.4rem]  brightness-0 invert md:invert-0'
            src={ThreeDot}
            alt=''
          />
        ) : (
          <img
            onClick={handleClick}
            className='lg:h-4 h-5 xl:h-5 w-[0.4rem]  brightness-0 invert md:invert-0'
            src={doticon}
            alt=''
          />
        )}
      </Popover>
     </div> */}
  
    </div>
  );
};

export default Profile;

import React from 'react'
import * as Images from "../assets/images"


function ProcessImage({
    className,
    lockClassName = "h-[18px]",
    alt,
    src,
    alreadyContacted,
    myphoto,
    profileStatus,
    blurPhoto,
    passwordProtected,
    onClick,
    isAvatarPic,
    containerClassName = "",
    children,
    imgStatus,
    ...props
}) {


    const blurImage =  isAvatarPic ? "N" : blurPhoto
    const showLock = isAvatarPic ? "N" : passwordProtected
    const source = src?.includes("femaleavatar") ? Images.WomanIcon : src?.includes("maleavatar") ? Images.ManIcon : src

    const OverlayMessage = ({ profilePic, pictureStatus }) => {
        if (
          profilePic?.includes("femaleavatar") || 
          profilePic?.includes("maleavatar") || 
          pictureStatus === "Approved" || 
          pictureStatus == undefined
        ) {
          return null; // Don't show overlay on default avatars
        }
      
        return (
          <div className="absolute bottom-0 left-0 w-full mx-auto">
            <div className='bg-[#00000066] w-max px-10 py-2 flex items-center justify-center gap-2 mx-auto'>
                <img src={Images.WhiteInfoIcon} className="w-4 h-4" alt="" />
                <p className="text-[14px] xl:text-[16px] font-medium text-[#FFFFFF] text-center">
                {pictureStatus}
                </p>
            </div>
          </div>
        );
      };      

    return (
        <div onClick={onClick} className={`relative inline-block ${containerClassName}`}>
            <img className={`${className} ${blurImage == "Y" ? "blur-[4px]" : ""}`} alt={alt} src={source} {...props} />
            {showLock === "Y" ? <img
                className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${lockClassName}`}
                src={"/Assets/Images/lockicon.svg"}
                alt="Person"
            /> : null}
            
            {imgStatus && <OverlayMessage profilePic={src} pictureStatus={imgStatus} />}
            {children}
        </div>
    )
}

export default ProcessImage
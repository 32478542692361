import React from 'react'
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';



export const AfterregistrationSelectInput = ({
    name,
    onChange,
    options,
    value,
    defaultValue,
    error,
    title,
    required,
    getOptionLabel,
    getOptionValue,
    onBlur,
    disabled,
    icon,
    wrapperClassName,
    isMulti = false,
}) => {


    const OthersToEnd = (castes) => {
        if (!castes || !Array.isArray(castes)) {
            return [];
        }
        const othersIndex = castes.findIndex((caste) => caste.name === "Others");
        const othersOption = othersIndex !== -1 ? castes.splice(othersIndex, 1)[0] : null;
        if (othersOption) {
            castes.push(othersOption);
        }

        return castes;
    }

    const customStyles = {
        control: (base) => {
            return {
                ...base,
                border: "1px solid #8F8F8F",
                outline: "none",
                color: "#575556"
            }
        },

        singleValue: (base) => {
            return {
                ...base,
                color: "#575556"
            }
        },

        option: (base, optionState) => ({
            ...base,
            color: optionState?.isSelected ? "#575556" : "#575556",
            backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#F7F8FA" : "",
            // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
            position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
            bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
            "&:hover": {
                backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
            },
            cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",

        }),

    };

    console.log(defaultValue, 'defaultValue');
    // const isMobile = useMediaQuery({ maxWidth: 768 });

    const removeBorder = {
        control: (provided, state) => ({
            ...provided,
            border: "none", // Remove the default border
            boxShadow: "none", // Remove the default focus outline (if needed)
            "&:hover": {
                border: "none", // Ensure the border doesn't appear on hover
            },
        }),
    };
    return (

        <>
            <div className={`flex md:hidden gap-3 items-center  mb-[2rem] ${wrapperClassName}`}>
                <img alt='' className="w-[2.5rem] h-[2.5rem]" src={icon} />
                <div className={`relative w-full md:hidden`}>
                    <p className={` absolute -top-3 left-3 bg-white px-1 text-[11px] text-[#575556]  md:text-[12px] lg:text-[14px] md:text-[#7A7A7A] flex  md:font-semibold  transition-all duration-200 
            peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
          peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
            peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}>
                        {title}
                        {required && <span className="text-[#D10A11] ml-1"> *</span>}
                    </p>
                    <label className={` pepeer block border-[1px] w-full border-[#8F8F8F] rounded-[3px] bg-transparent md:px-4 py-1 text-gray-900 `}>
                        <Select
                            isMulti={isMulti}
                            isSearchable={true}
                            placeholder={title}
                            name={name}
                            value={value}
                            onChange={onChange}
                            options={OthersToEnd(options)}
                            blurInputOnSelect
                            onBlur={onBlur}
                            defaultValue={value}
                            styles={removeBorder}
                            // menuIsOpen={true}
                            isDisabled={disabled}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
                            }}
                            getOptionLabel={getOptionLabel}
                            getOptionValue={getOptionValue}
                        />
                    </label>
                    {error && (
                        <p className={`text-[red] text-[12px]`}>{error}</p>
                    )}
                </div>
            </div>
            <div className={`hidden md:grid grid-cols-9 md:mt-[2rem]  mt-4 ${wrapperClassName}`}>
                <div className={`col-span-9  xl:col-span-2 md:col-span-3 lg:col-span-3  flex md:pl-[1rem] lg:pl-[2rem]  items-center`}>
                    <div className="flex gap-3 items-center  md:text-[12px] lg:text-[14px] text-[#7A7A7A] flex  font-semibold ">
                        <img alt='' className="w-[2.5rem] h-[2.5rem]" src={icon} />
                        {title}
                    </div>
                </div>
                <div className={`col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6    md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  lg:pr-[10rem] lg:pl-[3rem] xl:pl-[7rem]`}>
                    <p className="">
                        <div className="flex ">
                            <label className={`md:text-[12px] lg:text-[13px] xl:text-[15px] text-[#575556] w-full font-bold`}>
                                <Select
                                    isMulti={isMulti}
                                    isSearchable={true}
                                    placeholder={title}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    options={OthersToEnd(options)}
                                    blurInputOnSelect
                                    onBlur={onBlur}
                                    defaultValue={value}
                                    styles={customStyles}
                                    isDisabled={disabled}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
                                    }}
                                    getOptionLabel={getOptionLabel}
                                    getOptionValue={getOptionValue}
                                />

                                {error && (
                                    <p className={`text-[red] text-[12px]`}>{error}</p>
                                )}
                            </label>
                        </div>
                    </p>
                </div>
            </div>
        </>
    );
};

export default AfterregistrationSelectInput
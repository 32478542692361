import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  BasicDatePicker,
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  FloatingSelectInput,
  SaveCancelButton,
  SelectInput,
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST } from "../../../Services/api_services";
import moment from "moment";
import config from "../../../config";
import { validateNumber } from "../../../Utils";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { useTranslation } from "react-i18next";
import { complexicon, maritalicon, nameicon, physicalicon, weighticon , profileName, bodyTypeIcon, heightIcon, physicalStatusIcon} from "../../Kalyanlite/assets/images";
import { toast } from "react-toastify";
import axios from "axios";
import AfterregistrationSelectInput from "./AfterregistrationSelectInput";
import MyProfileEditview from "./MyProfileEditview";


let errorNameMessage = ""

const BasicForm = (props) => {

  //console.log(props.isPaid,'props.isPaid');
  const [isShowEdit, setEditView] = useState(true);
  const { loginDetail, logout } = useContext(LoginContext);
  const [submitStatus, setSubmitStatus] = useState(false)
  const [toastmsg,setToastmsg]=useState(false)
  const [disablesave,setDisableSave] = useState(true)
const [disablesavebtn,setDisableSaveBtn] = useState(false)
  const { t: trans } = useTranslation();
  console.log("Toastmsg", toastmsg); // Check if the state is being set correctly

  const formik = useFormik({
    initialValues: {
      name: props?.data?.name ? props?.data?.name : "",
      dob: props?.data?.dob ? moment(props?.data?.dob,'DD/MM/YYYY').format('') : "",
      height: props?.data?.height ? props?.data?.height[0]?.id : "",
      weight: props?.data?.weight ? props?.data?.weight.replace(" kgs","") : "",
      maritalStatus: props?.data?.maritalStatus
        ? props?.data?.maritalStatus[0]?.id
        : "",
      bodytype: props?.data?.bodyType ? props?.data?.bodyType[0]?.id : "",
      complexion: props?.data?.complexion ? props?.data?.complexion[0]?.id : "",
      physicalStatus: props?.data?.physicalStatus
        ? props?.data?.physicalStatus[0]?.id
        : "",
        descriptionEdit:props?.data?.descriptionEdit

    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter Name")
      .test("name", errorNameMessage, function (value) {
        const { name } = this.parent;
        let inputExtension = value.toLowerCase().split(" ")
        if (errorNameMessage != "" && errorNameMessage ) {
          formik.touched.name = true
          return this.createError({ message: errorNameMessage, path: "name" });
        }
          return true ;
      }),
      dob: Yup.string().required("Please Enter Date of Birth"),
      height: Yup.string().required("Please Select height"),
      weight: Yup.number()
      .integer()
      .positive()
      .moreThan(30, trans('registration.weight_must_be_greater_than_nu', { number: 30 }))
      .lessThan(200, trans('registration.weight_must_be_less_than_numbe', { number: 200 }))
      .required(trans('registration.weight_should_not_be_empty')),
      maritalStatus: Yup.string().required("Please Select Marital Status"),
      bodytype: Yup.string().required("Please Select Bodytype"),
      complexion: Yup.string().required("Please Select Complexion"),
      physicalStatus: Yup.string().required("Please Select Physical Status"),
    }),

    onSubmit: (values) => {
      //console.log(values);
      setSubmitStatus(true)
      apiCallBasic(values);
      // onClickTrack(EventName.profilebasicsaveButton);
    },
  });


  const apiCallBasic = async (value ,source) => {
    console.log(source,"source");
    
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      maritialStatusId: value.maritalStatus,
      dob: moment(value.dob,'YYYY-MM-DD').format('DD/MM/YYYY'),
      name: value.name,
      heightInInchesId: value.height,
      weight: value.weight,
      bodyTypeId: value.bodytype,
      complexionId: value.complexion,
      physicalStatusId: value.physicalStatus,
      descriptionEdit:value.descriptionEdit,
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "editFrom": props?.isFrom=="addDetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
       localStorage.getItem("profiledot") == "true" ?"profile_preview_three_dots": "Myprofile",
       requestfulfilledvia:props?.isFrom=="addDetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
       localStorage.getItem("profiledot") == "true" ?"profile_preview":"chat"

    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editbasicinfoUrl()}`,
      request
    );

    console.log(value.isFroms ,'formik.values.isFroms ');
    
    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        // alert("Basic Details saved");
        setSubmitStatus(false)
        // if(!submitStatus){
        //   setDisableSaveBtn(true)
        // }
        setDisableSave(true)
        props.callBackReload();
        // setEditView(false);
        if(toastmsg ){
                  toast.success(
                    <div className='flex items-center'>
                      {"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."}
                    </div>,
                   
                  );
                  
        }
      
        onClickTrack(EventName.profileBasicSaveButton , {[EventKeys.profileBasic]:value});
      }
    } else if (statusCode === 401) {
      logout();
    }
  };



  
  useEffect(()=>{

  },[props.isPaid])


  const selectedHeight = useMemo(()=>{
    return props?.dropDown?.heightInchesData?.filter(option=>option.id === formik.values.height)?.at(0)
  },[formik.values.height, props?.dropDown?.heightInchesData])

  const selectedMaritalStatus = useMemo(()=>{
    return props?.dropDown?.maritalStatus?.filter(option=>option.id === formik.values.maritalStatus)?.at(0)
  },[formik.values.maritalStatus, props?.dropDown?.maritalStatus])

  const selectedBodyType = useMemo(()=>{
    return props?.dropDown?.bodyTypeData?.filter(option=>option.id === formik.values.bodytype)?.at(0)
  },[formik.values.bodytype, props?.dropDown?.bodyTypeData])

  const selectedComplexion = useMemo(()=>{
    return props?.dropDown?.complexionData?.filter(option=>option.id === formik.values.complexion)?.at(0)
  },[formik.values.complexion, props?.dropDown?.complexionData])

  const selectedPhysicalStatus = useMemo(()=>{
    return props?.dropDown?.physicalStatusData?.filter(option=>option.id === formik.values.physicalStatus)?.at(0)
  },[formik.values.physicalStatus, props?.dropDown?.physicalStatusData])

  //console.log('new Date(formik.values.dob)',new Date(formik.values.dob),formik.values.dob);

  // const onNameChange = (e)=>{
  //   let text = e.target.value ??"";
  //   text = text.trimStart().replace(/[^a-zA-Z ]/g, "").replace(/  +/g, " ");
  //   setDisableSave(false)
  //   setToastmsg(true)
  //   //Don't delete below commented code
  //   // const len = text.split(".").length??0;
  //   // //console.log('len',len);
  //   // if(len <= 2){
  //   //   formik.setFieldValue("name",text)
  //   // }else{
  //   //   formik.setFieldValue("name",formik.values.name)
  //   // }
  //   formik.setFieldValue("name",text)


    
  // }

  const [nameErrorMessage, setNameErrorMessage] = useState("")

  const nameCheckList = async (value,e) => {
    // dispatch(validateName(value));
        let request = {
          name: value
        };
        let data = await axios.post(
          `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
          request
        );
          if (data.data.status === "Success") {
            // console.log(data.data.data.Messagge,"ata.data.data.Messagge");
            errorNameMessage = data.data.data.Messagge
            setNameErrorMessage(data.data.data.Messagge)
            formik.handleChange(e)
          }
    };
  const { onClickTrack } = useAnalytics()


  console.log(disablesave,submitStatus,"disablesave");
  const ComponentToRender = props.isFrom === 'addDetails' ? FloatingSelectInput : SelectInput;

  return (
    <div>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="p-5 md:mb-8 md:p-0">
        <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
        </ActionRestrict>

          <BasicTextView title={"Name"} value={props?.data?.name} />
          <BasicTextView title={"Age"} value={props?.data?.age} />
          <BasicTextView title={trans('view_profile.height')} value={props?.data?.height[0].name} />
          <BasicTextView title={"Weight"} value={props?.data?.weight} />
          <BasicTextView
            title={trans('view_profile.marital_status')}
            value={props?.data?.maritalStatus[0].name}
          />
          <BasicTextView
            title={"Body Type"}
            value={props?.data?.bodyType[0].name}
          />
          <BasicTextView
            title={trans('view_profile.complexion')}
            value={props?.data?.complexion[0].name}
          />
          <BasicTextView
            title={trans('view_profile.physical_status')}
            value={props?.data?.physicalStatus[0].name}
          />
        </div>
      )}
      {/* <!-- edit part  --> */}
      {props?.isFrom=="addDetails"?
        <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <div className={`p-0 ${props?.isFrom=="addDetails"? "lg:px-4":"md:p-0"} `}>
            {props.isPaid
              ? <BasicTextView title={trans('my_profile.name')} value={props?.data?.name}  isFrom={"profileaddDetails"} icon={profileName}/>
              : <MyProfileEditview
                title={trans('my_profile.name')}
                inputType={"text"}
                inputID={"name"}
                inputName={"name"}
                value={formik.values.name}
                  onChange={(e) => {
                    setNameErrorMessage("")
                    nameCheckList(e.target.value,e);
                    setDisableSave(false)
                    setDisableSaveBtn(true)
                    setToastmsg(true)
                    let text = e.target.value ?? "";
                    text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                    text = text.charAt(0).toUpperCase() + text.slice(1)
                    var splitStr = text.toLowerCase().split(' ');
                    for (var i = 0; i < splitStr.length; i++) {
                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                    }
                    text =  splitStr.join(' '); 
                    e.target.value = text;
                    if ((e.target.value.split(".").length - 1) <= 1) {
                      formik.handleChange(e)
                      // getstoreDetails(e)
                    }
                  }}
              
                error={formik.errors.name}
                required={true}
                disabled={props?.isPaid || props.editfromadmin == 'Y'}
                isFrom={"profileaddDetails"}
                icon={profileName}
                customClassName={"mt-[1rem]"}
              />}

            {props.isPaid
              ? <BasicTextView title={trans('my_profile.age')}  isFrom={"profileaddDetails"} value={props?.data?.age}  icon={nameicon}/>
              : <BasicDatePicker
                title={trans('generate_horoscope.date_of_birth')}
                // formatedValue={moment(formik.values.dob).format('DD-MM-YYYY')}
                // value={moment(formik.values.dob).toDate()}\
                formatedValue={formik.values.dob ? moment(formik.values.dob).format('DD-MM-YYYY') : ""}
                value={formik.values.dob ? moment(formik.values.dob).toDate() : null}
                onChange={(date) => {
                  //console.log('Date Of Birth', date);
                  formik.setFieldValue("dob", moment(date).format('YYYY-MM-DD'), true)
                  setDisableSave(false)
                }}
                error={formik.errors.dob}
                required={true}
                minDate={moment().subtract(70,"year").add(1,"day").toDate()}
                maxDate={moment().subtract(props?.data?.gender === "F" ? 18 : 21, "year").toDate()}
                disabled={props?.isPaid || props.editfromadmin == 'Y'}
                name="dob"
                isFrom={"profileaddDetails"}
                icon={nameicon}
              />}

            <AfterregistrationSelectInput
              name="height"
              title={trans('view_profile.height')}
              value={selectedHeight}
              onChange={(selected, ev) => {
                formik.setFieldValue("height", selected.id)
                setDisableSave(false)
              }}
              error={formik.errors.height}
              defaultValue={formik.values.height}
              options={props?.dropDown?.heightInchesData}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              isFrom={"profileaddDetails"}
              icon ={heightIcon}
              wrapperClassName="w-full"
            /> 

         
            <MyProfileEditview
              title={trans('view_profile.weight')}
              inputID={"weight"}
              inputName={"weight"}
              value={formik.values.weight}
              onChange={(e)=>{

                if (validateNumber(e.target.value) && (e.target.value.length <= 3)) {
                  formik.handleChange(e)
                  setDisableSave(false)
                  setToastmsg(true)
                }
               
              }}
              error={formik.errors.weight}
              required={true}
              inputType={"tel"} 
              className={"weight"}             
              disabled={props.editfromadmin == 'Y'}
              isFrom={"profileaddDetails"}
              icon ={weighticon}
            />
             <AfterregistrationSelectInput
              name="height"
              title={trans('view_profile.marital_status')}
              value={selectedMaritalStatus}
              onChange={(selected, ev) => {
                formik.setFieldValue("maritalStatus", selected.id)
                setDisableSave(false)
              }}
              error={formik.errors.maritalStatus}
              defaultValue={formik.values.maritalStatus}
              options={props?.dropDown?.maritalStatus}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              isFrom={"profileaddDetails"}
              icon={maritalicon}
              customClassName={props?.isFrom == "addDetails" && "mt-[2rem]"}
            /> 

             <AfterregistrationSelectInput
              name="bodytype"
              title={trans('my_profile.body_type')}
              value={selectedBodyType}
              onChange={(selected, ev) => {
                formik.setFieldValue("bodytype", selected.id)
                setDisableSave(false)
              }}
              error={formik.errors.bodytype}
              defaultValue={formik.values.bodytype}
              options={props?.dropDown?.bodyTypeData}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              isFrom={"profileaddDetails"}
              icon={bodyTypeIcon}
              customClassName={props?.isFrom == "addDetails" && "mt-[2rem]"}
            /> 
            
             <AfterregistrationSelectInput
              name="complexion"
              title={trans('view_profile.complexion')}
              value={selectedComplexion}
              onChange={(selected, ev) => {
                formik.setFieldValue("complexion", selected.id)
                setDisableSave(false)
              }}
              error={formik.errors.complexion}
              defaultValue={formik.values.complexion}
              options={props?.dropDown?.complexionData}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              isFrom={"profileaddDetails"}
              icon ={complexicon}
              customClassName={props?.isFrom == "addDetails" && "mt-[2rem]"}
            /> 
            
            <AfterregistrationSelectInput
              name="physicalStatus"
              title={trans('view_profile.physical_status')}
              value={selectedPhysicalStatus}
              onChange={(selected, ev) => {
                formik.setFieldValue("physicalStatus", selected.id)
                setDisableSave(false)
              }}
              error={formik.errors.physicalStatus}
              defaultValue={{ id: null, name: "Select"  }}
              options={props?.dropDown?.physicalStatusData}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              isFromReq={"basicdata"}
              isFrom={"profileaddDetails"}
              icon={physicalStatusIcon}
              customClassName={props?.isFrom == "addDetails" && "mt-[2rem]"}
            /> 

            <SaveCancelButton
              saveCallBack={(e) => {
                if (!disablesave) {
                  e.preventDefault();

                }

              }}
              disablesavebtn={disablesavebtn}
              cancelCallback={() => {
                // setEditView(false);
                formik.resetForm()
                setDisableSave(true)
                onClickTrack(EventName.profileBasicCancelButton)
              }}
              submitStatus={submitStatus}
              editfromadmin = {props.editfromadmin}
              setDisableSave={setDisableSave}
              disablesave={disablesave}
              isFrom="MyProfile"
              isFroms="profileaddDetails"
              
            />
          </div>
        ) : (
          <></>
        )}
      </form>
      :
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <div className={`p-5 ${props?.isFrom=="addDetails"? "md:px-4":"md:p-0"} `}>
            {props.isPaid
              ? <BasicTextView title={trans('my_profile.name')} value={props?.data?.name} />
              : <BasicEditTextView
                title={trans('my_profile.name')}
                inputType={"text"}
                inputID={"name"}
                inputName={"name"}
                value={formik.values.name}
                // onChange={onNameChange}
                onChange={(e) => {
                  nameCheckList(e.target.value,e);
                  setDisableSave(false)
                  setToastmsg(true)
                  let text = e.target.value ?? "";
                  text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                  text = text.charAt(0).toUpperCase() + text.slice(1)
                  var splitStr = text.toLowerCase().split(' ');
                  for (var i = 0; i < splitStr.length; i++) {
                      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                  }
                  text =  splitStr.join(' '); 
                  e.target.value = text;
                  if ((e.target.value.split(".").length - 1) <= 1) {
                    formik.handleChange(e)
                    // getstoreDetails(e)
                  }
                }}
            
                error={formik.errors.name}
                required={true}
                disabled={props?.isPaid || props.editfromadmin == 'Y'}
              />}

            {props.isPaid
              ? <BasicTextView title={trans('my_profile.age')} value={props?.data?.age} />
              : <BasicDatePicker
                title={trans('generate_horoscope.date_of_birth')}
                // formatedValue={moment(formik.values.dob).format('DD-MM-YYYY')}
                // value={moment(formik.values.dob).toDate()}\
                formatedValue={formik.values.dob ? moment(formik.values.dob).format('DD-MM-YYYY') : ""}
                value={formik.values.dob ? moment(formik.values.dob).toDate() : null}
                onChange={(date) => {
                  //console.log('Date Of Birth', date);
                  formik.setFieldValue("dob", moment(date).format('YYYY-MM-DD'), true)
                }}
                error={formik.errors.dob}
                required={true}
                minDate={moment().subtract(70,"year").add(1,"day").toDate()}
                maxDate={moment().subtract(props?.data?.gender === "F" ? 18 : 21, "year").toDate()}
                disabled={props?.isPaid || props.editfromadmin == 'Y'}
                name="dob"
              />}

            <ComponentToRender
              name="height"
              title={trans('view_profile.height')}
              value={selectedHeight}
              onChange={(selected, ev) => {
                formik.setFieldValue("height", selected.id)
              }}
              error={formik.errors.height}
              defaultValue={formik.values.height}
              options={props?.dropDown?.heightInchesData}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              customClassName={props?.isFrom == "addDetails" && "mt-[2rem]"}
            /> 

            {/* <BasiSelectView
              title={"Height"}
              inputID={"height"}
              inputName={"height"}
              value={formik.values.height}
              onChange={formik.handleChange}
              error={formik.errors.height}
              options={props?.dropDown?.heightInchesData}
              required={true}
            /> */}
            <BasicEditTextView
              title={trans('view_profile.weight')}
              inputID={"weight"}
              inputName={"weight"}
              value={formik.values.weight}
              onChange={(e)=>{
                if(validateNumber(e.target.value)){
                  formik.handleChange(e)
                }
              }}
              error={formik.errors.weight}
              required={true}
              inputType={"tel"} 
              className={"weight"}             
              disabled={props.editfromadmin == 'Y'}
            />
             <ComponentToRender
              name="height"
              title={trans('view_profile.marital_status')}
              value={selectedMaritalStatus}
              onChange={(selected, ev) => {
                formik.setFieldValue("maritalStatus", selected.id)
              }}
              error={formik.errors.maritalStatus}
              defaultValue={formik.values.maritalStatus}
              options={props?.dropDown?.maritalStatus}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              customClassName={props?.isFrom == "addDetails" && "mt-[2rem]"}
            /> 

            {/* <BasiSelectView
              title={trans('view_profile.marital_status')}
              inputID={"maritalStatus"}
              inputName={"maritalStatus"}
              value={formik.values.maritalStatus}
              onChange={formik.handleChange}
              error={formik.errors.maritalStatus}
              options={props?.dropDown?.maritalStatus}
              required={true}
            /> */}
             <ComponentToRender
              name="bodytype"
              title={trans('my_profile.body_type')}
              value={selectedBodyType}
              onChange={(selected, ev) => {
                formik.setFieldValue("bodytype", selected.id)
              }}
              error={formik.errors.bodytype}
              defaultValue={formik.values.bodytype}
              options={props?.dropDown?.bodyTypeData}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              customClassName={props?.isFrom == "addDetails" && "mt-[2rem]"}
            /> 
            {/* <BasiSelectView
              title={"Body Type"}
              inputID={"bodytype"}
              inputName={"bodytype"}
              value={formik.values.bodytype}
              onChange={formik.handleChange}
              error={formik.errors.bodytype}
              options={props?.dropDown?.bodyTypeData}
              required={true}
            /> */}
             <ComponentToRender
              name="complexion"
              title={trans('view_profile.complexion')}
              value={selectedComplexion}
              onChange={(selected, ev) => {
                formik.setFieldValue("complexion", selected.id)
              }}
              error={formik.errors.complexion}
              defaultValue={formik.values.complexion}
              options={props?.dropDown?.complexionData}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
            /> 
            {/* <BasiSelectView
              title={"Complexion"}
              inputID={"complexion"}
              inputName={"complexion"}
              value={formik.values.complexion}
              onChange={formik.handleChange}
              error={formik.errors.complexion}
              options={props?.dropDown?.complexionData}
              required={true}
            /> */}
            <ComponentToRender
              name="physicalStatus"
              title={trans('view_profile.physical_status')}
              value={selectedPhysicalStatus}
              onChange={(selected, ev) => {
                formik.setFieldValue("physicalStatus", selected.id)
              }}
              error={formik.errors.physicalStatus}
              defaultValue={{ id: null, name: "Select"  }}
              options={props?.dropDown?.physicalStatusData}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
              isFromReq={"basicdata"}
            /> 
            {/* <BasiSelectView
              title={trans('view_profile.physical_status')}
              inputID={"physicalStatus"}
              inputName={"physicalStatus"}
              value={formik.values.physicalStatus}
              onChange={formik.handleChange}
              error={formik.errors.physicalStatus}
              options={props?.dropDown?.physicalStatusData}
              required={true}
            /> */}

            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
                
              }}
              cancelCallback={() => {
                // setEditView(false);
                formik.resetForm()
                onClickTrack(EventName.profileBasicCancelButton)
              }}
              submitStatus={submitStatus}
              editfromadmin = {props.editfromadmin}
              isFrom="MyProfile"
            />
          </div>
        ) : (
          <></>
        )}
      </form>
}
    </div>
  );
};

export default BasicForm;

import React, { useContext, useRef } from 'react'

import KMLogo from "../../Images/KM logo@2x.png"
import AvatarImage from '../../Images/qwd.png'
import cameraIcon from '../../Images/camera (3).png'
import plusIcon from '../../Images/Group 57.png'
import WhatsAppIcon from '../../Images/whatsapp.svg'
import { useState } from 'react';
import { useEffect } from 'react';
import config from '../../config';
import EndPoints from '../../Constants/EndPoints';
import { imageSizeReducer } from '../../Components/ImageSizeReducer';
import { LoginContext } from '../../Contexts/LoginContext';
import { getDeviceInfo } from '../../Components/Kalyanlite/utils';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getIsLoginFromAdmin, validateImage } from '../../Utils';
import { CommonValidPopup, WhatsAppNumperPopup, WhatsAppNumperSuccessPopup } from '../../Components/CommonPopup'
import RouteSegments from '../../Routes/RouteSegment'
import Constants from '../../Constants/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { dashboardProfileData } from '../../Store/DashboardAPI/Action'

const UploadPhotoPage = () =>{

    const photoUploadref = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    const { loginDetail, logout } = useContext(LoginContext);
    const [isUploading, setIsUploading] = useState(false)
    const [currentimage, setCurrentImage] = useState();
    const [originalImage, setOriginalImage] = useState();
    const [notification, setNotification] = useState({ show: false, title: "", message: "" })
    const [isOriginalImageUploaded, setIsOriginalImageUploaded] = useState(() => localStorage.getItem("isOriginalImageUploaded") === "true")
    const [whatsAppOpen, setWhatsAppOpen] = useState(false)
    const [whatsAppSuccess, setWhatsAppSuccess] = useState(false)
    const [showUploadPhotoPopup, setShowUploadPhotoPopup] = useState(()=>{
        const show = sessionStorage.getItem("showUploadPhotoPopup") === "true"
        sessionStorage.removeItem("showUploadPhotoPopup")
        return show;
    })

    const { ProfileData } = useSelector(state => ({
        ProfileData: state.DashBoardAPI?.ProfileData?.data
    }));

    const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

    useEffect(() => {
        if (window.performance) {
            if (performance.navigation.type == 1) {
              console.log( "This page is reloaded" );
              setShowUploadPhotoPopup(true);
            } else {
              console.log( "This page is not reloaded");
            }
          }
    }, [])

    useEffect(() => {
        dispatch(dashboardProfileData())
    }, [])

    useEffect(() => {
      if(location?.state?.isFrom  == "browserback"){
        setShowUploadPhotoPopup(true);
      }
    }, [location])
    

    useEffect(() => {
      const handlePopState = () => {
          navigate(RouteSegments.UPLOAD_PHOTO_PAGE,{ state : { isFrom : "browserback"} })
      };
        if(location?.state?.isFrom == "previous"){
        
        }else{
            window.addEventListener("popstate", handlePopState);
        }
  
      return () => {
        window.addEventListener("popstate", handlePopState);
      };
    }, []);


    useEffect(() => {
        localStorage.setItem("isOriginalImageUploaded", isOriginalImageUploaded)
    }, [isOriginalImageUploaded])

    const originalImageUpload = async (file) => {

        setIsUploading(true);
        console.log("File Details:", file);
    
        if (file.size / 1024 / 1024 < 10) {
            try {
                let fileData = new FormData();
                fileData.append("email", loginDetail()[0]);
                fileData.append("userId", loginDetail()[1]);
                fileData.append("photoUploadFrom", getDeviceInfo());
                fileData.append("uploadfrom", "Gallery");
                fileData.append("original", file);
                fileData.append("uploadvia", "After_registration_2");
                fileData.append("requestfulfilledvia", "AfterRegistration");
                fileData.append("photorequesttypeid", 85);
                fileData.append("requestfulfilledfrom", getDeviceInfo());
                fileData.append("recieverprofileid", ProfileData?.smProfileId?.replace(/\D/g, ""));
    
                console.log("fileData Prepared:", fileData);
    
                let data = await axios.post(
                    `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
                    fileData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
    
                console.log(data, "API Response");
    
                if (data.data.statusCode === 200) {
                    setIsOriginalImageUploaded(data.data.data.status === "Success");
                    sessionStorage.removeItem("photoPage");
                    if (isCommunitySite) {
                        if (localStorage.getItem("submitpref")) {
                            localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.MATCH_GROUP)
                            navigate(RouteSegments.MATCH_GROUP)
                        }
                    } else {
                        setIsUploading(false);
                        localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.PROFILE_PREVIEW)
                        navigate(RouteSegments.PROFILE_PREVIEW,{state : {isFrom : "afterreg"}})
                    }
                } else {
                    setIsUploading(false);
                    setIsOriginalImageUploaded(false);
                }
            } catch (error) {
                console.error("Image Upload API Call Failed:", error);
                setIsUploading(false);
            }
        } else {
            alert("Please upload file less than 10 MB");
        }
    };
    

    //profile photo error message
    const showNotification = ({ description = "", title = "Status!" }) => {
        setNotification({ show: true, title: title, message: description })
    }

    const handleProfileSelect = (file) => {
        setOriginalImage(file);
        validateImage({
            file,
            onSizeError: (img) => {
                showNotification({ title: "Invalid image", description: "Height and Width must be more than 100px" })
            },
            onExtensionError: (fileType) => {
                showNotification({ title: "Invalid image", description: "Invalid extension!" })
            },
            onFileSizeError: (img) => {
                showNotification({ title: "Invalid image", description: "Image size should not be greater than 10mb" })
            },
            onSuccess: (img, readerStr) => {
                imageSizeReducer(file,originalImageUpload)
                showNotification({ title: "", description: "" })
                setCurrentImage(readerStr || "")
            }
        })
    };

    const handleWhatsAppClick = () => {
        setWhatsAppOpen(true)
    }
    
    const handleSkip = () => {

        if(isCommunitySite){
            localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.MATCH_GROUP)
            navigate(RouteSegments.MATCH_GROUP)
        }else{
            sessionStorage.removeItem("photoPage");
            localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.PROFILE_PREVIEW)
            navigate(RouteSegments.PROFILE_PREVIEW,{state : {isFrom : "afterreg"}})
        }
      }

  return (
    <>
     {showUploadPhotoPopup && <CommonValidPopup
        close={() => setShowUploadPhotoPopup(false)}
        title={"Alert!"}
        content={"Adding photo to your profile is mandatory"} />
      }
    {whatsAppOpen &&
        <WhatsAppNumperPopup close={()=>{setWhatsAppOpen(false)}} setWhatsAppSuccess={setWhatsAppSuccess} />
    }
    {whatsAppSuccess && 
        <WhatsAppNumperSuccessPopup close={()=>{setWhatsAppSuccess(false)}} />
    }
    
    <div className="font-Poppins bg-[#F2F1EF] h-screen flex flex-col">
        {/* Header */}
        <div className="bg-white flex-none">
            <a href="" className="flex items-center py-3 shadow-[0_21px_11px_-15px_#BE363629]">
                <img
                    src={KMLogo}
                    className="h-12 md:h-[4rem] mx-5 pl-[2.5rem] md:pl-[3.8rem] lg:h-[3.5rem]"
                    alt=""
                />
            </a>
        </div>

        {/* Main Content */}

        <div className="flex-grow mx-[1.75rem] md:mx-[5.75rem] mt-[3rem] mb-[3rem] shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_1px_5px_13px_-1px_rgba(45,78,255,0.15)]">
            <div className="p-[1.25rem] md:p-[4.25rem] overflow-hidden overflow-x-hidden h-full grow md:h-[83vh] xl:h-[78vh] 2xl:h-[80vh] lg:h-[75vh] bg-[#FFFFFF]">
                <div className='md:hidden lg:hidden'>
                    <p className="text-[#D63838] text-[22px] font-bold">Upload Photos*</p>
                </div>
                <div className="flex col-span-2 lg:flex justify-center md:space-x-5 lg:space-x-8 lg:pt-2 md:pt-2 pt-[2rem]">
                    <div
                        onClick={isOriginalImageUploaded ? null : () => photoUploadref.current?.click()}
                        className={`${isOriginalImageUploaded ? "" : "cursor-pointer"} rounded-[10px] p-4 px-[1rem] md:px-[1rem] lg:px-[2rem] border border-[#cccccc]`}
                    >
                        <span className="relative inline-block">
                            <img
                                alt=""
                                src={AvatarImage}
                                className="w-24 h-24 lg:w-28 lg:h-28  md:h-[8rem] md:w-auto [15rem] mx-5 md:mx-0 lg:mx-0"
                            />
                            {!isOriginalImageUploaded && (
                                <span
                                    className="absolute top-[4.3rem] right-[0.6rem] md:top-[6.2rem] lg:top-[5.2rem] md:right-[-0.4rem] lg:right-[-0.4rem] inline-flex items-center justify-center lg:px-[6px] md:px-1 md:text-[16px] lg:text-[24px] font-bold leading-none rounded-full"
                                >
                                    <img className="w-full h-[24px]" src={plusIcon} alt="" />
                                </span>
                            )}
                        </span>
                        {!isOriginalImageUploaded && (
                            <div className="flex flex-col items-center">
                                <p className="text-[#000000] font-segeo text-[11px] md:text-[10px] lg:text-[13px] whitespace-nowrap">
                                    Add Photos
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center space-y-2 pt-2">
                    <div className='md:block lg:block hidden'>
                        <p className="text-[#D63838] text-[22px] font-bold">Upload Photos*</p>
                    </div>
                    <p className="text-[15px] text-[#787878] flex text-center">
                        Profiles with photos get 10 times more responses.
                    </p>
                    <div className='md:block lg:block hidden'>
                        <div className="pt-3 justify-center flex items-center space-x-2">
                        <button
                            className="rounded-[10px] flex items-center justify-center gap-x-3 px-7 py-2 bg-[#D63838] text-white text-center text-[15px] w-[14rem]"
                            onClick={() => photoUploadref.current.click()}
                        >
                            <img className="w-[1.5rem] h-[1.3rem]" src={cameraIcon} alt="Upload Icon" />
                            <span className="whitespace-nowrap">
                                {isOriginalImageUploaded
                                    ? "Photo Uploaded Successfully!"
                                    : isUploading
                                    ? "Uploading..."
                                    : "Upload Photos"}
                            </span>
                            <input
                                ref={photoUploadref}
                                disabled={isOriginalImageUploaded}
                                style={{ display: "none" }}
                                type="file"
                                onChange={(event) => {
                                    handleProfileSelect(event.target.files[0]);
                                }}
                                accept="image/jpeg, image/png"
                            />
                        </button>
                            <button onClick={handleWhatsAppClick} className="cursor-pointer rounded-[10px] flex text-center justify-center px-7 py-2 bg-[#FCF5EB] text-[#25D366] border border-[#000000] text-[15px] w-[14rem] h-auto">
                                <img className="w-[1.5rem] h-[1.3rem]" src={WhatsAppIcon} alt="" />
                                <span className="px-3 whitespace-nowrap">Add via WhatsApp</span>
                            </button>
                        </div>
                    </div>
                    <div className="pt-3 justify-center flex-col space-y-3 md:hidden lg:hidden">
                    <button
                            className="rounded-[10px] flex items-center justify-center gap-x-3 px-7 py-2 bg-[#D63838] text-white text-center text-[15px] w-[14rem]"
                            onClick={() => photoUploadref.current.click()}
                        >
                            <img className="w-[1.5rem] h-[1.3rem]" src={cameraIcon} alt="Upload Icon" />
                            <span className="whitespace-nowrap">
                                {isOriginalImageUploaded
                                    ? "Photo Uploaded Successfully!"
                                    : isUploading
                                    ? "Uploading..."
                                    : "Upload Photos"}
                            </span>
                            <input
                                ref={photoUploadref}
                                disabled={isOriginalImageUploaded}
                                style={{ display: "none" }}
                                type="file"
                                onChange={(event) => {
                                    handleProfileSelect(event.target.files[0]);
                                }}
                                accept="image/jpeg, image/png"
                            />
                        </button>

                        <button onClick={handleWhatsAppClick} className="rounded-[10px] flex px-7 py-2 bg-[#FCF5EB] text-[#25D366] justify-center  text-center border border-[#000000] text-[15px] w-[14rem] h-auto">
                            <img className="w-[1.5rem] h-[1.3rem]" src={WhatsAppIcon} alt="" />
                            <span className="px-3 whitespace-nowrap">Add via WhatsApp</span>
                        </button>
                    </div>
                    <span className="text-[red] text-[12px]">{notification?.message}</span>
                </div>
                {  getIsLoginFromAdmin() &&
                <div className="flex mt-3 items-center justify-around py-4">
                    <div></div>
                    <div></div>
                    <div className="pl-[6rem] md:pl-[12rem]">
                        <div className="flex justify-end">
                            <button
                                onClick={() => {handleSkip();}}
                                type="button"
                                className="cursor-pointer pt-2 text-[#C1C1C1] text-[13px] underline"
                            >
                            Skip
                            </button>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>

    </div>
</>
  )
}

export default UploadPhotoPage
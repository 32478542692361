import React, { useContext, useEffect, useState } from 'react'
import HoroscopeComponent from '../../../Components/Edit Profile/MyProfileComponents/HoroscopeComponent'
import { useSelector } from 'react-redux';
import config from '../../../config';
import EndPoints from '../../../Constants/EndPoints';
import { GET } from '../../../Services/api_services';
import { LoginContext } from '../../../Contexts/LoginContext';

function PreviewHoroscope({ editMyProfileData, ProfileData,apiCallEditProfile }) {
    const [edithoroinfofreshData, setedithoroinfofresh] = useState();
    const { loginDetail, logout } = useContext(LoginContext);


    const { starList } = useSelector(state => ({
        starList: state.Home?.starList?.data?.data
    }));

    useEffect(() => {
        const apiHoroCallDropDown = async (type) => {

            let url = "";

            url = `${config.api.API_URL}${EndPoints.edithoroinfofreshUrl({ motherToungeId: editMyProfileData?.motherTongue?.at(0)?.id })}`;

            let { statusCode, data } = await GET(url);
            //console.log("dropdown data ", data);
            if (statusCode === 200) {
                setedithoroinfofresh(data.data);
            } else if (statusCode === 401) {
                logout();
            }

        };
        apiHoroCallDropDown()
    }, [])


    return (
        <HoroscopeComponent
            starList={starList}
            // refresh={horoscopeCallBack}
            data={editMyProfileData}
            callBackReload={() => {
                apiCallEditProfile()
             }}
            dropDown={edithoroinfofreshData}
            editfromadmin={ProfileData?.editfromadmin}
            isScroll={false}
            isFrom="horodetails"
        />
    )
}

export default PreviewHoroscope
import React, { useEffect, useRef } from 'react';
import { useContext, useState } from 'react';
import { NavBarContext } from '../../Contexts/NavBarContext';
import CloseIcon from "../../Images/Group 761@2x.png";
import { useAnalytics } from '../../Hooks/usePageChange';
import { LoginContext } from '../../Contexts/LoginContext';
import { CommonErrorPopup } from '../../Components/CommonPopup';
import HoroBasic from '../../Components/TrailPack/HoroBasic';
import HoroScopeDetails from './HoroScopeDetails';
import ProfileTrustMarkComponent from '../../Components/Edit Profile/ProfileTrustMarkComponent';
import TrustDocument from './TrustDocument';

const TrustDocPopup = ({ editMyProfielData, close, apiCallEditProfile, setShowTrustPopup }) => {

  const [showPopup, setShowPopup] = useState(true)

  const horopopupRef = useRef(null);


  const handleClickOutside = (event) => {
    if (
      horopopupRef.current &&
      !horopopupRef.current.contains(event.target) &&
      window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
    ) {
      close();
    }
  };



  useEffect(() => {
   
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    
  }, []);

  return (
    <>

      {showPopup &&

        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">


          <div ref={horopopupRef} className={`relative w-[100%]  md:w-[69%] lg:max-w-[60%] xl:max-w-[50%] md:my-6 md:mx-6`}>
            <div className="">

              <button
                className={` hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]`}
                onClick={() => close()}
              >
                <img className="w-6 h-6 " src={CloseIcon} alt="" />
              </button>

            </div>
            <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="relative flex-auto p-6">
                <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />

                <TrustDocument
                setShowPopup={setShowPopup}
                  isFroms="previewtrustdoc"
                  setShowTrustPopup={setShowTrustPopup}
                  // close={() => setShowTrustPopup(false)}
                  idproof={editMyProfielData?.idproof}
                  eduproof={editMyProfielData?.eduproof}
                  incomeproof={editMyProfielData?.incomeproof}
                  profileid={editMyProfielData?.profileId}
                  isPaid={true}
                  callBackReload={apiCallEditProfile}
                  isFrom={"edit"}
                  onButtonCallback={() => {
                    //   setActiveTab("4");
                  }}
                  callBackDelete={(url, selectedDoc) => {
                    let type;
                    if (selectedDoc == 'ID Proof') {
                      type = 'idproof';
                    }
                    else if (selectedDoc == 'Education Proof') {
                      type = 'educationproof';
                    }
                    else if (selectedDoc == 'Income Proof') {
                      type = 'incomeproof';
                    }
                    //   callBackHoroscope(url,type);
                  }}
                />

              </div>
            </div>
          </div>
        </div>
      }


    </>
  );
};
export default TrustDocPopup;
import config from "../config";

const EndPoints = {
  thumbnailUrl(type, fileName) {
    return `${config.api.chat_api}/storage/thumbnail_images/${type}/${fileName}`
  },
  
  mediaUrl(type, fileName) {
    return `${config.api.chat_api}/storage/original_files/${type}/${fileName}`
  },
  audioUrl(type, fileName) {
    return `${config.api.chat_api}/storage/${type}/${fileName}`
  },
  imageUrl(type, fileName) {
    return `${config.api.image_URL}/storage/app/public/original_files/${type}/${fileName}`
  },
  loginUrl() {
    return "/api/login";
  },
  notificationUrl(){
    return "/api/notificationlist";
  },
  dashboardUrl() {
    return "/api/dashboard";
  },
  getProfileData(){
    return "/api/getprofiledata"
  },
  getweeklyrecomendmatches(){
    return "/api/getweeklyrecomendmatches"
  },
  getdashboardcounts(){
    return "/api/getdashboardcounts"
  },
  weeklyMatchesURl() {
    return "/api/weeklymatchloadmore";
  },
  recommendedMatchesUrl() {
    return "/api/recommendationloadmore";
  },
  responseReceivedUrl() {
    return "/api/responsereceivedloadmore";
  },
  profilesLookingForUrl() {
    return "/api/profileslookingforloadmore";
  },
  profilesLookingFormeUrl() {
    return "/api/profileslookingformeloadmore";
  },
  photoRequestReceivedUrl() {
    return "/api/photorequestreceivedloadmore";
  },
  mutalMatchesUrl() {
    return "/api/mutalmatchesloadmore";
  },
  selectedProfilesUrl() {
    return "/api/selectedprofilesloadmore";
  },
  selectRejectUrl() {
    return "/api/selectedrejected";
  },
  viewPhoneUrl(){
    return "/api/viewphonenumber";
  },
  sendMessageUrl() {
    return "/api/sentmessage";
  },
  sendmessagesmsonly() {
    return "/api/sendmessagesmsonly";
  },
  recentlyViewUrl() {
    return "/api/recentviewloadmore";
  },
  exploreUrl() {
    return "/api/searchbyfilter";
  },
  inboxMessageUrl() {
    return "/api/inboxmatches";
  },
  inboxRequestViewUrl() {
    return "/api/requestview";
  },
  matchViewProfileUrl() {
    return "/api/matchviewprofile";
  },
  checkpasswordMatchUrl(){
  return "/api/checkpasswordmatch";
  },
  requestphotoUrl(){
    return "/api/requestPhoto";
  },
  checkPhotoPassword(){
    return "/api/checkpasswordmatch"
  },
  gteIsdCodeUrl(){
    return "/api/getisdcode";
  },
  submitparentsnumberUrl(){
    return "/api/submitparentsnumber";
  },
  similarMatchesUrl() {
    return "/api/similarviewloadmore";
  },
  editMyProfileUrl() {
    return "/api/editmyprofile";
  },
  profileMyplansURl() {
    return "/api/myplan";
  },
  viewedphoneandprofilesUrl() {
    return "/api/getviewedphoneandprofiles";
  },
  profileImageListUrl() {
    return "/api/imagelist";
  },
  profilePhotoUploadUrl() {
    return "/api/imageuploadnew";
  },
  profileOriginalImageUploadUrl() {
    return "/api/originalimageupload";
  },
  croppedPhotoUploadUrl() {
    return "/api/cropedimageupload";
  },  
  profilePhotoDeleteUrl() {
    return "/api/removeprofilepicture";
  },
  makeProfileImageUrl() {
    return "/api/makeprofilepicture";
  },
  trustDocumentUploadUrl() {
    return "/api/trustDocumentsUploadnew";
  },
  trustDoumentDeleteUrl() {
    return "/api/removetrustdocument";
  },
  getHoroscopeUrl() {
    return "/api/generatehoroscopedetails";
  },
  generateHoroscopeUrl() {
    return "/api/generateHoroscope";
  },
  getPincodeUrl(query) {
    return `/api/getpincode?q=${query}`;
  },
  settingsUrl() {
    return "/api/settings";
  },
  settingsEmailAlertUrl() {
    return "/api/saveEmailAlertsettings";
  },
  saveSettings() {
    return "/api/savesettings";
  },
  savePrivacySettings() {
    return "/api/saveprivacysettings";
  },
  editdescriptioninfosaveUrl() {
    return "/api/editdescriptioninfosave";
  },
  editbasicinfofreshUrl() {
    return "/api/editbasicinfofresh";
  },
  editbasicinfoUrl() {
    return "/api/editbasicinfo";
  },
  editreligioninfofreshUrl() {
    return "/api/editreligioninfofresh";
  },
  editreligioninfosaveUrl() {
    return "/api/editreligioninfosave";
  },
  edithoroinfofreshUrl({motherToungeId}) {
    const searchParams = new URLSearchParams({motherToungeId});
    return `/api/edithoroinfofresh?${searchParams.toString()}`;
  },
  edithoroscopeinfosave() {
    return "/api/edithoroscopeinfosave";
  },
  educationinfofreshUrl() {
    return "/api/educationinfofresh";
  },
  editeducationinfosaveUrl() {
    return "/api/editeducationinfosave";
  },
  editlocationinfofreshUrl() {
    return "/api/editlocationinfofresh";
  },
  editlocationinfosaveUrl() {
    return "/api/editlocationinfosave";
  },
  familyinfofreshUrl() {
    return "/api/familyinfofresh";
  },
  editfamilyinfosaveUrl() {
    return "/api/editfamilyinfosave";
  },
  edithabitsinfosaveUrl() {
    return "/api/edithabitsinfosave";
  },
  managepreferencesdataUrl(){
    return "/api/managepreferencesdata"
  },
  managepreferencessaveUrl(){
    return "/api/managepreferencessave";
  },
  getCastUrl(motherTounge, religion) {
    return `/api/getcaste?motherTongueId=${motherTounge}&religionId=${religion}`;
  },
  getSubCastUrl(castId) {
    return `/api/getsubcaste?casteId=${castId}`;
  },
  getProfileSubCastUrl(castId) {
    return `/api/getprofilesubcaste?casteId=${castId}`;
  },
  getRaasiUrl(starId,motherTounge) {
    return `/api/getraasi?starId=${starId}&motherToungeId=${motherTounge}`;
  },
  getStateUrl(countryId) {
    
    return `/api/getstates?countryId=${countryId}`;
  },
  getCityUrl(stateId) {
    return `/api/getcities?stateId=${stateId}`;
  },
  getPlaceUrl(query){
    return `/api/tags/get_tag_list?q=${query}`
  },
  sendOtpUrl(){
    return "/api/sendotp"
  },
  verifymobileUrl(){
    return "/api/verifymobile"
  },
  advanceSearchUrl() {
    return "/api/advancedsearch";
  },
  helpData(){
    return "/api/helpdata"
  },
  helpSave(){
    return "/api/helpsave"
  },
  savedDeleteProfile() {
    return "/api/savedeleteprofile"
  },
  savedDeleteProfileForFile() {
    return "/api/savedeleteprofileforfile"
  },
  basicSearchUrl(){
    return "/api/basicsearch"
  },
  searchPageNew() {
    return "/api/searchpagenew"
  },
  searchById(){
    return "/api/searchbyid"
  },
  kjbannerPopupDetail(){
    return "/api/muhuratbanner"
  },
  notificationCountUrl(){
    return "/api/notificationcount"
  },
  logout(){
    return "/api/logout"
  },
  requestPhotoHoroTrustParent(){
    return "/api/multirequesttype";
  },
  searchPageResultSet() {
    return "/api/searchpageresultset"
  },
  recomendmatchnew() {
    return "/api/recomendmatchnew"
  },
  partnerpreferencesearch(){
    return "/api/partnerpreferencesearch" 
  },
  bulkrequest(){
    return "/api/bulkrequest" 
  },
  bulkrequestpopup(){
    return "/api/bulkrequestpopup"
  },
  validateNameUrl() {
    return "/api/validatename";
  },
  searchfilterUrl(){
    return "/api/searchfilter";
  },
  updateContentCardstatusUrl() {
    return "/api/updateContentCardstatus"
  },
  savecastmigrationUrl() {
    return "/api/savecastmigration"
  },
  confirmcastmigrationUrl() {
    return "api/confirmcastmigration"
  },
  pixelScriptUrl() {
    return "/api/getPixelScript"
  },
  saveEmailUrl() {
    return "/api/saveemail";
  },
  kalyanPremierInterestedUrl(){
    return "/api/kalyan-premier-interested"
  },
  kalyanPremierUrl(){
    return "/api/kalyan-premier"
  },
  validateParentsNumberUrl() {
    return "/api/parentnumberExist";
  },
  microSiteStateUrl() {
    return `/api/muhuratbannermicrosite?loginFrom=Android&loginFromEmail=appointment`
  },
  muhuratstorebystatemicrosite(stateId) {
    return `/api/muhuratstorebystatemicrosite?stateId=${stateId}`
  },
  muhuratstoresubmitmicrosite() {
    return `/api/muhuratstoremicrosite`
  },
  getMediaFiles() {
    return `/api/getMediaFiles`
  },
  searchChatMessage() {
    return "/api/searchChatMessage"
  },
  searchGroupChatMessage() {
    return "/api/searchGroupMessage"
  },
  blockprofile() {
    return "/api/blockprofile";
  },
  unblockprofile() {
    return "/api/unblockprofile";
  },
  matchesliteapp() {
    return "/api/matchesliteapp";
  },
  getviewedDocument() {
    return "/api/getviewedDocument"
  },
  rejectedprofilesloadmore() {
    return "/api/rejectedprofilesloadmore"
  },
  getviewedphoneprofileanddocumentv3() {
    return "/api/getviewedphoneprofileanddocumentv3"
  },
  requestviewliteapp() {
    return "/api/requestviewliteapp"
  },
  createfamilygroupUrl() {
    return "/api/createfamilygroupv3";
  },
  groupLoginUrl() {
    return "/api/familyloginv3"
  },
  getfamilyUrl(profileId) {
    return `/api/getcreatedfamilygroupv3?profileiid=${profileId}`
  },
  getphonenumberUrl(parentsMobileNumber) {
    return `/api/getfamilyloginprofilesv3?mobilenumber=${parentsMobileNumber}`
  },
  switchtonewversion() {
    return "/api/switchtonewversion "
  },
  approveImageListUrl() {
    return "/api/approveimagelist";
  },
  getprofilehobbiesUrl(){
    return "/api/hobbiesandinterest"
  },
  getprofilehobbiesSaveUrl(){
    return "/api/hobbiesandinterestsave"
  },
  getfamilyChatList() {
    return `/api/getGroupChatList`
  },
  getGroupMediaFiles() {
    return `/api/getGroupMediaFiles`
  },
  saveWhatsappDetails() {
    return `/api/savewhatsappdetails`
  },
  getCountViaCube() {
    return "/api/getCountViaCube"
  },
  getFilterCount() {
    return "/api/getFilterCount"
  },
  getOtherdetailsData(){
    return "/api/otherdetails"
  },
  getCasteEdit(){
    return "/api/editcasteinfosave"
  },
  getCustomerServiceUrl(){
    return "/api/kmc/getConversation"
  },
  sendCustomerServiceMessageUrl() {
    return "/api/kmc/sentmessage";
  },
  unreadMsgCountUrl(){
    return "/api/kmc/unreadMessageCount";
  },
  updateKMCNotification(){
    return "/api/kmc/updateKMCNotification";
  },
  getstoreDetails(){
    return "/api/storeDetails"
  },
  clcnotificationurl(){
    return "/api/clcnotificationurl"
  },
  payemtPageVisitEndtime(assistanceId){
    return `/api/payementpagevisitendtime?paymentassistanceid=${assistanceId}`
  },
  getPixelScript() {
    return "/api/getPixelScript"
  },
  confirmcasteinfosaveUrl(){
    return "/api/confirmcasteinfosave"
  },
  trialpackbenefitDetails () {
    return "/api/trialpackbenefit"
  },
  trialpackstatus() {
    return "/api/trialpackstatus";
  },
  claimChottaPack(){
    return "/api/claimChottaPack";
  },
  SetFamilycodeUrl(){
    return `/api/setfamilycode`
  },
  removefamilygroupUrl(){
    return '/api/removefamilygroupv3'
  },
  additionalFamilyInfo() {
    return "/api/additionalfamilyinfofresh";
  },
  saveAdditionInfo(){
    return "/api/additionalfamilyinfoSave";
  },
  rankingPopupUrl(){
    return "/api/kmc/conversationRating";
  },
  MsgUnReadCountUrl(){
    return "/api/kmc/readMessage";
  },
  additionalFamilyinfofreshUrl() {
    return "/api/additionalfamilyinfofresh";
  },

  descriptionAutomateUrl(){
    return '/api/descriptionweb'
  },
  subcasteinfosaveUrl(){
    return '/api/subcasteinfosave'
  },
  monthlyincomeinfosaveUrl(){
    return '/api/monthlyincomeinfosave'
  },
  StoreRequestUrl(){
    return '/api/storerequesttype'
  },
  ProfileToChatSendMessageUrl(){
    return '/api/profileToChatSendMessage'
  },
  additionalfamilyinfoSaveViaChatUrl(){
    return '/api/additionalfamilyinfoSaveViaChat'
  },
  notificationClickedTrackingUrl(){
    return '/api/notificationclickedtracking'
  },
  vernacular(){
    return "/api/vernacular"
  },
  descriptionPopupUrl(){
    return '/api/descriptionPopup'
  },
  getdescriptiontooltipUrl(){
    return '/api/getdescriptiontooltip'
  },
  
  UpdateDeviceTokenUrl(){
    return '/api/updatedevicetoken'
  },
  whoSelectedMyProfileUrl(){
    return '/api/whoselectedmyprofile'
  },
  getPhotoViaWhatsappUrl(){
    return "/api/getPhotoViaWhatsapp";
  },
  getTrialPackUrl(){
    return "/api/gettrialpackurl";
  },
  notificationPopupUpdate(){
    return "/api/notificationpopupupdate";
  },
  samedaypopuptrackUrl(){
    return '/api/samedaypopuptrack'
  },
  membershipdetailsafterregistration(){
    return '/api/membershipdetailsafterregistration';
  },
  getpaymentrequestwebafterregistration(){
    return '/api/getpaymentrequestwebafterregistration';
  },
  trackforquikreply(){
    return '/api/trackforquickreply';
  },
  viewProfilesNumberUrl (){
    return '/api/viewProfilesNumber';
  }

};
export default EndPoints;

import React, { useRef, useState ,useEffect} from 'react'
import { Select, AutoComplete } from "antd";
import { CaretDownOutlined,CheckOutlined } from '@ant-design/icons'


const SelectDropdownUtil = (props) => {
  
  const selectRef = useRef()

  return (
    props?.value != undefined &&
    <>
    <Select
    // suffixIcon={<CaretDownOutlined />}
    {...props}
    showSearch
    style={{
      width: "100%"
    }}
    className={props?.menu=="aferRegister" ?"new disabled": (props?.fieldType >= 67 && props?.fieldType <= 84)   ? "assets additional" : props?.styles ? "highlight" : ""}
    mode="multiple"
    autoComplete="none"
    id={props?.id}
    name={props?.name}
    defaultValue={props.defalutValue?props?.defalutValue:props?.placeholder} 
    optionFilterProp="children"
    showArrow={props?.menu=="aferRegister"|| (props?.fieldType >= 67 && props?.fieldType <= 84) } 
    ref={selectRef} 
    disabled={props?.isDisabled}
    onChange={event => {
      props?.onChange(event);
      if(props.isFromProfile == 'MyProfile' || props.isFromAdditional == true){
          return '';
      }else{
        setTimeout(() => {
          selectRef.current.blur();
        }, 0);
      }}
    }
      filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    }
    options={
      props.isFromProfile == 'MyProfile' || props.isFromAdditional == true ? 
      props?.option && props?.option?.map((option, i) => ({
        value: option.id.toString(),
        label: (
          <div className="flex justify-between items-center">
            {props?.name === "countryNameId" ? option?.country : option?.name}
            {/* Conditionally show tick icon */}
            {!props?.value?.includes(option.id.toString()) && (
              <CheckOutlined style={{ color: 'grey' }} />
            )}
          </div>
        ),
      }))
      :
      props?.option && props?.option?.map((option,i)=>(
      option.heading == "Y" ?
      {
        label: option.name,
        options: 
        [
          {
            label: option.name,
            value: option.id,
          }
        ],
      }
      :
      {
                value: 
                      props?.name == "countryNameId" || props?.name == "currencyId" || props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                       option?.id.toString()
                       :props?.name == "maritalStatus"?
                       option.name
                       :props?.name == "maritalStatusId" ?
                       option.id
                       :option.id,
                label: props?.name == "countryNameId" ? 
                        option?.country:
                        props?.name == "currencyId"  ?
                        option.currency+","+option.country:
                        props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                       option?.isdcode+","+option.country:option.name,
              }
    ))
      
    }
  />
  
  {props?.errors && (
    <p className="text-[red] text-[12px]">
      {props?.errors}
    </p>
  )}{" "}
  </>
  )
}

const SelectDropdownUtilHeading = (props) => {
 console.log(props,"fvhng-ddd");
return (
  props?.value != undefined &&
  <>
  <Select
  // suffixIcon={<CaretDownOutlined />}
  {...props}
  showSearch
  style={{
    width: "100%"
  }}
  className={props?.menu=="aferRegister" ?"new ":""}
  mode="multiple"
  autoComplete="none"
  id={props?.id}
  name={props?.name}
  defaultValue={props.defalutValue?props?.defalutValue:props?.placeholder} 
  optionFilterProp="children"
  showArrow={props?.menu=="aferRegister"}
  onChange={event => {
      //console.log(event,"event")
      props?.onChange(event)}}
  filterOption={(input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }
  options={props?.option?.map((option,i)=>(
    option.heading == "Y" ?
  {
    label: option.name,
    options: 
    [
      {
        label: option.name,
        value: option.id,
      }
    ],
  }
  :
          {
              value: props?.name == "countryNameId" || props?.name == "currencyId" || props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.id.toString()
                     :option.id,
              label: props?.name == "countryNameId" ? 
                      option?.country:
                      props?.name == "currencyId"  ?
                      option.currency+","+option.country:
                      props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.isdcode+","+option.country:option.name,
            }
  ))
    
  }
/>

{props?.errors && (
  <p className="text-[red] text-[12px]">
    {props?.errors}
  </p>
)}{" "}
</>
)
}




const LocationSingleSelectDropdown = (props) => {
  ////console.log(props,"fvhng");
return (
  props?.value != undefined &&
  <>
  <div className='hidden md:block'>
  <Select
  // suffixIcon={<CaretDownOutlined />}
  {...props}
  showSearch
  style={{
    width: "100%"
  }}
  placeholder={props?.placeholder}
  className= {props?.menu=="afterReigister" ?"new text-[#7A7A7A] font-bold text-[14px]" :'text-[#7A7A7A] font-bold text-[14px]'}
  // onSearch={(e)=>{if(e.length>2){ props.callBackSearch(e)}}}
  onSearch={(e) => {
    if (e.length > 2) {
      props.callBackSearch(e); // Ensure this correctly updates the dropdown options
    }
  }}
  id={props?.id}
  name={props?.name}
  value={props?.value}
  defalutValue={props.defalutValue?props?.defalutValue:props?.placeholder}
  optionFilterProp="children"
  onChange={event => {
      //console.log(event,"event")
      props?.onChange(event)}}

  filterOption={(input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }
  options={props?.option?.map((option,i)=>(
          {
              value: props?.name == "countryNameId" || props?.name == "currencyId" || props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.id.toString()
                     :props.name==="placeOfBirth"?option.cityId :option.id,
              label: props?.name == "countryNameId" ? 
                      option?.country:
                      props?.name == "currencyId"  ?
                      option.currency+","+option.country:
                      props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.isdcode+","+option.country: props.name==="placeOfBirth"?
                     `${option?.placeName}-${option?.regionName}-${option?.countryName}`
                     :option.name,
            }
  ))
  }
  disabled={props.disabled}
/>

{props?.errors && (
  <p className="text-[red] text-[12px]">
    {props?.errors}
  </p>
)}{" "}
</div>

<>
    <div className={`flex md:hidden gap-3 items-center  mb-[2rem] `}>
                <img alt='' className="w-[2.5rem] h-[2.5rem]" src={props?.icon} />
                <div className={`relative w-full md:hidden`}>
                    <p className={` absolute -top-3 left-3 bg-white px-1 text-[12px] text-[#8F8F8F] md:text-[12px] lg:text-[14px] md:text-[#7A7A7A] flex  transition-all duration-200 
            peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
          peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
            peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}>
                        {props?.title}
                        {props?.required && <span className="text-[#D10A11] ml-1"> *</span>}
                    </p>
                    <label className={` pepeer block border-[1px] !w-[100%] border-[#8F8F8F] rounded-[3px] bg-transparent md:px-4 text-gray-900 `}>
                      
  <Select
  // suffixIcon={<CaretDownOutlined />}
  {...props}
  showSearch
  style={{
    width: "100%"
  }}
  placeholder={props?.placeholder}
  className= {props?.menu=="afterReigister" ?"new text-[#7A7A7A] font-bold text-[14px]" :'text-[#7A7A7A] font-bold text-[14px] !w-[100%]'}
  // onSearch={(e)=>{if(e.length>2){ props.callBackSearch(e)}}}
  onSearch={(e) => {
    if (e.length > 2) {
      props.callBackSearch(e); // Ensure this correctly updates the dropdown options
    }
  }}
  id={props?.id}
  name={props?.name}
  value={props?.value}
  defalutValue={props.defalutValue?props?.defalutValue:props?.placeholder}
  optionFilterProp="children"
  onChange={event => {
      //console.log(event,"event")
      props?.onChange(event)}}

  filterOption={(input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }
  options={props?.option?.map((option,i)=>(
          {
              value: props?.name == "countryNameId" || props?.name == "currencyId" || props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.id.toString()
                     :props.name==="placeOfBirth"?option.cityId :option.id,
              label: props?.name == "countryNameId" ? 
                      option?.country:
                      props?.name == "currencyId"  ?
                      option.currency+","+option.country:
                      props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.isdcode+","+option.country: props.name==="placeOfBirth"?
                     `${option?.placeName}-${option?.regionName}-${option?.countryName}`
                     :option.name,
            }
  ))
  }
  disabled={props.disabled}
/>
</label>

{props?.errors && (
  <p className="text-[red] text-[12px]">
    {props?.errors}
  </p>
)}{" "}

</div>
</div>
</>
</>
)
}

const SingleSelectDropdown = (props) => {
  ////console.log(props,"fvhng");
return (
  props?.value != undefined &&
  <>
  <Select
  // suffixIcon={<CaretDownOutlined />}
  {...props}
  showSearch
  style={{
    width: "100%"
  }}
  placeholder={props?.placeholder}
  className= {props?.menu=="afterReigister" ?"new text-[#7A7A7A] font-bold text-[14px]" :'text-[#7A7A7A] font-bold text-[14px]'}
  // onSearch={(e)=>{if(e.length>2){ props.callBackSearch(e)}}}
  onSearch={(e) => {
    if (e.length > 2) {
      props.callBackSearch(e); // Ensure this correctly updates the dropdown options
    }
  }}
  id={props?.id}
  name={props?.name}
  value={props?.value}
  defalutValue={props.defalutValue?props?.defalutValue:props?.placeholder}
  optionFilterProp="children"
  onChange={event => {
      //console.log(event,"event")
      props?.onChange(event)}}

  filterOption={(input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  }
  options={props?.option?.map((option,i)=>(
          {
              value: props?.name == "countryNameId" || props?.name == "currencyId" || props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.id.toString()
                     :props.name==="placeOfBirth"?option.cityId :option.id,
              label: props?.name == "countryNameId" ? 
                      option?.country:
                      props?.name == "currencyId"  ?
                      option.currency+","+option.country:
                      props?.name == "countryId" || props.name == "pMobileNumberCode" ?
                     option?.isdcode+","+option.country: props.name==="placeOfBirth"?
                     `${option?.placeName}-${option?.regionName}-${option?.countryName}`
                     :option.name,
            }
  ))
  }
  disabled={props.disabled}
/>

{props?.errors && (
  <p className="text-[red] text-[12px]">
    {props?.errors}
  </p>
)}{" "}
</>
)
}



const PincodeSingleSelectDropdown = (props) => {
  ////console.log(props,"fvhng");
return (
  props?.value != undefined &&
  <>
   <Select
    filterOption={false}
    showSearch={true}
    onChange={props?.onSelect}
    onSearch={props?.onSearch}
    id={props?.id.toString()}
    name={props?.name}
    // onChange={event =>{
    //   if(props?.onSelect){
    //     props?.onSelect(event)
    //   }
    //   return props?.onChange(props?.name)(event.toString())}
    // } 
    placeholder={props?.placeholder}
    defaultValue={props.value}
    value={props.value}
    
    options={props?.options}
      notFoundContent="No matches"
      style={{
        width: "100%"
      }}
  />

{props?.errors && (
  <p className="text-[red] text-[12px]">
    {props?.errors}
  </p>
)}{" "}
</>
)
}

export {SelectDropdownUtil,SelectDropdownUtilHeading, SingleSelectDropdown ,PincodeSingleSelectDropdown ,LocationSingleSelectDropdown}
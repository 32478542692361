import React from "react";
import { MultiSelectView } from "./PartnerPreferenceUtils";
import { useTranslation } from "react-i18next";

const PhysicalAttributes = (props) => {
  // props?.editbasicinfofreshData?.heightInchesData

  const maxheightOptions = React.useMemo(() => {
    return props?.editbasicinfofreshData?.maxheightId?.filter(option =>
      props.data.values.minheight === null
        ? true
        : parseInt(option.id) >= parseInt(props.data.values.minheight))
  }, [props.data.values.minheight, props?.editbasicinfofreshData?.maxheightId])

  //console.log('maxheightOptions',maxheightOptions,props.data.values.minheight);
  const isV3User = localStorage.getItem("isV3User")

  const { t: trans } = useTranslation();

  return (
    <div className="pl-[1.5rem] pr-5 py-5 md:p-4 md:py-3">
      <div className="justify-center items-center grid grid-cols-9 ">
        <div className={`${isV3User? "pl-[.3rem] md:pl-[1.5rem] lg:pl-[2.8rem]" : "" } col-span-9 md:col-span-3 lg:col-span-4`}>
          <p className={isV3User?"  md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold   ":"lg:pl-[1rem] md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  "}>
          {trans('my_profile.height')}
          </p>
        </div>
        <div className={`md:col-span-6 lg:col-span-5 col-span-9 ${isV3User? "pl-[.3rem] md:pl-[2.3rem] lg:pl-[3rem]" : "pl-[1.4rem] md:pl-[1.2rem] lg:pl-[2rem]" } `}>         
            <div className="flex space-x-3  ">
              <label className=" select font-bold text-[#575556] ">
                <select
                  name="minheight"
                  id="minheight"
                  onChange={(e) => {
                    props?.handleSelectInputChange?.(e);
                    props.data.handleChange(e);
                    if(parseInt(e.target.value) >= parseInt(props.data.values.maxheight??"0")){
                      const maxOptions = props?.editbasicinfofreshData?.maxheightId?.filter(option =>
                        parseInt(e.target.value) <= parseInt(option.id))
                        const len = props?.editbasicinfofreshData?.maxheightId?.length - 1
                        console.log("maxOptions",maxOptions,props?.editbasicinfofreshData?.maxheightId[len].id);
                      props.data.setFieldValue("maxheight",maxOptions[0]?.id??props?.editbasicinfofreshData?.maxheightId[len].id)
                    }
                    props.updateData(e);
                    if(props.isTrailPack == 'Y'){
                      props.setOnchangeData(false)
                    }
                  }}
                  disabled={props.editfromadmin == 'Y'}
                  value={props.data.values.minheight}
                  className={` w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px]  ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} `}
                >
                  {props?.editbasicinfofreshData?.minheightId?.map((e, i) => (
                    <option key={i} value={e.id}>{e.name}</option>
                  ))}
                </select>
              </label>
              <p className="flex justify-center items-center font-bold text-[#575556] md:text-[12px] lg:text-[14px] ">
              {trans('my_profile.to')}
              </p>
              <label className=" select font-bold text-[#575556] ">
                <select
                  name="maxheight"
                  id="maxheight"
                  onChange={(e) => {
                    props?.handleSelectInputChange?.(e);
                    props.data.handleChange(e);
                    props.updateData(e);
                    if(props.isTrailPack == 'Y'){
                      props.setOnchangeData(false)
                    }
                  }}
                  value={props.data.values.maxheight}
                  disabled={props.editfromadmin == 'Y'}
                  className={` w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px]  ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''} `}
                >
                  {maxheightOptions?.map((e, i) => (
                    <option key={i} value={e.id}>{e.name}</option>
                  ))}
                </select>
              </label>
            </div>         
        </div>
      </div>

      <MultiSelectView
        title={trans('my_profile.physical_status')}
        inputID={"physicalStatus"} 
        inputName={"physicalStatus"}
        value={props.data.values.physicalStatus}
        defalutValue={[props.data.values.physicalStatus]}
        onChange={(e) => {
          props?.handleSelectInputChange?.(e);
          props.data.setFieldValue("physicalStatus", Array.from(e));
          // props.data.handleChange
          if(props.isTrailPack == 'Y'){
            props.setOnchangeData(false)
          }
        }}
        error={props.data.errors.physicalStatus}
        options={props?.editbasicinfofreshData?.physicalStatus}
        page="physical"
        isDisabled={props.editfromadmin == 'Y'}
      />
    </div>
  );
};

export default PhysicalAttributes;

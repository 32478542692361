import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  HoroscopeSearchView,
  LocationSelectInput,
  SaveCancelButton,
  SelectInput,
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";

import { Select } from "antd";
import { LocationSingleSelectDropdown, SelectDropdownUtil, SingleSelectDropdown } from "../CustomSelect";
import config from "../../../config";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { useTranslation } from "react-i18next";
import AfterregistrationSelectInput from "./AfterregistrationSelectInput";
import MyProfileEditview from "./MyProfileEditview";
import { addressicon, city, countryimage, livesinicon, pincodeimage, state } from "../../Kalyanlite/assets/images";
import { toast } from "react-toastify";
const LocationComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isShowEdit, setEditView] = useState(true);
  const [countryDropDown, setcountryDropDown] = useState([]);
  const [stateDropDown, setstateDropDown] = useState([]);
  const [cityDropDown, setcityDropDown] = useState([]);
  const [pincodeDropDown, setpincodeDropDown] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false)
  const [selectedCountryNameValue, setSelectedCountryNameValue] = useState()
  const [toastmsg,setToastmsg]=useState(false)
    const [disablesave,setDisableSave] = useState(true)
  const { t: trans } = useTranslation();

  console.log(toastmsg,'pincodeDropDown');
  const formik = useFormik({
    initialValues: {
      country: props?.data?.country ? props?.data?.country[0]?.id : "",
      state: props?.data?.state ? props?.data?.state[0]?.id : "",
      city: props?.data?.city ? props?.data?.city[0]?.id : "",
      pincode: props?.data?.pincode ? props?.data?.pincode[0]?.id : "",
      address: props?.data?.address ? props?.data?.address : "",
      descriptionEdit:props?.data?.descriptionEdit,
      descriptionContentId: props?.data?.descriptionContentId,
    },
    enableReinitialize: true, 
    validationSchema: Yup.object().shape({
      country: Yup.string().required(trans('my_profile.please_select_country')),
      state: Yup.string().required(trans('my_profile.please_select_state')),
      city: Yup.string().required(trans('my_profile.please_select_city')),
      pincode:selectedCountryNameValue == "499"?
      Yup.string().required(trans('my_profile.please_select_pincode')):
      Yup.string().nullable(),
      address: Yup.string().nullable(),
    }),

    onSubmit: (values) => {
      setSubmitStatus(true)
      apiCallLocation(values);
    },
  });

  useEffect(() => {
    setSelectedCountryNameValue(props?.data?.country[0].id)
  },[props?.data?.pincode])

  const SubmitCall = (value) => {
    //console.log(value);
  };

  useEffect(() => {
    setcountryDropDown(props?.dropDown?.country);
    apiCallState(props?.data?.country[0].id);
    apiCallCity(props?.data?.state[0].id);
  }, [props?.dropDown]);
  useEffect(() => {}, [
    countryDropDown,
    stateDropDown,
    cityDropDown,
    pincodeDropDown,
    formik.values,
  ]);
  const apiCallState = async (countryID) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getStateUrl(countryID)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setstateDropDown(data.data.states);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallCity = async (stateID) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCityUrl(stateID)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setcityDropDown(data.data.cities);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallPincode = async (pincode) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getPincodeUrl(pincode)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setpincodeDropDown(data.data.pincode);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallLocation = async (value) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      countryId: value.country,
      stateId: value.state,
      cityId: value.city,
      pincode: value.pincode.toString(),
      address: value.address,
      descriptionEdit:value?.descriptionEdit,
      descriptionContentId:value?.descriptionContentId,
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "editFrom": props?.isFrom=="addLocation" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
       localStorage.getItem("profiledot") == "true" ?"profile_preview_three_dots":"Myprofile",
       "requestfulfilledvia":props?.isFrom=="addLocation" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
       localStorage.getItem("profiledot") == "true" ?"profile_preview":"chat"
      ,

    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editlocationinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
       
        if(toastmsg){
        toast.success(
          <div className='flex items-center'>
            {"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."}
          </div>,
         
        );
      }

        setSubmitStatus(false) 
        props.callBackReload();
        setDisableSave(true)
       
          
  
       
        onClickTrack(EventName.profileLocationSaveButton , {[EventKeys.profileLocation]:value})
      }

    
    } else if (statusCode === 401) {
      logout();
    }
  };

  const selectedCountry = useMemo(()=>{
    return countryDropDown?.filter(option=>option.id === formik.values.country)?.at(0)
  },[countryDropDown, formik.values.country])

  const selectedState = useMemo(()=>{
    return stateDropDown?.filter(option=>option.id === formik.values.state)?.at(0)
  },[formik.values.state, stateDropDown])

  const selectedCity = useMemo(()=>{
    return cityDropDown?.filter(option=>option.id === formik.values.city)?.at(0)
  },[cityDropDown, formik.values.city])

  console.log(formik.values.city,selectedCity,"selectedState123");
  

  const { onClickTrack } = useAnalytics()

  return (
    <div>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="p-5 md:mb-4 md:p-0">
          <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>

          <BasicTextView
            title={trans('view_profile.country')}
            value={
              props?.data?.country
                ? props?.data?.country[0].name
                : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={"State"}
            value={
              props?.data?.state ? props?.data?.state[0].name : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('set_your_partner_expectations.city')}
            value={
              props?.data?.city ? props?.data?.city[0].name : trans('my_profile.not_specified')
            }
          />
          {selectedCountryNameValue == "499" &&
          <BasicTextView
            title={"Pincode"}
            value={
              props?.data?.pincode
                ? props?.data?.pincode[0].name
                : trans('my_profile.not_specified')
            }
          />
          }
          <BasicTextView
            title={"Address"}
            value={
              props?.data?.address ? props?.data?.address : trans('my_profile.not_specified')
            }
          />
        </div>
      )}
      {/* <!-- edit part  -->  */}

      {props?.isFrom=="addLocation" ?
       <form onSubmit={formik.handleSubmit}>
       {isShowEdit ? (
         <div className="md:px-4">
           <AfterregistrationSelectInput
             name="country"
             title={trans('view_profile.country')}
             value={selectedCountry}
             onChange={(selected, ev) => {
               formik.setFieldValue("country", selected.id)
               formik.setFieldValue("state", "")
               formik.setFieldValue("city", "")
               setSelectedCountryNameValue(selected.id)
               setcityDropDown([])
               apiCallState(selected.id);
               setDisableSave(false)
             }}
             error={formik.errors.country}
             defaultValue={formik.values.country}
             options={countryDropDown}
             getOptionLabel={(option) => option.name}
             getOptionValue={(option) => option.id}
             disabled={props.editfromadmin == 'Y'}
             icon={countryimage}
           />
          
           <AfterregistrationSelectInput
             name="state"
             title={trans('my_profile.state')}
             value={selectedState}
             onChange={(selected, ev) => {
               formik.setFieldValue("state", selected.id)
               formik.setFieldValue("city", "")
               apiCallCity(selected.id);
               setDisableSave(false)
             }}
             error={formik.errors.state}
             defaultValue={formik.values.state}
             options={[{ id: "", name: "Select", isDisabled: true  }, ...stateDropDown]}
             getOptionLabel={(option) => option.name}
             getOptionValue={(option) => option.id}
             disabled={props.editfromadmin == 'Y'}
             icon={livesinicon}
           />
         
           <AfterregistrationSelectInput
             name="city"
             title={trans('set_your_partner_expectations.city')}
             value={selectedCity}
             onChange={(selected, ev) => {
               formik.setFieldValue("city", selected.id)
               setDisableSave(false)
             }}
             error={formik.errors.city}
             defaultValue={formik.values.city}
             options={cityDropDown}
             getOptionLabel={(option) => option.name}
             getOptionValue={(option) => option.id}
             disabled={props.editfromadmin == 'Y'}
             icon={livesinicon}

           />
          
           {selectedCountryNameValue == "499" && 
            <> 
            <div className="  grid-cols-9 mt-[2rem]  hidden md:grid">
           <div className="md:col-span-3 lg:col-span-3 xl:col-span-2 col-span-9 hidden md:flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
           <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  flex gap-3 items-center">
           <img src={pincodeimage} className='w-[2.5rem] h-[2.5rem]'/> {trans('my_profile.pincode')}
                   <span className="text-[#D10A11]">*</span>
                 </p>
               </div>
               <div className="col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6    md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  lg:pr-[10rem] lg:pl-[3rem] xl:pl-[7rem]">
               <div className="flex "> 
                     <label className=" select font-bold text-[#575556] w-full ">
                       
                       <LocationSingleSelectDropdown
                         id="pincode"
                         name="pincode"
                         value={props?.data?.pincode&&formik.values.pincode==props?.data?.pincode[0].id?props?.data?.pincode[0].name:formik.values.pincode}
                         onChange={(e) => {formik.setFieldValue("pincode", e)
                          setDisableSave(false)
                          }}
                         option={pincodeDropDown} 
                         callBackSearch={apiCallPincode}
                         // mode={"multiple"}
                         disabled={props.editfromadmin == 'Y'}
                       />
                       <SelectDropdownUtil
                         onChange={(e) => {
                           // formik.handleChange(e);
                           //console.log(e);
                           // if (e.target.value.length > 2) {
                           //   apiCallPincode(e.target.value);
                           // }
                         }}
                         editfromadmin = {props.editfromadmin}
                         // placeholder="select profile for"
                         option={pincodeDropDown}
                       />

                       {formik.errors.pincode && (
                         <p className="text-[red] text-[12px]">
                           {formik.errors.pincode}
                         </p>
                       )}
                     </label>
                   </div>               
               </div>
             </div>



             <div className=" grid grid-cols-9 mt-[2rem]  md:hidden">
           <div className="md:col-span-3 lg:col-span-3 xl:col-span-2 col-span-9 hidden md:flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
           <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  flex gap-3 items-center">
           <img src={city} className='w-[2.5rem] h-[2.5rem]'/> {trans('my_profile.pincode')}
                   <span className="text-[#D10A11]">*</span>
                 </p>
               </div>
               <div className="col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6  mb-[2rem]  md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  lg:pr-[10rem] lg:pl-[3rem] xl:pl-[7rem]">
               <div className="flex "> 
                    
                     <label className=" select font-bold text-[#575556] w-full ">
                       
                       <LocationSingleSelectDropdown
                       title={"pincode"}
                         id="pincode"
                         name="pincode"
                         value={props?.data?.pincode&&formik.values.pincode==props?.data?.pincode[0].id?props?.data?.pincode[0].name:formik.values.pincode}
                         onChange={(e) => {formik.setFieldValue("pincode", e)
                          setDisableSave(false)
                          }}
                         option={pincodeDropDown} 
                         callBackSearch={apiCallPincode}
                         // mode={"multiple"}
                         disabled={props.editfromadmin == 'Y'}
                         icon={pincodeimage}
                       />
                       <SelectDropdownUtil
                         onChange={(e) => {
                           // formik.handleChange(e);
                           //console.log(e);
                           // if (e.target.value.length > 2) {
                           //   apiCallPincode(e.target.value);
                           // }
                         }}
                         editfromadmin = {props.editfromadmin}
                         // placeholder="select profile for"
                         option={pincodeDropDown}
                       />

                       {formik.errors.pincode && (
                         <p className="text-[red] text-[12px]">
                           {formik.errors.pincode}
                         </p>
                       )}
                     </label>
                   </div>               
               </div>
             </div>
             
              </>
           }

        
           <MyProfileEditview
             title={trans('my_profile.address')}
             inputType={"text"}
             inputID={"address"}
             inputName={"address"}
             isFrom ="profileaddDetails"
             value={formik.values.address}
             onChange={(e)=>{
              formik.handleChange(e) 
              setDisableSave(false)
              setToastmsg(true)
             }}
             error={formik.errors.address}
             editfromadmin = {props.editfromadmin} 
             icon={addressicon}
           />

           <SaveCancelButton
            saveCallBack={(e) => {
              if (!disablesave) {
                e.preventDefault();
                setToastmsg(true)
              }  }}
             cancelCallback={() => {
               formik.resetForm()
               setDisableSave(true)
               onClickTrack(EventName.profileLocationCancelButton)
             }}
             submitStatus={submitStatus}
             editfromadmin={props.editfromadmin}
             setDisableSave={setDisableSave}
             disablesave={disablesave}
             isFrom="MyProfile"
             isFroms="profileaddDetails"
           />
         </div>
       ) : (
         <></>
       )}
     </form> :
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <div className="p-5 md:p-0">
            <SelectInput
              name="country"
              title={trans('view_profile.country')}
              value={selectedCountry}
              onChange={(selected, ev) => {
                formik.setFieldValue("country", selected.id)
                formik.setFieldValue("state", "")
                formik.setFieldValue("city", "")
                setSelectedCountryNameValue(selected.id)
                setcityDropDown([])
                apiCallState(selected.id);
              }}
              error={formik.errors.country}
              defaultValue={formik.values.country}
              options={countryDropDown}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
            />
            {/* <BasiSelectView
              title={"Country"}
              inputID={"country"}
              inputName={"country"}
              value={formik.values.country}
              onChange={(e) => {
                formik.handleChange(e);
                apiCallState(e.target.value);
              }}
              error={formik.errors.country}
              options={countryDropDown}
            /> */}
            <SelectInput
              name="state"
              title={trans('my_profile.state')}
              value={selectedState}
              onChange={(selected, ev) => {
                formik.setFieldValue("state", selected.id)
                formik.setFieldValue("city", "")
                apiCallCity(selected.id);
              }}
              error={formik.errors.state}
              defaultValue={formik.values.state}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...stateDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
            />
            {/* <BasiSelectView
              title={"State"}
              inputID={"state"}
              inputName={"state"}
              value={formik.values.state}
              onChange={(e) => {
                formik.handleChange(e);
                apiCallCity(e.target.value);
              }}
              error={formik.errors.state}
              options={stateDropDown}
            /> */}
            <SelectInput
              name="city"
              title={trans('set_your_partner_expectations.city')}
              value={selectedCity}
              onChange={(selected, ev) => {
                formik.setFieldValue("city", selected.id)
              }}
              error={formik.errors.city}
              defaultValue={formik.values.city}
              options={cityDropDown}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={props.editfromadmin == 'Y'}
            />
            {/* <BasiSelectView
              title={"City"}
              inputID={"city"}
              inputName={"city"}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.errors.city}
              options={cityDropDown}
            /> */}
            {selectedCountryNameValue == "499" &&
              <div className=" grid grid-cols-9 mt-[2rem] ">
                <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                  <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                    {trans('my_profile.pincode')}
                    <span className="text-[#D10A11]">*</span>
                  </p>
                </div>
                <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">               
                    <div className="flex ">
                      <label className=" select font-bold text-[#575556] w-full ">
                        {/* <Select
                        id={"pincode"}
                        name={"pincode"}
                        showSearch
                        style={{
                          width: "100%"
                        }}
                        value={formik.values.pincode}
                        onChange={event => {
                          //console.log(event)
                            if (event.target.value.length > 2) {
                              apiCallPincode(event.target.value);
                            }
                          formik.handleChange("pincode")(event)
                        }}
                        /> */}

                        <SingleSelectDropdown
                          id="pincode"
                          name="pincode"
                          value={props?.data?.pincode&&formik.values.pincode==props?.data?.pincode[0].id?props?.data?.pincode[0].name:formik.values.pincode}
                          onChange={(e) => formik.setFieldValue("pincode", e)}
                          option={pincodeDropDown}
                          callBackSearch={apiCallPincode}
                          // mode={"multiple"}
                          disabled={props.editfromadmin == 'Y'}
                        />
                        <SelectDropdownUtil
                          onChange={(e) => {
                            // formik.handleChange(e);
                            //console.log(e);
                            // if (e.target.value.length > 2) {
                            //   apiCallPincode(e.target.value);
                            // }
                          }}
                          editfromadmin = {props.editfromadmin}
                          // placeholder="select profile for"
                          option={pincodeDropDown}
                        />

                        {formik.errors.pincode && (
                          <p className="text-[red] text-[12px]">
                            {formik.errors.pincode}
                          </p>
                        )}
                      </label>
                    </div>               
                </div>
              </div>
            }

            {/* <BasiSelectView
              title={"Pincode"}
              inputID={"pincode"}
              inputName={"pincode"}
              value={formik.values.pincode}
              onChange={(e) => {
                formik.handleChange(e);
                if (e.target.value.length > 2) {
                  apiCallPincode(e.target.value);
                }
              }}
              error={formik.errors.pincode}
              options={pincodeDropDown}
            /> */}

            <BasicEditTextView
              title={trans('my_profile.address')}
              inputType={"text"}
              inputID={"address"}
              inputName={"address"}
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.errors.address}
              editfromadmin = {props.editfromadmin}
            />

            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              // saveCallBack={formik.handleSubmit}
              cancelCallback={() => {
                // setEditView(false);
                // props.refresh()
                formik.resetForm()
                onClickTrack(EventName.profileLocationCancelButton)
              }}
              submitStatus={submitStatus}
              editfromadmin = {props.editfromadmin}
              isFrom="MyProfile"
            />
          </div>
        ) : (
          <></>
        )}
      </form> }
    </div>
  );
};

export default LocationComponent;
import React from 'react'
import HobbyEdit from '../../../Components/Kalyanlite/HobbyEdit'

function PreviewHobbiesForm({ editMyProfileData, ProfileData,apiCallEditProfile }) {
    return (
        <HobbyEdit
            isFromMyProfile='MyProfile'
            callBackReload={() => { 
                apiCallEditProfile()
            }}
            editfromadmin={ProfileData?.editfromadmin}
            isScroll={false}
            isFrom={"hobbiesdetails"}
        />
    )
}

export default PreviewHobbiesForm
import React, { useContext, useEffect, useState } from 'react'
import DescriptionForm from '../../../Components/Edit Profile/MyProfileComponents/DescriptionForm'
import LocationComponent from '../../../Components/Edit Profile/MyProfileComponents/LocationComponent'
import EndPoints from '../../../Constants/EndPoints';
import config from '../../../config';
import { GET, POST } from '../../../Services/api_services';
import { LoginContext } from '../../../Contexts/LoginContext';

function PreviewLocationForm({ editMyProfileData, ProfileData ,callBackReload ,apiCallEditProfile}) {

const [editlocationinfofreshData,seteditlocationinfofresh] = useState([])
const [managePreferenceData, setPreferenceData] = useState();
const { loginDetail, logout } = useContext(LoginContext);

  const apiLocationCallDropDown = async (type) => {
    
    let url = "";

    url = `${config.api.API_URL}${EndPoints.editlocationinfofreshUrl()}`;

    let { statusCode, data } = await GET(url);
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      seteditlocationinfofresh(data.data);
    } else if (statusCode === 401) {
    }

  };


  const apiCallManagePreference = async () => {
    // setIsPartnerPrefLoading(true)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.managepreferencesdataUrl()}`,
      request
    );

    if (statusCode === 200) {
      setPreferenceData(data?.data);
    //   setLoading(true)
    } else if (statusCode === 401) {
    }
    // setIsPartnerPrefLoading(false)
  };
  

  useEffect(()=>{
    apiLocationCallDropDown()
  },[])

    return (
        <LocationComponent
            data={editMyProfileData}
            apiCallEditProfiles={apiCallEditProfile}
            isMyProfileDescription="Yes"
            editfromadmin={ProfileData?.editfromadmin}
            dropDown={editlocationinfofreshData}
            isFrom="addLocation"
            callBackReload={()=>{
                apiCallEditProfile()
                apiCallManagePreference()
              }}
        />
    )
}

export default PreviewLocationForm
import axios from "axios";
import Constants from "../Constants/constants";

export const post_method = (url, data) => {

       const token = localStorage.getItem(Constants.loginLocalStorageKeys.accessToken);

       const headers = {
       ...data?.headers,
       };

       // Attach token if it exists
       if (token && (url.includes("/api/basicdata") || url.includes("/api/profiledatainsert") || url.includes("/api/changemobilenumber") || url.includes("/api/requestagain") ||url.includes("/api/mobileverification") || url.includes("/api/mobileinfofresh"))) {
              headers.Authorization = `${token}`;
       }
       
       return axios.post(url, data,{headers: headers});
}

// export const post_method = (url, data) => {
//        return axios.post(url, data);
// }

export const get_method = (url) => {
       return axios.get(`${url}`);
};


// export const cms_post_method = (url, data) => {
//        return axios.post(url, data?.data, { headers: data?.headers });
// }

export const cms_post_method = (url, data) => {
       const token = localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin);

       const headers = {
       ...data?.headers,
       };

       // Attach token if it exists
       if (token) {
       headers.Authorization = `Bearer ${token}`;
       }

       return axios.post(url, data?.data, { headers: headers });
}
import React, { useEffect, useState } from 'react';
import CloseIcon from "../../../Components/Kalyanlite/assets/Images/Group 761.svg";
import { cancelIcon, cancellgrayicon, successicon, userprofile } from '../assets/images';
import { CommonRewardPopup } from '../../CommonPopup';
import { EventName } from '../../../Constants/constants';
import { useAnalytics } from '../../../Hooks/usePageChange';
import { useTranslation } from 'react-i18next';

const PromtCompletionPopup = ({ onClose, onOpenCommonReward, isFrom }) => {
    
    const { onClickTrack } = useAnalytics();

    const { t:trans } = useTranslation();

    useEffect(() => {
        // Set localStorage to false when the popup opens
        localStorage.setItem("chatprofileCompletion", "false");
        localStorage.setItem("profilepromptCompletion", "false");
    }, []);


    const OnClickComplete = () => {
       
        onClickTrack(EventName.View_Profile_Profile_Completion_Popup_Complete_Profile_Now_Button);
        onOpenCommonReward(); // Open CommonRewardPopup
        localStorage.setItem("chatprofileCompletion", "true");
        localStorage.setItem("profilepromptCompletion", "false");
        

    };

    return (
        <>
            <div className="fixed  hidden md:flex inset-0 z-50  items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
                <div
                    className="relative md:w-[28rem] md:my-6 md:mx-6"
                // ref={closePopupRef}
                >
                    <div className="">
                        <button
                            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <img className="w-6 h-6 " src={cancelIcon} alt="" />
                        </button>
                    </div>
                    <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-[20px] shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
                       <div className='relative p-6'>
                            <div className='flex flex-col justify-center items-center '>
                                <img src={successicon} className='w-[3rem] h-[3rem]' />

                                <p className='text-[#000000] text-[16px] py-2 pb-[3rem]'>{trans('Complete Profile Now Popup.message_sent_successfully')}</p>

                                {/* <hr class="w-[85%]  mx-auto mt-[4rem] mb-[1rem] border-[#7A7A7A] border-[1px]"/> */}

                                <div className='flex flex-col px-4 items-start border-[#CCCCCC] border-t  space-y-1 py-3 '>
                                    <p className='text-[#000000] font-bold text-[14px]'>{trans('Complete Profile Now Popup.next_add_more_details_to_your')}</p>
                                    <p className='text-[#1A1A1A] text-[13px] whitespace-nowrap'>{trans('Complete Profile Now Popup.profile_with_complete_details')}</p>

                                    <div className='flex justify-center gap-3 pt-[0.5rem]'>
                                        <img className='w-[2rem] h-[2rem]' src={userprofile} /> 
                                        <p className='text-[#7A7A7A] text-[13px]'>{trans('Complete Profile Now Popup.profiles_are_more_likely_to_re')}</p>
                                    </div>
                                </div>


                                <div className='py-3'>
                                    <button onClick={OnClickComplete} className='bg-[#D10A11]  px-3 py-2 rounded-full font-bold text-[13px] text-[#ffffff]'>{trans('Complete Profile Now Popup.complete_profile_now')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className={`fixed inset-0  md:hidden z-50 flex  overflow-x-hidden   h-full overflow-y-hidden md:overflow-y-auto md:items-end md:justify-center outline-none bg-opacityblack md:items-center focus:outline-none`}>
                <div
                    className={`relative w-[100%]   h-full md:w-[59%] lg:w-[30%] md:my-6 md:mx-6`}
                // ref={closePopupRef}
                >
                    <div className="">
                        <button
                            className=" bg-transparent z-40 border-0 text-black  absolute top-[23px] left-[23px]"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <img className="w-5 h-5 " src={cancellgrayicon} alt="" />
                        </button>
                    </div>

                    <div className={`border-0  md:rounded-[20px] shadow-lg h-full md:rounded-tr-[42px] md:rounded-tl-[42px] items-end justify-end flex flex-col md:mt-[0.5rem] md:mr-[0.5rem]  relative  w-full bg-white outline-none focus:outline-none`}>

                        <div className='h-[47vh] w-full flex items-center justify-center'>
                            <div className='flex flex-col items-center justify-center'>
                                <img src={successicon} className='w-[3rem] h-[3rem]' />

                                <p className='text-[#000000] text-[15px] pt-2'>Message sent successfully!</p>

                            </div>
                        </div>
                        <div className='flex flex-col'>

                            <div className={`flex flex-col  justify-end md:justify-center items-center py-[1.5rem] `}>


                                <hr class="w-[85%]  mx-auto mt-[4rem] mb-[1rem] border-[#CCCCCC] border-[1px]  " />

                                <div className='flex flex-col items-start  mx-[3rem] lg:mx-[4rem] space-y-1'>
                                    <p className='text-[#000000] font-bold text-[14px]'>Next, add more details to your profile</p>
                                    <p className='text-[#1A1A1A] text-[13px]'>Profile with complete details gets more responses.</p>

                                    <div className='flex justify-center gap-3 pt-[0.5rem]'>
                                        <img className='w-[2rem] h-[2rem]' src={userprofile} /> <p className='text-[#7A7A7A] text-[13px]'>Profiles are more likely to respond to profiles with complete details.</p>
                                    </div>
                                </div>


                                <div className='mt-2 '>
                                    <button onClick={OnClickComplete} className='bg-[#D10A11]  px-3 py-2 rounded-full font-bold text-[13px] text-[#ffffff]'>Complete Profile Now!</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
export default PromtCompletionPopup;
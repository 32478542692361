import React, { useEffect, useRef } from 'react';
import { useContext ,useState} from 'react';
import { NavBarContext } from '../../Contexts/NavBarContext';
import CloseIcon from "../../Images/Group 761@2x.png";
import { useAnalytics } from '../../Hooks/usePageChange';
import { LoginContext } from '../../Contexts/LoginContext';
import { CommonErrorPopup } from '../../Components/CommonPopup';
import HoroBasic from '../../Components/TrailPack/HoroBasic';
import HoroScopeDetails from './HoroScopeDetails';

const HoroScopePopup = ({ OnClose,editMyProfileData,apiCallEditProfile, setShowingSuccessPopup ,isFrom ,isFromSms}) => {
  
    const { completeNow, setCompleteNow, isGenerating, setIsGenerating,
      clickAddHoro, setClickAddHoro } = useContext(NavBarContext);
  
    const { loginDetail, logout } = useContext(LoginContext);
    const { onClickTrack } = useAnalytics()
    const isMobile = window.innerWidth <= 768;
    
    const horopopupRef = useRef(null);
  
    const [showErrorPopup, setShowErrorPopup] = useState(false); // State to control the error popup visibility
  

    const handleCloseErrorPopup = () => {
      setShowErrorPopup(false); // Hide the error popup
    };
  
  
    const handelCloseClick = () => {
      
     
      setCompleteNow(true)
      // setopenpopup(true)
      setClickAddHoro(false)
      OnClose()
    }
  
    const handleClickOutside = (event) => {
      if (
        horopopupRef.current &&
        !horopopupRef.current.contains(event.target) &&
        window.innerWidth <= 768 // Check if it's a mobile view (adjust this breakpoint as needed)
      ) {
        OnClose();
        setCompleteNow(true);
      }
    };
    
  
    useEffect(() => {
      if(isFromSms != "yes"){
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    }, []);
  
    return (
      <>
  
        {showErrorPopup && (
          <div className="fixed inset-0 flex justify-center items-center z-[1000]">
            <div className="bg-white p-4 rounded shadow-lg z-[1001]">
              <CommonErrorPopup
                message="Click save to close"
                closePopup={handleCloseErrorPopup}
              />
            </div>
          </div>
        )}
  
        <div className="fixed inset-0 z-50 flex items-end justify-center overflow-x-hidden overflow-y-auto outline-none bg-opacityblack md:items-center focus:outline-none">
  
  
          <div ref={horopopupRef} className={`relative w-[100%]  ${isFrom == "clcRedirection" ? "md:w-[80%]" : "md:w-[83%]"} lg:w-[48%] md:my-6 md:mx-6`}>        
              <div className="">
  
            <button
              className={`${isFromSms == "yes" && isMobile ? "block absolute top-3 right-[2px]" : "hidden"} md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[-6px]`}
              onClick={() => handelCloseClick()}
            >
              <img className="w-6 h-6 " src={CloseIcon} alt="" />
            </button>
  
          </div>
            <div className="border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-lg shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="relative flex-auto p-6">
                <hr class="h-[3px] my-1 bg-gray-200 border-[#BBBBBB] md:hidden border-[3px] rounded-md mx-[3rem] dark:bg-gray-700" />
  
           <HoroScopeDetails 
             OnClose={OnClose} isFrom="previewhoro" menu="addhoro"
             isFromSms={isFromSms}
            data={editMyProfileData} setShowingSuccessPopup={setShowingSuccessPopup} 
               from="trailpack"
               setClickAddHoro={setClickAddHoro}  
               callBackReload={apiCallEditProfile}/>
              
              </div>
            </div>
          </div>
        </div>
  
  
      </>
    );
  };
export default HoroScopePopup;
import React, { useEffect, useContext, useState } from "react";
import {  useNavigate } from "react-router";
import { Link  } from 'react-router-dom';
import RouteSegments, { RoutePaths } from "../../Routes/RouteSegment";
// import { NavFunction } from "../../Constants/constants";
import { NavListContext } from "../../Contexts/navContext";
import '../../index.css'
import { NavBarContext } from "../../Contexts/NavBarContext";
import { useLocation } from "react-router";
import useModule from "../../Hooks/useModule";
import Constants, { EditProfileMenu, InboxFilters } from "../../Constants/constants";
import TagClose from "../../Images/tagclose.svg"
import SearchIconRed from "../../Images/Group 256.svg"
import { SwitchBg, SwitchIcon } from "../Kalyanlite/assets/images";
import EndPoints from "../../Constants/EndPoints";
import config from "../../config";
import { GET } from "../../Services/api_services";
import { CMS_Login, CMS_Login_via } from "../../Helpers/api_helpers";
import { LoginContext } from "../../Contexts/LoginContext";
import Loader from "../Loader";

const BreadCrumbsData = [
  {
    value:RoutePaths.INBOX,
    label:"Inbox",
  },
  {
    value:RoutePaths.CONVERSATION,
    label:"Conversation",
  },
  {
    value:RoutePaths.PROFILE,
    label:"View Profile",
  },
  {
    value:RoutePaths.DASHBOARD,
    label:"Matches",
  },
  {
    value:RoutePaths.EDITPROFILE,
    label:"My Profile",
  },
  {
    value:RoutePaths.REMOVE_PROFILE,
    label:"Delete Profile",
  },
  {
    value:RoutePaths.SEARCH,
    label:"Search",
  },
  {
    value:RoutePaths.SAVEPARTNERPREFERENCES,
    label:"Search Result",
  },
  {
    value:RoutePaths.NOTIFICATION,
    label:"Notifications",
  },
  {
    value:RoutePaths.MEMBERSHIP_REGISTER,
    label:"Pay Now",
  },
  
]

const ViewMessageNav = (props) => {
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
  const location = useLocation();
  const navigate = useNavigate();
   const { navList, setNavList } = useContext(NavListContext);
  const { loginDetail } = useContext(LoginContext);
  const [isSwitching, setIsSwitching] = useState(false)

   const [breadCrumbs, setBreadCrumbs] = useState([])


  const {isInbox,isEditProfileMatch,isMatch} = useModule()


  const navigateToSearch = (e)=>{
    e.target.blur()
    navigate(RouteSegments.searchTab(1))
  }

  //console.log(navList,"navListnavList");
  const closeBreadCrumb = (menu) => {
    let index = navList?.length
    
    let navivationList = navList.filter((value)=>value!=menu)
    //console.log(navivationList[navivationList.length-1],"menu");
    if(menu == "Matches"){
      navigate(RouteSegments.DASHBOARD)
    }
    else if(menu == "Profile Page" && navivationList[navivationList.length-1] !="Matches"){
      if(navivationList[navivationList.length-1] == "Weekly Matches"){
        navigate(RouteSegments.WEEKLYVIEWALL, {
          state: { isfrom: "weekly" },
        });
       
      }
      else if(navivationList[navivationList.length-1] == "Recommended"){
        navigate(RouteSegments.RECOMMENDED, {
          state: { isfrom: "recommended" },
        });
      }
      else if(navivationList[navivationList.length-1] == "Response Received"){
        navigate(RouteSegments.ResponseReceived, {
          state: { isfrom: "response" },
        });
      }
      else if(navivationList[navivationList.length-1] == "Profile Looking For"){
        navigate(RouteSegments.PROFILELOOKINGFOR, {
          state: { isfrom: "lookingfor" },
        });
      }
      else if(navivationList[navivationList.length-1] == "Profile Looking For Me"){
        navigate(RouteSegments.PROFILELOOKINGFORME, {
          state: { isfrom: "lookingforme" },
        });
      }
      else if(navivationList[navivationList.length-1] == "Photo Request"){
        navigate(RouteSegments.PHOTOREQUESTRECEIVED, {
          state: { isfrom: "photorequest" },
        });
      }
      else if(navivationList[navivationList.length-1] == "Mutual Matches"){
        navigate(RouteSegments.MUTUALMATCHES, {
          state: { isfrom: "mutual" },
        });
      }
      else if(navivationList[navivationList.length-1] == "Selected Profile"){
        navigate(RouteSegments.SELECTEDPROFILES, {
          state: { isfrom: "selectedprofile" },
        });
      }
      else if(navivationList[navivationList.length-1] == "Recently Viewed"){
        navigate(RouteSegments.RECENTLYVIEW, {
          state: { isfrom: "recentlyviewed" },
        });
      }
      else if(navivationList[navivationList.length-1] == "Similar Matches"){
        navigate(RouteSegments.SIMILARMATCHES, {
          state: { isfrom: "similarmatches" },
        });
      }
    }
    else if(menu == "Weekly Matches" || menu == "Recommended" || menu == "Response Received" || menu == "Profile Looking For" || menu=="Profile Looking For Me" 
    || menu == "Photo Request" || menu == "Mutual Matches" || menu == "Selected Profile" || menu == "Recently Viewed" || menu == "Similar Matches"){
      navigate(RouteSegments.DASHBOARD)
    }
    else{ 
      
    }
    setNavList(navivationList)
  }
  const { openFilter } = useContext(NavBarContext)

  const onBreadCrumbClose = (index,label)=>{
    if(index === 0){
      return;
    }
    if((isInbox || isEditProfileMatch) && index === 1){
      return;
    }
    setBreadCrumbs(prev => {
      const temp = [...prev];
      const deleted = temp.splice(index, temp.length - 1)
      if (location.state?.newTab === true) {
        // const paths = location.pathname?.split("/").filter(path=>path!=="")
        // const navPath = paths.slice(0, paths.length - 1).join("/")
        // if (paths.length > 2) {
        //   navigate(`/${navPath}`)
        // } else {
          navigate("/")
        // }
      } else {
        navigate(-Math.abs(deleted.length))
      }
      return temp;
    })
  }
 

  useEffect(() => {

    //  const { height, width } = useWindowDimensions();
 
    // alert(window.innerHeight);

    // alert(window.height());
    
    const pathSegs = location.pathname.split("/").filter(seg => seg !== "")

    if (isInbox) {
      const labels = pathSegs.map((path, i) => {
        if (i === 1) {
          let arrys = []
          Object.keys(InboxFilters).forEach(key =>{
            arrys = [...arrys, ...InboxFilters[key]]
          })
          console.log("arrys",arrys);
          return arrys.find(p => p.urlPath === path)?.lable ?? ""
        }
        return BreadCrumbsData.find(b => b.value === path)?.label ?? ""
      })

      setBreadCrumbs(labels)
    } else if (isMatch) {
      const labels = pathSegs.map((path, i) => {
        if (i === 1 || pathSegs.includes("recently-viewed")) {
          const label = RouteSegments.breadCrumbUrl[path]
          if (label) {
            return label
          } else {
            return BreadCrumbsData.find(b => b.value === path)?.label ?? ""
          }
        }
        return BreadCrumbsData.find(b => b.value === path)?.label ?? ""
      })

      setBreadCrumbs(labels)
    } else if (isEditProfileMatch) {
      const labels = pathSegs.map((path, i) => {
        if (i === 1) {
          return EditProfileMenu.find(p => p.urlPath === path)?.lable ?? ""
        }
        return BreadCrumbsData.find(b => b.value === path)?.label ?? ""
      })

      setBreadCrumbs(labels)
    } else {
      const labels = pathSegs.map((path, i) => {
        return BreadCrumbsData.find(b => b.value === path)?.label ?? ""
      })

      setBreadCrumbs(labels)
    }
  }, [location, isInbox,isMatch, isEditProfileMatch])


  const switchApicall = async () => {
    setIsSwitching(true)
    await GET(
      `${config.api.API_URL}${EndPoints.switchtonewversion()}`
    );
    const storedToken = localStorage.getItem(Constants.loginLocalStorageKeys.accessToken);

        const cms_response = await CMS_Login_via({

            
            jwt_token: storedToken
        })
    setIsSwitching(false)
    

    console.log({ cms_response });
    if (cms_response.data) {


      const token = cms_response?.headers?.['authorization']; // Extract token

      if (token) {
        localStorage.setItem(Constants.loginLocalStorageKeys.accessToken, token); // Store token
      }
      

      
        console.log(token,"tokentoken" ,localStorage.setItem(Constants.loginLocalStorageKeys.accessToken, token));
        
      console.log(cms_response, "cms_response");
      localStorage.setItem(
        Constants.loginLocalStorageKeys.chatLogin,
        String(cms_response?.data?.access_token) ?? "OLD"
      );
      localStorage.setItem(
        Constants.loginLocalStorageKeys.chatLoginId,
        String(cms_response?.data?.id) ?? ""
      );
    }
  }
  

  const isV3User = localStorage.getItem("isV3User")
const onSwitchClick = async () => {
    if (isV3User) {
      localStorage.removeItem("isV3User")
      navigate(RouteSegments.DASHBOARD)
    } else {
      localStorage.setItem("isV3User", "true")
      await switchApicall()
      navigate(RouteSegments.MATCH_LITE)
    }
  }


  return (
    // <div className={location?.state?.type?"fixed mt-[4rem] inset-x-0 top-0 md:relative md:mt-0":""}>
    <div className={location?.state?.type?"section-2 1sticky  md:z-0 1mt-[4rem] inset-x-0 top-0 md:relative md:mt-0 1fixed-header":""}>
      {
        isSwitching && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
      }
    <nav className={`${props.className} md:block rounded-br-[33px] rounded-t-[33px] bg-white`}>
      <div className="hidden md:flex  bg-[#D10A11] p-2 px-9    justify-between">
        <div className=" hidden md:flex  lg:flex items-center space-x-3 md:space-x-3 lg:space-x-3">
          <p className="text-white text-[14px] pl-4 md:pl-2 font-segeo font-semibold ">
            You are here
          </p> 
            {breadCrumbs.map((sample, i) =>
              <div
                className="relative border border-[#FEBABA] text-[#FEBABA] text-[12px]
                     rounded-md  py-[0.4rem]">
                <span
                  className="flex px-2 justify-center"
                >
                  {sample}
                </span>
                {(isInbox || isEditProfileMatch) && i <=1
                  ? null
                  : <img alt="" src={TagClose}
                    onClick={() => onBreadCrumbClose(i,sample)}
                    className="absolute inline-flex cursor-pointer
                           top-[-1px] right-[-6px]
                           justify-center items-center w-6 h-6 md:w-[0.6rem] md:h-[0.6rem] 
                           " />
                }
              </div>
            )}
         
          </div>
          <div className="flex">
            <div onClick={onSwitchClick} className="relative mr-[22px] cursor-pointer">
              <img alt="switch" src={SwitchBg} className="h-[36px]" />
              <img alt="switch-icon" src={SwitchIcon} className="absolute -left-[40px] -top-[8px] h-[48px]" />
              <p className="italic font-bold font-segeo absolute left-[22px] right-0 top-[8px] switch-to-new">Switch to New</p>
            </div>
        {props.page === "searchpage" || props?.page === "payment"? 
        ""
        :
        <Link to={RouteSegments.SEARCH} 
        onClick={()=>{
          setMenuSelect("2")
        }}>
       {props.page==="viewall" ?"":
       <div className="hidden relative md:block"
         > 
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 placeholder-red-500 pointer-events-none">
            <img
               className="w-5 h-5" src={SearchIconRed} alt=""/>
            <span className="sr-only">Search icon</span>
          </div>
          <input
           readOnly="readonly"
            type="text"
            id="search-navbar"
            className="!placeholder-[#D10A11] outline-none border-none  block p-2 pl-10 w-full  rounded-md sm:text-sm "
            placeholder="Search"
          />
        </div>

       } 
        </Link>
        }
        {
          props.page === "payment" 
          ?  <p className="text-white">
          Need Help? Request a{" "}
          <span className="cursor-pointer" onClick={props.callBackPopup}>
            {" "}
            <u> Call Back </u>{" "}
          </span>
        </p>
          :null
        }
          </div>
        
      </div>

      {/* <div className={location?.state?.type? "md:hidden bg-[#D10A11] p-2 px-9  rounded-b-[30px] fixed mt-[2rem] inset-x-0 top-[2rem] z-10" :"md:hidden bg-[#D10A11] p-2 px-9  rounded-b-[30px]"}> */}
        <div className={location?.state?.type? "1mobile-breadcrumb md:hidden bg-[#D10A11] p-2 px-9  rounded-b-[30px] 1fixed 1mt-[2rem] inset-x-0 1top-[2rem] " :"md:hidden bg-[#D10A11] p-2 px-9  rounded-b-[30px]"}>
        {props?.page === "payment"? 
        ""
        :
        <div className="relative   ">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 placeholder-red-500  pointer-events-none">
            <img     className="w-5 h-5" src={SearchIconRed} alt=""/>
            <span className="sr-only">Search icon</span>
          </div>
          <input
            type="text"
            id="search-navbar"
            onClick={navigateToSearch}
            // onFocus={}
            className="search border-none block p-1 pl-10 w-64  rounded-md text-[14px] font-segeo font-semibold "
            placeholder="Search"
          /> 
        </div>
        }
          <div className="flex justify-between items-end ">
            <div className=" items-center flex-grow">
              <div className="space-x-3">
                <p className="text-white mt-2  text-[14px] font-segeo font-semibold ">
                  You are here
                </p>
              </div>

              <div className="flex items-center justify-between mt-2">
                <div id="nav-tags" className="flex justify-between w-full   overflow-y-auto">
                  <div className="space-x-3  flex  ">
                    {
                      breadCrumbs.map((navLabel, i) => (
                        <div onClick={() => onBreadCrumbClose(i, navLabel)} className="border relative flex-shrink-0 border-[#FEBABA] text-[#FEBABA] text-[12px] rounded-md p-1 px-2">
                          {navLabel}
                          {(isInbox || isEditProfileMatch) && i <= 1
                            ? null
                            : <span> <img alt="" src={TagClose}
                              // onClick={() => onBreadCrumbClose(i, navLabel)}
                              className="absolute inline-flex cursor-pointer top-[-1px] right-[-6px] justify-center items-center w-3 h-3 md:w-[0.6rem] md:h-[0.6rem] " />
                            </span>
                          }
                        </div>
                      ))
                    }

                    {/* <span className="border border-[#FEBABA] text-[#FEBABA] text-[12px] rounded-md p-1 px-2">
                Message
              </span>
              <span className="border border-[#FEBABA] text-[#FEBABA] text-[12px] rounded-md p-1 px-2">
                Unread Message
              </span> */}
                  </div>
                </div>
                {/* {props.page==="viewall" ?
                  <button
                    type="button"
                    className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden md:none "
                    //  @click="open = true"
                    onClick={openFilter}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <img alt="" className="" src="/Assets/Images/Group 230.svg" />
                  </button>
                  :""

                } */}
              </div>
            </div>
          </div>
        
      </div>
    </nav>
    </div>
  );
};

export default ViewMessageNav;

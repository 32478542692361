import React, { useContext, useEffect, useState } from 'react'
import AdditionalInformation from '../../../Components/Edit Profile/MyProfileComponents/AdditionalInformation'
import EndPoints from '../../../Constants/EndPoints';
import config from '../../../config';
import { GET } from '../../../Services/api_services';
import { LoginContext } from '../../../Contexts/LoginContext';

function PreviewAdditionalForm({ editMyProfileData, ProfileData, apiCallEditProfile }) {
    const { loginDetail, logout } = useContext(LoginContext);

    const [editAdditionalInfofreshData, setEditAdditionalInfofreshData] = useState();
    const [editlocationinfofreshData, seteditlocationinfofresh] = useState();

    const apiAdditionalCallDropDown = async (type) => {

        let url = "";
        url = `${config.api.API_URL}${EndPoints.additionalFamilyInfo()}`;

        let { statusCode, data } = await GET(url);
        //console.log("dropdown data ", data);
        if (statusCode === 200) {
            setEditAdditionalInfofreshData(data.data);
        } else if (statusCode === 401) {
            logout();
        }
    };

    const apiLocationCallDropDown = async (type) => {

        let url = "";

        url = `${config.api.API_URL}${EndPoints.editlocationinfofreshUrl()}`;

        let { statusCode, data } = await GET(url);
        //console.log("dropdown data ", data);
        if (statusCode === 200) {
            seteditlocationinfofresh(data.data);
        } else if (statusCode === 401) {
            logout();
        }

    };

    useEffect(() => {
        apiLocationCallDropDown()
        apiAdditionalCallDropDown()
    }, [])
    

    return (
        <AdditionalInformation
            data={editMyProfileData}
            // refresh={additionInfoCallBack}
            callBackReload={() => { 
                apiCallEditProfile();
            }}
            dropDown={editAdditionalInfofreshData}
            countrydata={editlocationinfofreshData}
            editfromadmin={ProfileData?.editfromadmin}
            isScroll={false}
            scrollToAdd={false}
            microisFrom={null}
            isFrom="additionalinfo"
        />
    )
}

export default PreviewAdditionalForm
import React, { useEffect, useState,useContext } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
  SelectInput,
  FloatingSelectInput,
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import { useMemo } from "react";
import config from "../../../config";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { getDeviceInfo, getGroupAuthId } from "../../Kalyanlite/utils";
import { useTranslation } from "react-i18next";
import { dhosamicon, raasiicon, staricon } from "../../Kalyanlite/assets/images";
import { toast } from "react-toastify";
import AfterregistrationSelectInput from "./AfterregistrationSelectInput";

const HoroscopeComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isShowEdit, setEditView] = useState(true);
  const [starDropDown, setStarDropDown] = useState([]);
  const [raasiDropDown, setRaasiDropDown] = useState([]);
  const [dosamDropDown, setDosamDropDown] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false)
  const [selectedStarValue, setSelectedStarValue] = useState()
  const [disablesave,setDisableSave] = useState(true)
  const [toastmsg,setToastmsg]=useState(false)

  const { t: trans } = useTranslation();

  const formik = useFormik({
    initialValues: {
      star: props?.data?.star ? props?.data?.star[0]?.id : "",
      raasi: props?.data?.raasi ? props?.data?.raasi?.[0]?.id : "",
      dosham: props?.data?.dosham ? props?.data?.dosham[0]?.id : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      star: Yup.string(),
      raasi: Yup.string(),
      dosham: Yup.string(),
    }),

    onSubmit: (values) => {
      setSubmitStatus(true)
      apiCallHoroscope(values);
    },
  });
  console.log(props?.data?.raasi,"props?.data?.raasi")
  const SubmitCall = (value) => {
    //console.log(value);
  };
  useEffect(() => {
    setStarDropDown(props?.dropDown?.starData);
    setDosamDropDown(props?.dropDown?.doshamData);
    
  }, [props?.dropDown, starDropDown]);

  useEffect(() => {
    if(props?.data?.star?.at(0)?.id){
      apiCallRaasi(props?.data?.star?.at(0)?.id);
    }
   
  }, [props?.data?.star ,props?.data?.raasi])
  

  const apiCallRaasi = async (id) => {
    const motherTongue = props?.data?.motherTongue[0]?.id;
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getRaasiUrl(id,motherTongue)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setRaasiDropDown(data.data.raasi);
        if (data.data.raasi.length === 1) {
          formik.setFieldValue("raasi", data.data.raasi[0]?.id);
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallHoroscope = async (value) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      starId: value.star,
      raasiId: value.raasi,
      doshamId: value.dosham,
      doshamId: value?.dosham,
      ...(value?.star != "" && {starrequesttypeid:21}),
      ...(value?.raasi != "" && {raasirequesttypeid:22}),
      ...(value?.dosham != "" && {doshamrequesttypeid:23}),
      requestfulfilledfrom: getDeviceInfo(),
      requestfulfilledvia: props?.isFrom=="horodetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
      localStorage.getItem("profiledot") == "true" ?"profile_preview":"chat",
      recieverprofileid:getGroupAuthId(),
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "editFrom": props?.isFrom=="horodetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
      localStorage.getItem("profiledot") == "true" ?"profile_preview_three_dots":"Myprofile",
      // messageTypeId:"2",
      // kmcareId: "1"    
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.edithoroscopeinfosave()}`,
      request
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        setSubmitStatus(false)
        setDisableSave(true)
        props.callBackReload();
        // setEditView(false);
//         if(props?.isFrom=="horodetails"){
//           toast(
//             <div className='flex items-center'>
//               {"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."}
//             </div>,
//             {
//               hideProgressBar: true, // Hides the progress bar
//             }
//           );
// }
        onClickTrack(EventName.profileHoroscopeSaveButton , {[EventKeys.profileHoroscope]:value})
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  
const selectedStar = useMemo(()=>{
  if(props?.starList?.starData){
  return props?.starList?.starData?.filter(option=>option.id === formik.values.star)?.at(0)
  }
  else{
  return starDropDown?.filter(option=>option.id === formik.values.star)?.at(0)
  }
},[formik.values.star, starDropDown,props?.starList?.starData])

const selectedRaasi = useMemo(()=>{
  return raasiDropDown?.filter(option=>option.id === formik.values.raasi)?.at(0)
},[formik.values.raasi, raasiDropDown])

const selectedDosham = useMemo(()=>{
  return dosamDropDown?.filter(option=>option.id === formik.values.dosham)?.at(0)
},[dosamDropDown, formik.values.dosham])

console.log(raasiDropDown,"raasiDropDown");

useEffect(() => {
  setStarDropDown(props?.dropDown?.starData);
  setDosamDropDown(props?.dropDown?.doshamData);
  setSelectedStarValue(selectedStar)
  
}, [props?.dropDown, selectedStar]);

const validStarDropDown = starDropDown || [];  // Fallback to empty array
const validRaasiDropDown = raasiDropDown || [];  // Fallback to empty array
const validDosamDropDown = dosamDropDown || []; 

const { onClickTrack } = useAnalytics()
const ComponentToRender = props.isFrom === 'horodetails' ? FloatingSelectInput : SelectInput;

  return (
    <div>
      {isShowEdit || props?.menu=="addastro"? (
        <></>
      ) : (
        <div className="p-5 md:mb-8 md:p-0">
          <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>
          <BasicTextView
            title={trans('view_profile.star')}
            value={
              props?.data?.star ? props?.data?.star[0].name : trans('my_profile.not_specified')
            }
          />
          <BasicTextView
            title={trans('view_profile.raasi')}
            value={
              props?.data?.raasi? props?.data?.raasi[0].name : trans('my_profile.not_specified')
              
            }
          />
          <BasicTextView
            title={trans('my_profile.chevvai_doshammanglik')}
            value={
              props?.data?.dosham
                ? props?.data?.dosham[0].name
                : trans('my_profile.not_specified')
            }
          />
        </div>
      )}


      {/* <!-- edit part  --> */ }
      {props?.isFrom=="horodetails" ?
       <form onSubmit={formik.handleSubmit}>
       {isShowEdit || props?.menu=="addastro"? (
         <div className="p-0 lg:px-4">
           <AfterregistrationSelectInput
               name="star"
               title={trans('view_profile.star')}
               value={selectedStarValue}
               onChange={(selected,ev) => { 
                 formik.setFieldValue("star", selected.id)
                 formik.setFieldValue("raasi", "")
                 apiCallRaasi(selected.id)
                 setDisableSave(false)
               }}
               error={formik.errors.star}
               defaultValue={formik.values.star}
               options={props?.starList?.starData?[{id:"",name:"Select", isDisabled: true },...props?.starList?.starData]:[{id:"",name:"Select", isDisabled: true },...validStarDropDown]}
               required={true}
               getOptionLabel={(option) => option.name}
               getOptionValue={(option) => option.id}
               isFrom="horoscopedetails"
               icon={staricon}
               customClassName={"mt-[1rem]"}
             /> 
          
           <AfterregistrationSelectInput
               name="raasi"
               title={trans('view_profile.raasi')}
               value={selectedRaasi}
               onChange={(selected,ev) => {
                 formik.setFieldValue("raasi", selected.id)
                 setDisableSave(false)
               }}
               error={formik.errors.raasi}
               defaultValue={formik.values.raasi}
               options={[{id:"",name:"Select", isDisabled: true },...validRaasiDropDown]}
               required={true}
               getOptionLabel={(option) => option.name}
               getOptionValue={(option) => option.id}
               isFrom="horoscopedetails"
               icon={raasiicon}
             /> 
          
            <AfterregistrationSelectInput
               name="dosham"
               title={trans('my_profile.chevvai_doshammanglik')}
               value={selectedDosham}
               onChange={(selected,ev) => {
                 formik.setFieldValue("dosham", selected.id)
                 setDisableSave(false)
               }}
               error={formik.errors.dosham}
               defaultValue={formik.values.dosham}
               options={[{id:"",name:"Select", isDisabled: true },...validDosamDropDown]}
               required={true}
               getOptionLabel={(option) => option.name}
               getOptionValue={(option) => option.id}
               isFrom="horoscopedetails"
               icon={dhosamicon}
             /> 
         
           <SaveCancelButton
             saveCallBack={(e) => {
              if (!disablesave) {
                e.preventDefault();
                setToastmsg(true)
                console.log("Toastmsg", toastmsg); // Check if the state is being set correctly

              }
             }}
             cancelCallback={() => {
               // setEditView(false);
               // props.refresh()
               formik.resetForm()
                setDisableSave(true)
               onClickTrack(EventName.profileHoroscopeCancelButton)
             }}
             submitStatus={submitStatus}
             isFrom="MyProfile"
             setDisableSave={setDisableSave}
             disablesave={disablesave}
              isFroms="profileaddDetails"
           />
         </div>
       ) : (
         <></>
       )}
     </form>:
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit || props?.menu=="addastro"? (
          <div className="p-5 md:p-0">
            <ComponentToRender
                name="star"
                title={trans('view_profile.star')}
                value={selectedStarValue}
                onChange={(selected,ev) => {
                  formik.setFieldValue("star", selected.id)
                  formik.setFieldValue("raasi", "")
                  apiCallRaasi(selected.id)
                }}
                error={formik.errors.star}
                defaultValue={formik.values.star}
                options={props?.starList?.starData?[{id:"",name:"Select1", isDisabled: true },...props?.starList?.starData]:[{id:"",name:"Select", isDisabled: true },...validStarDropDown]}
                required={true}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              /> 
            {/* <BasiSelectView
              title={"Star"}
              inputID={"star"}
              inputName={"star"}
              value={formik.values.star}
              onChange={(e) => {
                formik.handleChange(e);
                // //console.log(e.target.value,'e.values');
                apiCallRaasi(e.target.value);
              }}
              error={formik.errors.star}
              options={starDropDown}
            /> */}
            <ComponentToRender
                name="raasi"
                title={trans('view_profile.raasi')}
                value={selectedRaasi}
                onChange={(selected,ev) => {
                  formik.setFieldValue("raasi", selected.id)
                }}
                error={formik.errors.raasi}
                defaultValue={formik.values.raasi}
                options={[{id:"",name:"Select", isDisabled: true },...validRaasiDropDown]}
                required={true}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              /> 
            {/* <BasiSelectView
              title={"Raasi"}
              inputID={"raasi"}
              inputName={"raasi"}
              value={formik.values.raasi}
              onChange={formik.handleChange}
              error={formik.errors.raasi}
              options={raasiDropDown}
            /> */}
             <ComponentToRender
                name="dosham"
                title={trans('my_profile.chevvai_doshammanglik')}
                value={selectedDosham}
                onChange={(selected,ev) => {
                  formik.setFieldValue("dosham", selected.id)
                }}
                error={formik.errors.dosham}
                defaultValue={formik.values.dosham}
                options={[{id:"",name:"Select", isDisabled: true },...validDosamDropDown]}
                required={true}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              /> 
            {/* <BasiSelectView
              title={"Chevvai Dosham/Manglik"}
              inputID={"dosham"}
              inputName={"dosham"}
              value={formik.values.dosham}
              onChange={formik.handleChange}
              error={formik.errors.dosham}
              options={dosamDropDown}
            /> */}
            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                // setEditView(false);
                // props.refresh()
                formik.resetForm()
                onClickTrack(EventName.profileHoroscopeCancelButton)
              }}
              submitStatus={submitStatus}
              isFrom="MyProfile"
            />
          </div>
        ) : (
          <></>
        )}
      </form>}
    </div>
  );
};

export default HoroscopeComponent;

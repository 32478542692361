import React, { useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../Constants/constants";
import { useNavigate } from "react-router";
import RouteSegments from "../../Routes/RouteSegment";
import CloseIcon from "../../Images/Group 761@2x.png"
import { CommonCheckButton } from "../../Components/DashboardComponents/DashboardUtills";
import { useFormik } from "formik";
import { CommonValidPopup } from "../../Components/CommonPopup";
// import * as KalyanLiteSelector from "../../Store/KalyanLite/selectors";
import ProcessImage from "../../Components/Kalyanlite/Common/ProcessImage";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import OverflowToolTip from "../../Components/utils/OverflowTooltip";
import Loader from "../../Components/Loader";

const Phonenumberpopup = ({ parentno,phoneno,phoneNumData,isLoading,profilequalification,phonedata, editMyProfielData,profileData, ...props }) => {
  console.log("PhonePopup",phonedata);
  const navigate = useNavigate()
  useEffect(() => {
    console.log("phoneNumData",phoneNumData?.[0]?.parentsnumber);
  }, [phoneNumData]);
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );
  // const profileData = useSelector(KalyanLiteSelector.getMatchProfileById);
  console.log(phoneNumData?.profilepic,"profileDataprofileData");
  const closePopupRef = useRef()
  const { viewprofileid} = useSelector((state) => {
    return {
      viewprofileid: state.KalyanLite?.currentMatch?.data?.profileid,
    };
    
  });

  
  const loggedInProfileStatus = userData?.status

  // useEffect(()=>{
  //   let handler = (e) => {
  //     if(!closePopupRef.current.contains(e.target)){
  //       props.close()
  //     }
  //   }
  //   document.addEventListener('mousedown',handler)

  //   return() => {
  //     document.removeEventListener('mousedown',handler)
  //   }
  // })
  const isMobile = useMediaQuery({ maxWidth: 678 });

  const { t: trans } = useTranslation();


  const profileDetail = profileData;

  const showParentsNumber = ((props?.profileData?.profileparentsnumber == null || props.profileData?.profileparentsnumber == "") && props?.profileData?.parentnumberrequestcheck === false)
  const  showRequestPhoto = props?.profileData?.isAvatarPic === true && props?.profileData?.profilerequestcheck === false

  const formik = useFormik({
    initialValues: {
      requestParentsNumber: showParentsNumber,
      requestPhoto: showRequestPhoto,
      sendSms:"Y"
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      console.log("message", values);
      values = {
        ...values,
        typeList: [
          ...values?.requestParentsNumber ? ["Parents Number"] : [],
          ...values?.requestPhoto ? ["Photo"] : []
        ]
      }
      props.onSubmit(props?.profileData?.profileid, values);
      props.close();
    }
  });

  // if (phoneNumData?.status === "Validate" || phonedata?.status =="Validate") {
  //   return <CommonValidPopup close={props.close}
  //     title={props.isParents ? "Parent’s Number" : trans('view_profile.phone_number')}
  //     content={props.isParents ? "Your Profile is under validation.Post done, you can make a parent call" : "Your Profile is under validation.Post done, you can make a profile call"} />
  // }

  

  const profilepic = profileData?.profiledetails?.profileurl || phoneNumData?.[0]?.profilepic;
  // const profilepic = phoneNumData?.phoneNumberVieweeData?.[0]?.profilepic;
  const updatedPic = profilepic?.includes("femaleavatar") || profilepic?.includes("maleavatar") ? profilepic : profilepic

  const isAvatar = profilepic?.includes("femaleavatar") || profilepic?.includes("maleavatar") ? true :  false


  function formatLastSeen(lastSeenTimestamp) {
    const currentDate = new Date();
    const lastSeenDate = new Date(lastSeenTimestamp);

    console.log(lastSeenDate,"lastSeenDate");

    // Check if the user is online now
    if (lastSeenTimestamp === "Online Now") {
      
      return trans('view_profile.online');
    }

    // Check if the user was seen today
    if (
      currentDate.getFullYear() === lastSeenDate.getFullYear() &&
      currentDate.getMonth() === lastSeenDate.getMonth() &&
      currentDate.getDate() === lastSeenDate.getDate()
    ) {
      // Format time (e.g., Last seen today at 4:00 PM)
      return `Last seen today at ${lastSeenDate.toLocaleTimeString([], { hour: "numeric", hourCycle:"h12", minute: "2-digit" })}`;
    }

    // Check if the user was seen yesterday
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    if (
      yesterday.getFullYear() === lastSeenDate.getFullYear() &&
      yesterday.getMonth() === lastSeenDate.getMonth() &&
      yesterday.getDate() === lastSeenDate.getDate()
    ) {
      // Format time (e.g., Last seen yesterday at 6:00 PM)
      return `Last seen yesterday at ${lastSeenDate.toLocaleTimeString([], { hour: "numeric", hourCycle:"h12" , minute: "2-digit" })}`;
    }
  
    // Format date as DD/MM/YY (e.g., Last seen at 2/7/23)
    const day = lastSeenDate.getDate().toString().padStart(2, "0");
    const month = (lastSeenDate.getMonth() + 1).toString().padStart(2, "0");
    const year = lastSeenDate.getFullYear().toString().slice(2);
    const lastseenText = trans('view_profile.last_seen_at');
  
    return  `${lastseenText} ${day}/${month}/${year}`;
  }

  const reportClick = () => {
    // onClickTrack(EventName.View_Profile_Report_Report_an_abuse_Button,{[EventKeys.senderId]:userData?.profileId,[EventKeys.viewprofileid]:viewprofileid })
    // if (loggedInProfileStatus === "pending") {
    //   setNonValidationPopupData({ show: true, title: trans('non_validation_popup.non_validated_user'), content: "Your Profile is under validation. You can Report a Person after Your Profile is validated." })
    //   return;
    // }

    navigate(RouteSegments.HELP_AND_FAQ(), { state: { isfrom: "reportPhoneNumber", senderId: userData?.profileId, viewprofileid } });
  }

  return (
    <>
    {props?.isFrom=="profilephone" ?


 
    <div onClick={(e) => {
      if (e.target.id == "model-wrapper" && isMobile) {
        props?.close();
      }
    }}
     id="model-wrapper" className="font-Poppins-Regular flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[1000] outline-none focus:outline-none">
    
      <div className="relative w-full md:w-[66%] xl:w-[36%] lg:w-[38%]  md:my-6 md:mx-6" ref={closePopupRef}>
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[4px] md:right-[-10px]"
            onClick={() => {
              props?.close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className=" border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-2xl shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-5 justify-center   mx-2  rounded-t ">
            <h3 className=" text-[20px] lg:text-[17px] xl:text-[20px] text-[#D10A11] ml-2 font-semibold align-middle text-center">
              {props?.title}
            </h3>
          </div>
          { isLoading ? 
 <Loader/>:
          <div className="relative pb-5 lg:px-6 md:px-6 flex-auto">
            <div className="flex mx-2">
              <div className="">

                <ProcessImage
                  src={updatedPic}
                  alt=""
                  passwordProtected={profileDetail?.fromPasswordProtected}
                  blurPhoto={profileDetail?.blurPhoto}
                  isAvatarPic={profileDetail?.profiledetails?.isAvatarPic}
                  className={`w-12 h-12  md:w-20 object-cover md:h-20 lg:w-16 lg:h-16 xl:w-20 xl:h-20 rounded-[50%] ${isAvatar ? "border border-[#8292A8]" : ""}`}
                  lockClassName="h-[2rem] w-[2rem]"
                  containerClassName="flex w-max shrink-0"
                  alreadyContacted={profileDetail?.alreadyContected}
                />


              </div>
              <div className="flex flex-col lg:ml-2 md:ml-2">
                <div className="lg:ml-4 md:ml-4 ml-3 flex flex-col space-y-1 item-start justify-start">
                  
                    <div className="flex  justify-start items-center">
                        <span className='inline-flex items-center justify-center w-4 h-4 lg:w-3 lg:h-3 xl:w-4 xl:h-4 ms-2 text-xs font-semibold text-[#039511] bg-[#039511] rounded-full'>
                          <span className='w-[6px] h-[6px] inline-block bg-[#FDC300] rounded-full'></span>
                        </span>
                        <span className=" px-2 md:px-1 lg:px-2 text-[9px] md:text-[8px] lg:text-[12px] text-[#039511]">
                          {trans('view_profile.online')}
                      </span>
                    </div>
                  

                  <span className="text-[#1A1A1A] font-Poppins-SemiBold md:text-[16px] lg:text-[13px] xl:text-[16px] text-[10pt] ">
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.name || phoneNumData?.[0]?.name} |{" "}
                    <span className="text-[#1A1A1A] font-Poppins-Regular md:text-[13px] lg:text-[11px] xl:text-[13px] text-[10pt] font-medium">
                      {
                        phoneNumData?.phoneNumberVieweeData?.[0]
                          ?.smProfileId || phoneNumData?.[0]?.smProfileId
                      }
                    </span>
                  </span>
                  <span className="text-[#1A1A1A] font-Poppins-Regular  md:text-[13px] xl:text-[13px] lg:text-[11px] text-[10pt] ">
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.age || phoneNumData?.[0]?.age} |{" "}
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.height || phoneNumData?.[0]?.height} |{" "}
                    {/* {profilequalification} |{" "} */}
                    {profileDetail?.profiledetails?.profilequalification || editMyProfielData?.education?.[0]?.name} |{""}
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.religion || phoneNumData?.[0]?.religion} |{" "}
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.country || phoneNumData?.[0]?.country}
                  </span>
                </div>
                <div className="pl-[1rem]">
                  
                   
                            <div className=" block gap-3 md:grid lg:flex items-center justify-between space-x-2 lg:space-x-0 mt-3 md:grid-cols-2">
                            
                              {trans('view_phone_number_popup.verified_profile_phone_number') == 'Verified Profile Phone Number' ? 
                                <div className="flex-1 items-center space-x-2">
                                  <span className=" font-Poppins-Regular text-[#575556] pt-[0.1rem]  md:text-[12px] xl:text-[14px] text-[8pt] font-medium whitespace-nowrap">
                                    {trans('view_phone_number_popup.verified_profile_phone_number')}
                                  </span>
                                  <span className="col-span-2 text-[#000000] md:pl-3 lg:pl-0  md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold whitespace-nowrap">
                                    {/* +91 - 3242234433 */}
                                    {phoneNumData?.MobileNumber || phoneNumData?.[0]?.parentsisdcode +"-"+ phoneno}
                                  </span>
                                </div> 
                                :
                              <div className="grid grid-cols-4 gap-4">
                                <OverflowToolTip as='p' className="col-span-2 font-Poppins-Regular text-[#575556] pt-[0.1rem]  md:text-[12px] xl:text-[14px] text-[8pt] font-medium truncate">
                                  {trans('view_phone_number_popup.verified_profile_phone_number')}
                                </OverflowToolTip>
                                <span className="col-span-2 text-[#000000] md:pl-3 lg:pl-0  md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold whitespace-nowrap">
                                  {/* +91 - 3242234433 */}
                                  {phoneNumData?.MobileNumber || phoneNumData?.[0]?.parentsisdcode +"-"+ phoneno}
                                </span>
                              </div>
                              }
                      </div>
                      {parentno ?
                        <div className="block gap-3 lg:flex items-center justify-between space-x-2 mt-2 grid-cols-2">
                            {trans('view_phone_number_popup.parents_phone_number') == `Parent's Phone Number` ? 
                              <div className="flex-1 items-center space-x-2">
                                <span className="font-Poppins-Regular text-[#575556] pt-[0.1rem] md:text-[12px] xl:text-[14px] text-[8pt] font-medium">
                                  {trans('view_phone_number_popup.parents_phone_number')}
                                </span>
                                <span className="col-span-2 text-[#000000]  md:pl-3 lg:pl-0 md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold">
                                  {/* +91 - 3242234433 */}
                                  {/* {//console.log(props.phoneNumData,"phone")} */}
                                  { phoneNumData?.[0]?.parentsisdcode +"-"+ parentno}
                                </span>
                              </div> 
                            :
                            <>
                              <OverflowToolTip as='p' className="md:col-span-1 lg:col-span-2  truncate font-Poppins-Regular text-[#575556] pt-[0.1rem] md:text-[12px] xl:text-[14px] text-[8pt] font-medium">
                              {trans('view_phone_number_popup.parents_phone_number')}
                              </OverflowToolTip>
                              <span className="md:col-span-1 lg:col-span-2  text-[#000000]  md:pl-3 lg:pl-0 md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold">
                                {/* +91 - 3242234433 */}
                                {/* {//console.log(props.phoneNumData,"phone")} */}
                                { phoneNumData?.[0]?.parentsisdcode +"-"+ parentno}
                              </span>
                            </>
                            }
                        </div>
                              :""}
                             
                    
                      
                      
{/* 
                            {showRequestPhoto || showParentsNumber
                              ? <form onSubmit={formik.handleSubmit}>
                                <div className="flex items-center justify-around py-4">
                                  {showRequestPhoto
                                    ? <CommonCheckButton
                                      id={"requestPhoto"}
                                      name={"requestPhoto"}
                                      selected={formik.values.requestPhoto}
                                      checktext={trans('chat.request_photo')}
                                      value={formik.values.requestPhoto}
                                      onChange={(item) => {
                                        //console.log("check", item.target.value);
                                        formik.setFieldValue(
                                          "requestPhoto",
                                          !formik.values.requestPhoto
                                        );
                                      }}
                                    /> : null}
                                  {showParentsNumber
                                    ? <CommonCheckButton
                                      id={"requestParentsNumber"}
                                      name={"requestParentsNumber"}
                                      selected={formik.values.requestParentsNumber}
                                      checktext={trans('view_profile.request_parents_number')}
                                      value={formik.values.requestParentsNumber}
                                      onChange={(item) => {
                                        //console.log("check", item.target.value);
                                        formik.setFieldValue(
                                          "requestParentsNumber",
                                          !formik.values.requestParentsNumber
                                        );
                                      }}
                                    />
                                    : null
                                  }
                                </div>
                                <div className="flex justify-center mt-2">
                                  <button
                                    type="submit"
                                    className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                                  >
                                    {trans('send_bulk_request.send_request')}
                                  </button>
                                </div>
                              </form>
                              : null
                            } */}
                    </div>
                   
                  
                </div>
              </div>
            </div>}
          </div>
          {/* {(userData?.isPaid === "Y"  && phoneNumData && phoneNumData?.statusmessage !== 'This phone number is customized, please send a request to view phone number.') &&
          <div className="px-4">
            <div className="flex-col border-t border-gray-400 border-dashed lg:px-6 md:px-6 px-6"> 
              <p className="py-2 text-[#575556] font-Poppins-Regular  md:text-[13px] xl:text-[13px] lg:text-[11px] text-[10pt] ">{trans('view_phone_number_popup.people_register_from_various_s')}</p>
              <div className="flex justify-center pb-4 pt-1">
                <p className="text-[#575556] font-Poppins-SemiBold  md:text-[13px] xl:text-[13px] lg:text-[11px] text-[10pt]">{trans('view_phone_number_popup.click_here_to')} <span className="text-[#D10A11] cursor-pointer underline" onClick={reportClick}> {trans('view_phone_number_popup.report_incorrect')}</span></p>
              </div>
            </div>
          </div>
          } */}
        </div>
        
      </div>
    :
    <div onClick={(e) => {
      if (e.target.id == "model-wrapper" && isMobile) {
        props?.close();
      }
    }}
     id="model-wrapper" className="font-Poppins-Regular flex justify-center bg-opacityblack items-end md:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[1000] outline-none focus:outline-none">
      <div className="relative w-full md:w-[66%] xl:w-[36%] lg:w-[38%]  md:my-6 md:mx-6" ref={closePopupRef}>
        <div className="">
          <button
            className="hidden md:block bg-transparent z-40 border-0 text-black  absolute top-0 right-[4px] md:right-[-10px]"
            onClick={() => {
              props?.close();
            }}
          >
            <img className="h-6 w-6 " src={CloseIcon} alt="" />
          </button>
        </div>
        <div className=" border-0 rounded-tr-[42px] rounded-tl-[42px] md:rounded-2xl shadow-lg relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center py-5 justify-center   mx-2  rounded-t ">
            <h3 className=" text-[20px] lg:text-[17px] xl:text-[20px] text-[#D10A11] ml-2 font-semibold align-middle text-center">
              {props?.title}
            </h3>
          </div>
          <div className="relative pb-3 lg:px-6 md:px-6 flex-auto">
            <div className="flex mx-2">
              <div className="">

                <ProcessImage
                  src={updatedPic}
                  alt=""
                  passwordProtected={profileDetail?.fromPasswordProtected}
                  blurPhoto={profileDetail?.blurPhoto}
                  isAvatarPic={profileDetail?.profiledetails?.isAvatarPic}
                  className={`w-12 h-12  md:w-20 object-cover md:h-20 lg:w-16 lg:h-16 xl:w-20 xl:h-20 rounded-[50%] ${isAvatar ? "border border-[#8292A8]" : ""}`}
                  lockClassName="h-[2rem] w-[2rem]"
                  containerClassName="flex w-max shrink-0"
                  alreadyContacted={profileDetail?.alreadyContected}
                />


              </div>
              <div className="flex flex-col lg:ml-2 md:ml-2">
                <div className="lg:ml-4 md:ml-4 ml-3 flex flex-col space-y-1 item-start justify-start">
                  {phoneNumData?.phoneNumberVieweeData[0].lastOnline ===
                    "Online Now" ? (
                    <div className="flex  justify-start items-center">
                        <span className='inline-flex items-center justify-center w-4 h-4 lg:w-3 lg:h-3 xl:w-4 xl:h-4 ms-2 text-xs font-semibold text-[#039511] bg-[#039511] rounded-full'>
                          <span className='w-[6px] h-[6px] inline-block bg-[#FDC300] rounded-full'></span>
                        </span>
                        <span className=" px-2 md:px-1 lg:px-2 text-[9px] md:text-[8px] lg:text-[12px] text-[#039511]">
                          {trans('view_profile.online')}
                      </span>
                    </div>
                  ) : (
                      <span className="md:flex justify-start text-[#989898]  font-semibold text-[10px] px-1">
                     
                      {formatLastSeen(profileData?.profiledetails?.profilelastOnline)}
                    </span>
                  )}

                  <span className="text-[#1A1A1A] font-Poppins-SemiBold md:text-[16px] lg:text-[13px] xl:text-[16px] text-[10pt] ">
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.name} |{" "}
                    <span className="text-[#1A1A1A] font-Poppins-Regular md:text-[13px] lg:text-[11px] xl:text-[13px] text-[10pt] font-medium">
                      {
                        phoneNumData?.phoneNumberVieweeData[0]
                          ?.smProfileId
                      }
                    </span>
                  </span>
                  <span className="text-[#1A1A1A] font-Poppins-Regular  md:text-[13px] xl:text-[13px] lg:text-[11px] text-[10pt] ">
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.age} |{" "}
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.height} |{" "}
                    {/* {profilequalification} |{" "} */}
                    {profileDetail?.profiledetails?.profilequalification} |{""}
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.religion} |{" "}
                    {phoneNumData?.phoneNumberVieweeData?.[0]?.country}
                  </span>
                </div>
                <div className="">
                  {phoneNumData?.status === "SUCCESS" ? (
                    (localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                    "Your profile is hidden"
                    :
                    
                    phoneNumData?.phoneNumberVieweeData?.[0]?.profileStatus == "open" || phoneNumData?.phoneNumberVieweeData?.[0]?.profileStatus == "pending"?
                          <div className="whitespace-nowrap space-y-2">
                            {phoneNumData?.phoneNumberVieweeData?.[0]?.advisornumber && 
                            <div className=" block gap-3 md:grid lg:flex items-center space-x-2 lg:space-x-0 md:grid-cols-2">
                              <span className="font-Poppins text-[#D10A11] md:text-[12px] xl:text-[14px] text-[8pt] font-bold whitespace-nowrap">
                                Matrimony Advisor's  Number
                              </span>
                              <span className="text-[#000000] md:pl-3 lg:pl-0  md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold whitespace-nowrap">
                                {/* +91 - 3242234433 */}
                                {phoneNumData && phoneNumData?.phoneNumberVieweeData?.[0]?.advisornumber}
                              </span>
                            </div>}
                            {phoneNumData && phoneNumData?.phoneNumberVieweeData?.[0]?.displaycontent ?
                              <div className="flex flex-col items-start pl-3 md:pl-3 lg:pl-0 top-[-4rem]">
                                <span className="text-[#000000] text-[10pt] font-semibold">
                                {trans('view_phone_number_popup.this_phone_number_is_customize')}
                                </span>
                              </div>
                            
                            :
                            <>
                            <div className=" block gap-3 md:grid lg:flex items-center justify-between space-x-2 lg:space-x-0 mt-3 md:grid-cols-2">
                            
                              {trans('view_phone_number_popup.verified_profile_phone_number') == 'Verified Profile Phone Number' ? 
                                <div className="flex-1 items-center space-x-2">
                                  <span className=" font-Poppins-Regular text-[#575556] pt-[0.1rem]  md:text-[12px] xl:text-[14px] text-[8pt] font-medium whitespace-nowrap">
                                    {trans('view_phone_number_popup.verified_profile_phone_number')}
                                  </span>
                                  <span className="col-span-2 text-[#000000] md:pl-3 lg:pl-0  md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold whitespace-nowrap">
                                    {/* +91 - 3242234433 */}
                                    {phoneNumData?.MobileNumber}
                                  </span>
                                </div> 
                                :
                              <div className="grid grid-cols-4 gap-4">
                                <OverflowToolTip as='p' className="col-span-2 font-Poppins-Regular text-[#575556] pt-[0.1rem]  md:text-[12px] xl:text-[14px] text-[8pt] font-medium truncate">
                                  {trans('view_phone_number_popup.verified_profile_phone_number')}
                                </OverflowToolTip>
                                <span className="col-span-2 text-[#000000] md:pl-3 lg:pl-0  md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold whitespace-nowrap">
                                  {/* +91 - 3242234433 */}
                                  {phoneNumData?.MobileNumber}
                                </span>
                              </div>
                              }
                      </div>
                      {phoneNumData?.phoneNumberVieweeData?.[0]?.parentsnumber ?
                        <div className="block gap-3 grid lg:flex items-center justify-between space-x-2 mt-2 grid-cols-2">
                            {trans('view_phone_number_popup.parents_phone_number') == `Parent's Phone Number` ? 
                              <div className="flex-1 items-center space-x-2">
                                <span className="font-Poppins-Regular text-[#575556] pt-[0.1rem] md:text-[12px] xl:text-[14px] text-[8pt] font-medium">
                                  {trans('view_phone_number_popup.parents_phone_number')}
                                </span>
                                <span className="col-span-2 text-[#000000]  md:pl-3 lg:pl-0 md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold">
                                  {/* +91 - 3242234433 */}
                                  {/* {//console.log(props.phoneNumData,"phone")} */}
                                  {phoneNumData?.phoneNumberVieweeData?.[0]?.parentsisdcode
                                    + "-"+ phoneNumData?.phoneNumberVieweeData?.[0]?.parentsnumber}
                                </span>
                              </div> 
                            :
                            <>
                              <OverflowToolTip as='p' className="md:col-span-1 lg:col-span-2  truncate font-Poppins-Regular text-[#575556] pt-[0.1rem] md:text-[12px] xl:text-[14px] text-[8pt] font-medium">
                              {trans('view_phone_number_popup.parents_phone_number')}
                              </OverflowToolTip>
                              <span className="md:col-span-1 lg:col-span-2  text-[#000000]  md:pl-3 lg:pl-0 md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold">
                                {/* +91 - 3242234433 */}
                                {/* {//console.log(props.phoneNumData,"phone")} */}
                                {phoneNumData?.phoneNumberVieweeData?.[0]?.parentsisdcode
                                  + "-"+ phoneNumData?.phoneNumberVieweeData?.[0]?.parentsnumber}
                              </span>
                            </>
                            }
                        </div>
                      :""
                      }
                      </>}

                            {showRequestPhoto || showParentsNumber
                              ? <form onSubmit={formik.handleSubmit}>
                                <div className="flex items-center justify-around py-4">
                                  {showRequestPhoto
                                    ? <CommonCheckButton
                                      id={"requestPhoto"}
                                      name={"requestPhoto"}
                                      selected={formik.values.requestPhoto}
                                      checktext={trans('chat.request_photo')}
                                      value={formik.values.requestPhoto}
                                      onChange={(item) => {
                                        //console.log("check", item.target.value);
                                        formik.setFieldValue(
                                          "requestPhoto",
                                          !formik.values.requestPhoto
                                        );
                                      }}
                                    /> : null}
                                  {showParentsNumber
                                    ? <CommonCheckButton
                                      id={"requestParentsNumber"}
                                      name={"requestParentsNumber"}
                                      selected={formik.values.requestParentsNumber}
                                      checktext={trans('view_profile.request_parents_number')}
                                      value={formik.values.requestParentsNumber}
                                      onChange={(item) => {
                                        //console.log("check", item.target.value);
                                        formik.setFieldValue(
                                          "requestParentsNumber",
                                          !formik.values.requestParentsNumber
                                        );
                                      }}
                                    />
                                    : null
                                  }
                                </div>
                                <div className="flex justify-center mt-2">
                                  <button
                                    type="submit"
                                    className=" flex items-center justify-center lg:w-[2.5rem] lg:h-[1rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                                  >
                                    {trans('send_bulk_request.send_request')}
                                  </button>
                                </div>
                              </form>
                              : null
                            }
                    </div>
                    :
                    <div className="text-center">
                      {phoneNumData?.phoneNumberVieweeData?.[0]?.profileStatus== "hidden"?
                      "profile has been hidden"
                      :phoneNumData?.phoneNumberVieweeData?.[0]?.profileStatus== "inactive"?
                      "profile has been inactive"
                      :phoneNumData?.phoneNumberVieweeData?.[0]?.profileStatus== "delete"?
                      "profile has been deleted"
                      :phoneNumData?.phoneNumberVieweeData?.[0]?.profileStatus== "suspension"?
                      "profile has been suspended"
                      :""}
                    </div>
                  ) : phoneNumData?.status === "Upgrade" ? (
                    <div>
                      {(localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                        "Your profile is hidden"
                        :
                        <>
                        {phoneNumData?.phoneNumberVieweeData?.[0]?.advisornumber && 
                          <div className=" block gap-3 md:grid lg:flex items-center justify-between space-x-2 lg:space-x-0 md:grid-cols-2">
                            <span className="font-Poppins text-[#D10A11] md:text-[12px] xl:text-[14px] text-[8pt] font-bold whitespace-nowrap">
                              Matrimony Advisor's  Number
                            </span>
                            <span className="text-[#000000] md:pl-3 lg:pl-0  md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold whitespace-nowrap">
                              {/* +91 - 3242234433 */}
                              {phoneNumData && phoneNumData?.phoneNumberVieweeData?.[0]?.advisornumber}
                            </span>
                          </div>}
                      <span className="text-[#000000]  text-[14px] md:text-[14px] lg:text-[14px] font-semibold">
                      {phoneNumData?.statusmessage}
                      </span>
                      <div className="flex ml-[2rem] mt-3 md:w-[83%] lg:w-[100%]">
                        <button
                          onClick={() => {
                                  navigate(RouteSegments.MEMBERSHIP_REGISTER());
                            props.close();
                          }}
                                className=" flex items-center justify-center lg:w-[10rem] xl:w-[8.5rem] xl:min-w-[11rem] lg:h-[0.8rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[1.5rem] py-[0.5rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-4 md:text-[12px] xl:text-[14px]"
                        >
                          {trans('view_phone_number_popup.buy_now')}
                        </button>
                      </div>
                      </>}
                    </div>
                  ) : phoneNumData?.status === "Validate" ? (
                    <div className="mt-4">
                      {phoneNumData?.phoneNumberVieweeData?.[0]?.advisornumber && 
                      <div className=" block gap-3 md:grid lg:flex items-center justify-between space-x-2 lg:space-x-0 md:grid-cols-2">
                          <span className="font-Poppins text-[#D10A11] md:text-[12px] xl:text-[14px] text-[8pt] font-bold whitespace-nowrap">
                            Matrimony Advisor's  Number
                          </span>
                          <span className="text-[#000000] md:pl-3 lg:pl-0  md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold whitespace-nowrap">
                            {/* +91 - 3242234433 */}
                            {phoneNumData && phoneNumData?.phoneNumberVieweeData?.[0]?.advisornumber}
                          </span>
                        </div>}
                      <span className="text-[#575556]  md:text-[12px] lg:text-[14px] text-[8px]  font-bold">
                        {(localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                        "Your profile is hidden"
                        :
                        phoneNumData?.statusmessage}
                      </span>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <span className="text-[#575556]  md:text-[12px] lg:text-[14px] text-[8px]  font-bold">
                      {(localStorage.getItem(Constants.loginLocalStorageKeys.loginUserStatus)) == "hidden"?
                        "Your profile is hidden"
                        :
                        <>
                        {phoneNumData?.phoneNumberVieweeData?.[0]?.advisornumber && 
                        <div className=" block gap-3 md:grid lg:flex items-center justify-between space-x-2 lg:space-x-0 md:grid-cols-2">
                          <span className="font-Poppins text-[#D10A11] md:text-[12px] xl:text-[14px] text-[8pt] font-bold whitespace-nowrap">
                            Matrimony Advisor's  Number
                          </span>
                          <span className="text-[#000000] md:pl-3 lg:pl-0  md:text-[13px] lg:text-[13px] xl:text-[13px] text-[10pt] font-semibold whitespace-nowrap">
                            {/* +91 - 3242234433 */}
                            {phoneNumData && phoneNumData?.phoneNumberVieweeData?.[0]?.advisornumber}
                          </span>
                        </div>}
                        {phoneNumData?.statusmessage}
                        </>}
                     
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {(userData?.isPaid === "Y"  && phoneNumData && phoneNumData?.statusmessage !== 'This phone number is customized, please send a request to view phone number.') &&
          <div className="px-4">
            <div className="flex-col border-t border-gray-400 border-dashed lg:px-6 md:px-6 px-6"> 
              <p className="py-2 text-[#575556] font-Poppins-Regular  md:text-[13px] xl:text-[13px] lg:text-[11px] text-[10pt] ">{trans('view_phone_number_popup.people_register_from_various_s')}</p>
              <div className="flex justify-center pb-4 pt-1">
                <p className="text-[#575556] font-Poppins-SemiBold  md:text-[13px] xl:text-[13px] lg:text-[11px] text-[10pt]">{trans('view_phone_number_popup.click_here_to')} <span className="text-[#D10A11] cursor-pointer underline" onClick={reportClick}> {trans('view_phone_number_popup.report_incorrect')}</span></p>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    }
    </>
  );
};

export default Phonenumberpopup;

import React, { useEffect, useMemo, useState } from "react";
import { SelectDropdownUtil, SingleSelectDropdown } from "./CustomSelect";
import DatePicker from "react-datepicker";
import "../../Components/ReasonforDeletion/datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { EventKeys, EventName } from "../../Constants/constants";
import { IoMdArrowDropdown } from "react-icons/io";
import LoaderGif from "../../Gif/loader.gif";
import EditIcon from "../../Images/EditIcon.svg";
import EllipseImage from '../../Images/Ellipse 76.svg';
import profileGrayImage from '../../Images/Group 537-grey.svg';
import RightArrow from '../../Images/right-arrow (1).svg';
import CloseIcon from '../Kalyanlite/assets/Images/Group 166.svg';
import { useAnalytics } from "../../Hooks/usePageChange";
import CancelIcon from '../../Images/cancelicon.svg';
import { commaWithSpace } from "../../Utils";
import { useTranslation } from "react-i18next";
import OverflowToolTip from "../utils/OverflowTooltip";
import { useMediaQuery } from "react-responsive";

const ProfileMenuItem = (props) => {
  const isV3User = localStorage.getItem("isV3User")
  return (
    <div
      className="flex cursor-pointer"
      onClick={() => props.onClickCallBack()}
    >
      <div className={`${isV3User ? "flex items-center shrink-0 w-[2.5rem] h-[2.5rem] rounded-full" : "flex items-center"} bg-[${props?.backgroundColor}] `}>
        <img className={`${isV3User ? "w-[50%] block ml-auto mr-auto " : "w-[1rem]"}`} src={props.image} alt="" />
      </div>
      {isV3User ?
        <p
          className={`pl-[1rem] break-words md:w-[9rem] lg:w-[15rem] xl:w-full flex items-center ${props.isSelected ? "text-[#D71920]" : "text-[#575556]"
            } font-semibold md:text-[11px] lg:text-[15px] opacity-[0.9]`}
        >
          {props.title}
        </p>
        :
        <p
          className={`pl-2 flex items-center ${props.isSelected ? "text-[#D10A11]" : "text-[#575556]"
            } font-semibold md:text-[13px] lg:text-[16px] opacity-[0.9]`}
        >
          {props.title}
        </p>
      }
    </div>
  );
};

const BasicTextView = (props) => {
  console.log(props , "propsmnnm");
  
  return (
    <div className={`grid grid-cols-9 mt-4  
    ${props?.isFrom=="religiousview" || props?.isFrom =="profileaddDetails"? "mb-[2rem]":""} md:mt-[2rem]    items-center`}>

      <div className={` ${props?.isFrom=="religiousview" || props?.isFrom =="profileaddDetails"? "col-span-4 md:col-span-2":"col-span-9 md:col-span-4 "} md:pl-[1rem] lg:pl-[2rem]`}>
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
        {props?.isFrom =="profileaddDetails" || props?.isFroms=="familydetail" 
        || props?.isFrom=="addtionaldata" || props?.isFrom=="religiousview" || props?.isFrom=="religiousview"?
            <div className="flex gap-3 items-center">
              <img className="w-[2.5rem] h-[2.5rem]" src={props.icon} alt="icon" />
              {props.title}
              </div>  : props.title }
              
              
        </p>
      </div>
      <div className={` ${props?.isFrom=="religiousview" || props?.isFrom =="profileaddDetails"? "col-span-4 md:col-span-5  md:pr-[1rem] lg:pr-[4rem] md:pl-[8rem] lg:pl-[9rem]":"md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem] col-span-9 md:col-span-5 "} `}>
        <p className=" md:text-[12px] lg:text-[14px] md:text-[#7A7A7A] text-[#575556] font-semibold">
          {props.value}
        </p>
      </div>
    </div>
  );
};
const BasicTextViewWithImg = (props) => {
  return (
    <div className="relative flex md:p-2">
      <img src={EllipseImage} className="w-9 md:w-12" alt="" />
      <span className="flex justify-center lg:pl-[0.65rem] absolute mt-[10px] md:pl-[0.65rem] pl-1 md:mt-[14px] lg:mt-[12px] top-0 md:top-[6%] lg:top-[12%]">
        <img className="w-6 h-6 md:w-7 md:h-7" src={props.image} alt="" />
      </span>
      <div className=" pl-2 mt-2 md:mt-0 lg:mt-0 leading-[17px] md:leading-[20px] lg:leading-[26px] ">
        <p className="text-[#575556] font-normal text-[12px] md:text-[13px] lg:text-[14px]  ">
          {props.title}
        </p>
        <span className="text-[#575556] text-[14px] font-bold whitespace-nowrap">
          {props.value}
        </span>
      </div>
    </div>
  );
};
const BasicEditTextView = (props) => {

  const { t:trans } = useTranslation();
  
  console.log((props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44, 'props.fieldType')
  const FieldType = ((props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44) || (props.fieldType >= 67 && props.fieldType <= 84) || (props.fieldType >= 93 && props.fieldType <= 102)
  useEffect(() => {
    //console.log(props?.value,"othercaste");
  }, [props?.value])
  return (

    <>
    
    <div className={`  mt-4 ${props?.isFrom=="profileaddDetails"?"hidden md:grid":""} 
    ${(props?.isFrom == "requestPopup" && !FieldType)
     ? "grid grid-cols-9" : props?.menu == "addfamily" ? 'md:mt-[2rem] ' :  FieldType ? "" :  'grid grid-cols-9'}
     ${props?.inputName=="weight" || props?.inputName=="address" ?"mt-[1rem] md:mt-[2rem]":""} 
     ${props?.inputID=="otherSubCast"?" mt-[1rem] md:mt-[2rem]":""} 
     ${(props?.inputName=="otherCast" || props?.inputID=="subCasteOther" || props?.inputName=="mothersName" || (props?.inputName=="parentNumber" &&  !FieldType ))?"mt-[2rem]": ""}
     

      ${props?.isFrom == "additionalFields" ? "md:mt-[1rem] lg:mt-[1rem] xl:mt-[1.3rem]"
          
      : props?.isFrom == "requestPopup" ? "mt-[1rem]" : ""}   items-center md:p-0`} >

      <div className={` ${props?.isFrom=="profileaddDetails" || props?.isFroms=="familydetail" 
      || props?.isFrom=="addtionaldata" ||  props?.isFroms=="religious"?"col-span-4 md:col-span-3 lg:col-span-3 xl:col-span-2"
      : "col-span-9 md:col-span-4"}  
      ${(props?.menu == "addfamily" || FieldType) ? "md:pl-[1rem] lg:pl-[1rem]" :
       props.RelationError == 'relationShip' ? "md:pl-[1rem] lg:pl-[2rem]" : "md:pl-[1rem] lg:pl-[2rem]"} `}>
        <p className={` ${props?.menu == "addfamily" ? "text-[#000000] md:text-[14px] font-bold lg:text-[13px] py-1 "
           : (props.fieldType >= 67 && props.fieldType <= 84) || (props.fieldType >= 93 && props.fieldType <= 102) 
           ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1"
            : "text-[#7A7A7A] md:text-[12px] lg:text-[14px] flex  font-semibold"}    `}>
        {props?.isFrom =="profileaddDetails" || props?.isFroms=="familydetail" 
        || props?.isFrom=="addtionaldata" || props?.isFroms=="religious"?
            <div className="flex gap-3 items-center">
              <img className="w-[2.5rem] h-[2.5rem]" src={props.icon}/>
              {props.title}
              </div>  : props.title }
          {props.required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className={` ${props?.isFrom=="profileaddDetails" || props?.isFroms=="familydetail" 
      || props?.isFrom=="addtionaldata"?"col-span-9  lg:col-span-5 xl:col-span-4 md:col-span-6    md:pr-[1rem] xl:pr-[4rem] pt-2 md:pl-[4rem] lg:pl-[3rem] lg:pr-[4rem] xl:pl-[7rem]  md:mt-0"
      : props?.isFroms=="religious" ?"col-span-9 md:col-span-4  md:pr-[1rem] xl:pr-[4rem] pt-2 md:pl-[4rem] lg:pl-[3rem] !lg:pr-[4rem] xl:pl-[7rem]  md:mt-0":"col-span-9 md:col-span-5"}
       ${ ((props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44) ? 
       "md:pr-[1rem] lg:pr-[1rem] pt-2  md:pl-[1rem] md:mt-0" : (props.fieldType >= 67 && props.fieldType <= 84) 
       || (props.fieldType >= 93 && props.fieldType <= 102) 
       ? "md:pr-[1rem] lg:pr-[1rem]  md:pl-[1rem] ": props?.isFrom == "requestPopup" ? "md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem] mt-2 md:mt-0"
        : props?.menu == "addfamily" ? "md:pr-[1rem] lg:pr-[1rem]  md:pl-[1rem] mt-2 md:mt-0" 
         : props.RelationError == 'relationShip' ? "md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem] mt-2 md:mt-0" 
         :props?.isFroms=="religious" ?"md:pr-[1rem] lg:pr-[4rem] pt-2 md:pl-[4rem] lg:pl-[3rem] md:mt-0": "md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem] mt-2 md:mt-0"}  `}>
        <p className={props?.inputID === "parentNumber" ? "RegPhone" : ""}>
          <input
            type={props.inputType}
            id={props.inputID}
            name={props.inputName}
            value={props?.value}
            className={props?.menu == "addfamily" ? "border border-[#8F8F8F] text-[12px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold " + (props.className ?? '') :
              (props.fieldType >= 67 && props.fieldType <= 84) || (props.fieldType >= 93 && props.fieldType <= 102) ? "AdditionalFamily border border-[#8F8F8F] md:text-[12px] lg:text-[14px] xl:text-[15px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-Poppins-Regular " + (props.className ?? '') : props.editfromadmin == 'Y' ? 'border border-[#8F8F8F] text-[14px] xl:text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold cursor-not-allowed' + (props.className ?? '')  : " border border-[#8F8F8F] text-[14px] xl:text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold " + (props.className ?? '')}
            placeholder={props.placeholder}
            autoComplete="off"
            onChange={props.onChange}
            disabled={props?.disabled || props.editfromadmin == 'Y'}
            onKeyDown={props?.onKeyDown}
            defaultValue={props?.defaultValue}
          />
          {props.error && (
            <p className="text-[red] text-[12px]">{props.error}</p>
          )}
          {props?.inputID === "parentNumber" && (
            props?.phoneNumberError != "" && (
              <p className="text-[red] text-[12px]">{props.phoneNumberError}</p>
            )
          )}
        </p>
      </div>
      {props.RelationError == 'relationShip'  ?
        ((props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44) ? '' : 
          <div className="col-span-9 flex  pt-4 lg:ml-[2rem] md:ml-4">
            <p className="text-[12px] text-[red] md:mr-5">* {trans('my_profile.this_number_will_be_visible_to')}</p>
          </div> : ''
      }
     </div>

{props?.isFrom=="profileaddDetails" &&
     <div
  className={`relative w-full max-w-md mx-auto mb-[2rem] flex gap-3 items-center ${props?.customClassName ? props?.customClassName : ""} md:hidden ${
    props?.isFrom == "requestPopup" && !FieldType
      ? ""
      : props?.menu == "addfamily"
      ? "md:mt-[2rem]"
      : FieldType
      ? ""
      : ""
  } 
  ${
    props?.inputName == "weight" || props?.inputName == "address"
      ? "mt-[1rem] md:mt-[2rem]"
      : ""
  } 
  ${
    props?.inputID == "otherSubCast"
      ? "mt-[1rem] md:mt-[2rem]"
      : ""
  } 
  ${
    props?.inputName == "otherCast" ||
    props?.inputID == "subCasteOther" ||
    props?.inputName == "mothersName" ||
    (props?.inputName == "parentNumber" && !FieldType)
      ? "mt-[2rem]"
      : ""
  }
  ${
    props?.isFrom == "additionalFields"
      ? "md:mt-[1rem] lg:mt-[1rem] xl:mt-[1.3rem]"
      : props?.isFrom == "requestPopup"
      ? "mt-[1rem]"
      : ""
  }`}
>
  <img className="w-[2.5rem] h-[2.5rem]" src={props.icon}/>
  <div className={`relative w-full max-w-md mx-auto md:hidden`}>
  <label
    htmlFor={props.inputID || "custom-input"}
    className={`absolute -top-3 left-3 bg-white px-1 text-[11px] text-[#575556]  transition-all duration-200 
    peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
    peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
    peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}
  >
    {props.title}
    {props.required && <span className="text-[#D10A11] ml-1"> *</span>}
  </label>
  <input
    type={props.inputType || "text"}
    id={props.inputID || "custom-input"}
    name={props.inputName || ""}
    value={props?.value || ""}
    className={`peer block w-full border-[1px] border-[#8F8F8F] rounded-[3px] bg-transparent px-4 py-2 text-gray-900 
    focus:outline-none focus:ring-2 focus:ring-[#8F8F8F] ${
      props.disabled || props.editfromadmin == "Y" ? "cursor-not-allowed" : ""
    }`}
    placeholder=" "
    autoComplete="off"
    onChange={props.onChange}
    disabled={props?.disabled || props.editfromadmin == "Y"}
    onKeyDown={props?.onKeyDown}
    defaultValue={props?.defaultValue}
  />
  {props.error && (
    <p className="text-red-500 text-[12px]">{props.error}</p>
  )}
  {props.inputID === "parentNumber" &&
    props?.phoneNumberError && (
      <p className="text-red-500 text-[12px]">{props.phoneNumberError}</p>
    )}
  {props.RelationError == "relationShip" && (
    <div className="col-span-9 flex pt-1 lg:ml-[2rem] md:ml-4">
      <p className="text-[12px] text-[red] md:mr-5">
        * {trans("my_profile.this_number_will_be_visible_to")}
      </p>
    </div>
  )}
  </div>
</div>
}

 
 </>
  

  );
};

export const BasicDatePicker = ({icon, value, onChange, disabled, title, required, maxDate, name, error, formatedValue, minDate, menu ,isFrom}) => {
  return (
    <>
    {isFrom == "profileaddDetails" ?  
    <>
      <div className={`relative w-full  md:hidden mb-[2rem] mt-[1rem] md:mt-[2rem] flex gap-3 items-center  `}>
        <img className="w-[2.5rem] h-[2.5rem]" src={icon}/>
        <div className={`relative w-full  md:hidden`}>
        <label
          htmlFor={name}
          className={`absolute -top-3 left-3 bg-white px-1 text-[11px] text-[#575556]  transition-all duration-200 
          peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
          peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
          peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}
        >
          {title}
          {required && <span className="text-[#D10A11] ml-1"> *</span>}
        </label>

        <DatePicker
          wrapperClassName="basic-date-picker"
          selected={value}
          onChange={onChange}
          dateFormat="dd-MM-yyyy"
          minDate={minDate ?? new Date()}
          maxDate={maxDate ?? new Date()}
          showMonthDropdown
          showYearDropdown
          adjustDateOnChange
          dropdownMode="select"
          name={name}
        />
          {error && (
            <p className="text-red-500 text-[12px]">{error}</p>
          )}
        </div>
        </div>

        <div className={`  ${menu == "horodob" ? "mt-1" : " grid-cols-9 mt-4 md:mt-[2rem]"}   hidden md:grid  items-center md:p-0`}>
        <div className={` ${isFrom=="profileaddDetails"?"col-span-9 md:col-span-3 xl:col-span-2 lg:col-span-3 md:block":"col-span-9 md:col-span-4"} 
        ${menu == "horodob" ? "pl-[1rem] lg:pl-[1rem]" : "md:pl-[1rem] lg:pl-[2rem]"}  `}>
          <p className={` ${menu == "horodob" ? "text-[13px] font-bold lg:text-[13px] text-[#000000]" : "md:text-[12px] lg:text-[14px] text-[#7A7A7A]"} flex  font-semibold `}>
          {isFrom =="profileaddDetails" ?
              <div className="flex gap-3 items-center">
                <img className="w-[2.5rem] h-[2.5rem]" src={icon}/>
                {title}
                </div>  : title } 
            {required && <span className="text-[#D10A11] ml-1"> *</span>}
          </p>
        </div>
        <div className={`col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6    md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  lg:pr-[10rem] lg:pl-[3rem] xl:pl-[7rem]`}>
          {disabled
            ? <p className={` ${menu == "horodob" ? "   text-[#000000] md:text-[12px] lg:text-[12px]" : "md:text-[#7A7A7A] text-[#575556] md:text-[12px] lg:text-[14px]"}  font-semibold`}>
              {formatedValue}
            </p>
            : <p className={` ${menu == "horodob" ? "md:text-[12px] lg:text-[12px]" : ""}`}>
              <DatePicker
                wrapperClassName="basic-date-picker"
                selected={value}
                onChange={onChange}
                dateFormat="dd-MM-yyyy"
                minDate={minDate ?? new Date()}
                maxDate={maxDate ?? new Date()}
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                dropdownMode="select"
                name={name}
              />
              {error && (
                <p className="text-[red] text-[12px]">{error}</p>
              )}
            </p>}
        </div>
      </div>
      </>
  
     
      :
      <div className={`  ${menu == "horodob" ? "mt-1" : "grid grid-cols-9 mt-4 md:mt-[2rem]"}    items-center md:p-0`}>
      <div className={` ${isFrom=="profileaddDetails"?"col-span-9 md:col-span-2 md:block":"col-span-9 md:col-span-4"} 
      ${menu == "horodob" ? "pl-[1rem] lg:pl-[1rem]" : "md:pl-[1rem] lg:pl-[2rem]"}  `}>
        <p className={` ${menu == "horodob" ? "text-[13px] font-bold lg:text-[13px] text-[#000000]" : "md:text-[12px] lg:text-[14px] text-[#7A7A7A]"} flex  font-semibold `}>
        {isFrom =="profileaddDetails" ?
            <div className="flex gap-3 items-center">
              <img className="w-[2.5rem] h-[2.5rem]" src={icon}/>
              {title}
              </div>  : title } 
          {required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className={` ${isFrom=="profileaddDetails"?"col-span-9 md:col-span-4 md:pr-[1rem] lg:pr-[3rem] md:pl-[7rem] mt-2":"col-span-9 md:col-span-5"} 
      ${menu == "horodob" ? " pl-[1rem] mt-1" : "md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem] mt-2"} md:mt-0`}>
        {disabled
          ? <p className={` ${menu == "horodob" ? "   text-[#000000] md:text-[12px] lg:text-[12px]" : "md:text-[#7A7A7A] text-[#575556] md:text-[12px] lg:text-[14px]"}  font-semibold`}>
            {formatedValue}
          </p>
          : <p className={` ${menu == "horodob" ? "md:text-[12px] lg:text-[12px]" : ""}`}>
            <DatePicker
              wrapperClassName="basic-date-picker"
              selected={value}
              onChange={onChange}
              dateFormat="dd-MM-yyyy"
              minDate={minDate ?? new Date()}
              maxDate={maxDate ?? new Date()}
              showMonthDropdown
              showYearDropdown
              adjustDateOnChange
              dropdownMode="select"
              name={name}
            />
            {error && (
              <p className="text-[red] text-[12px]">{error}</p>
            )}
          </p>}
      </div>
    </div>
    }
    </>
  );
};

const BasiSelectView = (props) => {

  const { t: trans } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 678 });

  return (
    (props?.isFrom == "profesdetails" && isMobile ? 
    <div className="flex gap-3 items-center  mb-[2rem]">
        <img className="w-[2.5rem] h-[2.5rem]" src={props?.icon}/>
        <div className={`relative w-full max-w-md mx-auto md:hidden`}>
          <p className={` absolute -top-3 left-3 bg-white px-1 text-[11px] text-[#575556]  transition-all duration-200 
          peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
        peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
          peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}>
            {props?.title}
            {props?.title == trans('view_profile.raasi') || props?.title == trans('view_profile.star') || props?.title == trans('my_profile.chevvai_doshammanglik') ?
              "" : props?.required  && <span className="text-[#D10A11] ml-1"> *</span>}
          </p>
          <label className="selectWithArrow pepeer block w-full border-[1px] border-[#8F8F8F] rounded-[3px] bg-transparent  py-1 text-gray-900 ">
            <select
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              onChange={props.onChange}
              onSelect={props.onChange}
              disabled={props?.isDisabled}
              className={` bg-white py-1 focus:outline-none ${props?.disabled ? 'cursor-not-allowed' : ''}  `}
            >
              {props?.options?.length > 1 &&
                <option disabled>Select</option>}
              {props?.options?.map((e, i) => (
                <option
                  key={e.id}
                  selected={props.value === e.id && true}
                  value={props.inputID == "parentsIsdCode" ? e.isdcode : e.id}
                >
                  {props.inputID == "parentsIsdCode"
                    ? e.isdcode + " - " + e.country
                    : e.name}
                </option>
              ))}
            </select>
            {props.error && (
              <p className="text-[red] text-[12px]">{props.error}</p>
            )}
          </label>

        </div>
      </div>
   :
    <div className=" grid grid-cols-9 mt-4  md:mt-[2rem]   ">
      <div className={`${props?.isFrom=="profesdetails" ? "col-span-9  xl:col-span-2 md:col-span-3 lg:col-span-3":"col-span-9  md:col-span-4  "} flex items-center  md:pl-[1rem] lg:pl-[2rem] `}>
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
        {props?.isFrom =="profesdetails" ?
            <div className="flex gap-3 items-center">
              <img className="w-[2.5rem] h-[2.5rem]" src={props?.icon}/>
              {props?.title}
              </div>  : props?.title }
          {props.required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className={`  ${props?.isFrom=="profesdetails"?"col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6  lg:pl-[3rem] xl:pl-[4rem]  md:pr-[1rem] lg:pr-[10rem] xl:pr-[4rem] md:pl-[4rem]  xl:pl-[7rem]":"col-span-9  md:col-span-5 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]"} `}>
        {
          props?.disabled
            ? <p className=" md:text-[12px] lg:text-[14px] md:text-[#7A7A7A] text-[#575556] font-semibold">
              {props.value}
            </p>
            : <p className={`${props?.title == trans('view_profile.employment_type') ?? "HideSelect"}`}>
              <div className="flex ">
                <label className=" select font-bold text-[#7A7A7A] w-full ">
                  <select
                    name={props.inputName}
                    value={props.value}
                    id={props.inputID}
                    onChange={props.onChange}
                    onSelect={props.onChange}
                    disabled={props?.isDisabled}
                    className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F]  text-[#575556] md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props?.disabled ? 'cursor-not-allowed' : ''}  `}
                  >
                    {props?.options?.length > 1 &&
                      <option disabled>Select</option>}
                    {props?.options?.map((e, i) => (
                      <option
                        key={e.id}
                        selected={props.value === e.id && true}
                        value={props.inputID == "parentsIsdCode" ? e.isdcode : e.id}
                      >
                        {props.inputID == "parentsIsdCode"
                          ? e.isdcode + " - " + e.country
                          : e.name}
                      </option>
                    ))}
                  </select>
                  {props.error && (
                    <p className="text-[red] text-[12px]">{props.error}</p>
                  )}
                </label>
              </div>
            </p>
        }
      </div>
    </div>
    )
  );
};

export const SelectInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
  fieldType,
  disabled,
  isFromReq,
  icon,
  isFroms
}) => {

  const { t: trans } = useTranslation();

  console.log(isFromReq,'isFromReq');
  

  const OthersToEnd = (castes) => {
    if (!castes || !Array.isArray(castes)) {
      return [];
    }
    const othersIndex = castes.findIndex((caste) => caste.name === "Others");
    const othersOption = othersIndex !== -1 ? castes.splice(othersIndex, 1)[0] : null;
    if (othersOption) {
      castes.push(othersOption);
    }

    return castes;
  }

  const customStyles = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid #8F8F8F",
        outline: "none",
        color: "#575556"
      }
    },

    singleValue: (base) => {
      return {
        ...base,
        color: "#575556"
      }
    },

    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#F7F8FA" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",

    }),

  };

  console.log(defaultValue,'defaultValue');
  
  return (
    <div className={` ${menu == "packastro" || (fieldType >= 27 && fieldType <= 30) || fieldType == 44 || (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? " mt-4" : fieldType == 19 ? '' : "grid grid-cols-9 md:mt-[2rem]  mt-4"}    `}>
      <div className={` ${isFrom=="profileaddDetails" || isFrom=="religious" || isFrom=="familydetail" || isFroms=="religious" ||  isFrom=="horoscopedetails"? "col-span-9  md:col-span-2 ":"col-span-9  md:col-span-4 "} flex ${menu == "packastro" || (fieldType >= 27 && fieldType <= 30) || fieldType == 44 || (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102)
         ? "md:pl-[1rem] lg:pl-[1rem]" : fieldType == 19 ? '' : "md:pl-[1rem] lg:pl-[2rem]"}  items-center   `}>
         {isFromReq="Additional" ? 
             <OverflowToolTip as="p" className={` md:truncate ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" : (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1" : "text-[#7A7A7A] md:text-[12px] lg:text-[14px]  font-semibold "}  `}>
              {isFrom =="profileaddDetails" ||  isFrom=="familydetail"  ||isFrom=="horoscopedetails"|| isFrom=="religious" || isFroms=="religious"  ?
            <div className="flex gap-3 items-center">
              <img className="w-[2.5rem] h-[2.5rem]" src={icon}/>
              {title}
              </div>  : title }
             </OverflowToolTip>
            : 
            isFromReq=="basicdata" ?
           <div> 
            
            <p className={` flex  ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" :
              (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1" : 
              "text-[#7A7A7A] md:text-[12px] lg:text-[14px]  font-semibold "}  `}>
                {title}
                <span className="text-[#D10A11] ml-1"> *</span>
             </p></div>
            :
        <p className={` flex  ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" :
         (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1" : 
         "text-[#7A7A7A] md:text-[12px] lg:text-[14px]  font-semibold "}  `}>
          {title}
          {title == trans('view_profile.raasi') || title == trans('view_profile.star') || title == trans('my_profile.chevvai_doshammanglik') ?
            "" : menu == "packastro" ?
              required && <span className="text-[#D10A11] ml-1"> *</span> : required  && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
        }
      </div>
      <div className={`${(fieldType >= 27 && fieldType <= 30) || fieldType == 44 || fieldType == 19 || (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? '' 
        :isFrom=="profileaddDetails" || isFrom=="religious"||  isFrom=="familydetail" || isFrom=="horoscopedetails" || isFroms=="religious"? 'col-span-9  md:col-span-4 md:pl-[7rem] pr-[3rem]':'col-span-9  md:col-span-5 md:pl-[1rem]'} ${menu === "packastro" ? "md:pr-[1rem] lg:pr-[1rem]" 
        : (fieldType >= 27 && fieldType <= 30) || fieldType == 44  ? 'md:pr-[1rem] lg:pr-[1rem] md:pl-[1rem] pt-2'
         : (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? 'md:pr-[1rem] lg:pr-[1rem] md:pl-[1rem]'
          : fieldType == 19 ? '' : "md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem]"}`}>
        <p className="">
          <div className="flex ">
            <label className={` ${menu == "packastro" ? "md:text-[12px]  lg:text-[12px] font-bold" : (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "AdditionalFamily md:text-[12px] lg:text-[13px] xl:text-[15px] text-[#575556]" :  disabled ? 'cursor-not-allowed md:text-[12px]  lg:text-[14px] font-bold' : "md:text-[12px]  lg:text-[14px] font-bold"} w-full `}>
              <Select
                isSearchable={true}
                placeholder={title == trans('view_profile.raasi') ? options ? options?.length == 2 ? options[1].name : trans('trial_pack_popup__family_detai.select')  : trans('trial_pack_popup__family_detai.select')  : trans('trial_pack_popup__family_detai.select') }
                name={name}
                value={value === undefined ? "null" : value}
                onChange={onChange}
                options={OthersToEnd(options)}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                styles={customStyles}
                // menuIsOpen={true}
                isDisabled={disabled}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
                }}
                // styles={{
                //   control:(base)=>{
                //     return {
                //       ...base,
                //       border:"1px solid #8F8F8F",
                //       outline:"none",
                //       color:"#575556"
                //     }
                //   },
                //   option:(base,optionState)=>{
                //     return {
                //       ...base,
                //       color:optionState.isSelected ?"white":"#575556"
                //     }
                //   },
                //   singleValue:(base)=>{
                //     return {
                //       ...base,
                //       color:"#575556"
                //     }
                //   },
                // }}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />

              {error && (
                <p className={`text-[red] text-[12px] ${fieldType == 19 ? 'pt-2' : ''}`}>{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  );
};

export const LocationSelectInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
  fieldType,
  disabled,
  isFromReq,
  icon,
  isFroms
}) => {

  const { t: trans } = useTranslation();

  console.log(isFromReq,'isFromReq');
  

  const OthersToEnd = (castes) => {
    if (!castes || !Array.isArray(castes)) {
      return [];
    }
    const othersIndex = castes.findIndex((caste) => caste.name === "Others");
    const othersOption = othersIndex !== -1 ? castes.splice(othersIndex, 1)[0] : null;
    if (othersOption) {
      castes.push(othersOption);
    }

    return castes;
  }

  const customStyles = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid #8F8F8F",
        outline: "none",
        color: "#575556"
      }
    },

    singleValue: (base) => {
      return {
        ...base,
        color: "#575556"
      }
    },

    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#F7F8FA" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",

    }),

  };

  console.log(defaultValue,'defaultValue');
  
  return (
    <div className={` ${menu == "packastro" || (fieldType >= 27 && fieldType <= 30) || fieldType == 44 || (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? " mt-4" : fieldType == 19 ? '' : "grid grid-cols-9 md:mt-[2rem]  mt-4"}    `}>
      <div className={` ${isFrom=="profileaddDetails" || isFrom=="religious" || isFrom=="familydetail" || isFroms=="religious" ||  isFrom=="horoscopedetails"? "col-span-9  md:col-span-2 ":"col-span-9  md:col-span-4 "} flex ${menu == "packastro" || (fieldType >= 27 && fieldType <= 30) || fieldType == 44 || (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102)
         ? "md:pl-[1rem] lg:pl-[1rem]" : fieldType == 19 ? '' : "md:pl-[1rem] lg:pl-[2rem]"}  items-center   `}>
         {isFromReq="Additional" ? 
             <OverflowToolTip as="p" className={` md:truncate ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" : (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1" : "text-[#7A7A7A] md:text-[12px] lg:text-[14px]  font-semibold "}  `}>
              {isFrom =="profileaddDetails" ||  isFrom=="familydetail"  ||isFrom=="horoscopedetails"|| isFrom=="religious" || isFroms=="religious"  ?
            <div className="flex gap-3 items-center">
              <img className="w-[2.5rem] h-[2.5rem]" src={icon}/>
              {title}
              </div>  : title }
             </OverflowToolTip>
            : 
            isFromReq=="basicdata" ?
           <div> 
            
            <p className={` flex  ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" :
              (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1" : 
              "text-[#7A7A7A] md:text-[12px] lg:text-[14px]  font-semibold "}  `}>
                {title}
                <span className="text-[#D10A11] ml-1"> *</span>
             </p></div>
            :
        <p className={` flex  ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" :
         (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1" : 
         "text-[#7A7A7A] md:text-[12px] lg:text-[14px]  font-semibold "}  `}>
          {title}
          {title == trans('view_profile.raasi') || title == trans('view_profile.star') || title == trans('my_profile.chevvai_doshammanglik') ?
            "" : menu == "packastro" ?
              required && <span className="text-[#D10A11] ml-1"> *</span> : required  && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
        }
      </div>
      <div className={`${(fieldType >= 27 && fieldType <= 30) || fieldType == 44 || fieldType == 19 || (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? '' 
        :isFrom=="profileaddDetails" || isFrom=="religious"||  isFrom=="familydetail" || isFrom=="horoscopedetails" || isFroms=="religious"? 'col-span-9  md:col-span-4 md:pl-[7rem] pr-[3rem]':'col-span-9  md:col-span-5 md:pl-[1rem]'} ${menu === "packastro" ? "md:pr-[1rem] lg:pr-[1rem]" 
        : (fieldType >= 27 && fieldType <= 30) || fieldType == 44  ? 'md:pr-[1rem] lg:pr-[1rem] md:pl-[1rem] pt-2'
         : (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? 'md:pr-[1rem] lg:pr-[1rem] md:pl-[1rem]'
          : fieldType == 19 ? '' : "md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem]"}`}>
        <p className="">
          <div className="flex ">
            <label className={` ${menu == "packastro" ? "md:text-[12px]  lg:text-[12px] font-bold" : (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "AdditionalFamily md:text-[12px] lg:text-[13px] xl:text-[15px] text-[#575556]" :  disabled ? 'cursor-not-allowed md:text-[12px]  lg:text-[14px] font-bold' : "md:text-[12px]  lg:text-[14px] font-bold"} w-full `}>
              <Select
                isSearchable={true}
                placeholder={title == trans('view_profile.raasi') ? options ? options?.length == 2 ? options[1].name : trans('trial_pack_popup__family_detai.select')  : trans('trial_pack_popup__family_detai.select')  : trans('trial_pack_popup__family_detai.select') }
                name={name}
                value={value === undefined ? "null" : value}
                onChange={onChange}
                options={OthersToEnd(options)}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                styles={customStyles}
                // menuIsOpen={true}
                isDisabled={disabled}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
                }}
                // styles={{
                //   control:(base)=>{
                //     return {
                //       ...base,
                //       border:"1px solid #8F8F8F",
                //       outline:"none",
                //       color:"#575556"
                //     }
                //   },
                //   option:(base,optionState)=>{
                //     return {
                //       ...base,
                //       color:optionState.isSelected ?"white":"#575556"
                //     }
                //   },
                //   singleValue:(base)=>{
                //     return {
                //       ...base,
                //       color:"#575556"
                //     }
                //   },
                // }}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />

              {error && (
                <p className={`text-[red] text-[12px] ${fieldType == 19 ? 'pt-2' : ''}`}>{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  );
};

export const SelectCurrencyInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  menu,
  isFrom,
  classNames,
  wrapperClassName="",
  isDisabled}) => {

    const { t: trans } = useTranslation();


  return (



    <div className={wrapperClassName}>
      <p>
        <div className="flex ">
          <label className={`font-bold ${menu == "addfamily" || isFrom=="familydetail" || isFrom=="additional"? "md:text-[12px] lg:text-[12px]  w-[5rem]" :classNames?classNames:  " md:text-[12px] lg:text-[14px] w-[6rem]"} ${isDisabled ? "cursor-not-allowed" : ""} `}>
            <Select
              isSearchable={true}
              placeholder={title == "currency" || title =="misdCode"? defaultValue : trans('my_profile.select')}
              name={name}
              value={value === undefined ? "null" : value}
              onChange={onChange}
              options={options}
              blurInputOnSelect
              onBlur={onBlur}
              defaultValue={defaultValue}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
              }}
              styles={{
                control: (base) => {
                  return {
                    ...base,
                    border: "1px solid #8F8F8F",
                    outline: "none",
                    color: "#575556"
                  }
                },
                option: (base, optionState) => {
                  return {
                    ...base,
                    color: optionState.isSelected ? "white" : "#575556"
                  }
                },
                singleValue: (base) => {
                  return {
                    ...base,
                    color: "#575556"
                  }
                },
              }}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              isDisabled={isDisabled}
            />

            {error && (
              <p className="text-[red] text-[12px]">{error}</p>
            )}
          </label>
        </div>
      </p>
    </div>

  );
};

const SelectViewWithHeading = (props) => {

  const {t:trans} =useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    ((props?.isFrom == "profesdetails" || props?.isFromAdditional) && isMobile ? 
    <div className={`flex md:hidden gap-3 items-center  mb-[2rem] ${props?.customClassName ? props?.customClassName : ""}`}>
        <img className="w-[2.5rem] h-[2.5rem]" src={props?.icon}/>
        <div className={`relative w-full  md:hidden`}>
          <p className={` absolute -top-3 left-3 bg-white px-1 text-[11px] text-[#575556]  transition-all duration-200 
          peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
        peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
          peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}>
            {props?.title}
            {props?.title == trans('view_profile.raasi') || props?.title == trans('view_profile.star') || props?.title == trans('my_profile.chevvai_doshammanglik') ?
              "" : props?.required  && <span className="text-[#D10A11] ml-1"> *</span>}
          </p>
          <label className="selectWithArrow pepeer block w-full border-[1px] border-[#8F8F8F] rounded-[3px] bg-transparent px-4 py-1 text-gray-900 ">
              <select
                name={props.inputName} 
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                onSelect={props.onChange}
                disabled={props?.disabled}
                className={`bg-white py-1 focus:outline-none  ${props?.disabled ? "!cursor-not-allowed" : "cursor-pointer"}`}
              >
                <option value="" disabled>{trans('my_profile.select')}</option>
                {props?.options && props?.options?.map((e, i) =>
                  e.heading === "Y" ? (
                    <option key={e.id} value={e.id} className="font-bold " disabled>
                      {e.name}
                    </option>
                  ) : (
                    <option key={e.id} value={e.id} className="ml-4 ">
                      {e.name}
                    </option>
                  )
                )}
              </select>
              {props?.error && (
              <p className={`text-[red] text-[12px]`}>{props?.error}</p>
            )}
            </label>

        </div>
      </div>
   :
    <div className=" grid grid-cols-9 mt-4  md:mt-[2rem]   ">
      <div className={` ${props?.isFrom=="profesdetails" || props?.isFroms=="addtionaldata"? "md:col-span-3 lg:col-span-3 xl:col-span-2 col-span-9":"md:col-span-4 col-span-9"} flex items-center  md:pl-[1rem] lg:pl-[2rem] `}>
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
        {props?.isFrom =="profesdetails" || props?.isFroms=="addtionaldata" ?
            <div className="flex gap-3 items-center">
              <img className="w-[2.5rem] h-[2.5rem]" src={props?.icon}/>
              {props?.title}
              </div>  : props?.title }
          {props?.isFrom != "additionalDetails" &&
            <span className="text-[#D10A11] ml-1"> *</span>
          }
        </p>
      </div>
      <div className={`${props?.isFrom=="profesdetails" || props?.isFroms=="addtionaldata"? "col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6  lg:pl-[3rem]  lg:pr-[10rem]  md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  xl:pl-[7rem] ":"md:col-span-5 col-span-9  md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]"} `}>
        <p className="">
          <div className="flex ">
            <label className=" select font-bold text-[#575556] w-full ">
              <select
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                onSelect={props.onChange}
                disabled={props?.disabled}
                className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props?.disabled ? "!cursor-not-allowed" : "cursor-pointer"}`}
              >
                <option value="" disabled>{trans('my_profile.select')}</option>
                {props?.options && props?.options?.map((e, i) =>
                  e.heading === "Y" ? (
                    <option key={e.id} value={e.id} className="font-bold " disabled>
                      {e.name}
                    </option>
                  ) : (
                    <option key={e.id} value={e.id} className="ml-4 ">
                      {e.name}
                    </option>
                  )
                )}
              </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  )
  );
};

const HoroscopeEditTextView = (props) => {
  return (
    <div className={props?.menu == "afterHoroscope" ? "flex flex-col  mt-4 md:mt-0 lg:mt-[2rem]     pr-[1rem] pt-[1rem]"
      : props?.menu == "addhoroscope" ? " mt-4  md:mt-[2rem] md:mb-[2rem] lg:mb-[3rem] " :
        props?.menu == "horoscope" ? " " :
          "flex flex-col md:grid md:grid-cols-9 mt-4  md:mt-[2rem]    pr-[1rem] pt-[1rem]"}>
      <div className={props?.menu == "afterHoroscope" ? "md:col-span-3 flex items-center " : "md:col-span-3 flex items-center pl-[1rem]"}>
        <p className={props?.menu == "afterHoroscope" ? " text-[15px] pb-2 md:text-[15px] lg:text-[15px] flex text-[#000000] font-semibold   "
          : props?.menu == "horoscope" ? "text-[13px]   flex text-[#000000] font-semibold " : "text-[13px]  md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  "
        }>
          {props.title}
        </p>
      </div>
      <div className={props?.menu == "afterHoroscope" ? "md:col-span-6  w-full " :
        props?.menu == "horoscope" ? "py-2 md:col-span-6 md:pl-[1rem] pl-[1rem] w-full" : "md:col-span-6 md:pl-[1rem] pl-[1rem] w-full "}>
        <p className={props?.menu == "afterHoroscope" ? "text-[15px] font-semibold  text-[#656565]"
          : props?.menu == "horoscope" ? "text-[#8F8F8F] font-semibold text-[13px]  md:text-[12px] lg:text-[12px]" : "text-sm font-bold text-[#656565]"}>{props.value}</p>
        {/* {props.isPaid
         ? <p className="text-sm font-bold">{props.value}</p>
         :<p className="w-full ">
          <input
            type={props.inputType}
            id={props.inputID}
            name={props.inputName}
            value={props.value}
            className=" border cursor-not-allowed bg-white border-[#8F8F8F] text-sm  rounded-[3px] h-[35px]  block w-full md:p-[0.4rem] lg:p-2.5  font-bold    "
            placeholder=""
            autoComplete="off"
            disabled="disabled"
            onChange={props.onChange}
          />
          {props.error && (
            <p className="text-[red] text-[12px]">{props.error}</p>
          )}
        </p>} */}
      </div>
    </div>
  );
};
const HoroscopeSelectView = (props) => {

  const { t:trans } = useTranslation();

  return (
    <div className={props?.menu === "after" ? "flex flex-col  mt-4  md:mt-[2rem]   md:mb-[2rem] lg:mb-[2rem] pr-[1rem]"
      : props?.menu == "addhoroscope" ? "" : "flex flex-col md:grid md:grid-cols-9 mt-4  md:mt-[2rem]   md:mb-[2rem] lg:mb-[3rem] pr-[1rem]"}>
      <div className={props?.menu === "after" ? "md:col-span-3 flex items-center " : "md:col-span-3 flex items-center  pl-[1rem] "}>
        <p className={props?.menu === "after" ? " text-[15px]  pb-1 md:text-[15px] lg:text-[15px] flex text-[#000000] font-semibold   " : props?.menu == "addhoroscope" ?
          "text-[13px]  flex text-[#000000] font-bold" : "text-[13px]  md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  "}>
          {props.title}<span className="text-[#D10A11]">*</span>
        </p>
      </div>
      <div className={props?.menu === "after" ? "md:col-span-6  " : "pl-[1rem]  md:col-span-6 md:pl-[1rem] "}>
        <p className="">
          {props?.menu === "after" ?
            <label className="   text-[#575556] w-full ">
              <span className="relative float-right top-[29px] md:top-[25px] right-[18px] arrow-clickabale"><img className="w-[13px]" src={RightArrow} alt="" /></span>

              <select
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                placeholder={props.placeholder}
                className="chartstyle w-full md:p-[0.9rem] h-[50px] p-2 lg:p-2.5  border border-[#D9D9D9] text-[#8F8F8F] text-[13px]  md:text-[14px] lg:text-[15px] bg-white rounded-[8px] "
              >

                <option value="" disabled selected >{trans('trial_pack_popup__horscope_det.select')}</option>
                {props?.options?.map((e, i) => (
                  <option value={e.id} selected={e.selected === "Y" && true}>
                    {e.name}
                  </option>
                ))}
              </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>
            :
            <label className=" select font-bold text-[#575556] w-full ">
              <select
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                placeholder={props.placeholder}
                className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px]  md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
              >
                <option value="" disabled selected >{trans('trial_pack_popup__horscope_det.select')}</option>
                {props?.options?.map((e, i) => (
                  <option value={e.id} selected={e.selected === "Y" && true}>
                    {e.name}
                  </option>
                ))}
              </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>

          }

        </p>
      </div>
    </div>
  );
};

const HoroscopeSearchView = (props) => {

  console.log(props.placeholder, "props.placeholder");
  return (
    <div className={props?.menu == "after" ? "flex flex-col  mt-4  pt-1 md:pt-0 md:mt-[2rem]   md:mb-[2rem] lg:mb-[2rem] pr-[1rem] "
      : props?.menu == "addhoroscope" ? "" : "flex flex-col md:grid md:grid-cols-9 mt-4  md:mt-[2rem]   md:mb-[2rem] lg:mb-[3rem] pr-[1rem] "}>
      <div className={props?.menu == "after" ? "md:col-span-3 flex items-center  " : "md:col-span-3 flex items-center  pl-[1rem] "}>
        <p className={props?.menu == "after" ? " text-[15px] pb-3 md:text-[15px] lg:text-[15px] flex text-[#000000] font-semibold   " : props?.menu == "addhoroscope" ? "text-[13px]   flex text-[#000000] font-semibold " : "text-[13px]  md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold   "}>
          {props.title}<span className="text-[#D10A11]">*</span>
        </p>
      </div>
      <div className={props?.menu == "after" ? "md:col-span-6  " : "pl-[1rem] md:col-span-6 md:pl-[1rem] "}>
        <p className="">
          {props?.menu === "after" ?
            <label className="text-[#575556] w-full ">

              <SingleSelectDropdown
                placeholder={props?.placeholder ?? "Select"}
                menu="afterReigister"
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                defaultValue={props?.defaultValue}
                option={props?.options}
                callBackSearch={props.callBackApi}
                suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
              // mode={"multiple"}
              />
              {/* <span className="relative float-right md:top-[-27px] top-[-31px] right-[15px] md:right-[24px] "><img className="w-[13px]" src={RightArrow} /></span> */}

              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>

            :
            <label className=" select font-bold text-[#575556] w-full ">
              <SingleSelectDropdown
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                option={props?.options}
                callBackSearch={props.callBackApi}
                placeholder={props.placeholder}
              // mode={"multiple"}
              />
              {/* <input
            name={props.inputName}
            value={props.value}
            id={props.inputID}
            onChange={(e) => {
              if (e.target.value.length > 2) {
                props.callBackApi(e.target.value);
              }

              props.onChange(e);
            }}
            className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
          >
          <datalist>
            {props?.options?.map((e, i) => (
              <option key={i} value={e.id}>
                {e.name}
              </option>
            ))}
          </datalist>
          </input> */}
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>
          }

        </p>
      </div>
    </div>
  );
};

const SaveCancelButton = (props) => {
  const { t: trans } = useTranslation();


  const { onClickTrack } = useAnalytics()
  return (
    <div className={`flex justify-center space-x-3  items-center  ${props?.isFrom == "Hobby" ? "mb-0 md:mb-[2rem] lg:mb-[2rem]"
      : props?.menu == "astroadd" ? " mt-[1rem] mb-0" : props?.type == "astropopup" ? "mt-[1rem] !mb-[1rem]" : " mt-[2rem] mb-[2rem]"}`}>
      {props?.isFrom == "Hobby" ?

        <button
          type="submit"
          onClick={props?.saveCallBack}
          
          className={`flex items-center justify-center text-white bg-[#D10A11] ${props?.disableSave?"cursor-not-allowed":"cursor-pointer"} border border-[#D10A11] md:w-[6rem] w-[9rem] whitespace-nowrap rounded-[5px] font-bold py-1 md:text-[12px] lg:text-[14px] `}
        >
          {props?.submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" /><span>{trans('my_profile.saving')}</span></> : <span className="truncate">{trans('my_profile.save')}</span>}
        </button>
        :
        props?.isFroms=="profileaddDetails"?

        <button
        type="submit"
        onClick={(e) => {
          if (props?.menu === "astroadd" || props?.type === "astropopup") {
            // props?.saveCallBack(e);
          }

        }}  className={`flex items-center justify-center text-white ${props?.disablesave ? "bg-[#E78387] cursor-not-allowed" : "bg-[#D10A11] "}
              border border-[#D10A11] lg:w-[6rem]   whitespace-nowrap bg-[#D10A11]
         rounded-[5px] font-bold py-1 md:text-[12px] lg:text-[14px] ${ props.editfromadmin == 'Y' ? 'cursor-not-allowed lg:w-[8rem]' : props.isFrom == "MyProfile" ? 'md:w-[8rem] w-[9rem]' : 'lg:w-[6rem] w-auto'}`} 
          disabled={ props?.disablesave}
      >
        {props?.submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" /><span>{trans('my_profile.saving')}</span></> : <span className="truncate">
          {trans('my_profile.save')}</span>}
      </button>
        :

        <button
          type="submit"
          onClick={(e) => {
            if (props?.menu === "astroadd" || props?.type === "astropopup") {
              // props?.saveCallBack(e);
            }

          }}          className={`flex items-center justify-center text-white ${props?.onchangedata || props?.onchangefamilydata ? "bg-[#E78387] cursor-not-allowed" : "bg-[#D10A11] "}
                border border-[#D10A11] lg:w-[6rem]  ${props?.disableSave?"cursor-not-allowed bg-[#E78387] ":"cursor-pointer bg-[#D10A11]"} whitespace-nowrap bg-[#D10A11]
           rounded-[5px] font-bold py-1 md:text-[12px] lg:text-[14px] ${ props.editfromadmin == 'Y' ? 'cursor-not-allowed lg:w-[8rem]' : props.isFrom == "MyProfile" ? 'md:w-[8rem] w-[9rem]' : 'lg:w-[6rem] w-auto'}`}  disabled={ props.editfromadmin == 'Y'}
        >
          {props?.submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" /><span>{trans('my_profile.saving')}</span></> : <span className="truncate">
            {trans('my_profile.save')}</span>}
        </button>
      }
      {props?.menu == "astroadd" || props?.type == "astropopup" ? "" :
        <button type="reset"
          onClick={props.cancelCallback} disabled={ props.editfromadmin == 'Y'}
          className={`flex items-center justify-center text-[#575556] bg-white border border-[#707070] whitespace-nowrap rounded-[5px] font-bold py-1 md:text-[12px] lg:text-[14px] ${ props.editfromadmin == 'Y' ? 'cursor-not-allowed lg:w-[8rem] w-[9rem]' : props.isFrom == "MyProfile" ? 'lg:w-[8rem] w-[9rem] px-2' : 'lg:w-[6rem] w-[9rem]'}`}
        >
          <OverflowToolTip as="p" className="truncate">
            {props.isFrom == "MyProfile" ? trans('my_profile.discard_changes') : trans('my_profile.cancel')}
          </OverflowToolTip>
        </button>
      }
    </div>
  );
};

const CropButton = (props) => {
  return (
    <button
      onClick={() => {
        props.onClick();
      }}
      className=" flex items-center justify-center w-[2.5rem] h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  py-[1rem] lg:py-5 text-[10px] md:text-[12px] lg:text-[14px]"
    >
      Crop
    </button>
  );
};
const EditButton = (props) => {

  const { t:trans } = useTranslation();

  useEffect(() => {
    console.log(props?.dashboard1Data?.editfromadmin, "props?.dashboard1Data");

  }, [props?.dashboard1Data])
  return (
    <div
      className=" flex justify-end pr-[1rem] mt-2 cursor-pointer"
      onClick={() => {
        // if(props?.dashboard1Data?.editfromadmin=="N"){
        props.editCallBack()

        // }
      }

      }
    >
      <div className="flex items-center justify-between ">
        <img alt="edit" className={`  w-[1.3rem]`} src={EditIcon} />
      </div>
      <p className={` md:text-[12px]  lg:text-[14px] flex items-center text-[#D10A11] font-semibold pl-2 `}>
      {trans('my_profile.edit')}
      </p>
    </div>
  );
};

const MyPlansTextView = (props) => {
  const isV3User = localStorage.getItem("isV3User")
  return (
    <>
      {isV3User ?
        <div className={` ${isV3User ? "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[14px] w-auto" : "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] "}`}>
          <OverflowToolTip as="p" className={` col-span-4 text-[#7A7A7A] bg-[${isV3User ? props.backgroundColor : "#F1F1F1"}] font-semibold pl-[1.5rem] items-center content-center h-[56px] md:min-h-min md:h-[36px]`}>
            {props.title}
          </OverflowToolTip>
          <p className={` col-span-5  text-[#575556]  bg-[${isV3User ? props.backgroundColor : "#F1F1F1"}] font-semibold pl-[1.5rem] flex items-center h-[56px] md:min-h-min md:h-[36px]`}>
            {props.value}
          </p>
        </div>
        :
        <div className={` ${isV3User ? "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[14px]" : "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] "}`}>
          <p className={`col-span-4 text-[#7A7A7A] bg-[#F1F1F1] font-semibold pl-[1.5rem] flex items-center h-[50px]`}>
            {props.title}
          </p>
          <p className={`col-span-5  text-[#575556]  bg-[${isV3User ? props.backgroundColor : "#F1F1F1"}] font-semibold pl-[1.5rem] flex items-center h-[50px]`}>
            {props.value}
          </p>
        </div>
      }
    </>
  );
};

export const MyPlansTextViewWithAction = ({ title, buttonText, onClick, backgroundColor, text }) => {
  const isV3User = localStorage.getItem("isV3User")
  return (
    <>
      {isV3User ?
        <div className={`${isV3User ? "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[14px]" : " grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] "}`}>
          <p className={`col-span-4 text-[#7A7A7A]  bg-[${backgroundColor}] font-semibold  pl-[1rem] lg:pl-[1.5rem] flex items-center h-[36px]`}>
            {title}
          </p>
          {/* //text-[#62ABFE] underline */}
          <div className="col-span-5 bg-[#F1F1F1] flex items-center justify-between h-[36px] pr-4 ">
            <p className="text-[#575556]  bg-[#F1F1F1] font-semibold  pl-[1rem] lg:pl-[1.5rem] flex items-center h-[36px]">
              {text}
            </p>
            <p onClick={onClick} className="cursor-pointer text-[#D10A11] font-bold">
              {buttonText}
            </p>
          </div>

        </div>
        :

        <div className={`${isV3User ? "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[14px]" : " grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] "}`}>
          <p className={`col-span-4 text-[#7A7A7A]  bg-[#F1F1F1] font-semibold pl-[1.5rem] flex items-center h-[50px]`}>
            {title}
          </p>
          {/* //text-[#62ABFE] underline */}
          <div className="col-span-5 bg-[#F1F1F1] flex items-center justify-end h-[50px] pr-4 pl-[1.5rem]">
            <p onClick={onClick} className="cursor-pointer text-[#D10A11] font-bold">
              {buttonText}
            </p>
          </div>

        </div>
      }
    </>
  );
};

const EduactionListTag = (props) => {
  return (
    props?.isFrom == "KM-lite" ?
      <div className="flex justify-center items-center border rounded-[8px] border-[#E9E9E9] bg-[#E9E9E9] gap-2 px-4 py-1.5">
        <div className=" font-semibold text-[#575556]">
          {props.text}
        </div>
        <div className="w-4 cursor-pointer">
          <img src={CloseIcon} alt="CloseIcon" className="w-4 h-4" onClick={() => props.callBackSingleSelect(props.id, false)} />
        </div>
      </div>
      :
      <div className="border rounded-[8px] border-[#D10A11] bg-[#D10A11] font-semibold text-white px-1 m-1">
        {props.text}
      </div>
  );
};

const StaticCircularProgressBar = ({
  value,
  profileStrengthRemaining,
  remainingProfileNav,
}) => {
  const radius = 45;
  const strokeWidth = 7;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (value / 100) * circumference;
  const [isShowDropDown, setSetShowDropDown] = useState(true);
  useEffect(() => { }, [isShowDropDown]);
  const { onClickTrack } = useAnalytics()

  const isV3User = localStorage.getItem("isV3User")

  return (
    <div className={isV3User ? "w-[60px] md:w-[100px] lg:w-[80px] xl:w-[100px] relative" : "w-[60px] lg:w-[100px] relative"}>
      <svg viewBox="0 0 100 100" style={{ transform: "rotate(-90deg)" }}>
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="none"
          stroke="#ddd"
          strokeWidth={strokeWidth}
        />
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="none"
          stroke="#039e3c"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progress}
        />
      </svg>
      {profileStrengthRemaining && (
        <div className=" text-[16px] md:text-[12px] lg:text-[16px]"
          style={{
            position: "absolute",
            top: "16%",
            left: "16%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold",

            // color: '#0074d9',
          }}
        // onMouseEnter={() => {
        //   setSetShowDropDown(true);
        // }}
        // onMouseLeave={() => {
        //   setSetShowDropDown(false);
        // }}

        >
          <button onClick={() => {
            setSetShowDropDown(true);
            onClickTrack(EventName.profileCompletionScoreButton, { [EventKeys.profiledropdown]: profileStrengthRemaining });
          }}>
            <img
              className=" w-4 h-4 md:w-[0.7rem] md:h-[0.7rem] lg:w-4 lg:h-4 cursor-pointer"
              alt=""
              src={profileGrayImage}
            />
          </button>

          <EditProfileDropDown
            isShowDropDown={isShowDropDown}
            profileList={profileStrengthRemaining}
            remainingProfileNav={remainingProfileNav}
            onClose={() => setSetShowDropDown(false)}
          />
        </div>
      )}
      <div
        className=" text-[16px] md:text-[12px] lg:text-[16px]"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontWeight: "bold",
          // fontSize: "16px",
          // color: '#0074d9',
        }}
      >
        {value}%
      </div>
    </div>
  );
};


const StaticlineProgressBar = ({
  value,
  profileStrengthRemaining,
  remainingProfileNav,
}) => {
  const radius = 45;
  const strokeWidth = 7;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (value / 100) * circumference;
  const [isShowDropDown, setSetShowDropDown] = useState(true);
  const isV3User = localStorage.getItem("isV3User");

  useEffect(() => { }, [isShowDropDown]);

  return (
    <div className="w-[19rem] lg:w-[100px] relative">

      {profileStrengthRemaining && (
        <div className="top-[0.2rem] w-[1.5rem] h-[1.5rem] text-[16px] md:text-[12px] lg:text-[16px]"
          style={{
            position: "absolute",

            left: isV3User ? "94%" : "100%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold",

            // color: '#0074d9',
          }}
        // onMouseEnter={() => {
        //   setSetShowDropDown(true);
        // }}
        // onMouseLeave={() => {
        //   setSetShowDropDown(false);
        // }}
        >
          <img className=" w-[1.4rem] h-[1.4rem] md:w-[0.7rem] md:h-[0.7rem] lg:w-4 lg:h-4" alt="" src={profileGrayImage} onClick={() => setSetShowDropDown(true)} />
          <EditProfileDropDown
            isShowDropDown={isShowDropDown}
            profileList={profileStrengthRemaining}
            remainingProfileNav={remainingProfileNav}
            onClose={() => setSetShowDropDown(false)}
          />
        </div>
      )}

    </div>
  );
};


const EditProfileDropDown = (props) => {
  const [isshown, setIsShown] = useState(props.isShowDropDown ?? false);
  useEffect(() => { setIsShown(props.isShowDropDown) }, [props.isShowDropDown]);

  return (
    <div
      id="dropdown"
      className={`${isshown ? "" : "hidden"
        } z-10 absolute right-1 top-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-[9rem] md:w-44 dark:bg-gray-700`}
    >
      <div className="relative">
        <ul
          className="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          {props?.profileList?.map((e, i) => (
            <li>
              <a
                onClick={() => {
                  // setIsShown(false);
                  props.remainingProfileNav(e);
                  props.onClose()
                }}
                className="block px-4 py-[2px] text-[10px] md:text-[14px] font-normal hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              >
                {e}
              </a>
            </li>
          ))}
        </ul>
        <button className="absolute top-[-4px] right-[-5px] w-4 h-4" onClick={props.onClose}><img src={CancelIcon} alt="" /></button>
      </div>
    </div>
  );
};

export const TextIconButton = ({ onClick, text = "", iconSrc = EditIcon, className = "" }) => {
  return (
    <button
      className={`flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <img alt="" className="w-[14px] h-[14px] md:w-[20px] md:h-[20px]" src={iconSrc} />
      <p className="text-[12px] md:text-[14px] flex items-center text-[#D10A11] font-semibold pl-2 ">
        {text}
      </p>
    </button>
  );
};



function RawSelectInput({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  placeholder,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur
}) {
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #8F8F8F",
      outline: "none",
      color: "#575556",

    }),
    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "white" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",

    }),
    singleValue: (base) => ({
      ...base,
      color: "#575556",

    }),
  };

  return (
    <>
      <Select
        isSearchable={true}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        blurInputOnSelect
        onBlur={onBlur}
        defaultValue={defaultValue}
        // menuIsOpen={true}
        className="text-[14px]"
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
        }}
        styles={customStyles}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
      {error && (
        <p className="text-[red] text-[12px]">{error}</p>
      )}
    </>
  );
}

export default RawSelectInput;

const HobbyListTag = (props) => {
  return (
    props?.isFrom == "show" ?
      <div className="border rounded-[8px] border-[#707070] text-[#575556] p-2 m-1 tex-[14px]">
        {props.text}
      </div>
      :
      <div className={`border rounded-[8px] border-[#707070]  p-2 m-1 text-[14px] cursor-pointer ${props?.selected == "Y" ? "bg-[#F9DADB] text-black" : "text-[#575556]"}`} onClick={props?.onClick}>
        {props.text}
      </div>
  );
};

const BasicTextViewWithImgHobby = (props) => {
  return (
    <div className="grid md:grid lg:grid md:grid-flow-row lg:grid-flow-row grid-cols-6 md:grid-cols-12 lg:grid-cols-12 gap-0 md:gap-[3rem] lg:gap-4">
      <div className="bg-[#D10A11] flex items-center justify-center md:col-span-1 lg:col-span-1 w-[2.4rem] h-[2.4rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem] rounded-[5rem] md:mx-1 lg:mx-3 my-4">
        <img className="w-6 h-6 md:w-6 md:h-6 lg:w-7 lg:h-7" src={props.image} alt="" />
      </div>
      <div className="pt-[1.2rem] md:pt-[1.2rem] lg:pt-[.9rem] px-0 md:px-4 lg:pl-4 leading-[17px] md:leading-[20px] lg:leading-[26px] col-span-5 md:col-span-8 lg:col-span-11 mp-habits-details -ml-[.2rem] md:ml-0 lg:ml-0 mp-hob-detail">
        <p className="text-[#575556] font-normal text-[12px] md:text-[13px] lg:text-[14px]">
          {props.title}
        </p>
        <span className="text-[#575556] text-[12px] md:text-[13px] lg:text-[14px] font-bold break-words">
          {commaWithSpace(props?.value)}
        </span>
      </div>
    </div>
  );
};

const BasicPinTextView = (props) => {
  //console.log(props.disabled, 'props.disabled')
  useEffect(() => {
    //console.log(props?.value,"othercaste");
  }, [props?.value])
  return (
    <div className="items-center md:p-0">
      <div className="col-span-9 md:col-span-4 ">
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
          {props.title}
          {props.required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className="col-span-9 md:col-span-5 ">
        <p className={props?.inputID === "parentNumber" ? "RegPhone" : ""}>
          <input
            type={props.inputType}
            id={props.inputID}
            name={props.inputName}
            value={props?.value}
            className={`border-[1px] border-[#D9D9D9] ${props?.value ? "highlight shadow-[0_0_6px_#F18181] rounded-[.5rem]" : ""} text-[14px] text-[#575556]  rounded-[6px] h-[40px]  block w-full p-[0.4rem] lg:p-2.5   ` + (props.className ?? '')}
            placeholder=""
            autoComplete="off"
            onChange={props.onChange}
            disabled={props?.disabled}
            onKeyDown={props?.onKeyDown}
          />
          {props.error && (
            <p className="text-[red] text-[12px]">{props.error}</p>
          )}
          {props?.inputID === "parentNumber" && (
            props?.phoneNumberError != "" && (
              <p className="text-[red] text-[12px]">{props.phoneNumberError}</p>
            )
          )}
        </p>
      </div>
    </div>
  );
};

export const SelectAstroInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
  label,
  type
}) => {

  const { t: trans } = useTranslation();

  console.log(error, "error");

  const OthersToEnd = (castes) => {
    if (!castes || !Array.isArray(castes)) {
      return [];
    }
    const othersIndex = castes.findIndex((caste) => caste.name === "Others");
    const othersOption = othersIndex !== -1 ? castes.splice(othersIndex, 1)[0] : null;
    if (othersOption) {
      castes.push(othersOption);
    }

    return castes;
  }

  const customStylesRaasi ={

    control: (base) => {
      return {
        ...base,
        border: "1px solid #8F8F8F",
        outline: "none",
        color: "#575556"
      }
    },

    singleValue: (base) => {
      return {
        ...base,
        color: "#575556"
      }
    },

    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "white" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",
      borderBottom: "1px solid #dcdcdc", // Adds the border-bottom to each option


    }),
  }

  const customStyles = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid #8F8F8F",
        outline: "none",
        color: "#575556"
      }
    },

    singleValue: (base) => {
      return {
        ...base,
        color: "#575556"
      }
    },

    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "white" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",


    }),

  };
  return (
    <div className={`   md:mt-[2rem] ${menu == "packastro" ? "" : "grid grid-cols-9"} mt-4    `}>
      <div className={`col-span-9  md:col-span-4 flex ${menu == "packastro" ? "md:pl-[1rem] lg:pl-[1rem]" : "md:pl-[1rem] lg:pl-[2rem]"}  items-center   `}>
        <p className={` flex  ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" : "text-[#7A7A7A] md:text-[12px] lg:text-[14px]"}  font-semibold  `}>
          {title} {isFrom == "requestStarMessage" ? <></> : <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className={`col-span-9  md:col-span-5 ${menu == "packastro" ? "md:pr-[1rem] lg:pr-[1rem]" : "md:pr-[1rem] lg:pr-[4rem]"}    md:pl-[1rem]`}>
        <p className="">
          <div className="flex ">
            <label className={`font-bold ${menu == "packastro" ? "md:text-[12px]  lg:text-[12px]" : "md:text-[12px]  lg:text-[14px]"} w-full `}>
              <Select
                isSearchable={true}
                placeholder={title == trans('view_profile.raasi') ? options ? options?.length == 2 ? options[1].name : trans('trial_pack_popup__family_detai.select') : trans('trial_pack_popup__family_detai.select') :trans('trial_pack_popup__family_detai.select')}
                // placeholder={label == "Raasi" ? options ? options?.length == 2 ? options[1].name : "Select" : "Select" : "Select"}
                name={name}
                value={value === undefined ? "null" : value}
                onChange={onChange}
                options={OthersToEnd(options)}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                styles={type=="addRassi"?customStylesRaasi :customStyles}

                // menuIsOpen={true}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
                }}
                // styles={{
                //   control:(base)=>{
                //     return {
                //       ...base,
                //       border:"1px solid #8F8F8F",
                //       outline:"none",
                //       color:"#575556"
                //     }
                //   },
                //   option:(base,optionState)=>{
                //     return {
                //       ...base,
                //       color:optionState.isSelected ?"white":"#575556"
                //     }
                //   },
                //   singleValue:(base)=>{
                //     return {
                //       ...base,
                //       color:"#575556"
                //     }
                //   },
                // }}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />

              {error && (
                <p className="text-[red] text-[12px]">{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  );
};

export const SelectHobbyInput = (props) => {

  const { t:trans } = useTranslation();

  console.log(props.value, "props.valueprops.value");

  const isV3User = localStorage.getItem("isV3User")
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {

  }, [props.placeholder])

  console.log(props.isFrom,'props.isFrom');

  return (
    (props?.isFrom == "hobbiesdetails" && isMobile ? 
    <div className="flex gap-3 items-center  mb-[2rem]">
        <img className="w-[2.5rem] h-[2.5rem]" src={props?.icon}/>
        <div className={`relative w-full  md:hidden`}>
          <p className={` absolute -top-3 left-3 bg-white px-1 text-[11px] text-[#575556]  transition-all duration-200 
          peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
        peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
          peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}>
            {props?.title}
            {props?.title == trans('view_profile.raasi') || props?.title == trans('view_profile.star') || props?.title == trans('my_profile.chevvai_doshammanglik') ?
              "" :  props?.required  && <span className="text-[#D10A11] ml-1"> *</span>}
          </p>
          <label className={` pepeer block w-full border-[1px] border-[#8F8F8F] rounded-[3px] bg-transparent px-2 py-1 text-gray-900 `}>
            <SelectDropdownUtil
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              placeholder={props.placeholder != null ? props.placeholder : `${trans('my_profile.any')} ${props.title}`}
              onChange={props.onChange}
              option={props?.options}
              styles={"border-none outline-none"}
            // mode={"multiple"}
              isDisabled={props.disabled}
            />

            {props?.error && (
              <p className={`text-[red] text-[12px]`}>{props?.error}</p>
            )}
          </label>
        </div>
      </div>
     :
    <div className={`  ${props.isFrom === "trailpack" ? 'pt-2 pb-2' :props?.isFrom =="hobbies" ? "md:mt-[2rem] md:grid md:grid-cols-9 px-[.3rem]  md:px-[1rem] lg:px-[1.4rem] mt-4  justify-center items-center  space-y-2 md:space-y-0":
       isV3User ? "md:grid md:grid-cols-9 px-[.3rem]  md:px-[1.4rem] lg:px-[1.4rem] mt-4 md:mt-[1rem] justify-center items-center  space-y-2 md:space-y-0" 
     :  "md:grid md:grid-cols-9 mt-4 md:mt-[2rem] justify-center items-center  space-y-2 md:space-y-0"} `}>

      <div
        className={`${props?.page === "search" ? "col-span-2 flex items-center pr-[0.1rem] "
          : props.page == 'physical' ? `md:col-span-3 lg:col-span-4 col-span-9 
          : props?.isFrom ==="hobbies" ?"md:col-span-2 lg:col-span-2 col-span-9 ":"md:col-span-3 lg:col-span-4 col-span-9 "} 
          ${props?.inputName=="sportsFitnessActivitiesGames"?"w-[102%]":""} ${isV3User ? "lg:pl-[1.4rem]" : "lg:pl-[1rem]"}  ` :  
           props?.isFrom ==="hobbies" ?`md:col-span-2 md:col-span-3 xl:col-span-2 xl:col-span-2 col-span-9 `:`md:col-span-3 lg:col-span-4 col-span-9 `}`} >
        {/* <p className={isV3User ? `md:text-[12px] lg:text-[14px] flex  ${props?.inputName=="moviesWatch"?"whitespace-nowrap":""}  ${props.isFrom === "trailpack" ? 'pb-2 lg:px-4 font-bold text-[#000000]' : ' text-[#575556] font-semibold'} ` : " md:text-[12px] lg:text-[14px] flex "}>
       <OverflowToolTip as="p" 
       className={isV3User ? `md:text-[12px] lg:text-[14px] flex  truncate ${props?.inputName=="moviesWatch"?"":""} 
        ${props.isFrom === "trailpack" ? 'pb-2 lg:px-4 font-bold text-[#000000]' : ' text-[#575556] font-semibold'} ` : " md:text-[12px] lg:text-[14px] flex "}
      > 
        
          {props.isFrom == "trailpack" ? (
            <>
              {props.title}<span className="text-[#D10A11]">*</span>
            </>
          ) : (
            props.title
          )}
        </p> */}
        {/* {props.isFrom == "trailpack" ?  */}
          {props?.inputName=="moviesWatch" &&props?.isFrom=="hobbies"?
          
            <OverflowToolTip as='p' className={isV3User ? `md:text-[12px] lg:text-[14px]  
           ${props.isFrom === "trailpack" ? 'pb-2 lg:px-4 font-bold text-[#000000] truncate' : 
           props?.isFrom=="hobbies"?'text-[#575556] font-semibold ':' text-[#575556] font-semibold truncate'} ` : " md:text-[12px] lg:text-[14px] flex "}>
            <>
              {props.isFrom == "trailpack" ? (
            <>
              {props.title}<span className="text-[#D10A11]">*</span>
            </>
          ) : (

             props?.isFrom=="hobbies"?
              <div className="flex gap-3 items-center">
                <img className="w-[2.5rem] h-[2.5rem]" src={props.icon}/>
                {props.title}
                </div>  : props.title 
          )}
            </>
          </OverflowToolTip>  :
          <OverflowToolTip as='p' className={isV3User ? `md:text-[12px] lg:text-[14px]   ${props?.inputName=="moviesWatch"?""
            : ""} 
             ${props.isFrom === "trailpack" ? 'pb-2 lg:px-4 font-bold text-[#000000] truncate' : 
             props?.isFrom=="hobbies"?'text-[#575556] font-semibold ':' text-[#575556] font-semibold truncate'} ` : " md:text-[12px] lg:text-[14px] flex "}>
              <>
                {props.isFrom == "trailpack" ? (
              <>
                {props.title}<span className="text-[#D10A11]">*</span>
              </>
            ) : (
  
               props?.isFrom=="hobbies"?
                <div className="flex gap-3 items-center">
                  <img className="w-[2.5rem] h-[2.5rem]" src={props.icon}/>
                  {props.title}
                  </div>  : props.title 
            )}
              </>
            </OverflowToolTip>  }
        
       
      </div>
      <div
        className={`${props?.page === "search" ? "col-span-7 "
          : props.page == 'physical' ? `md:col-span-6 lg:col-span-5 col-span-9
           ${isV3User ? "md:pl-[1.2rem] lg:pl-[2rem]" : "md:pl-[1.2rem] lg:pl-[2rem]"} ` :  props?.isFrom =="hobbies" ?`col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6     md:pl-[3.5rem] pl-[6rem] xl:pl-[6rem] md:pr-0 pr-[3rem]  lg:pr-[8rem] lg:pl-[2rem] xl:pr-[3rem] col-span-9 `:`md:col-span-6 lg:col-span-5 col-span-9 lg:pr-[1rem]   lg:pl-[1rem]`}`}
      >
        <div className="flex ">
          <label className=" select font-bold text-[#575556] w-full ">

            <SelectDropdownUtil
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              placeholder={props.placeholder != null ? props.placeholder : `${trans('my_profile.any')} ${props.title}`}
              onChange={props.onChange}
              option={props?.options}
            // mode={"multiple"}
              isDisabled={props.disabled}
            />
          </label>
        </div>
      </div>
    </div>
    )
  );
};

const SelectOccupationWithHeading = (props) => {
  const { t: trans } = useTranslation();

  return (
    <div className={` ${(props?.fieldType >= 27 && props?.fieldType <= 30) || props?.fieldType == 44 ? "  md:mt-[2rem]  mt-4" : props?.isFrom == "additionalFields" ? "md:mt-[1rem] lg:mt-[1rem] xl:mt-[1.3rem]  mt-4" : props?.fieldType == 19 ? '' : "grid grid-cols-9 md:mt-[2rem]  mt-4"}    `}>
      <div className={`col-span-9 md:col-span-4  md:pl-[1rem] lg:pl-[1rem]`}>
        <p className={`  text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold ${props?.isFrom == "additionalFields" ? '' : 'pb-1'}`}>
          {props?.title}
        </p>
      </div>
      <div className={`col-span-9 md:col-span-5 md:px-[1rem] mt-1`}>
        <p className="">
          <div className="flex ">
            <label className={` ${(props?.fieldType >= 67 && props?.fieldType <= 84) ? "AdditionalFamily md:text-[12px] lg:text-[13px] xl:text-[15px] text-[#575556]" : "md:text-[12px]  lg:text-[14px] font-bold"} w-full `}>                <select
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              onChange={props.onChange}
              onSelect={props.onChange}
              className="w-full md:p-[0.4rem] lg:px-2.5 h-[35px]  border border-[#8F8F8F] text-[#575556] md:text-[12px] lg:text-[14px] bg-white rounded-[3px] lite-scrollbar"
            >
              <option value="" disabled>{(props.fieldType >= 67 && props.fieldType <=75 ) ? props.placeholder : trans('my_profile.select')}</option>
              {props?.options?.map((e, i) =>
                e.heading === "Y" ? (
                  <option key={e.id} value={e.id} className="font-bold " disabled>
                    {e.name}
                  </option>
                ) : (
                  <option key={e.id} value={e.id} className="ml-4 ">
                    {e.name}
                  </option>
                )
              )}
            </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  );
};

export const SelectNativeInput =(props)  => {
  const {t: trans} = useTranslation()
  return (
    // <div className={`${'font-bold  md:text-[12px] lg:text-[14px] lg:w-[8rem] md:w-[6rem] w-[7rem]'  }  `}>
    //   <Select
    //       showSearch
    //       name={name}
    //       mode={mode}
    //       value={formattedOptions?.find(e => e.value == value)}
    //       placeholder={placeholder}
    //       options={formattedOptions}
    //       optionFilterProp='label'
    //       onChange={onChange}
    //       disabled={disabled} 
    //       components={{
    //         IndicatorSeparator: () => null,
    //         DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
    //       }}
    //       styles={{
    //         control: (base) => {
    //           return {
    //             ...base,
    //             border: "1px solid #8F8F8F",
    //             outline: "none",
    //             color: "#575556"
    //           }
    //         },
    //         option: (base, optionState) => ({
    //           ...base,
    //           color: optionState.isSelected ? "white" : "#575556",
    //           backgroundColor: optionState.isDisabled ? "#f0f0f0" : base.backgroundColor, // Grey out the disabled option
    //           cursor: optionState.isDisabled ? "not-allowed" : "pointer" // Make sure the cursor shows disabled for disabled options
    //         }),
    //         singleValue: (base) => {
    //           return {
    //             ...base,
    //             color: "#575556"
    //           }
    //         },
    //       }}
    //   />
    //   {/* <div className="absolute inset-y-0 top-0.5 right-1.5 flex items-center px-2 pointer-events-none">
    //     <img className="w-2.5 h-2.5" src={Images.selectarrow} alt="" />
    //   </div> */}
    //   {error && (
    //     <p className="text-[red] text-[12px]">{error}</p>
    //   )}
    // </div>

    <p className="">
      <div className={`flex  ${props.isFrom=='additional'? 'lg:w-[6rem] md:w-[4rem] w-[6rem]' : props?.classNames ? props?.classNames: 'lg:w-[8rem] md:w-[6rem] w-[8rem]'}`}>
        <label className=" select font-bold text-[#575556] w-full ">
          <select
            name={props.inputName}
            value={props.value}
            id={props.inputID}
            onChange={props.onChange}
            onSelect={props.onChange}
            disabled={props?.disabled}
            placeholder={trans('my_profile.villagetowndistrictcity')}
            className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props?.disabled ? "!cursor-not-allowed" : "cursor-pointer"}`}
          >
            <option value="" disabled>
              <OverflowToolTip as='p' className='lg:w-[7rem] md:w-[5rem] w-[7rem] truncate'>{trans('my_profile.villagetowndistrictcity')}</OverflowToolTip>
            </option>
            {props?.options && props?.options?.map((e, i) =>

                <option key={e.locationId} value={e.locationId} className="font-bold ">
                  {e.locationName}
                </option>
              
            )}
          </select>
          {props.error && (
            <p className="text-[red] text-[12px]">{props.error}</p>
          )}
        </label>
      </div>
    </p>

  )
}

export const FloatingSelectInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
  fieldType,
  disabled,
  isFromReq,
  icon,
  customClassName
}) => { 

  const { t: trans } = useTranslation();

  console.log(isFromReq,'isFromReq');
  

  const OthersToEnd = (castes) => {
    if (!castes || !Array.isArray(castes)) {
      return [];
    }
    const othersIndex = castes.findIndex((caste) => caste.name === "Others");
    const othersOption = othersIndex !== -1 ? castes.splice(othersIndex, 1)[0] : null;
    if (othersOption) {
      castes.push(othersOption);
    }

    return castes;
  }

  const customStyles = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid #8F8F8F",
        outline: "none",
        color: "#575556"
      }
    },

    singleValue: (base) => {
      return {
        ...base,
        color: "#575556"
      }
    },

    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#F7F8FA" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",

    }),

  };

  console.log(defaultValue,'defaultValue');
  const isMobile = useMediaQuery({ maxWidth: 678 });
  
  const removeBorder = {
    control: (provided, state) => ({
      ...provided,
      border: "none", // Remove the default border
      boxShadow: "none", // Remove the default focus outline (if needed)
    "&:hover": {
      border: "none", // Ensure the border doesn't appear on hover
    },
    }),
  };
  return (
    <>
      <div className={`flex md:hidden gap-3 items-center  mb-[2rem] ${customClassName ? customClassName : ""}`}>
        <img className="w-[2.5rem] h-[2.5rem]" src={icon}/>
        <div className={`relative w-full md:hidden`}>
          <p className={` absolute -top-3 left-3 bg-white px-1 text-[11px] text-[#575556]  md:text-[12px] lg:text-[14px] md:text-[#7A7A7A] flex  md:font-semibold  transition-all duration-200 
          peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
        peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
          peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}>
            {title}
            {title == trans('view_profile.raasi') || title == trans('view_profile.star') || title == trans('my_profile.chevvai_doshammanglik') ?
              "" : menu == "packastro" ?
                required && <span className="text-[#D10A11] ml-1"> *</span> : required  && <span className="text-[#D10A11] ml-1"> *</span>}
          </p>
          <label className={` pepeer block w-full border-[1px] border-[#8F8F8F] rounded-[3px] bg-transparent md:px-4 py-1 text-gray-900 `}>
            <Select
              isSearchable={true}
              placeholder={title == trans('view_profile.raasi') ? options ? options?.length == 2 ? options[1].name : trans('trial_pack_popup__family_detai.select')  : trans('trial_pack_popup__family_detai.select')  : trans('trial_pack_popup__family_detai.select') }
              name={name}
              value={value === undefined ? "null" : value}
              onChange={onChange}
              options={OthersToEnd(options)}
              blurInputOnSelect
              onBlur={onBlur}
              defaultValue={defaultValue}
              styles={removeBorder}
              // menuIsOpen={true}
              isDisabled={disabled}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
              }}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
            />

              {/* {error && (
                <p className={`text-[red] text-[12px] ${fieldType == 19 ? 'pt-2' : ''}`}>{error}</p>
              )} */}
          </label>
          {error && (
              <p className={`text-[red] text-[12px] ${fieldType == 19 ? 'pt-2' : ''}`}>{error}</p>
            )}
        </div>
      </div>
    <div className={`hidden md:grid grid-cols-9 md:mt-[2rem]  mt-4`}>
      <div className={`col-span-9  xl:col-span-2 md:col-span-3 lg:col-span-3  flex md:pl-[1rem] lg:pl-[2rem]  items-center`}>
      <div className="flex gap-3 items-center  md:text-[12px] lg:text-[14px] text-[#7A7A7A] flex  font-semibold ">
              <img className="w-[2.5rem] h-[2.5rem]" src={icon}/>
              {title}
      </div>
      </div>
      <div className={`col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6    md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  lg:pr-[10rem] lg:pl-[3rem] xl:pl-[7rem]`}>
        <p className="">
          <div className="flex ">
            <label className={` ${menu == "packastro" ? "md:text-[12px]  lg:text-[12px] font-bold" : (fieldType >= 67 && fieldType <= 84) || (fieldType >= 93 && fieldType <= 102) ? "AdditionalFamily md:text-[12px] lg:text-[13px] xl:text-[15px] text-[#575556]" :  disabled ? 'cursor-not-allowed md:text-[12px]  lg:text-[14px] font-bold' : "md:text-[12px]  lg:text-[14px] font-bold"} w-full `}>
              <Select
                isSearchable={true}
                placeholder={title == trans('view_profile.raasi') ? options ? options?.length == 2 ? options[1].name : trans('trial_pack_popup__family_detai.select')  : trans('trial_pack_popup__family_detai.select')  : trans('trial_pack_popup__family_detai.select') }
                name={name}
                value={value === undefined ? "null" : value}
                onChange={onChange}
                options={OthersToEnd(options)}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                styles={customStyles}
                // menuIsOpen={true}
                isDisabled={disabled}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
                }}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />

              {error && (
                <p className={`text-[red] text-[12px] ${fieldType == 19 ? 'pt-2' : ''}`}>{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
    </>
  );
};

export {
  StaticCircularProgressBar,
  EduactionListTag,
  SelectViewWithHeading,
  HoroscopeSearchView,
  HoroscopeEditTextView,
  HoroscopeSelectView,
  CropButton,
  MyPlansTextView,
  EditButton,
  BasicTextViewWithImg,
  SaveCancelButton,
  ProfileMenuItem,
  BasicTextView,
  BasicEditTextView,
  BasiSelectView,
  StaticlineProgressBar,
  RawSelectInput,
  HobbyListTag,
  BasicTextViewWithImgHobby,
  BasicPinTextView,
  SelectOccupationWithHeading
};

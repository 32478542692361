import React, { useContext } from 'react'
import { Drawer } from "antd"
import { NavBarContext } from '../Contexts/NavBarContext'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { TbArrowBigLeftFilled } from "react-icons/tb"
import { Disclosure } from '@headlessui/react'
import { GoChevronUp } from "react-icons/go"
import { BsHeadset } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import RouteSegments, { RouteHelper } from '../Routes/RouteSegment'
import Constants, { InboxFilters } from '../Constants/constants'
import AppStoreIcon from "../Images/appstore.png"
import GPlayIcon from "../Images/googleplay.png"
import kalyanJewellery from "../Images/kalyan_logo.svg"
import { SwitchBg, SwitchIcon } from './Kalyanlite/assets/images'
import { LoginContext } from '../Contexts/LoginContext'
import EndPoints from '../Constants/EndPoints'
import config from '../config'
import { CMS_Login_via } from '../Helpers/api_helpers'
import { GET } from '../Services/api_services'
import { useTranslation } from 'react-i18next'

const menuOptions = [
    {
        title: "Matches",
        leadingSrc: "/Assets/Images/MatchesIcon.svg",
        menuItems: [
            {
                id: 1,
                title: "Weekly Matches",
                to: `${RouteSegments.DASHBOARD}${RouteSegments.WEEKLYVIEWALL}`,
                state: { isfrom: "weekly" },
            },
            {
                id: 2,
                title: "Recommend Matches",
                to: `${RouteSegments.DASHBOARD}${RouteSegments.RECOMMENDED}`,
                state: { isfrom: "recommended" },
            },
            {
                id: 3,
                // title: "Profiles I am looking for",
                title : "Profiles Matching the Partner Expectation",
                to: `${RouteSegments.DASHBOARD}${RouteSegments.PROFILELOOKINGFOR}`,
                state: { isfrom: "lookingfor" },
            },
            {
                id: 4,
                title: "Profiles looking for me",
                to: `${RouteSegments.DASHBOARD}${RouteSegments.PROFILELOOKINGFORME}`,
                state: { isfrom: "lookingforme" },
            },
            {
                id: 5,
                title: "Mutual Matches",
                to: `${RouteSegments.DASHBOARD}${RouteSegments.MUTUALMATCHES}`,
                state: { isfrom: "mutual" },
            },
            {
                id: 6,
                title: "Selected Profiles",
                to: `${RouteSegments.DASHBOARD}${RouteSegments.SELECTEDPROFILES}`,
                state: { isfrom: "selectedprofile" },
            },
        ]
    },
    {
        title: "Search",
        leadingSrc: "/Assets/Images/SearchIcon.svg",
        menuItems: [
            {
                id: 7,
                title: "Basic Search",
                to: `${RouteSegments.searchTab(1)}`,
            },
            {
                id: 8,
                title: "Advanced Search",
                to: `${RouteSegments.searchTab(2)}`,
            },
        ]
    },
    {
        title: "Inbox",
        leadingSrc: "/Assets/Images/InboxIcon.svg",
        menuItems: [
            {
                id: 9,
                title: "Message",
                to: RouteHelper.getInboxByTab(InboxFilters.message[0].urlPath),
                // state: { menu: "MESSAGE", subMenu: 0 },
            },
            {
                id: 10,
                title: "Requests Received",
                to: RouteHelper.getInboxByTab(InboxFilters.requestReceived[0].urlPath),
                // state: { menu: "REQUESTRECEIVED", subMenu: 0 },
            },
            {
                id: 11,
                title: "Sent Requests",
                to: RouteHelper.getInboxByTab(InboxFilters.sentRequest[0].urlPath),
                // state: { menu: "REQUESTSENT", subMenu: 0 },
            },
        ]
    },
    {
        title: "Edit Profile",
        leadingSrc: "/Assets/Images/EditProfileIcon.svg",
        menuItems: [
            {
                id: 12,
                title: "My Profile",
                to: `${RouteSegments.editProfileTab(1)}`,
            },
            {
                id: 13,
                title: "Partner Preference",
                to: `${RouteSegments.editProfileTab(2)}`,
            },
            {
                id: 14,
                title: "Photo",
                to: `${RouteSegments.editProfileTab(3)}`,
            },
            {
                id: 15,
                title: "Trust Mark",
                to: `${RouteSegments.editProfileTab(4)}`,
            },
            {
                id: 16,
                title: "Horoscope",
                to: `${RouteSegments.editProfileTab(5)}`,
            },
            {
                id: 17,
                title: "Settings",
                to: `${RouteSegments.editProfileTab(6)}`,
            },
            {
                id: 18,
                title: "My plan",
                to: `${RouteSegments.editProfileTab(7)}`,
            },
            {
                id: 19,
                title: "Profiles viewed by me",
                to: `${RouteSegments.editProfileTab(8)}`,
            },
            {
                id: 20,
                title: "Who viewed my profile",
                to: `${RouteSegments.editProfileTab(9)}`,
            },
            {
                id: 21,
                title: "Phone numbers viewed by me",
                to: `${RouteSegments.editProfileTab(10)}`,
            },
            {
                id: 22,
                title: "Who viewed my phone number",
                to: `${RouteSegments.editProfileTab(11)}`,
            },
        ]
    },
]

export const DrawerItem = ({ onClick, children }) => {
    return <div onClick={onClick} className="cursor-pointer text-[12px] text-[#575556] hover:text-[#575556] py-1 ml-[48px]">{children}</div>
}

export function DrawerAccordian({ item, onItemSelect, children = null }) {
    return (
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2">
                        <div className='flex items-center'>
                            <img alt='matches' className='w-8 h-8' src={item.leadingSrc} />
                            <span className='pl-4 pr-2 text-[14px] font-medium text-[#D10A11]'>{item.title}</span>
                        </div>
                        <GoChevronUp
                            size={20}
                            color='#D10A11'
                            className={`${open ? 'rotate-180 transform' : ''}`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4">
                        {
                            children != null
                                ? <div className='ml-[48px]'>{children}</div>
                                : item.menuItems.map(menuItem => (<DrawerItem onClick={() => onItemSelect(menuItem)}>
                                    {menuItem.title}
                                    </DrawerItem>))
                        }
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}


function Sidebar({ profileData, onLogout }) {

    const { isDrawerOpen, openDrawer, closeDrawer } = useContext(NavBarContext)
    const navigate = useNavigate()

    const { t: trans } = useTranslation();
    
    const { loginDetail } = useContext(LoginContext);

    const onItemSelect = (item) => {
        navigate(item.to, { state: item.state })
        closeDrawer()
    }

    //console.log('profileData', profileData);

    const onPayNowClick = () => {
        if (profileData?.isPaid === "Y") {
            navigate(RouteSegments.editProfileTab(7))
        } else {
            navigate(RouteSegments.MEMBERSHIP_REGISTER())
        }
        closeDrawer()
    }

    const onLogoutClick = ()=>{
        onLogout()
        closeDrawer()
    }

    const onStoreClick = () => {
        window.open(
          RouteSegments.JEWELLERY_MICROSITE,
          '_blank' // <- This is what makes it open in a new window.
        );    
        // navigate(RouteSegments.JEWELLERY_MICROSITE, true);
      }


    const switchApicall = async () => {
        await GET(
            `${config.api.API_URL}${EndPoints.switchtonewversion()}`
        );

        const storedToken = localStorage.getItem(Constants.loginLocalStorageKeys.accessToken);

        const cms_response = await CMS_Login_via({

            
            jwt_token: storedToken
        })


        console.log({ cms_response });
        if (cms_response.data) {

            const token = cms_response?.headers?.['authorization']; // Extract token

            if (token) {
              localStorage.setItem(Constants.loginLocalStorageKeys.accessToken, token); // Store token
            }
            
  
            
              console.log(token,"tokentoken" ,localStorage.setItem(Constants.loginLocalStorageKeys.accessToken, token));
              
            console.log(cms_response, "cms_response");
            localStorage.setItem(
                Constants.loginLocalStorageKeys.chatLogin,
                String(cms_response?.data?.access_token) ?? "OLD"
            );
            localStorage.setItem(
                Constants.loginLocalStorageKeys.chatLoginId,
                String(cms_response?.data?.id) ?? ""
            );
        }
    }

    const isV3User = localStorage.getItem("isV3User")
    const onSwitchClick = async () => {
        if (isV3User) {
            localStorage.removeItem("isV3User")
            navigate(RouteSegments.DASHBOARD)
        } else {
            localStorage.setItem("isV3User", "true")
            await switchApicall()
            closeDrawer()
            navigate(RouteSegments.MATCH_LITE)
        }
    }

    return (
        <Drawer
            placement="left"
            closable={false}
            width="75%"
            bodyStyle={{ padding: "0", backgroundColor: "#D10A11" }}
            onClose={closeDrawer}
            open={isDrawerOpen}>
            <div className='flex flex-col h-screen'>
                <div className=''>
                    <div className='flex py-4 pl-4 justify-between'>
                        <TbArrowBigLeftFilled onClick={closeDrawer} size={26} color='#FEBABA' />
                        <div onClick={onSwitchClick} className="relative mr-[22px] cursor-pointer">
                            <img alt="switch" src={SwitchBg} className="h-[26px]" />
                            <img alt="switch-icon" src={SwitchIcon} className="absolute -left-[28px] -top-[8px] h-[36px]" />
                            <p className="text-[10px] italic font-bold font-segeo absolute left-[22px] right-0 top-[8px] switch-to-new">Switch to New</p>
                        </div>
                    </div>
                    <div className="flex-shrink-0 justify-center space-x-4 pb-4 flex items-center ">
                        <div className='w-[75px] h-[75px]'>
                            <Link href="#">
                                <img className="max-w-full max-h-full rounded-lg" src={profileData?.profile_imageurl} alt="" />
                            </Link>
                        </div>
                        <div>
                            <p className="text-white text-[18px] font-segeo font-bold flex justify-center items-center ">{profileData?.username} </p>
                            <p className="text-white text-[14px] font-segeo font-bold flex justify-center items-center">{profileData?.smProfileId}</p>
                        </div>
                    </div>
                </div>
                <div className='bg-white rounded-tl-[30px] pt-4 flex-grow'>
                    {
                        menuOptions.map(drawerItem => (<DrawerAccordian onItemSelect={onItemSelect} item={drawerItem} />))
                    }
                    <Disclosure>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2">
                                    <div className='flex items-center'>
                                        <img alt='matches' className='w-8 h-8' src="/Assets/Images/HelpIcon.svg" />
                                        <span className='pl-4 pr-2 text-[14px] font-medium text-[#D10A11]'>Help & FAQ</span>
                                    </div>
                                    <GoChevronUp
                                        size={20}
                                        color='#D10A11'
                                        className={`${open ? 'rotate-180 transform' : ''}`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4">
                                    <div className='ml-[48px]'>
                                        <div className='text-[12px] text-[#575556]'>To Contact the Partner</div>
                                        <button onClick={onPayNowClick} className='text-white text-[12px] font-semibold bg-[#D10A11] px-4 py-0.5 mt-1 rounded'>{profileData?.isPaid === "Y" ? "My Plan" : trans('matches.pay_now')}</button>

                                        <div className='flex items-center mt-4'>
                                            <div><BsHeadset color='#FEBABA' size={18} /></div>
                                            <div className='text-[12px] text-[#575556] pl-2'>For Help & Support</div>
                                        </div>
                                        <div className='text-[12px] text-[#D10A11] font-bold'>
                                            <a href={`tel:+${Constants.helpLinePhNo}`}>{Constants.helpLinePhNo}</a>
                                            </div>

                                        <div className='flex items-center mt-4'>
                                            <div><MdEmail color='#FEBABA' size={18} /></div>
                                            <div className='text-[12px] text-[#575556] pl-2'>Mail ID</div>
                                        </div>
                                        <div className='text-[12px] text-[#D10A11] font-bold'>
                                            {/* {Constants.helpLineMailId} */}
                                            <a href={`mailto:${Constants.helpLineMailId}`}>{Constants.helpLineMailId}</a>
                                            </div>
                                    </div>
                                </Disclosure.Panel>

                            </>
                        )}
                    </Disclosure>

                    <div onClick={onStoreClick} className="flex items-center justify-between w-full px-4 py-2 cursor-pointer">
                        <div className='flex items-center'>
                            <img alt='matches' className='w-8 h-8' src={kalyanJewellery} />
                            <span className='pl-4 pr-2 text-[14px] font-medium text-[#D10A11]'>Store</span>
                        </div>
                    </div>
                    
                    <div onClick={onLogoutClick} className="flex items-center justify-between w-full px-4 py-2 cursor-pointer">
                        <div className='flex items-center'>
                            <img alt='matches' className='w-8 h-8' src="/Assets/Images/LogoutIcon.svg" />
                            <span className='pl-4 pr-2 text-[14px] font-medium text-[#D10A11]'>Logout</span>
                        </div>
                    </div>

                    {/* <Disclosure>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2">
                                    <div className='flex items-center'>
                                        <img alt='matches' className='w-8 h-8' src="/Assets/Images/LogoutIcon.svg" />
                                        <span className='pl-4 pr-2 text-[14px] font-medium text-[#D10A11]'>Logout</span>
                                    </div>
                                    <GoChevronUp
                                        size={20}
                                        color='#D10A11'
                                        className={`${open ? 'rotate-180 transform' : ''}`}
                                    />
                                </Disclosure.Button>
                            </>
                        )}
                    </Disclosure> */}
                    <div className="mt-5 mb-4 px-3">
                        <p className=" text-center items-center  justify-center text-[16px] md:text-[11px] lg:text-[14px]  flex  text-[#575556] font-semibold">{trans('matches.download_our_app')}</p>
                        <div className="flex space-x-2 md:space-x-3 md:mt-[0.3rem] lg:mt-0">
                            <a href={RouteSegments.staticLinkForAppDownload.android} target="_blank">
                                
                            <img className='w-[8rem] lg:w-[8.5rem] xl:w-[8.5rem]' src={GPlayIcon} alt="" />
                            </a>
                            <a href={RouteSegments.staticLinkForAppDownload.ios} target="_blank">
                                
                            <img className='w-[8rem] lg:w-[8.5rem] xl:w-[8.5rem]' src={AppStoreIcon} alt="" />
                            </a>
                        </div>
                                                    
                        </div>




                </div>

            </div>
        </Drawer>
    )
}

export default Sidebar
import React, { useContext, useEffect, useState } from 'react'
import FamilyComponent from '../../../Components/Edit Profile/MyProfileComponents/FamilyComponent'
import EndPoints from '../../../Constants/EndPoints';
import config from '../../../config';
import { GET } from '../../../Services/api_services';
import { LoginContext } from '../../../Contexts/LoginContext';

function PreviewFamilyForm({ editMyProfileData, ProfileData, apiCallEditProfile }) {
    const { loginDetail, logout } = useContext(LoginContext);

    const [familyinfofreshData, setfamilyinfofresh] = useState();

    useEffect(() => {
        const apiFamilyCallDropDown = async (type) => {

            let url = "";

            url = `${config.api.API_URL}${EndPoints.familyinfofreshUrl()}`;

            let { statusCode, data } = await GET(url);
            //console.log("dropdown data ", data);
            if (statusCode === 200) {
                setfamilyinfofresh(data.data);
            } else if (statusCode === 401) {
                logout();
            }

        };
        apiFamilyCallDropDown()
    }, [])


    return (
    <FamilyComponent
            data={editMyProfileData}
            callBackReload={() => {
                apiCallEditProfile()
             }}
            // refresh={familyCallBack}
            dropDown={familyinfofreshData}
            type="editfamily"
            editfromadmin={ProfileData?.editfromadmin}
            isScroll={false}
            isFrom="familydetails"
        />
    )
}

export default PreviewFamilyForm
import React, { useContext, useEffect, useState } from 'react'
import ReligiousComponent from '../../../Components/Edit Profile/MyProfileComponents/ReligiousComponent'
import { useDispatch } from 'react-redux';
import { getDenominationList, registerStarList } from '../../../Store/Home/Action';
import config from '../../../config';
import EndPoints from '../../../Constants/EndPoints';
import { GET } from '../../../Services/api_services';
import { LoginContext } from '../../../Contexts/LoginContext';

function PreviewReligiousForm({ editMyProfileData, ProfileData, ispaid, apiCallEditProfile }) {
    const { logout } = useContext(LoginContext);
    const [editreligioninfofreshData, seteditreligioninfofresh] = useState();
console.log(ProfileData,"ProfileData");


    const dispatch = useDispatch();
    const apiCallStar = async (motherTounge) => {
        // getCastUrl

        dispatch(registerStarList(motherTounge))
    };

    useEffect(() => {
        const apiReligiousCallDropDown = async (type) => {

            let url = "";

            url = `${config.api.API_URL}${EndPoints.editreligioninfofreshUrl()}`;

            let { statusCode, data } = await GET(url);
            //console.log("dropdown data ", data);
            if (statusCode === 200) {
                seteditreligioninfofresh(data.data);
            } else if (statusCode === 401) {
                logout();
            }

        };

        apiReligiousCallDropDown();
    }, [])


    return (
        <ReligiousComponent
            apiCallStar={apiCallStar}
            data={editMyProfileData}
            dropDown={editreligioninfofreshData}
            callBackReload={() => {
                apiCallEditProfile()
             }}
            isPaid={ispaid != "N"}
            editfromadmin={ProfileData?.editfromadmin}
            isFrom="religiousdetails"
        />
    )
}

export default PreviewReligiousForm
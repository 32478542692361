import moment from "moment";
import Constants, { formatMessageGroupDate } from "../../Constants/constants";
import * as actionTypes from "./ActionTypes"
import { getGroupAuthId, getLoginRelation, getCustomerServiceAuthId } from "../../Components/Kalyanlite/utils";


const updateMedia = ({ state, mediaType, userId, isLoading, isLoadMore, mediaData = [] }) => {
    const media = mediaType == 3 ? "images" : mediaType == 4 ? "docs" : "links"
    let currentMedia = state.userMedia[media];
    let currentUserData = state.userMedia[media][userId];

    let mediaList = currentUserData?.data ? [...currentUserData.data, ...mediaData] : []

    return {
        ...state,
        userMedia: {
            ...state.userMedia,
            [media]: {
                ...currentMedia,
                [userId]: {
                    ...currentUserData,
                    isLoading: isLoading ?? currentUserData?.isLoading,
                    isLoadMore: isLoadMore ?? currentUserData?.isLoadMore,
                    data: mediaList
                }
            }
        }
    }
}

const updateUnreadCount = ({ state, count, conversationId }) => {

    const chatIndex = state?.chatListData?.findIndex(chat => chat?.conversation_id == conversationId)
    let profileCount = state?.unreadProfileCount;

    const chatListData = [...state.chatListData]
    if (chatIndex !== -1) {

        if (chatListData[chatIndex].unread_message_count > 0) {
            profileCount = profileCount - 1
        }

        chatListData[chatIndex].unread_message_count = count;
    }

    return {
        ...state,
        unreadProfileCount: profileCount,
        chatListData: chatListData,
        starCount: state?.currentChat?.starCount
    }

}

const updateMsgUnreadCount = ({ state, count, conversationId }) => {

    const chatIndex = state?.groupChatList?.findIndex(chat => chat?.conversation_id == conversationId)
    // let profileCount = state?.unreadProfileCount;

    const groupChatList = [...state.groupChatList]
    if (chatIndex !== -1) {

        // if (groupChatList[chatIndex].unread_message_count > 0) {
        //     profileCount = profileCount - 1
        // }

        groupChatList[chatIndex].unread_message_count = count;
    }

    return {
        ...state,
        // unreadProfileCount: profileCount,
        groupChatList: groupChatList,
        starCount: state?.currentChat?.starCount
    }

}

const updateUnreadMsgCount = ({ state, count, conversationId }) => {

    const chatIndex = state.Chats?.conversations?.findIndex(chat => chat?.conversation_id == conversationId)
    // let profileCount = state?.unreadProfileCount;

    const UnreadMsgCount = [...state.conversations]
    console.log(UnreadMsgCount,'UnreadMsgCount');
    if (chatIndex !== -1) {

        // if (groupChatList[chatIndex].unread_message_count > 0) {
        //     profileCount = profileCount - 1
        // }

        UnreadMsgCount[chatIndex].unreadCount = count;
    }

    return {
        ...state,
        // unreadProfileCount: profileCount,
        UnreadMsgCount: UnreadMsgCount,
        starCount: state?.currentChat?.starCount
    }

}

const updateUnReadCount = ({ state, count, conversationId }) => {

    const chatIndex = state.Chats?.conversations?.findIndex(chat => chat?.conversation_id == conversationId)
    // let profileCount = state?.unreadProfileCount;

    const UnreadMsgCount = [...state.conversations]
    console.log(UnreadMsgCount,'UnreadMsgCount');
    if (chatIndex !== -1) {

        UnreadMsgCount[chatIndex].unreadCount = count;
    }

    return {
        ...state,
        // unreadProfileCount: profileCount,
        UnreadMsgCount: UnreadMsgCount,
        starCount: state?.currentChat?.starCount
    }

}

const updateStar = ({ state, message }) => {

    const currentConversation = state.conversations[`${message?.conversation_id}`];
    // sender_deleted_at
    const messageIndex = currentConversation.messages.findIndex(chat => chat.id == message?.id)
    if (messageIndex != -1) {
        currentConversation.messages[messageIndex].is_starred = true
    }
    console.log({ currentConversation, message });

    return {
        ...state,
        isStarMessageLoading: true,
        conversations: {
            ...state.conversations,
            [`${message?.conversation_id}`]: {
                ...currentConversation,
            }
        }
    }
}

const updateGroupStar = ({ state, message }) => {

    const currentConversation = state.conversations[`${message?.group_conversation_id || message?.conversation_id}`];
    // sender_deleted_at

    const messageIndex = currentConversation.messages.findIndex(chat => chat.id == message?.id)

    if (messageIndex != -1) {
        currentConversation.messages[messageIndex].is_starred = true
    }
    console.log({ currentConversation, message });

    return {
        ...state,
        isGroupStarMessageLoading: true,
        conversations: {
            ...state.conversations,
            [`${message?.conversation_id}`]: {
                ...currentConversation,
            }
        }
    }
}

const updateBulkRequest = ({ state, isFetching, isLoadmoreFetching, data = [], page, contentCard, banner }) => {
    const bulkRequest = state.bulkRequest;
    const pagination = state.bulkRequest?.pagination;
    const currentPage = page ?? pagination.page;
    let updatedContentCard = {
        ...bulkRequest.contentCardData,
    }
    let updatedBanner = {
        ...bulkRequest.banner,
    }

    if (contentCard) {
        updatedContentCard[pagination.page] = contentCard
    }

    if (banner) {
        updatedBanner[pagination.page] = banner
    }


    return {
        ...state,
        bulkRequest: {
            pagination: {
                page: currentPage,
                size: Constants.matchRowCount,
            },
            contentCardData: updatedContentCard,
            banner: updatedBanner,
            isFetching: isFetching ?? bulkRequest?.isFetching,
            isLoadmoreFetching: isLoadmoreFetching ?? bulkRequest?.isLoadmoreFetching,
            hasMoreData: data.length === Constants.matchRowCount,
            data: currentPage == 1 ? [...data] : [...bulkRequest.data, ...data],
        }
    }
}

const processMessage = (messages) => {
    let dateref = null
    let updatedMessage = [];

    messages?.map(message => {
        const formatedGroupedDate = formatMessageGroupDate(message?.created_at)

        if (dateref && dateref == formatedGroupedDate) {
            updatedMessage.push({ ...message, groupedDate: null })
        } else {
            dateref = formatedGroupedDate;
            updatedMessage.push({ ...message, groupedDate: message?.created_at })
        }
    })

    return updatedMessage;
}



const updateMessageReactions = ({ state, data }) => {
    console.log({ state, data });
    // conversation_id
    // group_message_id
    const currentConversation = state?.conversations[`${data?.conversation_id}`]

    if (!currentConversation) return state;

    const currentMessageIndex = currentConversation?.messages?.findIndex(message => message?.id == data?.data?.group_message_id)
    const reactIndex = currentConversation?.messages[currentMessageIndex]?.reactions?.findIndex(reaction => reaction.reacted_by_id == data?.data?.reacted_by_id)

    let updatedReactions = []
    console.log({ reactIndex, currentConversation });
    if (reactIndex != -1) {
        updatedReactions = [...currentConversation?.messages[currentMessageIndex]?.reactions]
        updatedReactions[reactIndex] = data.data
        if (updatedReactions[reactIndex]?.reacted_by_id == getGroupAuthId()) {
            updatedReactions[reactIndex].reacted_by = "You"
        }
        updatedReactions[reactIndex].reacted_by_sender = updatedReactions[reactIndex]?.reacted_by_id == getGroupAuthId()
    } else {
        updatedReactions = [...currentConversation?.messages[currentMessageIndex]?.reactions]
        updatedReactions.push(data.data)
        if (updatedReactions[0]?.reacted_by_id == getGroupAuthId()) {
            updatedReactions[0].reacted_by = "You"
        }
        updatedReactions[0].reacted_by_sender = updatedReactions[0]?.reacted_by_id == getGroupAuthId()
    }

    const updatedMessage = {
        ...currentConversation?.messages[currentMessageIndex],
        reactions: updatedReactions
    }

    currentConversation.messages[currentMessageIndex] = updatedMessage


    return {
        ...state,
        conversations: {
            [`${data?.conversation_id}`]: {
                ...currentConversation
            }
        }
    };
}


const updateReadby = ({ state, data }) => {
    const currentConversation = state?.conversations[`${data?.conversation_id}`]
    if (!currentConversation) return state
    const lastIndex = currentConversation.messages?.length - 1
    const modifiedreadby = data?.read_by?.split(", ")?.filter(
        name => name != getLoginRelation())?.join(", ")
    console.log({ modifiedreadby, ss: getLoginRelation() });

    currentConversation.messages[lastIndex].read_by = modifiedreadby

    if (data?.is_read) {
        currentConversation.messages = currentConversation.messages?.map(msg => ({ ...msg, is_read: true }))
    }



    return {
        ...state,
        conversations: {
            [`${data?.conversation_id}`]: {
                ...currentConversation
            }
        }
    }
}



const initialState = {
    error: "",
    loading: true,
    unreadProfileCount: 0,
    isMessageSending: false,
    isChatListLoading: true,
    isChatListMoreLoading: false,
    hasMoreChatData: false,
    chatPage: 1,
    banner: {},
    sendMessageError: "",
    isMessageSelectionEnabled: false,
    scrollToBottom: 0,
    keepScrollPosition: false,
    profile: {},
    chatListData: [],
    
    groupChatList: [],
    unReadCustomerMsgCount: [],
    // isSearch: false,
    searchChatListData: "",
    conversationPagination: {
        page: 1,
    },
    currentChat: {
        conversationId: "",
        receiverId: "",
    },
    conversations: {},
    selectedChatIds: [],
    conversationStatusData: {},
    userMedia: {
        images: {},
        docs: {},
        links: {}
    },
    isStarMessageLoading: true,
    isGroupStarMessageLoading: true,

    bulkRequest: {
        pagination: {
            page: 0,
            size: Constants.matchRowCount,
        },
        isFetching: true,
        isLoadmoreFetching: false,
        hasMoreData: true,
        data: [],
    },
    familyCode : {
        isLoading : false,
        codeData : [],
        error : ""
    },
    rankingData : {
        isLoading : false,
        rankData : [],
        error : ""
    },

};


const Chats = (state = initialState, action) => {

    const payload = action.payload

    let currentConversation;
    let chatListData;
    let updatedConversation;
    let messageIndex;
    let chatListIndex;
    let banner;
    let isMultipleMessage;
    let len;
    let isEnd;
    let current;
    let currentConversationId;
    let isload;


    switch (action.type) {
        case actionTypes.GET_CONVERSATION:
            return {
                ...state,
                loading: action?.payload?.isMessageFrom == "pushNotification" ? false : true,
                scrollToBottom: 0,
                conversations: {}
            };
        case actionTypes.GET_CONVERSATION_SUCCESS:
            // isEnd = payload.message_data.length < Constants.maxConversation
            return {
                ...state,
                loading: false, 
                scrollToBottom: 1,
                currentChat: {
                    ...state.currentChat,
                    conversationId: payload.conversation_id,
                    conversationLastSeen: payload?.last_seen,
                    isOnline: payload?.isOnline, 
                    starCount: payload?.total_starred_message_count,
                    profilepopupShow : payload?.is_popup_show
                },
                conversations: {
                    [`${payload.conversation_id}`]: {
                        unreadCount: 0,
                        paidStatus: payload.paidStatus,
                        warning_message: payload.warning_message,
                        // hasConversationEnd: isEnd,
                        configMessageTypes:payload?.configMessageTypes,
                        messages: processMessage(payload?.is_starred ? payload.message_data : payload.message_data?.reverse())
                    }
                },
            }
        case actionTypes.GET_CONVERSATION_FAIL:
            return {
                ...state,
                error: payload
            }


        //group
        case actionTypes.GET_GROUP_CONVERSATION:
            return {
                ...state,
                loading: true,
                scrollToBottom: 0,
                conversations: {}
            };

        case actionTypes.GET_GROUP_CONVERSATION_SUCCESS:

            // isEnd = payload.message_data.length < Constants.maxConversation
            return {
                ...state,
                loading: false,
                scrollToBottom: 1,
                currentChat: {
                    ...state.currentChat,
                    receiverId: payload?.auth_id,
                    conversationId: payload.conversation_id,
                    conversationLastSeen: payload?.last_seen,
                    isOnline: payload?.isOnline,
                    starCount: payload?.starred_message_count ?? 0
                },
                conversations: {
                    [`${payload.conversation_id}`]: {
                        unreadCount: 0,
                        paidStatus: payload.paidStatus,
                        warning_message: payload.warning_message,
                        // hasConversationEnd: isEnd,
                        messages: processMessage(payload?.is_starred ? payload.messageData : payload.messageData?.reverse())
                    }
                },
            }


        //more conversation reducers - START
        case actionTypes.GET_MORE_CONVERSATION:
            current = state.conversations[`${payload.conversation_id}`];
            return {
                ...state,
                keepScrollPosition: false,
                conversations: {
                    ...state.conversations,
                    [`${payload.conversation_id}`]: {
                        ...current,
                        isMoreConversationLoading: true,
                        conversationPagination: {
                            page: (current?.conversationPagination?.page ?? 1) + 1,
                        }
                    }
                }
            }
        case actionTypes.GET_MORE_CONVERSATION_SUCCESS:
            isEnd = payload.message_data.length == 0
            currentConversation = state.conversations[`${payload.conversation_id}`];

            return {
                ...state,
                keepScrollPosition: true,
                conversations: {
                    ...state.conversations,
                    [`${payload.conversation_id}`]: {
                        ...currentConversation,
                        isMoreConversationLoading: false,
                        hasConversationEnd: isEnd,
                        messages: processMessage([
                            ...payload.message_data?.reverse(),
                            ...currentConversation.messages
                        ])

                    }
                }
            }
        case actionTypes.GET_MORE_CONVERSATION_FAIL:
            return state
        //more conversation reducers - END


        //more group conversation reducers - START
        case actionTypes.GET_GROUP_MORE_CONVERSATION:
            current = state.conversations[`${payload.conversation_id}`];
            return {
                ...state,
                keepScrollPosition: false,
                conversations: {
                    ...state.conversations,
                    [`${payload.conversation_id}`]: {
                        ...current,
                        isMoreConversationLoading: true,
                        conversationPagination: {
                            page: (current?.conversationPagination?.page ?? 1) + 1,
                        }
                    }
                }
            }
        case actionTypes.GET_GROUP_MORE_CONVERSATION_SUCCESS:
            isEnd = payload.messageData.length == 0
            currentConversation = state.conversations[`${payload.conversation_id}`];
            console.log({ currentConversation, payload });
            return {
                ...state,
                keepScrollPosition: true,
                conversations: {
                    ...state.conversations,
                    [`${payload.conversation_id}`]: {
                        ...currentConversation,
                        isMoreConversationLoading: false,
                        hasConversationEnd: isEnd,
                        messages: [
                            ...payload.messageData?.reverse(),
                            ...currentConversation.messages
                        ]

                    }
                }
            }


        // Customer service 
        //group
        case actionTypes.GET_CUSTOMER_SERVICE_CONVERSATION:
            isload = payload.is_first_msg ? false : true;
            return {
                ...state,
                loading: isload,
                scrollToBottom: 0,
                conversations: {}
            };

        case actionTypes.GET_CUSTOMER_SERVICE_CONVERSATION_SUCCESS:

            // isEnd = payload.message_data.length < Constants.maxConversation
            return {
                ...state,
                loading: false,
                scrollToBottom: 1,
                currentChat: {
                    ...state.currentChat,
                    receiverId: payload?.auth_id,
                    conversationId: payload.conversation_id,
                    conversationLastSeen: payload?.last_seen,
                    isOnline: payload?.isOnline,
                    starCount: payload?.starred_message_count ?? 0,
                    is_popup_show: action.payload.is_popup_show,
                },
                conversations: {
                    [`${payload.conversation_id}`]: {
                        unreadCount: 0,
                        paidStatus: payload.paidStatus,
                        warning_message: payload.warning_message,
                        // hasConversationEnd: isEnd,
                        messages: processMessage(payload?.is_starred ? payload.message_data : payload.message_data?.reverse()),
                        is_popup_show: action.payload.is_popup_show,
                    }
                },
            }

        //more conversation reducers - START
        case actionTypes.GET_CUSTOMER_SERVICE_MORE_CONVERSATION:
            current = state.conversations[`${payload.conversation_id}`];
            return {
                ...state,
                keepScrollPosition: false,
                conversations: {
                    ...state.conversations,
                    [`${payload.conversation_id}`]: {
                        ...current,
                        isMoreConversationLoading: true,
                        conversationPagination: {
                            page: (current?.conversationPagination?.page ?? 1) + 1,
                        }
                    }
                }
            }
        case actionTypes.GET_CUSTOMER_SERVICE_MORE_CONVERSATION_SUCCESS:
            isEnd = payload.message_data.length == 0
            currentConversation = state.conversations[`${payload.conversation_id}`];

            return {
                ...state,
                keepScrollPosition: true,
                conversations: {
                    ...state.conversations,
                    [`${payload.conversation_id}`]: {
                        ...currentConversation,
                        isMoreConversationLoading: false,
                        hasConversationEnd: isEnd,
                        messages: processMessage([
                            ...payload.message_data?.reverse(),
                            ...currentConversation.messages
                        ])

                    }
                }
            }
       case actionTypes.GET_CUSTOMER_SERVICE_MORE_CONVERSATION_FAIL:
        return state
        
       case actionTypes.STAR_MESSAGE:
            return updateStar({
                state,
                message: payload
            })
        case actionTypes.STAR_MESSAGE_SUCCESS:
            console.log(payload, "starredMessageSuccess");
            return {
                ...state,
                isStarMessageLoading: false,
                starredMessageSuccess: payload,
                updatedStarCount: state?.currentChat?.starCount == 0 ? 1 : state?.currentChat?.starCount,
            }
        case actionTypes.STAR_MESSAGE_FAIL:
            return {
                ...state,
                isStarMessageLoading: false,
                error: payload
            }

        case actionTypes.GROUP_STAR_MESSAGE:
            return updateGroupStar({
                state,
                message: payload
            })
        case actionTypes.GROUP_STAR_MESSAGE_SUCCESS:
            console.log(payload, "groupStarredMessageSuccess");
            return {
                ...state,
                isGroupStarMessageLoading: false,
                groupStarredMessageSuccess: payload,
                updatedStarCount: state?.currentChat?.starCount == 0 ? 1 : state?.currentChat?.starCount,
            }
        case actionTypes.GROUP_STAR_MESSAGE_FAIL:
            return {
                ...state,
                isGroupStarMessageLoading: false,
                error: payload
            }

            // offline
            case actionTypes.OFFLINE_PROFILE:
         return{
            ...state,
            loading: true,
          };
         
          case actionTypes.OFFLINE_PROFILE_SUCCESS:
          console.log(action.payload.data,"statse1");
          return{
            ...state,
            profiledata:action.payload.data,
            loading: false,
          };
        
        case actionTypes.OFFLINE_PROFILE_FAIL:
          ////console.log( action.payload.data,"123456");
          return{
            ...state,
            error: action.payload.data,
            loading: false,
            isUserLogout: false,
          };
         

        //more conversation reducers - START
        // case actionTypes.GET_CONVERSATION_BY_PAGE:
        //     const current = state.conversations[`${payload.conversation_id}`];
        //     return {
        //         ...state,
        //         keepScrollPosition: false,
        //         conversations: {
        //             ...state.conversations,
        //             [`${payload.conversation_id}`]: {
        //                 ...current,
        //                 isMoreConversationLoading: true,
        //                 conversationPagination: {
        //                     page: (current?.conversationPagination?.page ?? 1) + 1,
        //                 }
        //             }
        //         }
        //     }
        // case actionTypes.GET_CONVERSATION_BY_PAGE_SUCCESS:
        //     const isEnd = payload.message_data.length < Constants.maxConversation
        //     currentConversation = state.conversations[`${payload.conversation_id}`];

        //     return {
        //         ...state,
        //         keepScrollPosition: true,
        //         conversations: {
        //             ...state.conversations,
        //             [`${payload.conversation_id}`]: {
        //                 ...currentConversation,
        //                 isMoreConversationLoading: false,
        //                 hasConversationEnd: isEnd,
        //                 messages: [
        //                     ...payload.message_data?.reverse(),
        //                     ...currentConversation.messages
        //                 ]

        //             }
        //         }
        //     }
        // case actionTypes.GET_CONVERSATION_BY_PAGE_FAIL:
        //     return state
        //more conversation reducers - END
        case actionTypes.OFFLINE_PROFILE:
            state = {
              ...state,
              loading: true,
            };
            break;
            case actionTypes.OFFLINE_PROFILE_SUCCESS :
            console.log(action.payload.data,"statse1");
            state = {
              ...state,
              profiledata:action.payload.data,
              loading: false,
            };
            break;
          case actionTypes.OFFLINE_PROFILE_FAIL:
            ////console.log( action.payload.data,"123456");
            state = {
              ...state,
              error: action.payload.data,
              loading: false,
              isUserLogout: false,
            };
            break;
  
        //chat list
        case actionTypes.GET_CHAT_LIST:
            return {
                ...state,
                chatPage: action.payload?.page,
                isChatListLoading: action?.payload?.isMessageFrom == "pushNotification" ? false : action.payload?.page === 1,
                isChatListMoreLoading: action.payload?.page > 1,
            };
        case actionTypes.GET_CHAT_LIST_SUCCESS:
            console.log(action.payload.list_data, "chatListData");
            chatListData = [...state?.chatListData]
            banner = { ...state.banner }

            if (action.payload.list_data) {
                chatListData = state?.chatPage === 1 ? [...action.payload.list_data] : [...chatListData, ...action.payload.list_data]
            }

            if (action.payload.bannerweb) {
                banner = { ...banner, [state?.chatPage]: action.payload.bannerweb }
            }

            return {
                ...state,
                chatListData: chatListData,
                unreadProfileCount: state?.chatPage === 1 ? payload?.profile_count : state?.unreadProfileCount,
                isChatListLoading: false,
                isChatListMoreLoading: false,
                banner: banner,
                hasMoreChatData: action.payload.list_data?.length === 10,
                configMessageTypes:action.payload.configMessageTypes
            }
        case actionTypes.GET_CHAT_LIST_FAIL:
            return {
                ...state,
                error: payload
            }

        case actionTypes.GET_GROUP_CHAT_LIST_SUCCESS:
            chatListData = [...state?.groupChatList]

            if (action.payload.groupData) {
                chatListData = [...action.payload.groupData]
            }

            return {
                ...state,
                groupChatList: chatListData
            }

        //chat list
        case actionTypes.GET_BULK_REQUEST:
            return updateBulkRequest({
                state,
                page: action.payload?.page,
                isFetching: action.payload?.page === 1,
                isLoadmoreFetching: action.payload?.page > 1,
            });
        case actionTypes.GET_BULK_REQUEST_SUCCESS:
            return updateBulkRequest({
                state,
                data: action.payload.list_data,
                isFetching: false,
                isLoadmoreFetching: false,
                banner: action.payload.bannerweb,
            })

        case actionTypes.GET_BULK_REQUEST_FAIL:
            return {
                ...state,
                error: payload
            }


        case actionTypes.TOGGLE_MUTE_CONVERSATIONS:
            chatListIndex = state?.chatListData?.findIndex(chat => chat?.profile_id == payload?.receiver_id)
            chatListData = [...state.chatListData]
            if (chatListIndex !== -1) {
                chatListData[chatListIndex].is_muted = payload?.operation === "mute"
            }
            console.log({ chatListData, chatListIndex });
            return {
                ...state,
                chatListData: chatListData,
            }

        //search chat list
        case actionTypes.SEARCH_CHAT_LIST:
            return state;
        case actionTypes.SEARCH_CHAT_LIST_SUCCESS:
            console.log(action.payload.list_data, "chatListData");
            return {
                ...state,
                // isSearch: true,
                searchChatListData: action.payload.list_data,
            }
        case actionTypes.SEARCH_CHAT_LIST_FAIL:
            return {
                ...state,
                error: payload
            }

        //set current chat handlers
        case actionTypes.SET_CURRENT_CHAT:
            return {
                ...state,
                loading: true,
                currentChat: payload
            }

        //set current chat handlers
        case actionTypes.CLOSE_CURRENT_CHAT:
            return {
                ...state,
                currentChat: initialState.currentChat
            }


        //send message handlers
        case actionTypes.SEND_MESSAGE:
            return {
                ...state,
                isMessageSending: true,
                currentMessage: payload,
                scrollToBottom: 0,
            }
        case actionTypes.SEND_MESSAGE_SUCCESS:
            // 
            isMultipleMessage = Array.isArray(payload?.data?.message_data)
            const index = state?.chatListData?.findIndex(chat => chat?.profile_id == state?.currentChat?.receiverId)
            const chatList = [...state.chatListData]
            currentConversation = state.conversations[`${payload.data?.conversation_id}`];
            if (index !== -1) {
                if (isMultipleMessage) {
                    len = payload?.data?.message_data?.length - 1;
                    chatList[index].last_message = payload?.data?.message_data[len]?.original_file_name
                } else {
                    chatList[index].last_message = payload?.data?.message_data?.message_type_id == 4 || payload?.data?.message_data?.message_type_id == 3 ? payload?.data?.message_data?.original_file_name : payload?.data?.message_data?.content
                }
            }

            var first = payload.data?.conversation_id;
            chatList.sort(function (x, y) { return x?.conversation_id == first ? -1 : y?.conversation_id == first ? 1 : 0; });


            return {
                ...state,
                isMessageSending: false,
                currentMessage: null,
                scrollToBottom: 1,
                chatListData: chatList,
                conversations: {
                    [`${payload.data?.conversation_id}`]: {
                        ...currentConversation,
                        unreadCount: 0,
                        messages: isMultipleMessage
                            ? [
                                ...currentConversation.messages,
                                ...payload?.data?.message_data
                            ] : [
                                ...currentConversation.messages,
                                payload?.data?.message_data
                            ]
                    }
                }
            }

        // profile send message
        case actionTypes.PROFILE_SEND_MESSAGE:
            return {
                ...state,
                isMessageSending: true,
                currentMessage: payload,
                scrollToBottom: 0,
            }
        case actionTypes.PROFILE_SEND_MESSAGE_SUCCESS:
            isMultipleMessage = Array.isArray(payload?.data?.message_data)
            const currentIndex = state?.chatListData?.findIndex(chat => chat?.profile_id == state?.currentChat?.receiverId)

            const currentChatList = [...state.chatListData]
            currentConversation = state.conversations[`${payload.data?.conversation_id}`];
            if (currentIndex !== -1) {
                if (isMultipleMessage) {
                    len = payload?.data?.message_data?.length - 1;
                    currentChatList[currentIndex].last_message = payload?.data?.message_data[len]?.original_file_name
                } else {
                    currentChatList[currentIndex].last_message = payload?.data?.message_data?.content
                }
            }
            console.log(currentChatList[currentIndex],'sfjjsffskjfksjfk');


            var first = payload.data?.conversation_id;
            currentChatList.sort(function (x, y) { return x?.conversation_id == first ? -1 : y?.currentChatList == first ? 1 : 0; });

            return {
                ...state,
                isMessageSending: false,
                currentMessage: null,
                scrollToBottom: 1,
                chatListData: currentChatList,
                conversations: {
                    [`${payload.data?.conversation_id}`]: {
                        ...currentConversation,
                        unreadCount: 0,
                        messages: [
                                ...currentConversation.messages,
                                payload?.data?.message_data
                            ]
                    }
                }
            }

        case actionTypes.SEND_GROUP_MESSAGE:
            return {
                ...state,
                isMessageSending: true,
                currentMessage: payload,
                scrollToBottom: 0,
            }

        case actionTypes.SEND_GROUP_MESSAGE_SUCCESS:

            isMultipleMessage = Array.isArray(payload?.data?.message_data)
            currentConversation = state.conversations[`${payload.data?.conversation_id}`];

            if (!currentConversation) {
                currentConversation = {
                    messages: []
                }
            }

            console.log("SEND_GROUP_MESSAGE_SUCCESS", { payload, currentConversation, state });

            return {
                ...state,
                isMessageSending: false,
                currentMessage: null,
                scrollToBottom: 1,
                conversations: {
                    [`${payload.data?.conversation_id}`]: {
                        ...currentConversation,
                        unreadCount: 0,
                        messages: isMultipleMessage
                            ? [
                                ...currentConversation.messages,
                                ...payload?.data?.message_data
                            ] : [
                                ...currentConversation.messages,
                                payload?.data?.message_data
                            ]
                    }
                }
            }
        case actionTypes.MESSAGE_RECEIVED:
            const chatIndex = state?.chatListData?.findIndex(chat => chat?.conversation_id == payload.conversation_id)
            chatListData = [...state.chatListData]
            isMultipleMessage = Array.isArray(payload)
            let original_file_name = ""
            let currentConId = isMultipleMessage ? payload[payload.length - 1]?.conversation_id : payload?.conversation_id
            if (isMultipleMessage) {
                original_file_name = payload[payload.length - 1].original_file_name
            } else if (payload?.message_type_id == 4) {
                original_file_name = payload.original_file_name;
            }
            if (chatIndex !== -1) {
                chatListData[chatIndex].unread_message_count = chatListData[chatIndex].unread_message_count + 1;
                chatListData[chatIndex].last_message = original_file_name ? original_file_name : payload.content
            }
            currentConversation = state.conversations[`${currentConId}`];



            if (currentConversation?.messages) {
                updatedConversation = [...currentConversation.messages]
                if (isMultipleMessage) {
                    updatedConversation = [...updatedConversation, ...payload]
                } else {
                    updatedConversation = [...updatedConversation, payload]
                }
            } else {
                updatedConversation = [payload];
            }

            return {
                ...state,
                scrollToBottom: state.scrollToBottom + 1,
                chatListData: chatListData,
                conversations: {
                    ...state.conversations,
                    [`${currentConId}`]: {
                        ...currentConversation,
                        messages: updatedConversation
                    }
                },
            }

        case actionTypes.GROUP_MESSAGE_RECEIVED:
            isMultipleMessage = Array.isArray(payload)
            currentConversationId = isMultipleMessage ? `${payload[0]?.group_conversation_id}` : `${payload?.group_conversation_id}`

            currentConversation = state.conversations[currentConversationId];


            if (currentConversation?.messages) {
                updatedConversation = [...currentConversation.messages]
                if (isMultipleMessage) {
                    updatedConversation = [...updatedConversation, ...payload]
                } else {
                    updatedConversation = [...updatedConversation, payload]
                }
            } else {
                updatedConversation = [payload];
            }

            return {
                ...state,
                groupChatList: state?.groupChatList?.map(data => ({
                    ...data,
                    last_message: payload.content,
                    // unread_message_count: data?.unread_message_count ? data?.unread_message_count + 1 : 1,
                })),
                conversations: {
                    ...state.conversations,
                    [currentConversationId]: {
                        ...currentConversation,
                        messages: updatedConversation
                    }
                },
            }

        case actionTypes.SEND_MESSAGE_FAIL:
            return {
                ...state,
                isMessageSending: false,
                sendMessageError: payload,
            }
// customer service
            case actionTypes.SEND_CUSTOMER_SERVICE_MESSAGE:
                return {
                    ...state,
                    isMessageSending: true,
                    currentMessage: payload,
                    scrollToBottom: 0,
                }
    
            case actionTypes.SEND_CUSTOMER_SERVICE_MESSAGE_SUCCESS:
    
                isMultipleMessage = Array.isArray(payload?.data?.message_data)
                currentConversation = state.conversations[`${payload.data?.conversation_id}`];
                if (!currentConversation) {
                    currentConversation = {
                        messages: []
                    }
                }
    
                console.log("SEND_CUSTOMER_SERVICE_MESSAGE_SUCCESS", { payload, currentConversation, state });
    
                return {
                    ...state,
                    isMessageSending: false,
                    currentMessage: null,
                    scrollToBottom: 1,
                    isKmcareConversationExist:true,
                    conversations: {
                        [`${payload.data?.conversation_id}`]: {
                            ...currentConversation,
                            unreadCount: 0,
                            messages: isMultipleMessage
                                ? [
                                    ...currentConversation.messages,
                                    ...payload?.data?.message_data
                                ] : [
                                    ...currentConversation.messages,
                                    payload?.data?.message_data
                                ]
                        }
                    }
                }
            case actionTypes.SEND_CUSTOMER_SERVICE_MESSAGE_FAIL:
                return {
                    ...state,
                    isMessageSending: false,
                    sendMessageError: payload,
                }


        case actionTypes.CHAT_UNREAD_MSG_COUNT:
            return {
                ...state,
                scrollToBottom: 0,
                loading: true
            };
            
        // case actionTypes.CHAT_UNREAD_MSG_COUNT_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         conversations: {
        //             ...state.conversations,
        //             scrollToBottom: 1,
        //            unreadCount: action.payload.unread_message_count
                    
        //         }
        //     };
        case actionTypes.CHAT_UNREAD_MSG_COUNT_SUCCESS:
          
            return {
                ...state,
                loading: false,
                isKmcareConversationExist: action.payload.conversation_id ? true: false,
                unReadCustomerMsgCount: {
                    ...state.unReadCustomerMsgCount,
                    scrollToBottom: 1,
                    conversation_id: action.payload.conversation_id,
                    unread_message_count: action.payload.unread_message_count
                    
                }
            };

        case actionTypes.CHAT_UNREAD_MSG_COUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };


            case actionTypes.RESET_UN_READ_COUNT:
            return {
                ...state,
                scrollToBottom: 0,
                loading: true
            };
            
        case actionTypes.RESET_UN_READ_COUNT_SUCCESS:
          
            return {
                ...state,
                loading: false,
                isKmcareConversationExist: action.payload.conversation_id ? true: false,
                unReadCustomerMsgCount: {
                    ...state.unReadCustomerMsgCount,
                    scrollToBottom: 1,
                    conversation_id: action.payload.conversation_id,
                    unread_message_count: action.payload.unread_message_count
                    
                }
            };

        case actionTypes.RESET_UN_READ_COUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        // Ranking Popup

        case actionTypes.RANKING_POPUP:
            return {
                ...state,
                rankingData: {
                    ...state.rankingData,
                    isLoading: true,
                },
            };
        case actionTypes.RANKING_POPUP_SUCCESS:
            return {
                ...state,
                rankingData: {
                    isLoading: false,
                    rankData: action.payload,
                    error: '',
                },
            };
        case actionTypes.RANKING_POPUP_FAIL:
            return {
                ...state,
                rankingData: {
                    isLoading: false,
                    rankData: [],
                    error: action.payload,
                },
            };

        //chat selection
        case actionTypes.ADD_SELECTED_CHAT:
            return {
                ...state,
                selectedChatIds: [
                    ...state.selectedChatIds,
                    payload.chatId,
                ]
            };
        case actionTypes.REMOVE_SELECTED_CHAT:
            const ids = state.selectedChatIds.filter(id => id != payload.chatId);
            return {
                ...state,
                selectedChatIds: ids,
            };
        case actionTypes.REMOVE_ALL_SELECTED_CHAT:
            return {
                ...state,
                selectedChatIds: [],
            };;
        case actionTypes.TOGGLE_MESSAGE_SELECTION:
            const enableSelection = !state?.isMessageSelectionEnabled;
            return {
                ...state,
                selectedChatIds: enableSelection == false ? [] : state.selectedChatIds,
                isMessageSelectionEnabled: enableSelection,
            };;
        case actionTypes.DELETE_CHAT:
            currentConversation = state.conversations[`${payload?.message?.conversation_id}`];
            // sender_deleted_at
            messageIndex = currentConversation.messages.findIndex(chat => chat.id == payload?.message?.id)

            if (messageIndex != -1) {
                currentConversation.messages[messageIndex].sender_deleted_at = new Date().toDateString()
            }
            // updatedConversation = currentConversation.messages.filter(chat => chat.id != payload?.message?.id)


            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [`${payload?.conversation_id}`]: {
                        ...currentConversation,
                        messages: updatedConversation
                    }
                }
            }
        case actionTypes.GET_CHAT_MEDIA:
            return updateMedia({
                state,
                mediaType: payload.type_id,
                userId: payload.conversation_id,
                isLoading: true,
            })
        case actionTypes.GET_CHAT_MEDIA_SUCCESS:
            return updateMedia({
                state,
                userId: payload.conversation_id,
                mediaType: payload.type_id,
                isLoading: false,
                mediaData: payload.data,
            })
        //coversation status
        case actionTypes.GET_CONVERSATION_STATUS:
            return state;
        case actionTypes.GET_CONVERSATION_STATUS_SUCCESS:
            // console.log(action.payload, "coversationstatus");
            return {
                ...state,
                conversationStatusData: action.payload,
            }
        case actionTypes.GET_CONVERSATION_STATUS_FAIL:
            return {
                ...state,
                error: payload
            }

        case actionTypes.RESET_UNREAD_COUNT:
            return updateUnreadCount({ state, count: 0, conversationId: payload.conversationId })

        case actionTypes.RESET_MSG_UNREAD_COUNT:
            return updateMsgUnreadCount({ state, count: 0, conversationId: payload.conversationId })
        
        case actionTypes.RESET_UNREAD_MSG_COUNT:
            return updateUnreadMsgCount({state, count: 0, conversationId: payload.conversationId})

        case actionTypes.GET_FAMILY_CHAT_DETAIL_SUCCESS:
            console.log(action.payload, "family chat details data");

            return {
                ...state,
                chatsDetails: action.payload,
            }

        case actionTypes.DELETE_GROUP_MESSAGE:
            if (payload && payload.message && payload.message.conversation_id) {
                currentConversation = state.conversations[payload.message.conversation_id];

                if (currentConversation && currentConversation.messages) {
                    messageIndex = currentConversation.messages.findIndex(chat => chat.id == payload.message.id);

                    if (messageIndex !== -1) {
                        // Update sender_deleted_at property
                        currentConversation.messages[messageIndex].sender_deleted_at = new Date().toDateString();
                    }
                }
            }
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [`${payload?.message?.conversation_id}`]: {
                        ...currentConversation,
                        // messages: updatedConversation
                    }
                }
            }


        //  case actionTypes.REACTIONS_ADD_SUCCESS:
        //     const messageId = action?.payload?.id;
        //     const addedReaction = action?.payload?.data;
        //     const conversation_id = action?.payload?.conversation_id
        //     if (!messageId || !addedReaction) {
        //         return state;
        //     }
        //     return {
        //         ...state,
        //         addedReaction: addedReaction,

        //         conversations: {
        //             ...state.conversations,
        //             [conversation_id]: {
        //                 messages:state.conversations[conversation_id].messages.map(message => {
        //                     if (message.id == messageId) {
        //                         // Update the message with the added reaction
        //                         return {
        //                             ...message,
        //                             reactions: message.reactions?.reacted_by_sender ? [addedReaction] : [...message.reactions,addedReaction],
        //                         };
        //                     }
        //                     return message;
        //                 }),
        //             },
        //             }
        //         }  

        case actionTypes.REACTIONS_ADD_SUCCESS:
            return updateMessageReactions({ state, data: payload });

        case actionTypes.REACTIONS_RECEIVED:
            return updateMessageReactions({ state, data: payload });

        case actionTypes.READ_BY_RECEIVED:
            return updateReadby({ state, data: payload })

        // family code
        case actionTypes.FAMILY_SET_FAMILY_CODE:
            return {
                ...state,
                familyCode: {
                    ...state.familyCode,
                    isLoading: true,
                },
            };
        case actionTypes.FAMILY_SET_FAMILY_CODE_SUCCESS:
            return {
                ...state,
                familyCode: {
                    isLoading: false,
                    codeData: action.payload,
                    error: '',
                },
            };
        case actionTypes.FAMILY_SET_FAMILY_CODE_FAIL:
            return {
                ...state,
                familyCode: {
                    isLoading: false,
                    codeData: [],
                    error: action.payload,
                },
            };

        default:
            return state;
    }

};

export default Chats;
import React, { useState, useContext, useEffect } from "react";
import { FloatingSelectInput } from "../../Components/Edit Profile/EditProfileUtills";
import { useTranslation } from "react-i18next";
import LoaderGif from "../../Gif/loader.gif";
import { POST } from "../../Services/api_services";
import config from "../../config";
import { getGroupAuthId } from "../../Components/Kalyanlite/utils";
import { LoginContext } from "../../Contexts/LoginContext";
import OverflowToolTip from "../../Components/utils/OverflowTooltip";
import { drinking, foodhabit, smoking } from "../../Components/Kalyanlite/assets/images";
import EndPoints from "../../Constants/EndPoints";
import { toast } from "react-toastify";
import Constants from "../../Constants/constants";
import AfterregistrationSelectInput from "../../Components/Edit Profile/MyProfileComponents/AfterregistrationSelectInput";

const HabitsDetails = (props) => {
    const [submitStatus, setSubmitStatus] = useState(false);
    const [selectedFoodHabit, setSelectedFoodHabit] = useState("");
    const [selectedSmokingHabit, setSelectedSmokingHabit] = useState("");
    const [selectedDrinkingHabit, setSelectedDrinkingHabit] = useState("");

    const { loginDetail, logout } = useContext(LoginContext);
    const { t: trans } = useTranslation();
    const [disableSave, setDisableSave] = useState(true)
    const [toastmsg,setToastmsg]=useState(false)
    const [initialValues, setInitialValues] = useState({
        foodHabits: { label: "", value: "" },
        smokingHabits: { label: "", value: "" },
        drinkingHabits: { label: "", value: "" }
    });

    const foodHabitsOptions = [
        { label: "Vegetarian", value: "1" },
        { label: "Non Vegetarian", value: "2" },
        { label: "Eggetarian", value: "3" },
    ];

    const smokingOptions = [
        { label: "No", value: "1" },
        { label: "Occasionally", value: "2" },
        { label: "Yes", value: "3" },
    ];

    // On mount, set initial values from props.data
    // useEffect(() => {
    //     if (props?.data) {
    //         setSelectedFoodHabit(props?.data?.food?.[0]?.name || "");
    //         setSelectedSmokingHabit(props?.data?.smoking?.[0]?.name || "");
    //         setSelectedDrinkingHabit(props?.data?.drinking?.[0]?.name || "");
    //     }
    // }, [props?.data]);

    useEffect(() => {
        if (props?.data) {
            const foodOption = foodHabitsOptions?.find(option => option.value === props?.data?.food?.[0]?.id);
            const smokingOption = smokingOptions?.find(option => option.value === props?.data?.smoking?.[0]?.id);
            const drinkingOption = smokingOptions?.find(option => option.value === props?.data?.drinking?.[0]?.id);

            setSelectedFoodHabit(foodOption || null);
            setSelectedSmokingHabit(smokingOption || null);
            setSelectedDrinkingHabit(drinkingOption || null);
            setInitialValues({
                foodHabits: foodOption || null,
                smokingHabits: smokingOption || null,
                drinkingHabits: drinkingOption || null,
            });
        }
    }, [props?.data]);
     
    const handleDiscardChanges = (e) => {
        // Reset form values to the initial state
        e.preventDefault();
        setSelectedFoodHabit(initialValues.foodHabits);
        setSelectedSmokingHabit(initialValues.smokingHabits);
        setSelectedDrinkingHabit(initialValues.drinkingHabits);
        setDisableSave(true);
      };

    // const apicallHabits = async () => {
    //     const request = {
    //         email: loginDetail()[0],
    //         userId: loginDetail()[1],
    //         foodId: selectedFoodHabit,
    //         smokingId: selectedSmokingHabit,
    //         drinkingId: selectedDrinkingHabit,
    //         requestfulfilledfrom: "Desktop",
    //         requestfulfilledvia: "viewprofile",
    //         recieverprofileid: getGroupAuthId(),
    //     };

    //     let { statusCode, data } = await POST(
    //         `${config.api.API_URL}${EndPoints.edithabitsinfosaveUrl()}`,
    //         request
    //       );
    //     if (statusCode === 200 && data.data.status === "SUCCESS") {
    //         setSubmitStatus(false);
    //         props.callBackReload();
    //     } else if (statusCode === 401) {
    //         logout();
    //     }
    // };


    const apicallHabits = async (value) => {
        // Map selected habits to their corresponding IDs
        const foodId = selectedFoodHabit?.value ? selectedFoodHabit?.value : null;
    
        const smokingId = selectedSmokingHabit?.value ? selectedSmokingHabit?.value  : null;
    
        const drinkingId = selectedDrinkingHabit?.value ? selectedDrinkingHabit?.value : null;
    
        // Construct the request object in the desired format
        const request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            foodId: foodId,
            smokingId: smokingId,
            drinkingId: drinkingId,
            foodhabitrequesttypeid: 24, // Add hardcoded type ID for food habit
            smokingrequesttypeid: 25,  // Add hardcoded type ID for smoking habit
            drinkingrequesttypeid: 26, // Add hardcoded type ID for drinking habit
            requestfulfilledfrom: "Desktop",
            requestfulfilledvia: props?.isFrom=="habitdetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile"  :
            localStorage.getItem("profiledot") == "true" ?"profile_preview":"chat",
            recieverprofileid: getGroupAuthId(),
            loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "editFrom": props?.isFrom=="habitdetails" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
            localStorage.getItem("profiledot") == "true" ?"profile_preview_three_dots":"Myprofile",

        };
    
        // Make the API call
        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.edithabitsinfosaveUrl()}`,
            request
        );
    
        if (statusCode === 200 && data.data.status === "SUCCESS") {
            setSubmitStatus(false);
            props.callBackReload();
            setDisableSave(true)

                // toast(
                //   <div className='flex items-center'>
                //     {"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."}
                //   </div>,
                //   {
                //     hideProgressBar: true, // Hides the progress bar
                //   }
                // );
      
        } else if (statusCode === 401) {
            logout();
        }
    };
    
    return (
        <div className="p-0 lg:px-4">
            <form>
                {/* Food Habit Select */}
                <div>
                    <AfterregistrationSelectInput
                        name="foodHabits"
                        title={trans("my_profile.food_habits")}
                        value={selectedFoodHabit} // This should be an object like { label: "Vegetarian", value: "vegetarian" }
                        onChange={(option) =>{ setSelectedFoodHabit(option)
                            setDisableSave(false)
                        }}
                        placeholder={trans("my_profile.select")}
                        options={foodHabitsOptions}
                        icon={foodhabit}
                        isFrom="familydetail"
                        customClassName={"mt-[1rem]"}
                    />

                </div>

                {/* Smoking Habit Select */}
                <div>
                    <AfterregistrationSelectInput
                        title={"Smoking"}
                        name="smokingHabits"
                        options={smokingOptions}
                        value={selectedSmokingHabit}
                        onChange={(option) => {setSelectedSmokingHabit(option)
                            setDisableSave(false)
                        }}
                        placeholder={trans("my_profile.select")}
                        icon={smoking}
                        isFrom="familydetail"
                    />
                </div>

                {/* Drinking Habit Select */}
                <div>
                    <AfterregistrationSelectInput
                        title={"Drinking"}
                        name="drinkingHabits"
                        options={smokingOptions} // Use smokingOptions for drinking as well
                        value={selectedDrinkingHabit}
                        onChange={(option) =>{ setSelectedDrinkingHabit(option)
                            setDisableSave(false)
                        }}
                        placeholder={trans("my_profile.select")}
                        icon={drinking}
                        isFrom="familydetail"
                    />
                </div>

                {/* Save and Cancel buttons */}
                <div className="flex justify-center space-x-3 items-center mt-[2rem] mb-[2rem]">
                    <button
                    type="button"
                        onClick={(e) => {
                            if (!disableSave) {
                                e.preventDefault()
                                setToastmsg(true)
                                setSubmitStatus(true);
                                apicallHabits();
                                console.log("Toastmsg", toastmsg); // Check if the state is being set correctly
                  
                              }
                           
                        }}
                        disabled={props.editfromadmin === "Y" || disableSave}
                        className={`flex items-center justify-center text-white bg-[#D10A11] border border-[#D10A11] rounded-[5px] font-bold lg:w-[6rem] w-[9rem] py-1 md:text-[12px] lg:text-[14px] ${props.editfromadmin === "Y" || disableSave? " bg-[#E78387] cursor-not-allowed" : ""}`}
                    >
                        {submitStatus ? (
                            <>
                                <img src={LoaderGif} className="w-5 h-5" alt="" />
                                <span>{trans('my_profile.saving')}</span>
                            </>
                        ) : (
                            <span>{trans('my_profile.save')}</span>
                        )}
                    </button>

                    <button
                        onClick={handleDiscardChanges}
                        disabled={props.editfromadmin === "Y"}
                        className={`flex items-center justify-center text-[#575556] bg-white border border-[#707070] rounded-[5px] font-bold lg:w-[8rem] w-[9rem] py-1 md:text-[12px] lg:text-[14px] ${props.editfromadmin === "Y" ? "cursor-not-allowed" : ""}`}
                    >
                        <OverflowToolTip as="p" className="truncate">
                            {trans('my_profile.discard_changes')}
                        </OverflowToolTip>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default HabitsDetails;

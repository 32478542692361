import React, { useEffect, useRef } from 'react'

function MyProfileEditview({
    inputClassName,
    wrapperClassName,
    mobileWrapperClassName,
    labelWrapperClassName,
    inputType,
    error,
    title,
    icon,
    required,
    inputID,
    inputName,
    placeholder,
    onChange,
    disabled,
    value,
    onKeyDown,
    isFrom,
    suggestions,
    onSuggestionSelect,
    closeSuggestion
}) {

console.log({suggestions});


    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.target.closest('.suggestion-options')) {
                // Handle the outside click here
                console.log('Clicked outside');
                if (closeSuggestion) {
                    closeSuggestion();
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={wrapperRef} className={`hidden md:grid md:grid-cols-9  mt-[2rem] items-center md:p-0 ${wrapperClassName}`} >
                <div className={` col-span-9  xl:col-span-2 md:col-span-3 lg:col-span-3  flex md:pl-[1rem] lg:pl-[2rem]  items-center ${labelWrapperClassName}`}>
                    <p className={`text-[#7A7A7A] md:text-[12px] lg:text-[14px] flex  font-semibold`}>
                        {icon ?
                            <div className="flex gap-3 items-center">
                                <img alt='' className="w-[2.5rem] h-[2.5rem]" src={icon} />
                                {title}
                            </div> : title}
                        {required && <span className="text-[#D10A11] ml-1"> *</span>}
                    </p>
                </div>
                <div className={`col-span-9  lg:col-span-6 xl:col-span-4 md:col-span-6    md:pr-[1rem] xl:pr-[4rem] md:pl-[4rem]  lg:pr-[10rem] lg:pl-[3rem] xl:pl-[7rem]`}>
                    <p className='relative'>
                        <input
                            type={inputType}
                            id={inputID}
                            name={inputName}
                            value={value}
                            className={`border border-[#8F8F8F] text-[14px] xl:text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold ${inputClassName}`}
                            placeholder={placeholder}
                            autoComplete="off"
                            onChange={onChange}
                            disabled={disabled}
                            onKeyDown={onKeyDown}
                            defaultValue={value}
                        />
                        {error && (
                            <p className="text-[red] text-[12px]">{error}</p>
                        )}
                        {suggestions && suggestions.length > 0 && (
                        <div className="absolute z-50 w-full">
                            <div className={`z-50 w-full max-h-[15rem] overflow-y-auto lite-scrollbar`} style={{ backgroundColor: '#FFFFFF', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                                <p className='text-[#000000] bg-white text-[15px] border-b border-[#E9E9E9] px-5 py-3 sticky top-0'>Do you Mean</p>
                                <ul className='suggestion-options'>
                                    {suggestions.map(subcaste => (
                                        <li key={subcaste.id} className='px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onSuggestionSelect(subcaste)
                                            }}
                                        >{subcaste.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    </p>
                </div>
            </div>

            {isFrom == "profileaddDetails" &&
                <div ref={wrapperRef}
                    className={`relative w-full  mb-[2rem] flex gap-3 items-center md:hidden ${mobileWrapperClassName}`}
                >
                    <img alt='' className="w-[2.5rem] h-[2.5rem]" src={icon} />
                    <div className={`relative w-full  md:hidden`}>
                        <label
                            htmlFor={inputID || "custom-input"}
                            className={`absolute -top-2 left-3 bg-white px-1 text-[11px] text-[#575556] transition-all duration-200 
peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-4 peer-placeholder-shown:bg-transparent 
     peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base 
     peer-focus:-top-3 peer-focus:left-3 peer-focus:bg-white peer-focus:text-[#8F8F8F]`}
                        >
                            {title}
                            {required && <span className="text-[#D10A11] ml-1"> *</span>}
                        </label>
                        <input
                            type={inputType || "text"}
                            id={inputID || "custom-input"}
                            name={inputName || ""}
                            value={value || ""}
                            className={`peer block w-full border-[1px] border-[#8F8F8F] rounded-[3px] bg-transparent px-4 py-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#8F8F8F] ${disabled ? "cursor-not-allowed" : ""
                                }`}
                            placeholder=" "
                            autoComplete="off"
                            onChange={onChange}
                            disabled={disabled}
                            onKeyDown={onKeyDown}
                            defaultValue={value}
                        />
                        {error && (
                            <p className="text-red-500 text-[12px]">{error}</p>
                        )}
                        {suggestions && suggestions.length > 0 && (
                        <div className="absolute z-50 w-full">
                            <div className={`z-50 w-full max-h-[15rem] overflow-y-auto lite-scrollbar`} style={{ backgroundColor: '#FFFFFF', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                                <p className='text-[#000000] bg-white text-[15px] border-b border-[#E9E9E9] px-5 py-3 sticky top-0'>Do you Mean</p>
                                <ul className='suggestion-options'>
                                    {suggestions.map(subcaste => (
                                        <li key={subcaste.id} className='px-5 py-2 text-[15px] text-[#8F8F8F] cursor-pointer'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onSuggestionSelect(subcaste)
                                            }}
                                        >{subcaste.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    </div>
                </div>}
        </>)

}

export default MyProfileEditview
import React from 'react'
import DescriptionForm from '../../../Components/Edit Profile/MyProfileComponents/DescriptionForm'

function PreviewDescription({ editMyProfileData, ProfileData ,callBackReload ,apiCallEditProfile}) {
    return (
        <DescriptionForm
            data={editMyProfileData}
            apiCallEditProfile={apiCallEditProfile}
            isMyProfileDescription="Yes"
            editfromadmin={ProfileData?.editfromadmin}
            isFrom="addDiscription"
        />
    )
}

export default PreviewDescription
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { getDeviceInfo, getGroupAuthId } from "../../Components/Kalyanlite/utils";
import { LoginContext } from "../../Contexts/LoginContext";
import EndPoints from "../../Constants/EndPoints";
import { GET, POST } from "../../Services/api_services";
import config from "../../config";
import Constants from "../../Constants/constants";
import { SelectFamilyInput } from "./PartnerPreferenceUtils";
import LoaderGif from "../../Gif/loader.gif"
import { useDispatch, useSelector } from "react-redux";
import { registerStarList } from "../../Store/Home/Action";

import staricon from "../../Images/star-icon.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RouteSegments from "../../Routes/RouteSegment";

const transformToOption = (item) => ({
    value: item.id,
    label: item.name
});

export default function Horoscope({ data, dropDown }) {
    console.log({ data, dropDown });

    const isCommunitySite = localStorage.getItem(
        Constants.loginLocalStorageKeys.isCommunitySite
    );
    const { loginDetail, logout } = useContext(LoginContext);
    const [starDropDown, setStarDropDown] = useState([]);
    const [raasiDropDown, setRaasiDropDown] = useState([]);
    const [dosamDropDown, setDosamDropDown] = useState([]);
    const [stardata, setStarData] = useState([])
    const dispatch = useDispatch();
    const { starList } = useSelector(state => ({
        starList: state.Home?.starList?.data?.data
    }));
    console.log({ starList });
    const navigate = useNavigate()

    const { ProfileData } = useSelector(state => ({
        ProfileData: state.DashBoardAPI?.ProfileData?.data
      }));

    useEffect(() => {
        if (dropDown) {
            setDosamDropDown(dropDown?.doshamData?.map(transformToOption));
        }
    }, [dropDown]);

    useEffect(() => {
        if (data?.star?.at(0)?.id) {
            apiCallRaasi(data?.star?.at(0)?.id);
        }
        if (data?.motherTongue) {
            const motherTongue = data?.motherTongue[0]?.id;
            dispatch(registerStarList(motherTongue))
        }
    }, [data])

    useEffect(() => {
        if (starList?.starData) {
            setStarDropDown(starList?.starData?.map(transformToOption));
        }
    }, [starList?.starData])

    useEffect(() => {
        apiCallStarDetailFromId();
    }, [])


    const apiCallRaasi = async (id) => {
        const motherTongue = data?.motherTongue[0]?.id;
        let { statusCode, data: dropdowndata } = await GET(
            `${config.api.API_URL}${EndPoints.getRaasiUrl(id, motherTongue)}`
        );
        if (statusCode === 200) {
            if (dropdowndata.status == "Success") {
                setRaasiDropDown(dropdowndata.data.raasi?.map(transformToOption));
                if (dropdowndata.data.raasi.length === 1) {
                    formik.setFieldValue("raasi", dropdowndata.data.raasi[0]?.id);
                }
            }
        } else if (statusCode === 401) {
            logout();
        }
    };


    const apiCallStarDetailFromId = async (value) => {
        let request = {
            user_id: loginDetail()[1],
        };

        const response = await axios.post(`${config.cms_api}/getStarDetailFromId`, request)


        if (response.status == 200) {
            setStarData(response.data?.stars ?? []);
        } else if (response.status === 401) {
            logout();
        }
    };

    const apiCallHoroscope = async (value) => {
        let request = {
            email: loginDetail()[0],
            userId: loginDetail()[1],
            starId: value.star,
            raasiId: value.raasi,
            doshamId: value.dosham,
            requestfulfilledfrom: getDeviceInfo(),
            requestfulfilledvia: "afterRegistration",
            recieverprofileid: getGroupAuthId(),
            // messageTypeId:"2",
            // kmcareId: "1"  
        };

        let { statusCode, data } = await POST(
            `${config.api.API_URL}${EndPoints.edithoroscopeinfosave()}`,
            request
        );

        if (statusCode === 200) {
            if (data.status === "Success") {
                handleSkipClick()
            }
        } else if (statusCode === 401) {
            logout();
        }
    };

    const formik = useFormik({
        initialValues: {
            star: data?.star ? data?.star[0]?.id : "",
            raasi: data?.raasi ? data?.raasi[0]?.id : "",
            dosham: data?.dosham ? data?.dosham[0]?.id : "",
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            apiCallHoroscope(values);
        },
    });

    const handleSkipClick = () => {
        
        console.log(localStorage.getItem(Constants.loginLocalStorageKeys.isOriginalImageUploaded),'iop');

        if(localStorage.getItem(Constants.loginLocalStorageKeys.isOriginalImageUploaded) == "true"){
            if (isCommunitySite) {
                if (localStorage.getItem("submitpref")) {
                    localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.MATCH_GROUP)
                    navigate(RouteSegments.MATCH_GROUP)
                }
            } else {
                // localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.DOWNLOAD_PAGE)
                navigate(RouteSegments.PROFILE_PREVIEW,{state : {isFrom : "afterreg"}})
            }
        }else{
            localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.UPLOAD_PHOTO_PAGE)
            sessionStorage.setItem("photoPage",true);
            navigate(RouteSegments.UPLOAD_PHOTO_PAGE,{state : { isFrom : "previous" }})
        }
    }

    return <div className="mx-2 lg:pb-[2rem] md:pb-[1rem] pb-[0.5rem]">
        <div className="mt-[2rem] md:mt-[2rem] lg:mt-[2rem] lg:pl-[2rem]">
            <div className="space-y-2">
                <p
                    ref={(ref) => {
                        ref?.scrollIntoView({ behavior: "smooth" })
                    }}
                    className="text-[#D63838] font-bold text-[18px] md:text-[18px] lg:text-[22px]">
                    Add Astrological Information
                </p>
            </div>
        </div>
        <div className={`mt-4 md:mx-auto lg:w-[30rem] flex justify-center items-center gap-2 md:gap-5 py-2 px-2 md:px-5 rounded-lg bg-[#FEBABB]`}>
            <div className="w-[4rem] h-[3rem]"><img className="md:h-[3rem]" src={staricon} alt="" /></div>
            <div className="text-[13px] space-y-2">
                <p className="text-[#2C2B2B] text-[10px] md:text-[17px]">
                    We are Recommending the below stars to choose based on your Date of Birth
                </p>
                <div className="flex gap-3">
                    {stardata?.map((star) => (
                        <button
                            key={star.id}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                formik.setFieldValue("star", `${star.id}`, false);
                                apiCallRaasi(star.id);
                            }}
                            className={`${String(formik.values.star) === String(star.id) ? "bg-[#D10A11]" : "bg-[#7A7A7A]"} text-[8px] md:text-[12px] rounded-md text-white text-center p-2`}
                        >
                            {star.value}
                        </button>
                    ))}
                </div>

            </div>

        </div>
        <form onSubmit={formik.handleSubmit}>
            <div className="p-0 md:p-0 lg:px-[2rem]">
                <SelectFamilyInput
                    name="star"
                    title="Star"
                    value={formik.values.star}
                    onChange={(selected) => {
                        formik.setFieldValue("star", selected)
                        formik.setFieldValue("raasi", "")
                        apiCallRaasi(selected)
                    }}
                    error={formik.errors.star}
                    defaultValue={formik.values.star}
                    options={[{ value: null, label: "Select", disabled: true }, ...starDropDown]}
                    menu="aferRegister"
                    placeholder="Select Star"
                    disabled={formik.isSubmitting}
                />

                <SelectFamilyInput
                    name="raasi"
                    title="Raasi"
                    value={formik.values.raasi}
                    onChange={(selected) => {
                        formik.setFieldValue("raasi", selected)
                    }}
                    error={formik.errors.raasi}
                    defaultValue={formik.values.raasi}
                    options={[
                        { value: null, label: "Select", disabled: true },
                        ...raasiDropDown,
                    ]}
                    menu="aferRegister"
                    placeholder="Select Raasi"
                    disabled={formik.isSubmitting}
                />

                <SelectFamilyInput
                    name="dosham"
                    title="Dosham"
                    value={formik.values.dosham}
                    onChange={(selected, ev) => {
                        formik.setFieldValue("dosham", selected);
                    }}
                    error={formik.errors.dosham}
                    defaultValue={formik.values.dosham}
                    options={[
                        { value: null, label: "Select", disabled: true },
                        ...dosamDropDown,
                    ]}
                    menu="aferRegister"
                    placeholder="Select Dosham"
                    disabled={formik.isSubmitting}

                />
                <div className="flex  mt-7 items-center justify-around  py-4">
                    <div></div>
                    <button
                        type="submit"
                        className={`text-[14px]  text-white px-4 lg:px-[2.5rem] py-2.5 rounded-md ${!formik.dirty ? "bg-[#DA3535] opacity-50 cursor-not-allowed" : "bg-[#DA3535] cursor-pointer"}`}
                        disabled={!formik.isValid || formik.isSubmitting}>
                        {formik.isSubmitting ? <div className="flex"><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" /><span>Submitting...</span></div> : "Submit & Continue"}
                    </button>
                    {isCommunitySite ? null : (

                        <div className="pl-[6rem] md:pl-[12rem]">
                            {false ? '' :
                                <button
                                    type="button"
                                    onClick={handleSkipClick}
                                    className="cursor-pointer pt-2 text-[#C1C1C1] text-[13px] underline"
                                >
                                    Skip
                                </button>}
                        </div>
                    )}
                </div>

            </div>
        </form >
    </div >
}
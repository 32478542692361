import React from 'react'
import HabitsDetails from '../HabitesDetails'

function PreviewHabbitsForm({ editMyProfileData, ProfileData,apiCallEditProfile }) {
    return (
        <HabitsDetails
            data={editMyProfileData}
            callBackReload={() => { 
                apiCallEditProfile()
            }}
            editfromadmin={ProfileData?.editfromadmin}
            isScroll={false}
            isFrom="habitdetails"
        />
    )
}

export default PreviewHabbitsForm
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as KalyanLiteSelector from "../../Store/KalyanLite/selectors";
import hobbiesicon from '../../Components/Kalyanlite/assets/Images/hobbiesicon.svg';
import musicgenresicon from '../../Components/Kalyanlite/assets/Images/musicgenresicon.svg';
import musicicon from '../../Components/Kalyanlite/assets/Images/musicicon.svg';
import bookicon from '../../Components/Kalyanlite/assets/Images/bookicon.svg';
import movieicon from '../../Components/Kalyanlite/assets/Images/movieicon.svg';
import typemovieicon from '../../Components/Kalyanlite/assets/Images/typemoiveicon.svg';
import sportsicon from '../../Components/Kalyanlite/assets/Images/sportsicon.svg';
import cuisineicon from '../../Components/Kalyanlite/assets/Images/cuisineicon.svg';
import languageicon from '../../Components/Kalyanlite/assets/Images/languageicon.svg';
import languageimage from '../../Components/Kalyanlite/assets/Images/languageimage.svg';
import physicalstatusIcon from '../../Components/Kalyanlite/assets/Images/physicalstatusIcon.svg';
import familystatusIcon from '../../Components/Kalyanlite/assets/Images/familyStatus.svg';
import familyTypeIcon from '../../Components/Kalyanlite/assets/Images/familyType.svg';
import familyValueIcon from '../../Components/Kalyanlite/assets/Images/familyValues.svg';
import parentsIncomeIcon from '../../Components/Kalyanlite/assets/Images/parentsIncome.svg';

import EmployedType from '../../Components/Kalyanlite/assets/Images/EmployedIn.svg';
import Designation from '../../Components/Kalyanlite/assets/Images/designation.svg';

import FatherIcon from '../../Components/Kalyanlite/assets/Images/father.svg';
import MotherIcon from '../../Components/Kalyanlite/assets/Images/mother.svg';
import SisterBrotherIcon from '../../Components/Kalyanlite/assets/Images/sister_brother.svg';
import MarriedIcon from '../../Components/Kalyanlite/assets/Images/married.svg';

import { commaWithSpace, getIsLoginFromAdmin } from "../../Utils";

import { getDeviceInfo, getGroupAuthId, requestLookup } from './utils';
import Constants, { EventName } from '../../Constants/constants';
import { profileSendMessage } from '../../Store/Chats/Action';
import LoaderGif from "../../Gif/loader.gif";
import { storeRequestType } from '../../Store/KalyanLite/Action';
import { useAnalytics } from '../../Hooks/usePageChange';
import { CommonProfilePopup, ValidationNotificationPopup } from '../CommonPopup';
import { useTranslation } from 'react-i18next';
import * as Images from "../../Components/Kalyanlite/assets/images";
import { NavBarContext } from '../../Contexts/NavBarContext';



export const BasicDetails = ({ page,menu ,postData}) => {

    const profileData = useSelector(KalyanLiteSelector.getMatchProfileById)

    const { t: trans } = useTranslation();

    console.log(profileData, "profileDataprofileData");

    const additionalDetails = profileData?.profiledetails?.profileGender === "M" ? 'his_additional_details' : 'her_additional_details';


    return (

        <div className='lg:px-8 md:px-5 mb-5 md:mb-8 text-[13px] lg:bg-[#F0F2F5] bg-[#ffffff] mx-3 mt-[2rem] md:mt-[-4rem] lg:mt-0 space-y-4'>
            {page === "chat" ? <p className='border-[#E9E9E9] border-b-[2px] w-full pt-[3rem] hidden lg:block md:hidden'></p> : null}
            {profileData?.aboutprofile == null || profileData?.aboutprofile === 'Not Specified' || profileData?.aboutprofile === "requested" || profileData?.aboutprofile.trim() == "" ? 
            (
            // <div className='lg:mt-5 md:mt-24'></div>
            <>           {menu=="chatprofile"?

                <RequestItem 
                className={"flex md:p-2  py-1 lg:mt-5 md:mt-24"}
                // icon={"/Assets/Images/Group 274.svg"}
                title={trans('view_profile.about_me')}
                requestKey={"description"}
                value={profileData?.aboutprofile}
                keyName={"aboutprofile"}
                page={page}
                type="profilechat"
                
                eventTrack={EventName.View_Profile_Not_Specified_field_request_Description_box_Button}
                style={{ marginRight: "20px" }}
                postData={postData}
                />
                :
                <RequestItem 
                className={"flex md:p-2  py-1 lg:mt-5 md:mt-24"}
                // icon={"/Assets/Images/Group 274.svg"}
                title={trans('view_profile.about_me')}
                requestKey={"description"}
                value={profileData?.aboutprofile}
                keyName={"aboutprofile"}
                page={page}
                eventTrack={EventName.View_Profile_Not_Specified_field_request_Description_box_Button}
                style={{ marginRight: "20px" }}
                profileMobileNumber={profileData?.ProfileMobileNumber}
                postData={postData}
                />
            }
                <p className='border-[#E9E9E9] border-b-[2px] w-full pt-[.5rem] mb-4 md:mb-4 lg:mb-4'></p>
            </>
            
            ):
             <div className={`hidden md:block  py-5 lg:py-0 ${page === "chat" ? "mt-1" : ""}`}>
             <p className={`px-4 md:px-0 text-[#1A1A1A] text-[20px] tracking-wide font-Poppins font-semibold pt-[3rem]  lg:pt-0`}>{trans('view_profile.about_me')}</p>
             <p className='px-4 md:px-0 text-[#1A1A1A] text-[12px] font-light font-Poppins-Regular pt-4'>{profileData?.aboutprofile || 'Not Specified'}</p>
             <p className='border-[#E9E9E9] border-b-[2px] w-full pt-[1rem]'></p>
         </div>
}

            <div className="px-4 md:px-0">
                <p className="text-[#575556] text-[16px] md:text-[16px] lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui">
                    {trans('view_profile.basic_details')}
                </p>
                <div className='hidden md:block'>
                    <div className="grid grid-cols-2 py-2 lg:grid-cols-4 md:gap-4 lg:space-x-8">
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 503.svg"}
                            title={trans('view_profile.age')}
                            value={
                                profileData?.basic_detailsprofile?.basicprofileage
                            }
                            style={{ marginRight: "20px" }}
                        />
                        <DetailsItem
                            className={
                                "flex pl-[2rem] leading-[17px] mt-2 md:mt-0 md:leading-0 md:p-2"
                            }
                            icon={"/Assets/Images/Group 500.svg"}
                            title={trans('view_profile.weight')}
                            value={
                                profileData?.basic_detailsprofile?.basicprofileweight?.replaceAll(",", ",  ")

                            }
                            style={{ marginRight: "20px" }}
                        />
                        <DetailsItem
                            className={
                                "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                            }
                            icon={"/Assets/Images/Group 272.svg"}
                            title={trans('view_profile.marital_status')}
                            value={
                                profileData?.basic_detailsprofile?.basicprofilemartialstatus
                            }
                            style={{ marginRight: "20px" }}
                        />
                        <DetailsItem
                            className={
                                "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 pl-[2rem] md:p-2"
                            }
                            icon={"/Assets/Images/Group 504.svg"}
                            title={trans('view_profile.citizen')}
                            value={
                                profileData?.basic_detailsprofile?.basicprofilecitzen
                            }
                            style={{ marginRight: "20px" }}
                        />
                    </div>
                    <div className='grid grid-cols-2 gap-4 py-2 lg:grid-cols-4 lg:space-x-8'>
                        <DetailsItem
                            className={"flex mt-[0.5rem] md:mt-0 md:p-2"}
                            icon={"/Assets/Images/Group 505.svg"}
                            title={trans('view_profile.lives_in')}
                            value={
                                profileData?.basic_detailsprofile?.basicprofilelivesin.replaceAll(",", ",  ")
                            }
                            style={{ marginRight: "20px" }}
                        />
                        <DetailsItem
                            className={
                                "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                            }
                            icon={"/Assets/Images/Group 272.svg"}
                            title={trans('view_profile.physical_status')}
                            value={
                                profileData?.basic_detailsprofile?.basicphysicalstatus
                            }
                            style={{ marginRight: "20px" }}
                        />
                        <DetailsItem
                            className={
                                "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                            }
                            icon={physicalstatusIcon}
                            title={trans('view_profile.complexion')}
                            value={
                                profileData?.basic_detailsprofile?.basicprofilecomplexion
                            }
                            style={{ marginRight: "20px" }}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-2 py-2 md:hidden lg:grid-cols-4 lg:gap-4 md:gap-4">
                    <DetailsItem
                        className={"flex md:p-2 "}
                        icon={"/Assets/Images/Group 503.svg"}
                        title={trans('view_profile.age')}
                        value={
                            profileData?.basic_detailsprofile?.basicprofileage
                        }
                    />
                    <DetailsItem
                        className={
                            "flex pl-[2rem] leading-[17px] mt-2 md:mt-0 md:leading-0 md:p-2"
                        }
                        icon={"/Assets/Images/Group 500.svg"}
                        title={trans('view_profile.weight')}
                        value={
                            profileData?.basic_detailsprofile?.basicprofileweight?.replaceAll(",", ",  ")

                        }
                    />
                    <DetailsItem
                        className={
                            "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                        }
                        icon={"/Assets/Images/Group 272.svg"}
                        title={trans('view_profile.marital_status')}
                        value={
                            profileData?.basic_detailsprofile?.basicprofilemartialstatus
                        }
                    />
                    <DetailsItem
                        className={
                            "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 pl-[2rem] md:p-2"
                        }
                        icon={"/Assets/Images/Group 504.svg"}
                        title={trans('view_profile.citizen')}
                        value={
                            profileData?.basic_detailsprofile?.basicprofilecitzen
                        }
                    />
                    <DetailsItem
                        className={"flex mt-[0.5rem] md:mt-0 md:p-2"}
                        icon={"/Assets/Images/Group 505.svg"}
                        title={trans('view_profile.lives_in')}
                        value={
                            profileData?.basic_detailsprofile?.basicprofilelivesin?.replaceAll(",", ",  ")
                        }
                    />
                    <DetailsItem
                        className={
                            "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                        }
                        icon={"/Assets/Images/Group 272.svg"}
                        title={trans('view_profile.physical_status')}
                        value={
                            profileData?.basic_detailsprofile?.basicphysicalstatus
                        }
                    />
                    <DetailsItem
                        className={
                            "flex leading-[17px] mt-[0.5rem] md:mt-0 md:leading-0 md:p-2"
                        }
                        icon={physicalstatusIcon}
                        title={trans('view_profile.complexion')}
                        value={
                            profileData?.basic_detailsprofile?.basicprofilecomplexion
                        }
                    />
                </div>

                <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                <div className='mt-4'>
                    <p className="text-[#575556] text-[16px] md:text-[16px] lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui">
                        {profileData?.profiledetails?.profileGender === "M"
                            ? trans('view_profile.his_religious_background')
                            : trans('view_profile.her_religious_background')}{" "}
                    </p>


                    {(profileData?.religious?.mothertougue?.includes("Christian")) ?
                        <>
                            <div className="grid grid-cols-1 gap-2 py-2 lg:grid-cols-4 md:grid-cols-2 lg:pb-0 lg:gap-4 md:gap-4 lg:space-x-8">
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    icon={"/Assets/Images/Group 506.svg"}
                                    title={trans('view_profile.religion__mother_tongue')}
                                    value={`${profileData?.religious?.mothertougue}`}
                                    style={{ marginRight: "20px" }}
                                />
                                {profileData?.religious?.mothertougue?.includes("Christian") && <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    icon={"/Assets/Images/Group 274.svg"}
                                    title={trans('view_profile.denomination')}
                                    value={profileData?.religious?.domain}
                                    style={{ marginRight: "20px" }}
                                />}
                                <DetailsItem
                                    className={"flex md:p-2 py-1"}
                                    icon={"/Assets/Images/Group 274.svg"}
                                    title={trans('view_profile.caste')}
                                    value={profileData?.religious?.caste}
                                    style={{ marginRight: "20px" }}
                                />
                                {(profileData?.religious?.subcaste != 'Not Specified' && profileData?.religious?.subcaste != null && profileData?.religious?.subcaste != "requested") ? (
                                <DetailsItem
                                    className={"flex md:p-2  py-1"}
                                    icon={"/Assets/Images/Group 274.svg"}
                                    title={trans('view_profile.sub_caste')}
                                    value={profileData?.religious?.subcaste}
                                    style={{ marginRight: "20px" }}
                                />
                                ):
                                <RequestItem 
                                className={"flex md:p-2  py-1"}
                                icon={"/Assets/Images/Group 274.svg"}
                                title={trans('view_profile.sub_caste')}
                                requestKey={"Sub Caste"}
                                value={profileData?.religious?.subcaste}
                                keyName={"religious?.subcaste"} 
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_SubCaste_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                                />
                                }
                            </div>
                            {profileData?.religious?.gothrom != 'Not Specified'
                             && profileData?.religious?.gothrom != null && 

                            <div className="grid grid-cols-1 py-2 lg:grid-cols-4 md:grid-cols-2 lg:pb-2 lg:pt-0 lg:space-x-8">
                                <DetailsItem
                                    className={"flex md:p-2  py-1"}
                                    icon={"/Assets/Images/Group 274.svg"}
                                    title={trans('view_profile.gothram')}
                                    value={profileData?.religious?.gothrom}
                                    style={{ marginRight: "20px" }}
                                />
                            </div>
                             }
                        </>
                        :
                        <div className="grid grid-cols-1 gap-2 py-3 lg:grid-cols-4 md:grid-cols-2 lg:gap-4 md:gap-4 lg:space-x-8">
                            <DetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={"/Assets/Images/Group 506.svg"}
                                title={trans('view_profile.religion__mother_tongue')}
                                value={`${profileData?.religious?.mothertougue}`}
                                style={{ marginRight: "20px" }}
                            />
                            {profileData?.religious?.mothertougue?.includes("Christian") && <DetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={"/Assets/Images/Group 274.svg"}
                                title={trans('view_profile.denomination')}
                                value={profileData?.religious?.domain}
                                style={{ marginRight: "20px" }}
                            />}
                            <DetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={"/Assets/Images/Group 274.svg"}
                                title={trans('view_profile.caste')}
                                value={profileData?.religious?.caste}
                                style={{ marginRight: "20px" }}
                            />
                            {(profileData?.religious?.subcaste != 'Not Specified' && profileData?.religious?.subcaste != null && profileData?.religious?.subcaste != "requested") ? (
                            <DetailsItem
                                className={"flex md:p-2  py-1"}
                                icon={"/Assets/Images/Group 274.svg"}
                                title={trans('view_profile.sub_caste')}
                                value={profileData?.religious?.subcaste}
                                style={{ marginRight: "20px" }}
                            />
                            ):
                            <RequestItem 
                                className={"flex md:p-2  py-1"}
                                icon={"/Assets/Images/Group 274.svg"}
                                title={trans('view_profile.sub_caste')}
                                requestKey={"Sub Caste"}
                                value={profileData?.religious?.subcaste}
                                keyName={"religious?.subcaste"}
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_SubCaste_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                            }
                            {profileData?.religious?.gothrom != 'Not Specified' && profileData?.religious?.gothrom != null ? (
                            <DetailsItem
                                className={"flex md:p-2  py-1"}
                                icon={"/Assets/Images/Group 274.svg"}
                                title={trans('view_profile.gothram')}
                                value={profileData?.religious?.gothrom}
                                style={{ marginRight: "20px" }}
                            />
                            ):<></>}
                        </div>
                    }
                </div>
                {/* {profileData?.horoscope?.star == 'Not Specified' && profileData?.horoscope?.rashi == 'Not Specified' && profileData?.horoscope?.dosham == 'Not Specified' ? (<></> ): */}
                <>
                <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                <div className='mt-4'>
                    <p className="text-[#575556] text-[16px] md:text-[16px] lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui">
                    {trans('view_profile.horoscope')}
                    </p>
                    <div className="grid lg:grid-cols-4 grid-cols-2 py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8">
                        {profileData?.horoscope?.star != 'Not Specified' && profileData?.horoscope?.star != null && profileData?.horoscope?.star != 'requested' ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 511.svg"}
                            title={trans('view_profile.star')}
                            value={profileData?.horoscope?.star}
                            style={{ marginRight: "20px" }}
                        />
                        ):
                        <RequestItem 
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 511.svg"}
                            title={trans('view_profile.star')}
                            requestKey={"star"}
                            value={profileData?.horoscope?.star}
                            keyName={"horoscope?.star"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Star_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.horoscope?.rashi != 'Not Specified' && profileData?.horoscope?.rashi != null && profileData?.horoscope?.rashi != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 512.svg"}
                            title={trans('view_profile.raasi')}
                            value={profileData?.horoscope?.rashi}
                            style={{ marginRight: "20px" }}
                        />
                        ):
                        <RequestItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 512.svg"}
                            title={trans('view_profile.raasi')}
                            requestKey={"raasi"}
                            value={profileData?.horoscope?.rashi}
                            keyName={"horoscope?.rashi"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Raasi_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.horoscope?.dosham != 'Not Specified' && profileData?.horoscope?.dosham != null && profileData?.horoscope?.dosham != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 513.svg"}
                            title={trans('view_profile.dosham')}
                            value={profileData?.horoscope?.dosham}
                            style={{ marginRight: "20px" }}
                        />
                        ):
                        <RequestItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 513.svg"}
                            title={trans('view_profile.dosham')}
                            requestKey={"dosham"}
                            value={profileData?.horoscope?.dosham}
                            keyName={"horoscope?.dosham"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_ChevvaiDosham_Mangalik_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                    </div>
                </div></>
               {/* } */}
                {/* {profileData?.familydetails?.familystatus == 'Not Specified' && profileData?.familydetails?.familytype == 'Not Specified' && profileData?.familydetails?.familyvalues == 'Not Specified' && (profileData?.familydetails?.parentsmonthlyincome == 0 || profileData?.familydetails?.parentsmonthlyincome == 'Not Specified') ? (<></> ): */}
                <>
                <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                <div className='mt-4'>
                    <p className="text-[#575556] text-[16px] md:text-[16px] lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui">
                    {trans('view_profile.family_details')}
                    </p>
                    <div className="grid lg:grid-cols-4 grid-cols-2 py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8">
                    {profileData?.familydetails?.familystatus != 'Not Specified' && profileData?.religious?.gothrom != null && profileData?.familydetails?.familystatus != 'requested' ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={familystatusIcon}
                            title={trans('view_profile.family_status')}
                            value={profileData?.familydetails?.familystatus}
                            style={{ marginRight: "20px" }}
                        />
                    ):
                        <RequestItem
                            className={"flex md:p-2 "}
                            icon={familystatusIcon}
                            title={trans('view_profile.family_status')}
                            requestKey={"family_status"}
                            value={profileData?.familydetails?.familystatus}
                            keyName={"familydetails?.familystatus"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_FamilyStatus_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                    }
                    {profileData?.familydetails?.familytype != 'Not Specified' && profileData?.familydetails?.familytype!= null && profileData?.familydetails?.familytype!= "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={familyTypeIcon}
                            title={trans('view_profile.family_type')}
                            value={profileData?.familydetails?.familytype}
                            style={{ marginRight: "20px" }}
                        />
                    ):
                        <RequestItem
                            className={"flex md:p-2 "}
                            icon={familyTypeIcon}
                            title={trans('view_profile.family_type')}
                            requestKey={"family_type"}
                            value={profileData?.familydetails?.familytype}
                            keyName={"familydetails?.familytype"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_FamilyType_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                    }
                    {profileData?.familydetails?.familyvalues != 'Not Specified' && profileData?.familydetails?.familyvalues!= null && profileData?.familydetails?.familyvalues!= "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={familyValueIcon}
                            title={trans('view_profile.family_values')}
                            value={profileData?.familydetails?.familyvalues}
                            style={{ marginRight: "20px" }}
                        />
                    ):
                        <RequestItem
                            className={"flex md:p-2 "}
                            icon={familyValueIcon}
                            title={trans('view_profile.family_values')}
                            requestKey={"family_values"}
                            value={profileData?.familydetails?.familyvalues}
                            keyName={"familydetails?.familyvalues"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_FamilyValues_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                    }
                        {profileData?.familydetails?.parentsmonthlyincome !='Not Specified' && profileData?.familydetails?.parentsmonthlyincome != 0 && profileData?.familydetails?.parentsmonthlyincome != null && profileData?.familydetails?.parentsmonthlyincome != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={parentsIncomeIcon}
                            title={trans('view_profile.parents_income')}
                            value={profileData?.familydetails?.parentsmonthlyincome}
                            style={{ marginRight: "20px" }}
                        />
                        ):
                            <RequestItem
                                className={"flex md:p-2 "}
                                icon={parentsIncomeIcon}
                                title={trans('view_profile.parents_income')}
                                requestKey={"family_monthly_income"}
                                value={profileData?.familydetails?.parentsmonthlyincome}
                                keyName={"familydetails?.parentsmonthlyincome"}
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_ParentsMonthlyIncome_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                    </div>
                </div></>
                {/* } */}
                {/* {profileData?.foodandhabits?.dietPreferences == 'Not Specified' && profileData?.foodandhabits?.drinking == 'Not Specified' && profileData?.foodandhabits?.Smoking == 'Not Specified'? (<></> ): */}
                <>
                <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                <div className="mt-4 md:pt-0">
                    <p className="text-[#575556] text-[16px] md:text-[16px] lg:text-[15px] xl:text-[17px] font-bold font-segoe-ui">
                        {trans('view_profile.food_and_habits')}
                    </p>
                    <div className="grid lg:grid-cols-4 grid-cols-2 py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8">
                    {profileData?.foodandhabits?.dietPreferences != 'Not Specified' && profileData?.foodandhabits?.dietPreferences != null && profileData?.foodandhabits?.dietPreferences != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 509.svg"}
                            title={trans('view_profile.food_habits')}
                            value={
                                profileData?.foodandhabits?.dietPreferences
                            }
                            style={{ marginRight: "20px" }}
                        />
                    ):
                        <RequestItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 509.svg"}
                            title={trans('view_profile.food_habits')}
                            requestKey={"food_habits"}
                            value={profileData?.foodandhabits?.dietPreferences}
                            keyName={"foodandhabits?.dietPreferences"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_FoodHabits_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                    }
                    {profileData?.foodandhabits?.drinking != 'Not Specified' && profileData?.foodandhabits?.drinking != null && profileData?.foodandhabits?.drinking != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 507 (1).svg"}
                            title={trans('view_profile.drinking')}
                            value={profileData?.foodandhabits?.drinking}
                            style={{ marginRight: "20px" }}
                        />
                    ):
                        <RequestItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 507 (1).svg"}
                            title={trans('view_profile.drinking')}
                            requestKey={"drinking"}
                            value={profileData?.foodandhabits?.drinking}
                            keyName={"foodandhabits?.drinking"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Drinking_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                    }
                    {profileData?.foodandhabits?.Smoking != 'Not Specified' && profileData?.foodandhabits?.Smoking != null && profileData?.foodandhabits?.Smoking != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 508.svg"}
                            title={trans('view_profile.smoking')}
                            value={profileData?.foodandhabits?.Smoking}
                            style={{ marginRight: "20px" }}
                        />
                    ):
                        <RequestItem
                            className={"flex md:p-2 "}
                            icon={"/Assets/Images/Group 508.svg"}
                            title={trans('view_profile.smoking')}
                            requestKey={"smoking"}
                            value={profileData?.foodandhabits?.Smoking}
                            keyName={"foodandhabits?.Smoking"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Smoking_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                    }
                    </div>
                </div></>
                 {/* } */}
                <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                <p className="text-[#575556] md:pt-0 text-[16px] md:text-[17px] lg:text-[16px] xl:text-[18px] font-bold mt-4 font-segoe-ui">
                    {profileData?.profiledetails?.profileGender === "M"
                        ? trans('view_profile.his_professional_details')
                        : trans('view_profile.her_professional_details')}{" "}
                </p>
                <div className="grid grid-cols-1 gap-2 py-3 lg:grid-cols-4 md:grid-cols-2 lg:gap-4 md:gap-4 lg:space-x-8">
                    <DetailsItem
                        className={"flex md:p-2 py-1"}
                        icon={"/Assets/Images/Group 514.svg"}
                        title={trans('view_profile.degree')}
                        value={profileData?.professionaldetails?.degree}
                        style={{ marginRight: "20px" }}
                    />
                    <DetailsItem
                        className={"flex md:p-2 py-1"}
                        icon={EmployedType}
                        title={trans('view_profile.employment_type')}
                        value={
                            profileData?.professionaldetails?.employementType
                        }
                        style={{ marginRight: "20px" }}
                    />
                    <DetailsItem
                        className={"flex md:p-2 py-1"}
                        icon={Designation}
                        title={trans('view_profile.designation')}
                        value={
                            profileData?.professionaldetails?.designation
                        }
                        style={{ marginRight: "20px" }}
                    />
                    {profileData?.professionaldetails?.AnnualIncome != 'Not Specified' && profileData?.professionaldetails?.AnnualIncome != null && profileData?.professionaldetails?.AnnualIncome  != 0 && profileData?.professionaldetails?.AnnualIncome != "requested" ? (
                    <DetailsItem
                        className={"flex md:p-2 py-1"}
                        icon={"/Assets/Images/Group 499.svg"}
                        title={trans('view_profile.monthly_income')}
                        value={
                            profileData?.professionaldetails?.AnnualIncome
                        }
                        style={{ marginRight: "20px" }}
                    />
                    ):
                    <RequestItem
                        className={"flex md:p-2 py-1"}
                        icon={"/Assets/Images/Group 499.svg"}
                        title={trans('view_profile.monthly_income')}
                        requestKey={"Monthly Income"}
                        value={profileData?.professionaldetails?.AnnualIncome}
                        keyName={"professionaldetails?.AnnualIncome"}
                        page={page}
                        eventTrack={EventName.View_Profile_Not_Specified_field_request_MonthlyIncome_Button}
                        style={{ marginRight: "20px" }}
                        profileMobileNumber={profileData?.ProfileMobileNumber}
                        postData={postData}
                    />
                    }

                </div>

                {/* {isHobbiesEmpty
                    ? null
                    :  */}
                    <>
                        <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                        <p className="text-[#575556] md:pt-0 text-[16px] md:text-[17px] lg:text-[16px] xl:text-[18px] font-bold mt-4 font-segoe-ui">
                            {trans('view_profile.hobbies__interests')}
                        </p>
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-4 py-2 mt-2">
                        {profileData?.hobbiesInterest?.hobbiesAndInterest != 'Not Specified' && profileData?.hobbiesInterest?.hobbiesAndInterest != null && profileData?.hobbiesInterest?.hobbiesAndInterest != "requested" ? (
                            <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={hobbiesicon}
                                title={trans('view_profile.hobbies__interests')}
                                value={profileData?.hobbiesInterest?.hobbiesAndInterest ?
                                    profileData?.hobbiesInterest?.hobbiesAndInterest : 'Not Specified'}
                                style={{ marginRight: "20px" }}
                            />
                        ):
                            <RequestItem
                                className={"flex md:p-2 py-1"}
                                icon={hobbiesicon}
                                title={trans('view_profile.hobbies__interests')}
                                requestKey={"hobbies_and_interests"}
                                value={profileData?.hobbiesInterest?.hobbiesAndInterest}
                                keyName={"hobbiesInterest?.hobbiesAndInterest"}
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                        {profileData?.hobbiesInterest?.musicListen != 'Not Specified' && profileData?.hobbiesInterest?.musicListen != null && profileData?.hobbiesInterest?.musicListen != "requested" ? (
                            <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={musicgenresicon}
                                title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.music_genres_he_listen_to'):trans('view_profile.music_genres_she_listen_to')}
                                value={
                                    profileData?.hobbiesInterest?.musicListen ?
                                        profileData?.hobbiesInterest?.musicListen : 'Not Specified'
                                }
                                style={{ marginRight: "20px" }}
                            />
                        ):
                            <RequestItem
                                className={"flex md:p-2 py-1"}
                                icon={musicgenresicon}
                                title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.music_genres_he_listen_to'):trans('view_profile.music_genres_she_listen_to')}
                                requestKey={"music_genres_you_listen_to"}
                                value={profileData?.hobbiesInterest?.musicListen}
                                keyName={"hobbiesInterest?.musicListen"}
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                        {profileData?.hobbiesInterest?.favoriteMusicDirectors != 'Not Specified' && profileData?.hobbiesInterest?.favoriteMusicDirectors != null && profileData?.hobbiesInterest?.favoriteMusicDirectors != "requested" ? (
                            <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={musicicon}
                                title={trans('view_profile.favorite_music_directors')}
                                value={
                                    profileData?.hobbiesInterest?.favoriteMusicDirectors ?
                                        profileData?.hobbiesInterest?.favoriteMusicDirectors : 'Not Specified'
                                }
                                style={{ marginRight: "20px" }}
                            />
                        ):
                            <RequestItem
                                className={"flex md:p-2 py-1"}
                                icon={musicicon}
                                title={trans('view_profile.favorite_music_directors')}
                                requestKey={"favourite_music_directors"}
                                value={profileData?.hobbiesInterest?.favoriteMusicDirectors}
                                keyName={"hobbiesInterest?.favoriteMusicDirectors"}
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                        {profileData?.hobbiesInterest?.TypeofBooksYouRead != 'Not Specified' && profileData?.hobbiesInterest?.TypeofBooksYouRead != null && profileData?.hobbiesInterest?.TypeofBooksYouRead != "requested" ? (
                            <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={bookicon}
                                title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.books_he_read') : trans('view_profile.books_she_read') }
                                value={
                                    profileData?.hobbiesInterest?.TypeofBooksYouRead ?
                                        profileData?.hobbiesInterest?.TypeofBooksYouRead : 'Not Specified'
                                }
                                style={{ marginRight: "20px" }}
                            />
                        ):
                            <RequestItem
                                className={"flex md:p-2 py-1"}
                                icon={bookicon}
                                title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.books_he_read') : trans('view_profile.books_she_read') }                                requestKey={"type_of_books_you_read"}
                                value={profileData?.hobbiesInterest?.TypeofBooksYouRead}
                                keyName={"hobbiesInterest?.TypeofBooksYouRead"}
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                        {profileData?.hobbiesInterest?.TypeOfMoviesYouWatch != 'Not Specified' && profileData?.hobbiesInterest?.TypeOfMoviesYouWatch != null && profileData?.hobbiesInterest?.TypeOfMoviesYouWatch != "requested" ? (
                            <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={typemovieicon}
                                title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.movies_he_watch'):  trans('view_profile.movies_she_watch')}
                                value={
                                    profileData?.hobbiesInterest?.TypeOfMoviesYouWatch ?
                                        profileData?.hobbiesInterest?.TypeOfMoviesYouWatch : 'Not Specified'
                                }
                                style={{ marginRight: "20px" }}
                            />
                        ):
                            <RequestItem
                                className={"flex md:p-2 py-1"}
                                icon={typemovieicon}
                                title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.movies_he_watch'):  trans('view_profile.movies_she_watch')}                                requestKey={"type_of_movies_you_watch"}
                                value={profileData?.hobbiesInterest?.TypeOfMoviesYouWatch}
                                keyName={"hobbiesInterest?.TypeOfMoviesYouWatch"}
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                        {profileData?.hobbiesInterest?.FavoriteMovieDirectors != 'Not Specified' && profileData?.hobbiesInterest?.FavoriteMovieDirectors != null && profileData?.hobbiesInterest?.FavoriteMovieDirectors != "requested" ? (
                            <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={movieicon}
                                title={trans('view_profile.favorite_movie_directors')}
                                value={
                                    profileData?.hobbiesInterest?.FavoriteMovieDirectors ?
                                        profileData?.hobbiesInterest?.FavoriteMovieDirectors : 'Not Specified'
                                }
                                style={{ marginRight: "20px" }}
                            />
                        ):
                            <RequestItem
                                className={"flex md:p-2 py-1"}
                                icon={movieicon}
                                title={trans('view_profile.favorite_movie_directors')}
                                requestKey={"favourite_movie_directors"}
                                value={profileData?.hobbiesInterest?.FavoriteMovieDirectors}
                                keyName={"hobbiesInterest?.FavoriteMovieDirectors"}
                                page={page}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                        {profileData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames != 'Not Specified' && profileData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames != null && profileData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames != "requested" ? (
                            <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={sportsicon}
                                title={trans('view_profile.sports_fitness_activities_game')}
                                value={
                                    profileData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames ?
                                        profileData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames : 'Not Specified'
                                }
                                style={{ marginRight: "20px" }}
                            />
                        ):
                            <RequestItem
                                className={"flex md:p-2 py-1"}
                                icon={sportsicon}
                                title={trans('view_profile.sports_fitness_activities_game')}
                                requestKey={"sports_fitness_activities_and_games"}
                                value={profileData?.hobbiesInterest?.SportsAndFitnessActivitiesAndGames}
                                page={page}
                                keyName={"hobbiesInterest?.SportsAndFitnessActivitiesAndGames"}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                        {profileData?.hobbiesInterest?.FavouriteCuisine != 'Not Specified' && profileData?.hobbiesInterest?.FavouriteCuisine != null && profileData?.hobbiesInterest?.FavouriteCuisine != "requested" ? (
                            <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={cuisineicon}
                                title={trans('view_profile.favourite_cuisine')}
                                value={
                                    profileData?.hobbiesInterest?.FavouriteCuisine ?
                                        profileData?.hobbiesInterest?.FavouriteCuisine : 'Not Specified'
                                }
                                style={{ marginRight: "20px" }}
                            />
                        ):
                            <RequestItem
                                className={"flex md:p-2 py-1"}
                                icon={cuisineicon}
                                title={trans('view_profile.favourite_cuisine')}
                                requestKey={"favourite_cuisine"}
                                value={profileData?.hobbiesInterest?.FavouriteCuisine}
                                keyName={"hobbiesInterest?.FavouriteCuisine"}
                                eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                page={page}
                                style={{ marginRight: "20px" }}
                                profileMobileNumber={profileData?.ProfileMobileNumber}
                                postData={postData}
                            />
                        }
                            {profileData?.religious?.mothertougue.includes("Malayalam") ?
                                profileData?.hobbiesInterest?.languagesYouKnow != 'Not Specified' && profileData?.hobbiesInterest?.languagesYouKnow != null && profileData?.hobbiesInterest?.languagesYouKnow != "requested" ? (
                                <HobbiesDetailsItem
                                className={"flex md:p-2 py-1"}
                                icon={languageimage}
                                title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.languages_he_know') : trans('view_profile.languages_she_know')}
                                value={
                                    profileData?.hobbiesInterest?.languagesYouKnow ?
                                        profileData?.hobbiesInterest?.languagesYouKnow : 'Not Specified'
                                }
                                style={{ marginRight: "20px" }}
                            />):
                                <RequestItem
                                    className={"flex md:p-2 py-1"}
                                    icon={languageimage}
                                    title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.languages_he_know') : trans('view_profile.languages_she_know')}                                    requestKey={"languages_you_know"}
                                    value={profileData?.hobbiesInterest?.languagesYouKnow}
                                    keyName={"hobbiesInterest?.languagesYouKnow"}
                                    eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                    page={page}
                                    style={{ marginRight: "20px" }}
                                    profileMobileNumber={profileData?.ProfileMobileNumber}
                                    postData={postData}
                                />
                            : "" }
                            {profileData?.religious?.mothertougue.includes("Malayalam") ?
                                profileData?.hobbiesInterest?.languagesThatIwishToKnow != 'Not Specified' && profileData?.hobbiesInterest?.languagesThatIwishToKnow != null && profileData?.hobbiesInterest?.languagesThatIwishToKnow != "requested" ? (
                                <HobbiesDetailsItem
                                    className={"flex md:p-2 py-1"}
                                    icon={languageicon}
                                    title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.languages_that_he_wish_to_know') : trans('view_profile.languages_that_she_wish_to_kno')}                                    value={
                                        profileData?.hobbiesInterest?.languagesThatIwishToKnow ?
                                            profileData?.hobbiesInterest?.languagesThatIwishToKnow : 'Not Specified'
                                    }
                                    style={{ marginRight: "20px" }}
                                />):
                                <RequestItem
                                    className={"flex md:p-2 py-1"}
                                    icon={languageicon}
                                    title={profileData?.profiledetails?.profileGender === "M" ? trans('view_profile.languages_that_he_wish_to_know') : trans('view_profile.languages_that_she_wish_to_kno')}                                    requestKey={"languages_that_i_wish_to_know"}
                                    value={profileData?.hobbiesInterest?.languagesThatIwishToKnow}
                                    keyName={"hobbiesInterest?.languagesThatIwishToKnow"}
                                    eventTrack={EventName.View_Profile_Not_Specified_field_request_Hobbies_and_Interest_Button}
                                    page={page}
                                    style={{ marginRight: "20px" }}
                                    profileMobileNumber={profileData?.ProfileMobileNumber}
                                    postData={postData}
                                />
                                : ""}

                        </div>
                    </>
                    {/* } */}

                    <p className='border-[#E9E9E9] border-b-[2px] w-full hidden md:block'></p>
                <p className="text-[#575556] md:pt-0 text-[16px] md:text-[17px] lg:text-[16px] xl:text-[18px] font-bold mt-4 font-segoe-ui">
                    {profileData?.profiledetails?.profileGender === "M"
                        ? trans('view_profile.his_additional_details') : trans('view_profile.his_additional_details')}{" "}
                </p>
                <>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8 py-2 mt-2">
                        {profileData?.[additionalDetails]?.fathersName != 'Not Specified' && profileData?.[additionalDetails]?.fathersName != null && profileData?.[additionalDetails]?.fathersName != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={FatherIcon}
                            title={trans('view_profile.fathers_name')}
                            value={profileData?.[additionalDetails]?.fathersName}
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={FatherIcon}
                            title={trans('view_profile.fathers_name')}
                            requestKey={"Father name"}
                            value={profileData?.[additionalDetails]?.fathersName}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.fathersName" : "her_additional_details?.fathersName"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Fathers_Name_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.[additionalDetails]?.fathersOccupation != 'Not Specified' && profileData?.[additionalDetails]?.fathersOccupation != "" && profileData?.[additionalDetails]?.fathersOccupation != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={Designation}
                            title={trans('view_profile.fathers_occupation')}
                            value={profileData?.[additionalDetails]?.fathersOccupation}
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={Designation}
                            title={trans('view_profile.fathers_occupation')}
                            requestKey={"Father occupation"}
                            value={profileData?.[additionalDetails]?.fathersOccupation}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.fathersOccupation" : "her_additional_details?.fathersOccupation"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Fathers_Occupation_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.[additionalDetails]?.fathersNative != 'Not Specified' && profileData?.[additionalDetails]?.fathersNative != "" && profileData?.[additionalDetails]?.fathersNative != "requested" ? (
                        <DetailsItem
                            className="flex md:p-2 py-1"
                            icon={Images.Native}
                            title="Father's Native"
                            value={profileData?.[additionalDetails]?.fathersNative}
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={Images.Native}
                            title="Father's Native"
                            requestKey="fathers_native"
                            value={profileData?.[additionalDetails]?.fathersNative}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.fathersNative" : "her_additional_details?.fathersNative"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Fathers_Native_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.[additionalDetails]?.mothersName != 'Not Specified' && profileData?.[additionalDetails]?.mothersName != "" && profileData?.[additionalDetails]?.mothersName != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={MotherIcon}
                            title={trans('view_profile.mothers_name')}
                            value={
                                profileData?.[additionalDetails]?.mothersName
                            }
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={MotherIcon}
                            title={trans('view_profile.mothers_name')}
                            requestKey={"Mother name"}
                            value={profileData?.[additionalDetails]?.mothersName}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.mothersName" : "her_additional_details?.mothersName"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Mothers_Name_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }

                    </div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8 pb-2">
                        
                        {profileData?.[additionalDetails]?.mothersOccupation != 'Not Specified' && profileData?.[additionalDetails]?.mothersOccupation != "" && profileData?.[additionalDetails]?.mothersOccupation != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={Designation}
                            title={trans('view_profile.mothers_occupation')}
                            value={
                                profileData?.[additionalDetails]?.mothersOccupation
                            }
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={Designation}
                            title={trans('view_profile.mothers_occupation')}
                            requestKey={"Mother occupation"}
                            value={profileData?.[additionalDetails]?.mothersOccupation}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.mothersOccupation" : "her_additional_details?.mothersOccupation"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Mothers_Occupation_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }

                        {profileData?.[additionalDetails]?.mothersNative != 'Not Specified' && profileData?.[additionalDetails]?.mothersNative != "" && profileData?.[additionalDetails]?.mothersNative != "requested" ? (
                        <DetailsItem
                            className="flex md:p-2 py-1"
                            icon={Images.Native}
                            title="Mother's Native"
                            value={profileData?.[additionalDetails]?.mothersNative}
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={Images.Native}
                            title="Mother's Native"
                            requestKey="mothers_native"
                            value={profileData?.[additionalDetails]?.mothersNative}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.mothersNative" : "her_additional_details?.mothersNative"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Mothers_Native_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        
                        {profileData?.[additionalDetails]?.brothers != 'Not Specified' && profileData?.[additionalDetails]?.brothers != "" && profileData?.[additionalDetails]?.brothers != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={SisterBrotherIcon}
                            title={trans('view_profile.brothers')}
                            value={
                                profileData?.[additionalDetails]?.brothers
                            }
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={SisterBrotherIcon}
                            title={trans('view_profile.brothers')}
                            requestKey={"Brothers"}
                            isHaveNoSiblings={profileData?.[additionalDetails]?.ishavenosiblings}
                            value={profileData?.[additionalDetails]?.brothers}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.brothers" : "her_additional_details?.brothers"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Brothers_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.[additionalDetails]?.marriedBrothers != 'Not Specified' && profileData?.[additionalDetails]?.marriedBrothers != "" && profileData?.[additionalDetails]?.marriedBrothers != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={MarriedIcon}
                            title={trans('view_profile.married_brothers')}
                            value={
                                profileData?.[additionalDetails]?.marriedBrothers
                            }
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={MarriedIcon}
                            title={trans('view_profile.married_brothers')}
                            requestKey={"Married brothers"}
                            isHaveNoSiblings={profileData?.[additionalDetails]?.ishavenosiblings}
                            value={profileData?.[additionalDetails]?.marriedBrothers}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.marriedBrothers" : "her_additional_details?.marriedBrothers"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Married_Brothers_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }

                    </div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8 pb-2'>
                        {profileData?.[additionalDetails]?.sisters != 'Not Specified' && profileData?.[additionalDetails]?.sisters != "" && profileData?.[additionalDetails]?.sisters != "requested" ? ( 
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={SisterBrotherIcon}
                            title={trans('view_profile.sisters')}
                            value={
                                profileData?.[additionalDetails]?.sisters
                            }
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8 pb-2flex md:p-2 py-1"}
                            icon={SisterBrotherIcon}
                            title={trans('view_profile.sisters')}
                            requestKey={"Sisters"}
                            isHaveNoSiblings={profileData?.[additionalDetails]?.ishavenosiblings}
                            value={profileData?.[additionalDetails]?.sisters}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.sisters" : "her_additional_details?.sisters"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Sisters_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.[additionalDetails]?.marriedSisters != 'Not Specified' && profileData?.[additionalDetails]?.marriedSisters != "" && profileData?.[additionalDetails]?.marriedSisters != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={MarriedIcon}
                            title={trans('view_profile.married_sisters')}
                            value={
                                profileData?.[additionalDetails]?.marriedSisters
                            }
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={MarriedIcon}
                            title={trans('view_profile.married_sisters')}
                            requestKey={"Married sisters"}
                            isHaveNoSiblings={profileData?.[additionalDetails]?.ishavenosiblings}
                            value={profileData?.[additionalDetails]?.marriedSisters}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.marriedSisters" : "her_additional_details?.marriedSisters"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Married_Sisters_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.[additionalDetails]?.asset != 'Not Specified' && profileData?.[additionalDetails]?.asset != "" && profileData?.[additionalDetails]?.asset != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={"/Assets/Images/Group 514.svg"}
                            title={trans('view_profile.assets_owned_by_family')}
                            value={
                                profileData?.[additionalDetails]?.asset
                            }
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={"/Assets/Images/Group 499.svg"}
                            title={trans('view_profile.assets_owned_by_family')}
                            requestKey={"Assets owned"}
                            value={profileData?.[additionalDetails]?.asset}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.asset" : "her_additional_details?.asset"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Assets_Owned_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }

                        {profileData?.[additionalDetails]?.livesIn != 'Not Specified' && profileData?.[additionalDetails]?.livesIn != "" && profileData?.[additionalDetails]?.livesIn != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={"/Assets/Images/Group 505.svg"}
                            title={profileData?.profiledetails?.profileGender === "M" ? "Groom Lives in" : "Bride Lives in"}
                            value={profileData?.[additionalDetails]?.livesIn}
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={"/Assets/Images/Group 505.svg"}
                            title={profileData?.profiledetails?.profileGender === "M" ? "Groom Lives in" : "Bride Lives in"}
                            requestKey="groom_lives_in"
                            value={profileData?.[additionalDetails]?.livesIn}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.livesIn" : "her_additional_details?.livesIn"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_Lives_in_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        
                    </div>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8 pb-2'>
                        {profileData?.[additionalDetails]?.workState != 'Not Specified' && profileData?.[additionalDetails]?.workState != "" && profileData?.[additionalDetails]?.workState != "requested" ? ( 
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={Images.StateImage}
                            title="State"
                            value={profileData?.[additionalDetails]?.workState}
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:py-3  md:py-3 lg:gap-4 md:gap-4 gap-2 lg:space-x-8 pb-2flex md:p-2 py-1"}
                            icon={Images.StateImage}
                            title="State"
                            requestKey="state"
                            value={profileData?.[additionalDetails]?.workState}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.workState" : "her_additional_details?.workState"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_State_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                        {profileData?.[additionalDetails]?.workCity != 'Not Specified' && profileData?.[additionalDetails]?.workCity != "" && profileData?.[additionalDetails]?.workCity != "requested" ? (
                        <DetailsItem
                            className={"flex md:p-2 py-1"}
                            icon={Images.CityImage}
                            title={"City"}
                            value={
                                profileData?.[additionalDetails]?.workCity
                            }
                            style={{ marginRight: "20px" }}
                        />)
                        :
                        <RequestItem 
                            className={"flex md:p-2 py-1"}
                            icon={Images.CityImage}
                            title="City"
                            requestKey="city"
                            value={profileData?.[additionalDetails]?.workCity}
                            keyName={profileData?.profiledetails?.profileGender === "M" ? "his_additional_details?.workCity" : "her_additional_details?.workCity"}
                            page={page}
                            eventTrack={EventName.View_Profile_Not_Specified_field_request_City_Button}
                            style={{ marginRight: "20px" }}
                            profileMobileNumber={profileData?.ProfileMobileNumber}
                            postData={postData}
                        />
                        }
                    </div>
                </>
            </div>
        </div>
    )
}

const DetailsItem = (props) => {
    return (
        <div
            className="flex justify-start item-start"
        // className={props.className}
        >
            <div className="lg:w-[2rem] w-9 md:w-12 xl:w-12">
                <div className="relative flex justify-center item-center">
                    <div className="w-9 md:w-12 lg:w-[2rem] xl:w-12">
                        <img src="/Assets/Images/Ellipse 76.svg" className="w-9 md:w-12" alt="" />
                    </div>
                    <span className="absolute w-9 md:w-[2.6rem] mt-[5px] md:mt-[10px] lg:mt-[7px] xl:mt-[5px]">
                        <div className="flex justify-center item-center">
                            {/* <span className="flex justify-center pl-[0.4rem] md:pl-[0.65rem] lg:pl-[0.65rem]  absolute mt-[8px] md:mt-[10px] lg:mt-[13px]"> */}
                            <img className="w-[1.5rem] h-[1.5rem] lg:w-[1rem] lg:h-[1rem] xl:w-[1.5rem] xl:h-[2rem] " src={props.icon} alt="" />
                            {/* </span> */}
                        </div>
                    </span>
                </div>
            </div>
            <div className={`lg:pl-2 md:pl-2 pl-4   mt-0 md:mt-0 lg:mt-0 leading-[19px] md:leading-[23px] lg:leading-[17px] xl:leading-[26px]`}>
                <p className="text-[#575556] font-regular text-[12px] xl:text-[12px] lg:text-[10px] font-segoe-ui 2xl:break-words">
                    {props.title}
                </p>
                <span
                    className="text-[#575556] w-[106%] md:w-[100%] lg:w-[130%] lg:text-[10px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui"
                    style={{ display: 'inline-block' }}
                >
                    {props.value}
                </span>
            </div>

        </div>
    );
};
export { DetailsItem };


const HobbiesDetailsItem = (props) => {
    
    const { t:trans } = useTranslation();
    
    if (props.value === 'Not Specified') return null
    return (
        <div
            className="flex items-start justify-start py-1 pr-0 md:py-0 lg:py-0 md:pr-4 lg:pr-0 md:hob-detail"
        // className={props.className}
        >
            <div className="lg:w-[2rem] w-9 md:w-12 xl:w-12">
                <div className="relative flex justify-center item-center">

                    <span className="">
                        <div className=" flex w-[2.4rem] md:w-[3rem] lg:w-[3rem] justify-center item-center">
                            {/* <span className="flex justify-center pl-[0.4rem] md:pl-[0.65rem] lg:pl-[0.65rem]  absolute mt-[8px] md:mt-[10px] lg:mt-[13px]"> */}
                            <img className="w-[5rem] md:w-[3rem] lg:w-[4rem] md:h-[3rem] lg:h-[4rem] habits-image xl:w-[3rem] xl:h-[3rem]" src={props.icon} alt="" />
                            {/* </span> */}
                        </div>
                    </span>
                </div>
            </div>
            <div className="pl-4 md:pl-2 md:mt-0 lg:mt-0 leading-[19px] md:leading-[23px] lg:leading-[17px] xl:leading-[26px] habits-details md:hob-item">
                <p className="text-[#575556] font-regular text-[12px] xl:text-[12px] lg:text-[10px] font-segoe-ui break-words">
                    {props.title}
                </p>
                <span
                    className="text-[#575556] w-[17rem] md:w-[10rem] lg:w-[16rem] text-[12px] md:text-[12px] lg:text-[10px] xl:text-[12px]  break-words font-bold font-segoe-ui hob-width"
                    style={{ display: 'inline-block' }}
                >
                    {commaWithSpace(props?.value)}
                </span>
            </div>

        </div>
    );
};
export { HobbiesDetailsItem };

const RequestItem = (props) => {

    const { t: trans } = useTranslation();
    const [requestField, setRequestField] = useState("");
    const isRequested = props?.value == 'requested' ? true : false

    const { onClickTrack } = useAnalytics()

    let {requestTypeLoading,requestKey} = useSelector(state => ({
        requestTypeLoading: state.KalyanLite?.requestTypeLoading,
        requestKey: state.KalyanLite?.requestKey,
      }));  
    const [showAdminPopup, setShowAdminPopup] = useState(false)
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)

    console.log(requestKey,"requestKey"); 
   
    const dispatch = useDispatch();

    const [callRequest, setCallRequest] = useState(null);
    const {setIsNewRequest,isCallPopup,setIsCallPopup, setIsCall} = useContext(NavBarContext)

    const { viewprofileid,currentConversationId, storeResponseData, userData } = useSelector((state) => {
        return {
          viewprofileid: state.KalyanLite?.currentMatch?.data?.profileid,
          currentConversationId: state?.Chats?.conversationStatusData?.conversation_id,
          storeResponseData : state?.KalyanLite?.responseData,
          userData : state?.DashBoardAPI?.ProfileData?.data
        };
      });

    const sendFieldRequestAPI = async (conversationId) => {

        let data =  {
            "sender_id": getGroupAuthId(),
            "receiver_id": viewprofileid,
            "message_type_id": Object.keys(requestLookup).find(k => requestLookup[k] === props?.requestKey),
            "loginFrom": getDeviceInfo(),
            "isFrom": props?.page == "chat" ? "chat" : "viewprofile"
         }
         dispatch(profileSendMessage(data))
         props?.postData()
      };

    const storeRequestTypeApi = async () => {
        let request = {
            sender_profileid: getGroupAuthId(),
            receiver_profileid: viewprofileid,
            requesttypeid: Object.keys(requestLookup).find(k => requestLookup[k] === props?.requestKey),
            loginFrom: getDeviceInfo(),
            requestsentvia:"viewprofile",
            keyName : props?.keyName,
            fromPage : "viewprofile"        
        }
        await dispatch(storeRequestType(request)) 
    }

    const handleRequestClick = (title) =>{
        if(getIsLoginFromAdmin()){
            setShowAdminPopup(true)
        }
        else{
            setRequestField(title)
            storeRequestTypeApi()
            sendFieldRequestAPI()
            onClickTrack(props?.eventTrack);
        }
    }

    console.log(currentConversationId,"looking",localStorage.getItem(Constants.loginLocalStorageKeys.loginId),viewprofileid);
    useEffect(() => {
        if (storeResponseData?.status === "Success") {
            setCallRequest(storeResponseData?.data?.Popup);
        }
    }, [storeResponseData]);

    useEffect(()=>{
        setCallRequest(null)
    },[])

    useEffect(()=>{
        if(callRequest == 'Y'){
            setIsNewRequest(true)
        }
        if(userData?.isPaid == "Y" && callRequest == 'Y'){
            setIsCallPopup('close');
        }
    },[callRequest != null])

    return (
        <div
            className="flex justify-start item-start"
        >
            {showAdminPopup && <CommonProfilePopup
                close={() => setShowAdminPopup(false)}
                title={"Alert!"}
                content={"You cannot send message/request from Admin"} />
            }

            {showSuccessPopup && <ValidationNotificationPopup isFrom={"viewProfile"} message={"Request Send Successfully"} onClose={()=> setShowSuccessPopup(false)} />}

            <div className={`${props?.title === trans('view_profile.about_me') ? "hidden" : ""} lg:w-[2rem] w-9 md:w-12 xl:w-12`}>
                <div className="relative flex justify-center item-center">
                    <div className="w-9 md:w-12 lg:w-[2rem] xl:w-12">
                        <img src="/Assets/Images/Ellipse 76.svg" className="w-9 md:w-12" alt="" />
                    </div>
                    <span className="absolute w-9 md:w-[2.6rem] mt-[5px] md:mt-[10px] lg:mt-[7px] xl:mt-[5px]">
                        <div className="flex justify-center item-center">
                            {/* <span className="flex justify-center pl-[0.4rem] md:pl-[0.65rem] lg:pl-[0.65rem]  absolute mt-[8px] md:mt-[10px] lg:mt-[13px]"> */}
                            <img className="w-[1.5rem] h-[1.5rem] lg:w-[1rem] lg:h-[1rem] xl:w-[1.5rem] xl:h-[2rem] " src={props.icon} alt="" />
                            {/* </span> */}
                        </div>
                    </span>
                </div>
            </div>
            <div className={`lg:pl-2 md:pl-2 pl-4 ${props?.type=="profilechat" ? "mt-0 md:mt-[8rem] lg:mt-0": "mt-0 md:mt-0 lg:mt-0"}  leading-[19px] md:leading-[23px] lg:leading-[17px] xl:leading-[26px]`}>
                <p className={`${props?.title === trans('view_profile.about_me') ? "pb-2 md:px-0 text-[#1A1A1A] text-[20px] tracking-wide font-Poppins font-semibold pt-[1rem] md:pt-[5rem] lg:pt-0" : "text-[#575556] font-regular text-[12px] xl:text-[12px] lg:text-[10px] font-segoe-ui break-words"}`}>
                    {props.title}
                </p>
                {requestTypeLoading && props?.keyName == requestKey ? 
                    <img alt="loader" src={LoaderGif} className={`w-10 h-10`} />
                :
                props?.isHaveNoSiblings == "Y" ?
                <span
                    className={`text-start text-[#575556] w-[106%] md:w-[100%] lg:w-[130%] lg:text-[10px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui`}
                    style={{ display: 'inline-block' }}
                >
                    --
                </span>
                :
                // (isCallPopup == 'close' && isRequested) ?
                isRequested ? 
                <button
                    className='text-start w-[106%] md:w-[100%] lg:w-[130%] lg:text-[10px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui'
                    style={{ display: 'inline-block' }}
                >
                    <div className='md:hidden'>
                        <div className='flex-col'>
                            <span className="text-[#575556] cursor-not-allowed underline underline-offset-2" >
                                {trans('view_profile.requested')} |
                            </span> 
                            <span className='text-[#D10A11] underline underline-offset-2 cursor-pointer whitespace-nowrap' onClick={()=> setIsCall(true)}> Call {props.profileMobileNumber}</span>
                        </div>
                    </div>
                    <div className='md:block hidden'>
                        <div className='flex-col'>
                            <p className="text-[#575556] cursor-not-allowed underline underline-offset-2" >
                                {trans('view_profile.requested')} |
                            </p> 
                            <p className='text-[#D10A11] underline underline-offset-2 cursor-pointer' onClick={()=> setIsCall(true)}> Call {props.profileMobileNumber}</p>
                        </div>
                    </div>
                </button> 
                :
                <button
                    onClick={()=>handleRequestClick(props.title)}
                    disabled={isRequested}
                    className={`text-start ${isRequested ? "text-[#575556] cursor-not-allowed" : "text-[#D10A11] cursor-pointer "}  underline underline-offset-2 w-[106%] md:w-[100%] lg:w-[130%] lg:text-[10px] xl:text-[12px] text-[12px] break-words font-bold font-segoe-ui`}
                    style={{ display: 'inline-block' }}
                >
                    {isRequested ? trans('view_profile.requested') : trans('view_profile.request') }
                </button>
                }
            </div>

        </div>
    );
};
export { RequestItem };

export default BasicDetails
import React, { useState, useEffect, useContext } from "react";
import { EditButton, SaveCancelButton } from "../EditProfileUtills";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST } from "../../../Services/api_services";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import config from "../../../config";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";
import { getDeviceInfo, getGroupAuthId } from "../../Kalyanlite/utils";
import { useTranslation } from "react-i18next";
import { DescriptionPopup } from "../../CommonPopup";
import { toast } from "react-toastify";
const DescriptionForm = (props) => {
 
  const { t:trans } = useTranslation();
  
  const { onClickTrack } = useAnalytics()
  const [isShowEdit, setEditView] = useState(true);
  // api/editdescriptioninfosave
  const { loginDetail, logout } = useContext(LoginContext);
  const [descriptionPopup, setDescriptionPopup] = useState(false);
  const [editMyProfielData,setEditMyProfielData] = useState([]);
  const [isDescriptionEdited,setIsDescriptionEdited] = useState(false);
  const [toastmsg,setToastmsg]=useState(false)
  const [disablesave,setDisableSave] = useState(true)

  const formik = useFormik({
    initialValues: {
      moreInfo:  editMyProfielData?.moreInfo != undefined ? editMyProfielData?.moreInfo : props?.data?.moreInfo ? props?.data?.moreInfo : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      moreInfo: Yup.string(),
    }),

    onSubmit:async (values) => {
      await apiCallEditProfile(values);
    },
  });

  useEffect(() => {}, [props.data]);
  useEffect(() => {}, [formik.values, props?.data?.moreInfo]);
  const SubmitCall = (values) => {
    //console.log(values);
  };

  const apiEditProfile = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);
     
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallEditProfile = async (values) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      more_info: values.moreInfo,
      ...(values.moreInfo != "" && {descriptionrequesttypeid:43}),
      requestfulfilledfrom:getDeviceInfo(),
      requestfulfilledvia:props?.isFrom=="addDiscription" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
      localStorage.getItem("profiledot") == "true" ?"profile_preview":"chat",
      recieverprofileid:getGroupAuthId(),
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      "editFrom": props?.isFrom=="addDiscription" && localStorage.getItem("profiledot") != "true" ?"profile_preview":props?.isFrom=="editmyprofile" ?"Myprofile" :
      localStorage.getItem("profiledot") == "true" ?"profile_preview_three_dots":"Myprofile",
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editdescriptioninfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        // alert("Description saved");
        props.callBackReload()
        setDisableSave(true)

        if(props?.isFrom=="addDiscription"){
          toast.success(
            <div className='flex items-center'>
              {"Detail added Successfully. The added detail will reflect in your profile after verification by Kalyan Matrimony."}
            </div>,
            
          );
}
        // setEditView(false)
        apiEditProfile()
        onClickTrack(EventName.profileDescriptionSaveButton , {[EventKeys.profileDescription]:values });
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const onInfoChange = (e)=>{
    let text = e.target.value??"";

    text = text.replace(/  +/g, " ")

    formik.setFieldValue('moreInfo',text)
  }

 useEffect(()=>{
  if(isDescriptionEdited && !descriptionPopup){
    apiEditProfile() 
  }
  
},[isDescriptionEdited])

  return (
    <div>
      {descriptionPopup && 
      props?.isFrom=="addDiscription"?
        <DescriptionPopup 
          onClose={() =>setDescriptionPopup(false)}
          moreInfo={props?.data?.moreInfo}
          descriptionEdit={props?.data?.descriptionEdit}
          isFrom="addDiscription"
          apiEditProfile={props?.apiCallEditProfile} // Note: singular "Profile" here
          data={props?.data}
          setIsDescriptionEdited={setIsDescriptionEdited}
         
        />
        :descriptionPopup && 
        <DescriptionPopup 
          onClose={() =>setDescriptionPopup(false)}
          moreInfo={props?.data?.moreInfo}
          descriptionEdit={props?.data?.descriptionEdit}
          isFrom="viewprofile"
          data={props?.data}
          apiEditProfile={props?.apiCallEditProfile}
          setIsDescriptionEdited={setIsDescriptionEdited}

          
        />
      }
      {isShowEdit ? (
        <></>
      ) : (
        <>
          {" "}
          <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />
          </ActionRestrict>
          <p className={`mb-4 w-full mt-2 px-[1rem] md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold break-words ${props?.data?.moreInfo ? "" : "text-center"}`}>
            {props?.data?.moreInfo ? props?.data?.moreInfo : trans('my_profile.not_specified')}
          </p>
        </>
      )}

      {props?.isFrom=="addDiscription" ?
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <>
          {console.log(props.editfromadmin,'props.editfromadmin')}
            <div className={`  ${props?.isFrom=="addDiscription"?"m-[0.3rem]":"m-[1rem]"} `}>
              <p className="  ">
                <textarea
                  id="moreInfo"
                  rows="4"
                  className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-[3px] border border-[#8F8F8F] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                  placeholder=""
                  name="moreInfo"
                  value={formik?.values?.moreInfo?.replace(/\s\s+/g, ' ')}
                  onChange={onInfoChange}
                  disabled={props.editfromadmin == 'Y'}
                  onClick={() =>{
                    // setDisableSave(false)
                    setDescriptionPopup(true)
                    setIsDescriptionEdited(false)
                  }}
                ></textarea>
              </p>
            </div>
            <SaveCancelButton
              submitStatus={formik.isSubmitting}

              saveCallBack={(e) => {
                if (!disablesave) {
                  e.preventDefault();
                  setToastmsg(true)
                  console.log("Toastmsg", toastmsg); // Check if the state is being set correctly
    
                }
    
              }}
              
              cancelCallback={() => {
                formik.resetForm()
                setDisableSave(true)
                onClickTrack(EventName.profileDescriptionCancelButton)
              }}
              isMyProfileDescription= {props?.isMyProfileDescription}
              editfromadmin = {props.editfromadmin} 
              setDisableSave={setDisableSave}
             disablesave={disablesave}
             isFrom="MyProfile"
              isFroms="profileaddDetails"
            />
          </>
        ) : (
          <></>
        )}
      </form>
      :
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <>
          {console.log(props.editfromadmin,'props.editfromadmin')}
            <div className=" m-[1rem] ">
              <p className="  ">
                <textarea
                  id="moreInfo"
                  rows="4"
                  className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-[3px] border border-[#8F8F8F] ${props.editfromadmin == 'Y' ? 'cursor-not-allowed' : ''}`}
                  placeholder=""
                  name="moreInfo"
                  value={formik?.values?.moreInfo?.replace(/\s\s+/g, ' ')}
                  onChange={onInfoChange}
                  disabled={props.editfromadmin == 'Y'}
                  onClick={() =>{
                    setDescriptionPopup(true)
                    setIsDescriptionEdited(false)
                  }}
                ></textarea>
              </p>
            </div>
            <SaveCancelButton
              submitStatus={formik.isSubmitting}
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                formik.resetForm()
                onClickTrack(EventName.profileDescriptionCancelButton)
              }}
              isMyProfileDescription= {props?.isMyProfileDescription}
              editfromadmin = {props.editfromadmin} 
              isFrom="MyProfile"
            />
          </>
        ) : (
          <></>
        )}
      </form>}
    </div>
  );
};

export default DescriptionForm;
